import React from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector, useDispatch} from 'react-redux'
import {palette} from '../assets/common'
import {DetailLabel, Content, Flexed} from '../styles/shared'

const TokenDetails = ({tokenDetails}) => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    return tokenDetails ? (
        <>
            <Col isDarkTheme={isDarkTheme}>
                <Flex direction="row" justify="space-between" isDarkTheme={isDarkTheme}>
                    <DetailLabel isDarkTheme={isDarkTheme}>Name</DetailLabel>
                    <Content isDarkTheme={isDarkTheme}>{tokenDetails?.name}</Content>
                </Flex>
            </Col>
            <Col isDarkTheme={isDarkTheme}>
                <Flex direction="row" justify="space-between" isDarkTheme={isDarkTheme}>
                    <DetailLabel isDarkTheme={isDarkTheme}>Symbol</DetailLabel>
                    <Content isDarkTheme={isDarkTheme}>{tokenDetails?.symbol}</Content>
                </Flex>
            </Col>
            <Col isDarkTheme={isDarkTheme}>
                <Flex direction="row" justify="space-between" isDarkTheme={isDarkTheme}>
                    <DetailLabel isDarkTheme={isDarkTheme}>Decimals</DetailLabel>
                    <Content isDarkTheme={isDarkTheme}>{tokenDetails?.decimals}</Content>
                </Flex>
            </Col>
        </>
    ) : null
}

const Flex = styled(Flexed)`
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
`

export default TokenDetails
