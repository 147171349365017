import React from 'react'
import { useCountdown } from '../hooks'
import DateTimeDisplay from './DateTimeDisplay'
import { useSelector } from 'react-redux'

const ExpiredNotice = () => {
    return (
        <div className="expired-notice">
            <span>Sale Closed</span>
        </div>
    )
}

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    return (
        <div className="show-counter">
            <div target="_blank" rel="noopener noreferrer" className={isDarkTheme ? 'countdown-link-dark-mode' : 'countdown-link'}>
                <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
                <p>:</p>
                <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
                <p>:</p>
                <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
                <p>:</p>
                <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
            </div>
        </div>
    )
}

const CountdownTimer = ({ targetDate, isLaunchpadClosed, updateList }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate)
    if (days + hours + minutes + seconds <= 0 || isLaunchpadClosed) {
        if (updateList !== undefined) {
            updateList()
        }
        return null
    } else {
        return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
    }
}
export default CountdownTimer
