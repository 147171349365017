import {NetworksTypes} from '../utils'

export const AirdropFactoryContract = {
    contractId: (chainId) => {
        if (chainId == NetworksTypes.EthereumMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.EthereumTestNet.chainId) {
            return '0xc3f87CfC2b285C57435Be8bF7de8b1CcDfa7c473'
        } else if (chainId == NetworksTypes.BinanceMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.BinanceTestNet.chainId) {
            return '0x7dAfe5468C7e2Fb38369687C1A8b06dd6abFEf29'
        } else if (chainId == NetworksTypes.PolygonMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.PolygonTestNet.chainId) {
            return '0xc3f87CfC2b285C57435Be8bF7de8b1CcDfa7c473'
        }
    },
    abi: [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'airdropCreator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'airdropAddress',
                    type: 'address'
                }
            ],
            name: 'airdropCreated',
            type: 'event'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenAddress',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'quantity',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'distributionTimeUnix',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct SSSairdropfactory.airdropinfo',
                    name: '_airdropinfoo',
                    type: 'tuple'
                }
            ],
            name: 'createAirdrop',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'Id',
                    type: 'uint256'
                }
            ],
            name: 'getairdropAddress',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getairdropCount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
}
