import {NetworksTypes} from '../utils'

export const LockFactoryContract = {
    contractId: (chainId) => {
        if (chainId == NetworksTypes.EthereumMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.EthereumTestNet.chainId) {
            return '0x243FCAC7284CeB21f85152a9EED24D1270E0976a'
        } else if (chainId == NetworksTypes.BinanceMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.BinanceTestNet.chainId) {
            return '0x0CA49f32E2f5f793a1E88e53146f5bae3005355d'
        } else if (chainId == NetworksTypes.PolygonMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.PolygonTestNet.chainId) {
            return '0xEfF080BcAA086c188fa3f60c9569c727740235a2'
        }
    },
    abi: [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: '_lockCreator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: '_lockAddress',
                    type: 'address'
                }
            ],
            name: 'lockCreated',
            type: 'event'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenAddress',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'beneficiary',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'unlockTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'quantity',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct SSSlockfactory.info',
                    name: '_infoo',
                    type: 'tuple'
                }
            ],
            name: 'createLock',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'Id',
                    type: 'uint256'
                }
            ],
            name: 'getlockAddress',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getlockCount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
}
