import React, { useState, useEffect } from 'react'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../../assets/common'
import { Label, Info, Alblur, Para, CreateTokenLabel } from '../../styles/shared'
import styled from 'styled-components'
import { Spacer, InputText, TextArea, Flexed, LoadingPanel, LoadingPanelContent, StepperHeading, InfoIcon, RadioBox, CheckLabel, Step } from '../../styles/shared'
import Button from '../../components/Button'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import { ethers } from 'ethers'
import moment from 'moment'
import { ERC20 } from '../../contracts/ERC20'
import { saveUser, openMainPage } from '../../actions/authActions'
import { api } from '../../config/index'
import { token_regex, url_regex, img_url_regex, startsWithNumber } from '../../utils/regex'
import { AirdropFactoryContract } from '../../contracts/AirdropFactoryContract'
import { AirdropPrivateFactoryContract } from '../../contracts/AirdropPrivateFactoryContract'
import NetworksDropdown from '../../components/NetworksDropdown'
import { DocumentLinks, fetchTokenDetails, showConnectionError, showError, showSuccess, preventNegativeValues, parseTo18 } from '../../utils/index'
import TokenDetails from '../../components/TokenDetails'

import axios from 'axios'
import AirdropDetails from '../../components/AirdropDetails'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { timeAfterMinutes } from '../../utils/timeAfterMinutes'
import { DatetimePicker } from '../../components/DatePicker'
import CreateTokenModal from '../../components/Modals/CreateTokenModal'

const CreateAirdrop = ({ saveUserHandler }) => {
    const _dispatch = useDispatch()
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    let signer = useSelector((state) => state.auth.signer)
    let user = useSelector((state) => state.auth.user)
    const airdropFactoryContract = useSelector((state) => state.auth.airdropFactoryContract)
    const airdropPrivateFactoryContract = useSelector((state) => state.auth.airdropPrivateFactoryContract)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)
    const [airdropInfo, setAirdropInfo] = useState('')

    const [tokenAddress, setTokenAddress] = useState('')
    const [tokenAddressError, setTokenAddressError] = useState(false)
    const [loading, setLoading] = useState(false)

    const [whitelist, setWhitelist] = useState([])
    const [whiteListString, setWhitelistString] = useState('')
    const [whitelistError, setWhitelistError] = useState(false)
    const [saleTitle, setSaleTitle] = useState('')
    const [saleTitleError, setSaleTitleError] = useState(false)
    const [tokenQuantity, setTokenQuantity] = useState('')
    const [tokenQuantityError, settokenQuantityError] = useState(false)
    const [distributionTime, setDistributionTime] = useState(new Date())
    const [distributionTimeError, setDistributionTimeError] = useState(false)
    const [logoUrl, setLogoUrl] = useState('')
    const [logoUrlError, setLogoUrlError] = useState('')
    const [discord, setDiscord] = useState('')
    const [twitter, setTwitter] = useState('')
    const [website, setWebsite] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState(false)
    const [telegramLink, setTelegramLink] = useState('')
    const [telegramLinkError, setTelegramLinkError] = useState(false)
    const [discordError, setDiscordError] = useState(false)
    const [twitterError, setTwitterError] = useState(false)
    const [websiteError, setWebsiteError] = useState(false)
    const [tokenDetails, setTokenDetails] = useState(null)
    const [isPrivateAirdrop, setIsPrivateAirdrop] = useState(false)
    const [isValidationCompleted, setIsValidationCompleted] = useState(true) //todo..by default it is set to true

    const [isBegin, setIsBegin] = useState(true)
    const [onFinish, setOnFinish] = useState(false)
    const [activeStep, setActiveStep] = useState(1)
    const [stepOne, setStepOne] = useState(true)
    const [stepTwo, setStepTwo] = useState(false)

    const [openCreateTokenModal, setOpenCreateTokenModal] = useState(false)

    useEffect(() => {
        if (user != null) {
            if (connectedNetwork) {
                goToStep(1)
                getTokenDetails(tokenAddress)
                setTokenAddressError(false)
            } else {
                setTokenDetails(null)
            }
        } else {
            goToStep(1)
            setTokenDetails(null)
        }
    }, [user, connectedNetwork])

    const getTokenDetails = async (address) => {
        if (connectedNetwork && address) {
            setLoading(true)
            const _tokenDetails = await fetchTokenDetails(address, signer, user.address)
            setTokenDetails(_tokenDetails)
            setLoading(false)
        }
    }

    const getTokensApproval = async () => {
        try {
            const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
            let tokenApproval = null
            if (isPrivateAirdrop) {
                tokenApproval = await erc20.approve(AirdropPrivateFactoryContract.contractId(connectedNetwork.chainId), parseTo18(tokenQuantity, erc20))
            } else {
                tokenApproval = await erc20.approve(AirdropFactoryContract.contractId(connectedNetwork.chainId), parseTo18(tokenQuantity, erc20))
            }
            await tokenApproval.wait()
            return tokenApproval
        } catch (error) {
            console.log(error)
            showError('Please approve tokens')
            return false
        }
    }

    const createAirdrop = async () => {
        if (!airdropFactoryContract) {
            showConnectionError()
            return
        }
        setLoading(true)
        let addresslist = []
        let quantityList = []
        const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
        let decimals = await erc20.decimals()

        whitelist?.map((i) => addresslist.push(i?.address))
        whitelist?.map((i) => quantityList.push(ethers.utils.parseUnits(i?.value.toString(), decimals)))
        const isTokenApproved = await getTokensApproval()
        if (isTokenApproved) {
            try {
                let tuple = {
                    tokenAddress: tokenAddress,
                    quantity: ethers.utils.parseUnits(tokenQuantity.toString(), decimals),
                    distributionTimeUnix: moment(distributionTime).unix().toString()
                }
                let _createAirdrop = null
                if (isPrivateAirdrop) {
                    tuple.users = addresslist
                    tuple.userQuantity = quantityList
                    _createAirdrop = await airdropPrivateFactoryContract.createAirdrop(tuple, { value: parseTo18('0.01') }) //todo..check decimals here
                } else {
                    _createAirdrop = await airdropFactoryContract.createAirdrop(tuple)
                }
                const response = await _createAirdrop.wait()
                if (response) {
                    const airdropAddress = response?.events[2]?.args != undefined ? response?.events[2]?.args[1] : response.events[3].args[1]
                    let _airdropInfo = {}
                    _airdropInfo.airdropAddress = airdropAddress
                    _airdropInfo.isPrivate = isPrivateAirdrop
                    _airdropInfo.chainId = connectedNetwork?.chainId
                    setAirdropInfo(_airdropInfo)
                    axios
                        .post(
                            `${api}/air_drop/add`,
                            {
                                userAddress: user.address,
                                tokenAddress: tuple?.tokenAddress,
                                airdropAddress: airdropAddress,
                                title: saleTitle,
                                logoUrl: logoUrl,
                                website: website,
                                twitter: twitter,
                                telegram: telegramLink,
                                discord: discord,
                                description: description,
                                chainId: connectedNetwork.chainId,
                                isPrivate: isPrivateAirdrop,
                                tokenName: tokenDetails?.name,
                                tokenSymbol: tokenDetails?.symbol,
                                quantity: tokenQuantity,
                                distributionTime: tuple?.distributionTimeUnix,
                                users: isPrivateAirdrop ? tuple?.users : [],
                                userQuantity: isPrivateAirdrop ? tuple.userQuantity : []
                            },
                            {
                                headers: {
                                    authorization: `bearer ${user.token}`
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.status) {
                                user.airdrops.push(response.data.data)
                                saveUserHandler(user)
                                resetCreateAirdrop()
                                showSuccess('Airdrop created successfully')
                                _dispatch(openMainPage(false))
                            }
                            setLoading(false)
                        })
                        .catch(function (error) {
                            setLoading(false)
                            showError(error.message)
                        })
                } else {
                    setLoading(false)
                    showError('Airdrop creation failed')
                }
            } catch (error) {
                console.log(error)
                setLoading(false)
                showError(error.reason)
            }
        } else {
            setLoading(false)
        }
    }

    //todo..move this validateImageURl to social validation and remove its default value set at line 72

    function validateImageURl(url) {
        if (url) {
            if (!img_url_regex.test(url.trim())) {
                setIsValidationCompleted(true)
                setLogoUrlError('Only supported image extensions are allowed')
            } else if (url?.length > 255) {
                setIsValidationCompleted(true)
                setLogoUrlError('Maximum 255 characters allowed')
            } else {
                let img = new Image()
                img.src = url
                img.onload = function () {
                    setLogoUrl(url)
                    setIsValidationCompleted(true)
                    setLogoUrlError(false)
                }
                img.onerror = function () {
                    setLogoUrlError('Only supported image extensions are allowed')
                    setIsValidationCompleted(true)
                }
            }
        } else {
            setIsValidationCompleted(true) //todo..check this logic as well
            // setLogoUrlError(false)
            // setLogoUrl('')
        }
    }

    const socialValiation = () => {
        let _isValid = true
        // if (logoUrl) {
        //     // when used inside socialValiation(), does not wait for processing image src
        //     setIsValidationCompleted(false)
        //     if (!img_url_regex.test(logoUrl.trim())) {
        //         _isValid = false
        //         setLogoUrlError('Only supported image extensions are allowed')
        //     } else if (logoUrl?.length > 255) {
        //         _isValid = false
        //         setLogoUrlError('Maximum 255 characters allowed')
        //     } else {
        //         let img = new Image()
        //         img.src = logoUrl
        //         img.onload = function () {
        //             // setLogoUrl(logoUrl)
        //             setIsValidationCompleted(true)
        //             // setLogoUrlError(false)
        //         }
        //         img.onerror = function () {
        //             _isValid = false
        //             setLogoUrlError('Only supported image extensions are allowed')
        //             setIsValidationCompleted(true)
        //         }
        //     }
        // } else {
        //     setIsValidationCompleted(true) //todo..check this logic as well
        //     // setLogoUrlError(false)
        //     // setLogoUrl('')
        // }

        if (!saleTitle) {
            _isValid = false
            setSaleTitleError('Sale title must start with a letter, minimum of three characters and special character not allowed')
        } else if (saleTitle?.length < 3 || startsWithNumber(saleTitle)) {
            _isValid = false
            setSaleTitleError('Sale title must start with a letter, minimum of three characters and special character not allowed')
        } else {
            setSaleTitleError(false)
        }
        if (moment(distributionTime, 'DD/MM/YYYY HH:mm').diff(moment(timeAfterMinutes(5), 'DD/MM/YYYY HH:mm')) < 0) {
            _isValid = false
            setDistributionTimeError(`Distribution time must be greater than ${timeAfterMinutes(5, true)}. For safe side we need atleast 5 minutes for the transaction`)
        } else {
            setDistributionTimeError(false)
        }
        if (!isPrivateAirdrop) {
            if (!tokenQuantity) {
                _isValid = false
                settokenQuantityError('Token quantity is required')
                // } else if ((tokenQuantity.indexOf('.') == -1) == false && tokenQuantity !== '') {
                //     _isValid = false
                //     settokenQuantityError('Invalid token quantity')
            } else if (parseFloat(tokenQuantity) == parseFloat(0)) {
                _isValid = false
                settokenQuantityError('Invalid token quantity')
            } else {
                settokenQuantityError(false)
            }
        }
        if (website) {
            if (!url_regex.test(website)) {
                _isValid = false
                setWebsiteError('Invalid website url')
            } else if (website?.length > 255) {
                setWebsiteError('Maximum 255 characters allowed')
                _isValid = false
            } else {
                setWebsiteError(false)
            }
        } else {
            setWebsiteError(false)
        }

        if (twitter) {
            if (!url_regex.test(twitter)) {
                _isValid = false
                setTwitterError('Invalid twitter url')
            } else if (twitter?.length > 255) {
                setTwitterError('Maximum 255 characters allowed')
                _isValid = false
            } else {
                setTwitterError(false)
            }
        } else {
            setTwitterError(false)
        }

        if (telegramLink) {
            if (!url_regex.test(telegramLink)) {
                _isValid = false
                setTelegramLinkError('Invalid telegram url')
            } else if (telegramLink?.length > 255) {
                setTelegramLinkError('Maximum 255 characters allowed')
                _isValid = false
            } else {
                setTelegramLinkError(false)
            }
        } else {
            setTelegramLinkError(false)
        }

        if (discord) {
            if (!url_regex.test(discord)) {
                _isValid = false
                setDiscordError('Invalid discord url')
            } else if (discord?.length > 255) {
                setDiscordError('Maximum 255 characters allowed')
                _isValid = false
            } else {
                setDiscordError(false)
            }
        } else {
            setDiscordError(false)
        }

        if (isPrivateAirdrop) {
            if (whitelist.length == 0 && whiteListString?.length < 43) {
                _isValid = false
                setWhitelistError('Invalid address or quantity')
            } else if (whitelist.length == 0) {
                _isValid = false
                setWhitelistError('Atleast one whitelist address is required')
            }
        }

        if (description?.length > 0 && description?.length < 128) {
            _isValid = false
            setDescriptionError('Description must be at least 128 characters long')
        } else {
            setDescriptionError(false)
        }
        return _isValid
    }

    const goToStep = (index) => {
        if (index === 1) {
            stepOne === false ? setStepOne(true) : ''
        } else {
            setStepOne(false)
        }
        if (index === 2) {
            stepTwo === false ? setStepTwo(true) : ''
        } else {
            setStepTwo(false)
        }
    }

    const scrollToStepFirst = () => {
        setTimeout(scrollFirst, 100)
    }

    const scrollToStepSecond = () => {
        setTimeout(scrollSecond, 100)
    }

    const scrollFirst = () => {
        document.getElementById('firstStep').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
    }

    const scrollSecond = () => {
        document.getElementById('secondStep').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
    }

    const completeFirstStep = () => {
        if (!connectedNetwork) {
            setTokenAddressError('Metamask is not connected')
        } else if (connectedNetwork && tokenAddress?.length === 0) {
            setTokenAddressError('Token address is required')
        } else if (!tokenDetails) {
            setTokenAddressError(`Token not found on ${connectedNetwork.title}`)
        } else if (!token_regex.test(tokenAddress)) {
            setTokenAddressError('Invalid token address')
        } else {
            setTokenAddressError(false)
            setStepOne(false)
            setStepTwo(true)
            scrollToStepSecond()
            goToStep(2)
            setActiveStep(2)
        }

        // if (connectedNetwork && tokenAddress?.length === 0) {
        //     setTokenAddressError(true)
        // } else if (tokenAddress.trim() != '' && token_regex.test(tokenAddress) && tokenDetails) {
        //     setTokenAddressError(false)
        //     setStepOne(false)
        //     setStepTwo(true)
        //     scrollToStepSecond()
        //     goToStep(2)
        //     setActiveStep(2)
        // } else {
        //     setTokenAddressError(true)
        // }
    }

    const addWhiteListAddress = (addVal, keyPressed) => {
        if (addVal?.length <= 43 && keyPressed === 'Enter') {
            setWhitelistError('Invalid address or quantity')
            return
        }
        setWhitelistString(addVal)
        let _whiteList = []
        if (addVal?.includes(',')) {
            _whiteList = addVal.split(',')
        } else if (keyPressed !== null && keyPressed === 'Enter') {
            _whiteList.push(addVal)
        }
        _whiteList = _whiteList.map((_item) => {
            if (_item?.charAt(42) === ':') {
                let _tokenAddress = _item.slice(0, 42)
                let _value = parseInt(_item?.slice(43, _item?.length))
                if (token_regex.test(_tokenAddress) === true) {
                    if (parseInt(_value) > 0) {
                        if (whitelist.find((x) => x.address?.substring(0, 42) === _item?.substring(0, 42))) {
                            setWhitelistError('Address already added')
                        } else {
                            setWhitelistError(false)
                            setWhitelistString('')
                            return { address: _tokenAddress, isValid: true, value: _value }
                        }
                    } else {
                        setWhitelistError('Invalid quantity')
                        return { address: _tokenAddress, isValid: false, value: _value }
                    }
                } else {
                    setWhitelistError('Invalid address')
                    return { address: _tokenAddress, isValid: false, value: _value }
                }
            } else {
                return { address: _item?.substring(0, 42), isValid: false, value: parseInt(_item?.slice(43, _item?.length)) }
            }
        })
        whitelist.map((_item) => _whiteList.push(_item))
        _whiteList = _whiteList.filter((i) => i?.isValid === true)
        _whiteList = _whiteList?.filter((value, index, self) => index === self.findIndex((t) => t.address === value.address))
        let _totalValue = 0
        _whiteList?.map((i) => (_totalValue = _totalValue + i?.value))
        setTokenQuantity(_totalValue)
        setWhitelist(_whiteList)
    }

    const removeWhiteListAddress = (removeVal) => {
        if (whitelist?.length > 0) {
            let _whitelist = whitelist.filter((_item) => {
                return _item !== removeVal
            })
            setWhitelist(_whitelist)
        }
    }

    const resetCreateAirdrop = () => {
        setActiveStep(1)
        setStepOne(true)
        setStepTwo(false)
        setTokenAddress('')
        getTokenDetails(null)
        setTokenDetails(null)
        setIsPrivateAirdrop(false)
        setWhitelist([])
        setWhitelistString('')
        setSaleTitle('')
        setTokenQuantity(0)
        setDistributionTime(new Date())
        setLogoUrl('')
        setWebsite('')
        setTwitter('')
        setTelegramLink('')
        setDiscord('')
        setDescription('')
    }

    return (
        <>
            {isMianPageOpen ? (
                <Wrapper>
                    <CustomRow>
                        <Col>
                            <Flexed direction="row" justify="flex-end">
                                <a target="_blank" href={DocumentLinks('createAirDrop')}>
                                    <InfoIconAlign isDarkTheme={isDarkTheme} />
                                </a>
                            </Flexed>
                        </Col>
                        <Container>
                            <StapperRow>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List onClick={() => (isBegin ? goToStep(1) : null)}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 0 || activeStep > 4}>
                                                    1
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 0 || activeStep > 4}>
                                                    Token & Network
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Enter the token address</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 2 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List
                                        onClick={() => {
                                            isBegin ? completeFirstStep() : null
                                        }}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4}>
                                                    2
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4}>
                                                    Social Info
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Let the crypto family know who you are</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                            </StapperRow>

                            <Row>
                                <Col>
                                    <Stepper>
                                        {stepOne && (
                                            <Item id="firstStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <Col>
                                                                <Flexed direction="row" align="center" justify="space-between">
                                                                    <Label isDarkTheme={isDarkTheme}>
                                                                        Token Address <span>*</span>
                                                                    </Label>
                                                                    <CreateTokenLabel isDarkTheme={isDarkTheme} onClick={() => (!connectedNetwork ? showConnectionError() : setOpenCreateTokenModal(true))}>
                                                                        Create token
                                                                    </CreateTokenLabel>
                                                                </Flexed>
                                                                <InputText
                                                                    value={tokenAddress}
                                                                    onChange={(e) => {
                                                                        setTokenAddress(e.target.value.toLowerCase())
                                                                        getTokenDetails(e.target.value.toLowerCase())
                                                                        setTokenAddressError(false)
                                                                    }}
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                />
                                                                {tokenAddressError && <Alblur>{tokenAddressError}</Alblur>}
                                                                <Info isDarkTheme={isDarkTheme}>Airdrop Creation Fee: 3.72 BNB</Info>
                                                            </Col>
                                                            <TokenDetails tokenDetails={tokenDetails} />
                                                            <Col>
                                                                <Spacer height={2} />
                                                                <Label isDarkTheme={isDarkTheme}>Select Airdrop Type</Label>
                                                            </Col>
                                                            <Col>
                                                                <Flexed margin="0.5rem 0rem 0.5rem 0rem" direction="row" align="center">
                                                                    <RadioBox
                                                                        isDarkTheme={isDarkTheme}
                                                                        id="public"
                                                                        name="type"
                                                                        type="radio"
                                                                        checked={isPrivateAirdrop == false}
                                                                        onChange={(e) => {
                                                                            setIsPrivateAirdrop(false)
                                                                        }}
                                                                    />
                                                                    <CheckLabel htmlFor="public" isDarkTheme={isDarkTheme}>
                                                                        Public
                                                                    </CheckLabel>
                                                                </Flexed>
                                                                <Flexed direction="row" align="center">
                                                                    <RadioBox
                                                                        isDarkTheme={isDarkTheme}
                                                                        id="private"
                                                                        name="type"
                                                                        type="radio"
                                                                        checked={isPrivateAirdrop == true}
                                                                        onChange={(e) => {
                                                                            setIsPrivateAirdrop(true)
                                                                        }}
                                                                    />
                                                                    <CheckLabel htmlFor="private" isDarkTheme={isDarkTheme}>
                                                                        Private
                                                                    </CheckLabel>
                                                                </Flexed>
                                                            </Col>
                                                            <NetworksDropdown />
                                                            <Col>
                                                                {(activeStep > 0 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button isDarkTheme={isDarkTheme} ifClicked={completeFirstStep} label={'Next'} />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}
                                        {stepTwo && (
                                            <Item id="secondStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            {isPrivateAirdrop && (
                                                                <CustomCol lg={12}>
                                                                    <Label isDarkTheme={isDarkTheme}>
                                                                        Whitelist Addresses (Count: {whitelist?.length}) <span>*</span>
                                                                    </Label>
                                                                    <AddressesContainer isDarkTheme={isDarkTheme} justifyContent={'start'}>
                                                                        <Col>
                                                                            <ScrollingContent justifyContent={'space-between'}>
                                                                                {whitelist.map((item, index) => {
                                                                                    return (
                                                                                        <MiniTextContainer xl={6} lg={6} md={12} sm={12} xs={12} isDarkTheme={isDarkTheme} key={index}>
                                                                                            <AddressLabel isDarkTheme={isDarkTheme}>{item?.address + ':' + item?.value}</AddressLabel>
                                                                                            <CloseIcon
                                                                                                onClick={() => {
                                                                                                    removeWhiteListAddress(item)
                                                                                                }}
                                                                                            />
                                                                                        </MiniTextContainer>
                                                                                    )
                                                                                })}
                                                                            </ScrollingContent>
                                                                        </Col>
                                                                        <SmallInput
                                                                            width={'auto'}
                                                                            border={'none'}
                                                                            isDarkTheme={isDarkTheme}
                                                                            value={whiteListString}
                                                                            placeholder={(user != null ? user?.address : '0x0000000000000000000000000000000000000000') + ':100'}
                                                                            onChange={(e) => {
                                                                                setWhitelistError(false)
                                                                                addWhiteListAddress(e.target.value.toLowerCase(), null)
                                                                            }}
                                                                            onKeyDown={(e) => addWhiteListAddress(whiteListString, e?.key)}
                                                                        />
                                                                    </AddressesContainer>
                                                                    {whitelistError && <Alblur>{whitelistError}</Alblur>}
                                                                    <Spacer height={1} />
                                                                </CustomCol>
                                                            )}
                                                            <CustomCol xs={12} sm={12} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Airdrop Title <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    value={saleTitle}
                                                                    onChange={(e) => {
                                                                        setSaleTitle(e.target.value)
                                                                        setSaleTitleError(false)
                                                                    }}
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {saleTitleError && <Alblur>{saleTitleError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>

                                                            <CustomCol xs={12} sm={12} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Total Tokens ({tokenDetails?.symbol})<span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={tokenQuantity}
                                                                    type="number"
                                                                    placeholder="0"
                                                                    min="1"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    disabled={isPrivateAirdrop ? true : false}
                                                                    onChange={(e) => {
                                                                        setTokenQuantity(e.target.value)
                                                                        settokenQuantityError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                />
                                                                {tokenQuantityError && <Alblur>{tokenQuantityError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>

                                                            <CustomCol xs={12} sm={12} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Airdrop Distribution Time (UTC) <span>*</span>
                                                                </Label>
                                                                <DatetimePicker isDarkTheme={isDarkTheme} state={distributionTime} setState={setDistributionTime} setError={setDistributionTimeError} />
                                                                <br />
                                                                {distributionTimeError && <Alblur>{distributionTimeError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol xs={12} sm={12} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Logo URL</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={logoUrl}
                                                                    onChange={(e) => {
                                                                        setLogoUrl(e.target.value)
                                                                        setLogoUrlError(false)
                                                                        validateImageURl(e.target.value)
                                                                    }}
                                                                    placeholder="Supported image extensions: png, jpg, jpeg or gif"
                                                                />
                                                                {!isValidationCompleted && logoUrl?.length > 0 && <Alblur>Processing logo url</Alblur>}
                                                                {logoUrlError && <Alblur>{logoUrlError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol xs={12} sm={12} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Website</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={website}
                                                                    onChange={(e) => {
                                                                        setWebsite(e.target.value)
                                                                        setWebsiteError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {websiteError && <Alblur>{websiteError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>

                                                            <CustomCol xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Twitter</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={twitter}
                                                                    onChange={(e) => {
                                                                        setTwitter(e.target.value)
                                                                        setTwitterError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {twitterError && <Alblur>{twitterError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Telegram</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={telegramLink}
                                                                    onChange={(e) => {
                                                                        setTelegramLink(e.target.value)
                                                                        setTelegramLinkError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {telegramLinkError && <Alblur>{telegramLinkError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Discord</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={discord}
                                                                    onChange={(e) => {
                                                                        setDiscord(e.target.value)
                                                                        setDiscordError(false)
                                                                    }}
                                                                    placeholder=""
                                                                />
                                                                {discordError && <Alblur>{discordError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol>
                                                                <Label isDarkTheme={isDarkTheme}>Description</Label>
                                                                <TextArea
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={description}
                                                                    onChange={(e) => {
                                                                        setDescription(e.target.value)
                                                                        setDescriptionError(false)
                                                                    }}
                                                                    row="3"
                                                                    placeholder="Minimum of 128 characters"
                                                                />
                                                                {descriptionError && <Alblur>{descriptionError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <Col>
                                                                {(activeStep > 1 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Back'}
                                                                            margin={'0rem 1rem 0rem 0rem'}
                                                                            ifClicked={() => {
                                                                                scrollToStepFirst()
                                                                                setStepOne(true)
                                                                                setStepTwo(false)
                                                                                setActiveStep(1)
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            ifClicked={() => {
                                                                                if (socialValiation() && !logoUrlError && isValidationCompleted && !whitelistError) {
                                                                                    if (connectedNetwork) {
                                                                                        createAirdrop()
                                                                                    } else {
                                                                                        showConnectionError()
                                                                                    }
                                                                                }
                                                                            }}
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Create Airdrop'}
                                                                        />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}
                                    </Stepper>
                                </Col>
                            </Row>
                        </Container>
                        <Spacer height={3} />
                    </CustomRow>
                </Wrapper>
            ) : (
                <AirdropDetails _airdropInfo={airdropInfo} />
            )}
            {loading && (
                <LoadingPanelContent>
                    <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                </LoadingPanelContent>
            )}
            {openCreateTokenModal && (
                <CreateTokenModal
                    user={user}
                    open={openCreateTokenModal}
                    setTokenAddress={setTokenAddress}
                    setTokenDetails={setTokenDetails}
                    onCloseModal={() => {
                        setOpenCreateTokenModal(false)
                    }}
                />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    padding-top: 2rem;
    ${media.lg`
        width: 90%;
    `};
    ${media.xl`
        width: 90%;
    `};
    ${media.xs`
        padding-top: 0rem;
    `}
    ${media.sm`
        padding-top: 2rem;
    `}
`

const StapperRow = styled(Row)`
    ${media.xs`
        display:none;
    `}
`

const Form = styled(Row)`
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
        padding:  1.5rem 1rem;
    `};
    ${media.sm`
        padding: 1.5rem 1rem;
    `};
    ${media.md`
        padding: 2rem;
    `};
`

const CustomRow = styled(Row)`
    justify-content: center;
    ${media.sm`
        width: 100%;
    `};
`

const CustomCol = styled(Col)`
    padding: 0rem 1rem;
    box-sizing: border-box;
`

const CardCol = styled(Col)`
    &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 20%;
        left: 11rem;
        right: 0rem;
        z-index: 1;
        border-top: 1px solid ${({ active, isDarkTheme }) => (active ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_dark}` : `${palette.gray_light}`)};
        ${media.xs`
            left: 4rem;
            top: 34%;
        `}
        ${media.sm`
        left: 6rem;
        top: 26%;
        `}
        @media screen and (min-width: 0px) and (max-width: 669px) {
            display: none !important;
        }
        ${media.md`
            top: 20%;
            left: 11rem;
        `}
        ${media.lg`
            left: 11rem;
        `}
        ${media.xl`
            left: 11rem;
        `}
    }
`

const List = styled.div`
    display: flex;
    margin: 1rem 0rem;
    cursor: pointer;
`
const Card = styled.div`
    position: relative;
    border-radius: 0.2rem;
    border: none;
    font-size: 1rem;
    width: 12rem;
    height: 3rem;
    ${media.xs`
        width: 16rem;
        height: 4rem;
    `}
    ${media.sm`
        width: 15rem;
        height: 6rem;
    `}
    ${media.md`
        width: 14rem;
        height: 8rem;
    `}
    ${media.lg`
        width: 13rem;
        height: 8rem;
    `}
    ${media.xl`
        width: 14rem;
        height: 8rem;
    `}
`

const Stepper = styled.ul`
    padding: 0;
`

const Item = styled.li`
    list-style: none;
    margin-bottom: 2rem;
`

const StepperBody = styled.div``

const StepperFooter = styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const Discription = styled.div`
    display: flex;
    flex-direction: column;
`

const AddressesContainer = styled(Row)`
    height: auto;
    margin: 0;
    border-radius: 0.3rem;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    background-color: transparent;
`

const ScrollingContent = styled(Row)`
    max-height: 8rem;
    overflow: hidden;
    overflow-y: scroll;
`

const MiniTextContainer = styled(Col)`
    min-width: 48%;
    display: flex;
    justify-content: space-between;
    border-radius: 0.5rem;
    margin: 0.25rem 0;
    padding: 0;
`

const AddressLabel = styled(Label)`
    margin: 0.5rem;
    font-size: 0.8rem;
`

const CloseIcon = styled(AiOutlineCloseCircle)`
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    cursor: pointer;
    margin: 0.2rem 1rem 0 0;
`

const SmallInput = styled(InputText)`
    border: none;
`

const InfoIconAlign = styled(InfoIcon)`
    right: 0.938rem;
    ${media.xs`right:0rem;`}
    ${media.sm`right:0.938rem;`}
`

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAirdrop)
