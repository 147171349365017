import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {palette} from '../assets/common'
import {useLocation} from 'react-router-dom'

const Layout = ({children}) => {
    const {pathname} = useLocation()
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)

    return (
        <SLayout>
            <Header />
            <Sidebar />
            <SMain isDarkTheme={isDarkTheme} active={pathname === '/'}>
                {pathname === '/' && isDarkTheme && <Overlay />}
                {children}
            </SMain>
        </SLayout>
    )
}

const SLayout = styled.div`
    display: flex;
`

const SMain = styled.main`
    // padding: 1rem;
    width: 100%;
    padding-bottom: 0rem;
    overflow: auto;
    // background: ${({isDarkTheme, active}) => (active ? `url('/images/defi.gif')` : isDarkTheme ? `${palette.black2}` : `${palette.white}`)};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`

const Overlay = styled.div`
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    background: black;
    opacity: 0.8;
`

export default Layout
