import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { useDispatch, useSelector } from 'react-redux'
import { ethers } from 'ethers'
import DateTimePicker from 'react-datetime-picker'
import { connect } from 'react-redux'
import { TokenStandardContract } from '../contracts/TokenStandardContract'
import { TokenLiquidityContract } from '../contracts/TokenLiquidityContract'
import { useNavigate } from 'react-router-dom'
import { getNetworkById, DocumentLinks } from '../utils'
import { Flexed, NetworkContent, ProfileImageDiv, LoadingPanelContent, LoadingPanel, Heading, AddressText, DetailLabel, Content, Twitter, Telegram, Discord, World, InfoIcon, TextPlaceHolder } from '../styles/shared'
// import axios from 'axios'
import { openMainPage } from '../actions/authActions'
import { Copy } from './CopyTooltip'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const MyTokenDetails = ({ tokenDetails }) => {
    const signer = useSelector((state) => state.auth.signer)
    const user = useSelector((state) => state.auth.user)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    const _dispatch = useDispatch()

    const [tokenName, setTokenName] = useState(null)
    const [tokenSymbol, setTokenSymbol] = useState(null)
    const [totalTokens, setTotalTokens] = useState(null)
    const [tokenDecimals, setTokenDecimals] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [tokenContract, setTokenContract] = useState(null)
    const [yieldTransactionFee, setYieldTransactionFee] = useState(null)
    const [liquidityTransactionFee, setLiquidityTransactionFee] = useState(null)

    useEffect(async () => {
        if (signer) {
            if (tokenDetails?.chainId != connectedNetwork?.chainId) {
                _dispatch(openMainPage(true))
                return
            }
            let _tokenContract = null
            if (tokenDetails?.type == 'Standard') {
                _tokenContract = new ethers.Contract(tokenDetails?.tokenAddress, TokenStandardContract.abi, signer)
            } else {
                _tokenContract = new ethers.Contract(tokenDetails?.tokenAddress, TokenLiquidityContract.abi, signer)
            }
            setTokenContract(_tokenContract)
        }
    }, [signer, connectedNetwork])

    useEffect(async () => {
        if (tokenContract) {
            await readTokenDetails()
        }
    }, [tokenContract])

    const readTokenDetails = async () => {
        setLoading(true)
        const name = await tokenContract.name()
        setTokenName(name)

        const symbol = await tokenContract.symbol()
        setTokenSymbol(symbol)

        let decimals = await tokenContract.decimals()
        setTokenDecimals(decimals)

        let totalSupply = await tokenContract.totalSupply()

        totalSupply = ethers.utils.formatUnits(totalSupply, decimals)

        if (tokenDetails?.type != 'Standard') {
            setTotalTokens(totalSupply)

            let _taxFee = await tokenContract._taxFee()
            _taxFee = ethers.utils.formatEther(_taxFee)
            setYieldTransactionFee(_taxFee * 1000000000000000000)

            let _liquidityFee = await tokenContract._liquidityFee()
            _liquidityFee = ethers.utils.formatEther(_liquidityFee)
            setLiquidityTransactionFee(_liquidityFee * 1000000000000000000)
        } else {
            setTotalTokens(totalSupply)
        }
        setLoading(false)
    }

    return (
        <>
            {!connectedNetwork && navigate('/')}
            <Wrapper>
                <MainRow id="row">
                    <Col lg={12} xl={7} xxl={8}>
                        <Form isDarkTheme={isDarkTheme}>
                            <Row>
                                <a target="_blank" href={DocumentLinks('tokenDetails')}>
                                    <InfoIcon right={'1rem'} top={'1rem'} xsPosition="absolute" />
                                </a>
                                <ProfileContent>
                                    <Flexed direction="row" align="center">
                                        <div>
                                            <ProfileImageDiv isDarkTheme={isDarkTheme} src={''} />
                                        </div>
                                        <Heading isDarkTheme={isDarkTheme}>{tokenName}</Heading>
                                    </Flexed>
                                </ProfileContent>
                                <AddressColumn isDarkTheme={isDarkTheme}>
                                    <DetailLabel isDarkTheme={isDarkTheme}>Token Address</DetailLabel>
                                    {tokenDetails ? (
                                        <Flexed direction="row" align="center">
                                            <AddressText isDarkTheme={isDarkTheme} href={`${connectedNetwork?.tokenUrl}/${tokenDetails?.tokenAddress}`} target="_blank" rel="noopener noreferrer">
                                                {tokenDetails?.tokenAddress}
                                            </AddressText>
                                            <Copy text={`${connectedNetwork.tokenUrl}/${tokenDetails?.tokenAddress}`} isDarkTheme={isDarkTheme} />
                                        </Flexed>
                                    ) : (
                                        <TextPlaceHolder />
                                    )}
                                </AddressColumn>
                                <Column isDarkTheme={isDarkTheme}>
                                    <DetailLabel isDarkTheme={isDarkTheme}>Token Symbol</DetailLabel>
                                    {tokenSymbol ? <Content isDarkTheme={isDarkTheme}>{tokenSymbol}</Content> : <TextPlaceHolder />}
                                </Column>
                                <Column isDarkTheme={isDarkTheme}>
                                    <DetailLabel isDarkTheme={isDarkTheme}>Token Decimals</DetailLabel>
                                    {tokenDecimals ? <Content isDarkTheme={isDarkTheme}>{tokenDecimals}</Content> : <TextPlaceHolder />}
                                </Column>
                                <Column isDarkTheme={isDarkTheme}>
                                    <DetailLabel isDarkTheme={isDarkTheme}>Total Tokens</DetailLabel>
                                    {totalTokens ? <Content isDarkTheme={isDarkTheme}>{totalTokens}</Content> : <TextPlaceHolder />}
                                </Column>
                                {tokenDetails?.type != 'Standard' && (
                                    <>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Transaction fee to generate yield (%)</DetailLabel>
                                            {yieldTransactionFee ? <Content isDarkTheme={isDarkTheme}>{yieldTransactionFee}</Content> : <TextPlaceHolder />}
                                        </Column>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Transaction fee to generate liquidity (%)</DetailLabel>
                                            {liquidityTransactionFee ? <Content isDarkTheme={isDarkTheme}>{liquidityTransactionFee}</Content> : <TextPlaceHolder />}
                                        </Column>
                                    </>
                                )}
                            </Row>
                        </Form>
                    </Col>
                    <Col lg={12} xl={5} xxl={4}>
                        <Form isDarkTheme={isDarkTheme}>
                            <Row>
                                <Column isDarkTheme={isDarkTheme}>
                                    <DetailLabel isDarkTheme={isDarkTheme}>Token Type</DetailLabel>
                                    {tokenDetails?.type ? (
                                        <Content isDarkTheme={isDarkTheme} color={isDarkTheme ? 'aqua_blue' : 'blue'}>
                                            {tokenDetails?.type}
                                        </Content>
                                    ) : (
                                        <TextPlaceHolder />
                                    )}
                                </Column>
                                <Column isDarkTheme={isDarkTheme}>
                                    <DetailLabel isDarkTheme={isDarkTheme}>Network</DetailLabel>
                                    {tokenDetails?.chainId ? (
                                        <NetworkContent isDarkTheme={isDarkTheme} chainId={tokenDetails?.chainId}>
                                            {connectedNetwork?.title}
                                        </NetworkContent>
                                    ) : connectedNetwork ? (
                                        <NetworkContent isDarkTheme={isDarkTheme} chainId={connectedNetwork?.chainId}>
                                            {connectedNetwork?.title}
                                        </NetworkContent>
                                    ) : (
                                        <TextPlaceHolder />
                                    )}
                                </Column>
                            </Row>
                        </Form>
                    </Col>
                </MainRow>
                <Spacer />
            </Wrapper>
            {loading && (
                <LoadingPanelContent>
                    <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                </LoadingPanelContent>
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    // padding: 0rem 3rem 0rem 3rem;
`
const MainRow = styled(Row)`
    ${media.lg`margin-left:-5px`};
    ${media.xl`margin-left:-5px`};
    ${media.xxl`margin-left:-15px`};
`

const Form = styled(Container)`
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 1rem;
    position: relative;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`

const Status = styled.div`
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 0.8rem;
    line-height: 1.38rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    padding: 0.3rem 1rem;
    border-radius: 2rem;
    // background: ${({ status }) => (status == 'live' ? `${palette.green_gradient}` : status == 'upcoming' ? `${palette.yellow_gradient}` : `${palette.red_gradient}`)};
    color: ${({ status, isDarkTheme }) => (status == 'live' ? `${palette.green}` : status == 'upcoming' ? (isDarkTheme ? `${palette.yellow}` : `${palette.binance}`) : `${palette.red}`)};
    ${media.xs`
      margin-top:1rem;
    `};
    ${media.sm`
      display: flex;
    `};
`
const Dot = styled.span`
    background-color: ${({ status }) => (status == 'live' ? `${palette.green}` : status == 'upcoming' ? `${palette.yellow}` : `${palette.red}`)};
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    margin-right: 0.3rem;
`

const ButtonContainer = styled.div`
    display: flex;
    margin-left: 1rem;
`

const MaxButton = styled.a`
    padding-left: 1rem;
    position: absolute;
    text-align: center;
    margin: ${({ isDarkTheme }) => (isDarkTheme ? `0.3rem` : `0.3rem 2rem 0 0`)} !important;
    font-weight: 500;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)} !important;
    border-radius: 0.4rem;
    border: none;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    align-items: center;
    right: 0px;
    top: 0px;
    background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.max_button_bg}` : ``)} !important; ;
`

const ProfileContent = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    ${media.xs`
      display: block;
    `};
    ${media.sm`
      display: flex;
    `};
`

const Column = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    // cursor: pointer;
    border-bottom: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_dark}` : `${palette.gray}`)};
    gap: ${({ gap }) => (gap ? `${gap}` : '')};
`

const AddressColumn = styled(Column)`
    ${media.xs`
      display: block;
    `};
    ${media.sm`
      display: flex;
    `};
`
const List = styled(Row)`
    border-bottom: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_dark}` : `${palette.gray}`)};
`

const SecondButtonContainer = styled(Col)`
    margin-top: 0.5rem;
    justify-content: center;
    margin-bottom: 0rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
`

const DescriptionContent = styled.div`
    display: flex;
    align-items: center;
    & a {
        margin-left: 0.5rem;
        font-size: 1.5rem;
    }
`

const Flexeding = styled(Column)`
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-bottom: 0rem;
`

const Spacer = styled.div`
    height: 0.75rem;
`
const InputContent = styled.div`
    position: relative;
    margin: 0.5rem 0rem;
`
const InputText = styled.input`
    outline: none;
    height: 2.3rem;
    width: ${({ width }) => (width ? `${width}%` : '100%')};
    font-size: 0.9rem;
    padding: 0.4rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const Link = styled.a`
    cursor: pointer;
`

const EditDescription = styled.textarea`
    outline: none;
    width: ${({ width }) => (width ? `${width}%` : '28rem')};
    font-size: 0.9rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    padding: 0.2rem;
    border-radius: 0.3rem;
    box-sizing: border-box;
    & input {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    }
    & > div > button > svg {
        stroke: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)} !important;
    }
    & div {
        border: 0rem !important;
    }
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyTokenDetails)
