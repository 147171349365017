import React, {useState, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../assets/common'
import styled from 'styled-components'
import {AiOutlineCloseCircle, AiOutlineCloseSquare, AiOutlineCloudUpload, AiOutlineRight} from 'react-icons/ai'
import {DetailLabel, Content, Label, Info, Alblur, Para, Spacer, InputText, TextArea, LoadingPanelContent, LoadingPanel, StepperHeading, Heading, InfoIcon, Flexed, Text, Status} from '../styles/shared'
import Button from '../components/Button'
import {useSelector} from 'react-redux'
import {DocumentLinks, preventNegativeValues, showConnectionError, showError, showSuccess} from '../utils/index'
import {btnReset, v} from '../styles/variables'

import '@tensorflow/tfjs-backend-cpu'
import * as cocoSsd from '@tensorflow-models/coco-ssd'

import axios from 'axios'
import {api} from '../config/index'
import {digits_regex, email_regex, url_regex} from '../utils/regex'

const KYCRequest = ({data, onCloseCB}) => {
    const signer = useSelector((state) => state.auth.signer)
    const user = useSelector((state) => state.auth.user)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const [uploadData, setUploadData] = useState(null) // to be uploaded
    const [loading, setLoading] = useState(false)
    const [kycData, setKycData] = useState(null) // from db

    const [image, setImage] = useState('')
    const [imageErr, setImageErr] = useState(false)
    const [imageSpinner, setImageSpinner] = useState(false)

    const [cnic, setCnic] = useState('')
    const [cnicErr, setCnicErr] = useState(false)
    const [cnicSpinner, setCnicSpinner] = useState(false)

    const [passport, setPassport] = useState('')
    const [passportErr, setPassportErr] = useState(false)
    const [passportSpinner, setPassportSpinner] = useState(false)

    const [btnSpinner, setBtnSpinner] = useState(false)
    // dummy instructions text
    const instructionsList = ['Find duplicates very fast. Almost any hash function will work. Instead of searching for the whole image, you will look for the hash of the image', 'While normally hashing a file hashes the individual bits of data of the file, image hashing works on a slightly higher level. The difference is that with image hashing, if two pictures look practically identical but are in a different format, or resolution (or there is minor corruption, perhaps due to compression) they should hash to the same number. Despite the actual bits of their data being totally different, if they look practically identical to a human, they hash to the the same thing.', 'Images that look identical to us, can be very different if you will just compare the raw bytes.']

    useEffect(() => {
        if (connectedNetwork === null) {
            onCloseCB()
        }
    }, [connectedNetwork])

    useEffect(() => {
        if (data?.kycStatus !== 0) {
            fetchKycData(data?.launchpadAddress)
        }
    }, [data])

    const fetchKycData = async (launchpadAddress) => {
        setLoading(true)
        try {
            await axios
                .post(
                    `${api}/user/kyc_details`,
                    {
                        launchpadAddress: launchpadAddress
                    },
                    {
                        headers: {
                            authorization: `bearer ${user.token}`
                        }
                    }
                )
                .then((response) => {
                    if (response.data.status) {
                        setKycData(response.data.data)
                        setLoading(false)
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    showError(error.message)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const objectDetection = async (imageItem) => {
        const model = await cocoSsd.load({})
        const predictions = await model.detect(imageItem, 6)
        return predictions
    }

    const validateFaceImage = async (data) => {
        setImageSpinner(true)
        let faceImageElement = document.createElement('img')
        faceImageElement.src = image
        let predictions = await objectDetection(faceImageElement)
        if (!predictions?.length > 0 || predictions[0]?.class !== 'person') {
            setImage('')
            setImageErr('Face cannot be recognized')
            setImageSpinner(false)
            return false
        } else {
            setImageSpinner(false)
            return true
        }
    }

    const validateCnicImage = async (data) => {
        setCnicSpinner(true)
        let cnicImageElement = document.createElement('img')
        cnicImageElement.src = cnic
        let predictions = await objectDetection(cnicImageElement)
        if (!predictions?.length > 0 || predictions[0]?.class !== 'person') {
            setCnic('')
            setCnicErr('Face cannot be recognized')
            setCnicSpinner(false)
            return false
        } else {
            setCnicSpinner(false)
            return true
        }
    }

    const validatePassportImage = async (data) => {
        setPassportSpinner(true)
        let passportImageElement = document.createElement('img')
        passportImageElement.src = passport
        let predictions = await objectDetection(passportImageElement)
        if (!predictions?.length > 0 || predictions[0]?.class !== 'person') {
            setPassport('')
            setPassportErr('Face cannot be recognized')
            setPassportSpinner(false)
            return false
        } else {
            setPassportSpinner(false)
            return true
        }
    }

    // todo.. reconsider the model to classify person face correctly
    const handleRequestSend = async () => {
        let _uploadData = uploadData
        const response = await Promise.all([validateFaceImage(), validateCnicImage(), validatePassportImage()])
        if (response.every((e) => e === true)) {
            _uploadData = {...uploadData, launchpadAddress: data?.launchpadAddress, image: image, cnic: cnic, passport: passport}
            setLoading(true)
            if (signer) {
                await axios
                    .post(`${api}/user/kyc_request`, _uploadData, {
                        headers: {
                            authorization: `bearer ${user.token}`
                        }
                    })
                    .then((response) => {
                        showSuccess('KYC request successfully added for admin approval')
                        onCloseCB()
                        setLoading(false)
                    })
                    .catch((error) => {
                        setLoading(false)
                        showError(error?.message)
                    })
            } else {
                setLoading(false)
                showConnectionError()
            }
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const handleFaceImage = (e) => {
        if (e?.target.files[0]?.size > 300000) {
            setImageErr('File size exceeded; Should be less than 300KB')
            return
        }
        const reader = new FileReader()
        if (e?.target.files[0]) {
            reader.readAsDataURL(e?.target.files[0])
        }
        reader.onload = () => {
            if (reader.readyState === 2) {
                const base64 = reader.result
                setImage(base64)
                setImageErr(false)
            }
        }
    }

    const handleCNICImage = (e) => {
        if (e?.target.files[0]?.size > 300000) {
            setCnicErr('File size exceeded; Should be less than 300KB')
            return
        }
        const reader = new FileReader()
        if (e?.target.files[0]) {
            reader.readAsDataURL(e?.target.files[0])
        }
        reader.onload = () => {
            if (reader.readyState === 2) {
                const base64 = reader.result
                setCnic(base64)
                setCnicErr(false)
            }
        }
    }

    const handlePassportImage = (e) => {
        if (e?.target.files[0]?.size > 300000) {
            setPassportErr('File size exceeded; Should be less than 300KB')
            return
        }
        const reader = new FileReader()
        if (e?.target.files[0]) {
            reader.readAsDataURL(e?.target.files[0])
        }
        reader.onload = () => {
            if (reader.readyState === 2) {
                const base64 = reader.result
                setPassport(base64)
                setPassportErr(false)
            }
        }
    }

    const handleRemoveImage = (imageType) => {
        imageType === 'image' ? setImage('') : imageType === 'cnic' ? setCnic('') : imageType === 'passport' && setPassport('')
    }

    const validataUploadData = () => {
        let valid = true
        let titleError = ''
        let websiteError = ''
        let firstNameError = ''
        let lastNameError = ''
        let emailError = ''
        let phoneError = ''
        let imageError = ''
        let cnicError = ''
        let passportError = ''

        if (uploadData !== null) {
            if (uploadData?.title === '' || uploadData?.title === undefined) {
                valid = false
                titleError = 'Title is required'
            }
            if (uploadData?.website === '' || uploadData?.website === undefined) {
                valid = false
                websiteError = 'Website is required'
            } else if (!url_regex.test(uploadData?.website)) {
                valid = false
                websiteError = 'Invalid website url'
            } else if (uploadData?.website?.length > 255) {
                valid = false
                websiteError = 'Maximum 255 characters allowed'
            }
            if (uploadData?.firstName === '' || uploadData?.firstName === undefined) {
                valid = false
                firstNameError = 'First Name is required'
            }
            if (uploadData?.lastName === '' || uploadData?.lastName === undefined) {
                valid = false
                lastNameError = 'Last Name is required'
            }
            if (uploadData?.email === '' || uploadData?.email === undefined) {
                valid = false
                emailError = 'Email is required'
            } else if (!email_regex.test(uploadData?.email)) {
                valid = false
                emailError = `Invalid email.`
                // Must contain '@'`
            }
            if (uploadData?.phone === '' || uploadData?.phone === undefined) {
                valid = false
                phoneError = 'Phone is required'
            } else if (uploadData?.phone?.length < 9 || uploadData?.phone?.length > 15) {
                valid = false
                phoneError = 'Invalid phone'
            }
            if (image === '') {
                valid = false
                imageError = 'Image is required'
            }
            if (cnic === '') {
                valid = false
                cnicError = 'CNIC is required'
            }
            if (passport === '') {
                valid = false
                passportError = 'Passport is required'
            }
            setUploadData({...uploadData, titleError: titleError, websiteError: websiteError, firstNameError: firstNameError, lastNameError: lastNameError, emailError: emailError, phoneError: phoneError, imageError: imageError, cnicError: cnicError, passportError: passportError})
            setImageErr(imageError)
            setCnicErr(cnicError)
            setPassportErr(passportError)
        } else {
            valid = false
            showError('Please fill all the fields')
        }
        return valid
    }

    return (
        <>
            {loading && (
                <LoadingPanelContent>
                    <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                </LoadingPanelContent>
            )}
            <Wrapper>
                <CustomRow>
                    <Container>
                        <Row>
                            <Col lg={1}>
                                <SSidebarButton
                                    isDarkTheme={isDarkTheme}
                                    onClick={() => {
                                        onCloseCB()
                                    }}>
                                    <AiOutlineRight />
                                </SSidebarButton>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={8}>
                                <Heading margin="0rem 0rem 1.25rem 0rem" isDarkTheme={isDarkTheme}>
                                    My KYC Request
                                </Heading>
                            </Col>
                            <Col lg={5} md={5} sm={5} xs={3}>
                                <Flexed direction="row" justify="flex-end">
                                    <a target="_blank" href={DocumentLinks('createToken')}>
                                        <InfoIcon right="0.938rem" />
                                    </a>
                                </Flexed>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Stepper>
                                    <Item id="firstStep">
                                        <StepperBody>
                                            <Container>
                                                <Form isDarkTheme={isDarkTheme}>
                                                    <FlexedEnd lg={12}>
                                                        <Status status={'fair'}>Status: {data?.kycStatus === 1 ? 'Pending approval' : data?.kycStatus === 2 ? 'Approved' : data?.kycStatus === 3 ? 'Rejected' : data?.kycStatus === 4 ? 'Public' : 'Not requested yet'}</Status>
                                                    </FlexedEnd>
                                                    <Column lg={12} isDarkTheme={isDarkTheme}>
                                                        <FlexLabel isDarkTheme={isDarkTheme}>Project title</FlexLabel>
                                                        <FlexedEnd>
                                                            {data?.kycStatus === 0 ? (
                                                                <Blocked>
                                                                    <Input
                                                                        isDarkTheme={isDarkTheme}
                                                                        value={uploadData?.title}
                                                                        onChange={(e) => {
                                                                            setUploadData({...uploadData, title: e.target.value.toLowerCase(), titleError: ''})
                                                                        }}
                                                                    />
                                                                    {uploadData !== null && uploadData?.titleError !== '' && <Alblur>{uploadData?.titleError}</Alblur>}
                                                                </Blocked>
                                                            ) : (
                                                                <Text small isDarkTheme={isDarkTheme}>
                                                                    {kycData?.title}
                                                                </Text>
                                                            )}
                                                        </FlexedEnd>
                                                        <Spacer height={1} />
                                                    </Column>{' '}
                                                    <Column lg={12} isDarkTheme={isDarkTheme}>
                                                        <FlexLabel isDarkTheme={isDarkTheme}>Project website</FlexLabel>
                                                        <FlexedEnd>
                                                            {data?.kycStatus === 0 ? (
                                                                <Blocked>
                                                                    <Input
                                                                        isDarkTheme={isDarkTheme}
                                                                        value={uploadData?.website}
                                                                        onChange={(e) => {
                                                                            setUploadData({...uploadData, website: e.target.value.toLowerCase(), websiteError: ''})
                                                                        }}
                                                                    />
                                                                    {uploadData !== null && uploadData?.websiteError !== '' && <Alblur>{uploadData?.websiteError}</Alblur>}
                                                                </Blocked>
                                                            ) : (
                                                                <Text small isDarkTheme={isDarkTheme}>
                                                                    {kycData?.website}
                                                                </Text>
                                                            )}
                                                        </FlexedEnd>
                                                        <Spacer height={1} />
                                                    </Column>
                                                    <Column lg={12} isDarkTheme={isDarkTheme}>
                                                        <FlexLabel isDarkTheme={isDarkTheme}>First Name</FlexLabel>
                                                        <FlexedEnd>
                                                            {data?.kycStatus === 0 ? (
                                                                <Blocked>
                                                                    <Input
                                                                        isDarkTheme={isDarkTheme}
                                                                        value={uploadData?.firstName}
                                                                        onChange={(e) => {
                                                                            setUploadData({...uploadData, firstName: e.target.value.toLowerCase(), firstNameError: ''})
                                                                        }}
                                                                    />
                                                                    {uploadData !== null && uploadData?.firstNameError !== '' && <Alblur>{uploadData?.firstNameError}</Alblur>}
                                                                </Blocked>
                                                            ) : (
                                                                <Text small isDarkTheme={isDarkTheme}>
                                                                    {kycData?.firstName}
                                                                </Text>
                                                            )}
                                                        </FlexedEnd>
                                                        <Spacer height={1} />
                                                    </Column>
                                                    <Column lg={12} isDarkTheme={isDarkTheme}>
                                                        <FlexLabel isDarkTheme={isDarkTheme}>Last Name</FlexLabel>
                                                        <FlexedEnd>
                                                            {data?.kycStatus === 0 ? (
                                                                <Blocked>
                                                                    <Input
                                                                        isDarkTheme={isDarkTheme}
                                                                        value={uploadData?.lastName}
                                                                        onChange={(e) => {
                                                                            setUploadData({...uploadData, lastName: e.target.value.toLowerCase(), lastNameError: ''})
                                                                        }}
                                                                    />
                                                                    {uploadData !== null && uploadData?.lastNameError !== '' && <Alblur>{uploadData?.lastNameError}</Alblur>}
                                                                </Blocked>
                                                            ) : (
                                                                <Text small isDarkTheme={isDarkTheme}>
                                                                    {kycData?.lastName}
                                                                </Text>
                                                            )}
                                                        </FlexedEnd>
                                                        <Spacer height={1} />
                                                    </Column>
                                                    <Column lg={12} isDarkTheme={isDarkTheme}>
                                                        <FlexLabel isDarkTheme={isDarkTheme}>Email</FlexLabel>
                                                        <FlexedEnd>
                                                            {data?.kycStatus === 0 ? (
                                                                <Blocked>
                                                                    <Input
                                                                        isDarkTheme={isDarkTheme}
                                                                        value={uploadData?.email}
                                                                        onChange={(e) => {
                                                                            setUploadData({...uploadData, email: e.target.value.toLowerCase(), emailError: ''})
                                                                        }}
                                                                    />
                                                                    {uploadData !== null && uploadData?.emailError !== '' && <Alblur>{uploadData?.emailError}</Alblur>}
                                                                </Blocked>
                                                            ) : (
                                                                <Text small isDarkTheme={isDarkTheme}>
                                                                    {kycData?.email}
                                                                </Text>
                                                            )}
                                                        </FlexedEnd>
                                                        <Spacer height={1} />
                                                    </Column>
                                                    <Column lg={12} isDarkTheme={isDarkTheme}>
                                                        <FlexLabel isDarkTheme={isDarkTheme}>Phone</FlexLabel>
                                                        <FlexedEnd>
                                                            {data?.kycStatus === 0 ? (
                                                                <Blocked>
                                                                    <Input
                                                                        // type="number"
                                                                        isDarkTheme={isDarkTheme}
                                                                        value={uploadData?.phone}
                                                                        min="0"
                                                                        onChange={(e) => {
                                                                            let val = e.target.value
                                                                            if (/\D/g.test(e.target.value)) {
                                                                                val = e.target.value?.replace(/\D/g, '')
                                                                            }
                                                                            setUploadData({...uploadData, phone: val, phoneError: ''})
                                                                        }}
                                                                    />
                                                                    {uploadData !== null && uploadData?.phoneError !== '' && <Alblur>{uploadData?.phoneError}</Alblur>}
                                                                </Blocked>
                                                            ) : (
                                                                <Text small isDarkTheme={isDarkTheme}>
                                                                    {kycData?.phone}
                                                                </Text>
                                                            )}
                                                        </FlexedEnd>
                                                        <Spacer height={1} />
                                                    </Column>
                                                    <Column lg={12} isDarkTheme={isDarkTheme}>
                                                        <Col lg={2.8}>
                                                            <Label isDarkTheme={isDarkTheme}>Face image</Label>
                                                            {image !== '' ? (
                                                                <>
                                                                    {imageSpinner && (
                                                                        <MiniSpinner>
                                                                            <MiniLoading isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                                                                        </MiniSpinner>
                                                                    )}
                                                                    <Preview src={image}>
                                                                        <Close onClick={() => handleRemoveImage('image')} />
                                                                    </Preview>
                                                                    {imageSpinner && <ProcessingText>Processing face image...</ProcessingText>}
                                                                </>
                                                            ) : data?.kycStatus === 0 ? (
                                                                <>
                                                                    <UploadLabel1 htmlFor="face-image" isDarkTheme={isDarkTheme}>
                                                                        <AiOutlineCloudUpload />
                                                                        Upload Document
                                                                    </UploadLabel1>
                                                                    <InputFile
                                                                        type="file"
                                                                        id="face-image"
                                                                        isDarkTheme={isDarkTheme}
                                                                        onChange={(e) => {
                                                                            handleFaceImage(e)
                                                                        }}
                                                                    />
                                                                    {imageErr && <Alblur>{imageErr}</Alblur>}
                                                                </>
                                                            ) : (
                                                                kycData !== null && <Preview src={process.env.REACT_APP_AWS_URL + kycData?.image}></Preview>
                                                            )}
                                                            <Spacer height={1} />
                                                        </Col>

                                                        <Col lg={4.6}>
                                                            <Label isDarkTheme={isDarkTheme}>CNIC</Label>
                                                            {cnic !== '' ? (
                                                                <>
                                                                    {cnicSpinner && (
                                                                        <MiniSpinnerWide>
                                                                            <MiniLoading isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                                                                        </MiniSpinnerWide>
                                                                    )}
                                                                    <PreviewWider src={cnic}>
                                                                        <CloseWide onClick={() => handleRemoveImage('cnic')} />
                                                                    </PreviewWider>
                                                                    {cnicSpinner && <ProcessingText>Processing CNIC...</ProcessingText>}
                                                                </>
                                                            ) : data?.kycStatus === 0 ? (
                                                                <>
                                                                    <UploadLabel2 htmlFor="cnic-image" isDarkTheme={isDarkTheme}>
                                                                        <AiOutlineCloudUpload />
                                                                        Upload CNIC
                                                                    </UploadLabel2>
                                                                    <InputFile
                                                                        type="file"
                                                                        id="cnic-image"
                                                                        isDarkTheme={isDarkTheme}
                                                                        onChange={(e) => {
                                                                            handleCNICImage(e)
                                                                        }}
                                                                    />
                                                                    {cnicErr && <Alblur>{cnicErr}</Alblur>}
                                                                </>
                                                            ) : (
                                                                kycData !== null && <PreviewWider src={process.env.REACT_APP_AWS_URL + kycData?.cnic}></PreviewWider>
                                                            )}
                                                            <Spacer height={1} />
                                                        </Col>

                                                        <Col lg={4.6}>
                                                            <Label isDarkTheme={isDarkTheme}>Passport</Label>
                                                            {passport !== '' ? (
                                                                <>
                                                                    {passportSpinner && (
                                                                        <MiniSpinnerWide>
                                                                            <MiniLoading isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                                                                        </MiniSpinnerWide>
                                                                    )}
                                                                    <PreviewWider src={passport}>
                                                                        <CloseWide onClick={() => handleRemoveImage('passport')} />
                                                                    </PreviewWider>
                                                                    {passportSpinner && <ProcessingText>Processing passport...</ProcessingText>}
                                                                </>
                                                            ) : data?.kycStatus === 0 ? (
                                                                <>
                                                                    <UploadLabel3 htmlFor="passport-image" isDarkTheme={isDarkTheme}>
                                                                        <AiOutlineCloudUpload />
                                                                        Upload Passport
                                                                    </UploadLabel3>
                                                                    <InputFile
                                                                        type="file"
                                                                        id="passport-image"
                                                                        isDarkTheme={isDarkTheme}
                                                                        onChange={(e) => {
                                                                            handlePassportImage(e)
                                                                        }}
                                                                    />
                                                                    {passportErr && <Alblur>{passportErr}</Alblur>}
                                                                </>
                                                            ) : (
                                                                kycData !== null && <PreviewWider src={process.env.REACT_APP_AWS_URL + kycData?.passport}></PreviewWider>
                                                            )}
                                                            <Spacer height={1} />
                                                        </Col>
                                                    </Column>
                                                    {data?.kycStatus === 0 && instructionsList?.length > 0 && (
                                                        <Column lg={12} isDarkTheme={isDarkTheme}>
                                                            <InstructionsList>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Instructions <span>*</span>
                                                                </Label>
                                                                {instructionsList?.map((item, index) => {
                                                                    return (
                                                                        <ListItem isDarkTheme={isDarkTheme} key={index}>
                                                                            <Label isDarkTheme={isDarkTheme}>{item}</Label>
                                                                        </ListItem>
                                                                    )
                                                                })}
                                                            </InstructionsList>
                                                        </Column>
                                                    )}
                                                    {data?.kycStatus === 0 && (
                                                        <Col>
                                                            <StepperFooter>
                                                                <Button
                                                                    disabled={btnSpinner ? true : false}
                                                                    isDarkTheme={isDarkTheme}
                                                                    ifClicked={() => {
                                                                        if (validataUploadData()) {
                                                                            handleRequestSend()
                                                                        }
                                                                    }}
                                                                    label={btnSpinner ? 'Please wait...' : 'Submit'}
                                                                />
                                                            </StepperFooter>
                                                        </Col>
                                                    )}
                                                </Form>
                                            </Container>
                                        </StepperBody>
                                    </Item>
                                </Stepper>
                            </Col>
                        </Row>
                    </Container>
                </CustomRow>
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Container)`
    padding-top: 2rem;
    ${media.lg`
    width: 90%;
    `};
    ${media.xl`
    width: 90%;
    `};
    ${media.xs`
    padding-top: 0rem;
    `}
    ${media.sm`
    padding-top: 2rem;
    `}
`

const Form = styled(Row)`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`

const CustomRow = styled(Row)`
    justify-content: center;
    position: relative;
    ${media.sm`
    width: 100%;
    `};
`

const Stepper = styled.ul`
    padding: 0;
`

const Item = styled.li`
    list-style: none;
    margin-bottom: 2rem;
`

const StepperBody = styled.div``

const StepperFooter = styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
`

const InputFile = styled.input`
    display: none;
`

const UploadLabel1 = styled.label`
    cursor: pointer;
    max-height: 12rem;
    padding: 5rem 0;
    outline: none;
    display: flex;
    gap: 0.5rem;
    z-index: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    ::placeholder {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const UploadLabel2 = styled(UploadLabel1)``

const UploadLabel3 = styled(UploadLabel1)``

const FlexedCol = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Preview = styled.div`
    width: 12rem;
    height: 12rem;
    background: ${({src, isDarkTheme}) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.blue_light}`)};
    background-size: cover;
    background-position: center;
    border: 0.1rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 0.4rem;
    // cursor: pointer;
`

const PreviewWider = styled.div`
    width: 20rem;
    height: 12rem;
    background: ${({src, isDarkTheme}) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.blue_light}`)};
    background-size: cover;
    background-position: center;
    border: 0.1rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 0.4rem;
    // cursor: pointer;
`

const Close = styled(AiOutlineCloseCircle)`
    // color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
    color: ${palette.red};
    z-index: 1;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 88%;
`

const CloseWide = styled(Close)`
    left: 93%;
`

const MiniSpinner = styled.div`
    width: 12rem;
    position: absolute;
    // z-index: 999;
    top: 20%;
    left: 4%;
    margin: auto;
    bottom: 0;
    // background: ${palette.overlay};
`

const MiniSpinnerWide = styled.div`
    width: 20rem;
    position: absolute;
    // z-index: 999;
    top: 4%;
    left: 4%;
    margin: auto;
    bottom: 0;
    // background: ${palette.overlay};
`

const MiniLoading = styled.img`
    width: 100%;
    // z-index: 999;
    // top: 0;
    // right: 0;
    // left: 0;
    // margin: auto;
    // top: 0;
    // bottom: 0;
    filter: ${({isDarkTheme}) => (isDarkTheme ? `invert(61%) sepia(26%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)` : `invert(61%) sepia(2%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)`)};
    // filter: ${({isDarkTheme}) => (isDarkTheme ? `invert(100%) sepia(16%) saturate(7463%) hue-rotate(290deg) brightness(119%) contrast(115%)` : `invert(61%) sepia(2%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)`)};
`

const InstructionsList = styled.ul``

const SSidebarButton = styled.button`
    ${btnReset};
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black2}` : `${palette.blue_light}`)};
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.blue}`)};
    box-shadow: 0 0 4px ${({theme}) => palette.aqua_blue}, 0 0 7px ${({theme}) => palette.aqua_blue};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: ${({isOpen}) => (!isOpen ? `rotate(180deg)` : `initial`)};
`

const ListItem = styled.li`
    list-style-type: circle;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.heading}` : `${palette.pure_black}`)};
    // color: ${palette.red};
`

const FlexedEnd = styled(Col)`
    display: flex;
    justify-content: end;
`

const Column = styled(Col)`
    display: flex;
    justify-content: space-between;
    border-bottom: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_dark}` : `${palette.gray}`)};
    padding: 0.75rem;
    ${media.xs`
    display: block;
    `};
    ${media.sm`
    display: block;
    `};
    ${media.md`
    display: flex;
    `};
    ${media.lg`
    display: flex;
    `};
`

const FlexLabel = styled(Label)`
    width: 12rem;
    align-items: center;
    display: flex;
    gap: 2px;
`

const Input = styled(InputText)`
    width: 1;
`

const Blocked = styled.div`
    width: 100% !important;
    display: block;
`

const ProcessingText = styled(Alblur)`
    color: ${palette?.green};
`

export default KYCRequest
