import * as types from './types'

export const triggerConnect = () => {
    return {
        type: types.TRIGGER_CONNECT
    }
}

export const setConnected = (payload) => {
    return {
        type: types.CONNECTED_SUCCESS,
        value: payload
    }
}

export const setDisconnected = () => {
    return {
        type: types.DISCONNECTED_SUCCESS
    }
}

export const switchTheme = (payload) => {
    return {
        type: types.SWITCH_THEME,
        value: payload
    }
}

export const saveUser = (payload) => {
    return {
        type: types.SAVE_USER,
        value: payload
    }
}

export const openSideMenu = (payload) => {
    return {
        type: types.OPEN_SIDE_MENU,
        value: payload
    }
}

export const openMainPage = (payload) => {
    return {
        type: types.OPEN_MAIN_PAGE,
        value: payload
    }
}
