import React, { useState, useEffect, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../../assets/common'
import { TabList, Tab } from 'react-tabs'
import axios from 'axios'
import Table from 'react-bootstrap/Table'
import { useSelector, useDispatch } from 'react-redux'
import { Spacer, Flexed, Heading, Text, TabHeading, LoadingPanelContent, LoadingPanel, InfoIcon, SocialIcons, TextPlaceHolder, InputText, Form, Label } from '../../styles/shared'
import { api } from '../../config/index'
import moment from 'moment'
import { ethers } from 'ethers'
import PresaleList from '../../components/PresaleList'
import { PresaleContract } from '../../contracts/PresaleContract'
import Dropdown from '../../components/Dropdown'
import { DocumentLinks, showError, getJsonRpcProvider, getNetworkById } from '../../utils/index'
import { openMainPage } from '../../actions/authActions'
import PresaleDetails from './PresaleDetails'
import FairSaleDetails from '../fairSale/FairSaleDetails'
import PrivateSaleDetails from '../privateSale/PrivateSaleDetails'
import { BsXCircle, BsCheckCircle } from 'react-icons/bs'
import { FaList, FaThLarge } from 'react-icons/fa'
import Button from '../../components/Button'
import { AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai'
import TableViewItem from '../../components/TableViewItem'

// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const ViewAllPresales = ({ preSaleType }) => {
    const _dispatch = useDispatch()
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    const signer = useSelector((state) => state.auth.signer)
    const user = useSelector((state) => state.auth.user)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const [activeTab, setActiveTab] = useState(1)
    const [launchpadInfo, setLaunchpadInfo] = useState()

    const [allProducts, setAllProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [totalLength, setTotalLength] = useState(0)
    const [myLength, setMyLength] = useState(0)
    const [loading, setLoading] = useState(false)
    const [isFilteringInProcess, setIsFilteringInProcess] = useState(false)
    const [statusFilter, setStatusFilter] = useState('')

    const [advanceMode, setAdvanceMode] = useState('block')
    const [searchString, setSearchString] = useState('')
    const [page, setPage] = useState(0)
    const [totalDbItems, setTotalDbItems] = useState(0)

    const limit = 8

    const [option, setOption] = useState([
        { value: '', label: 'All' },
        { value: 'upcoming', label: 'Upcoming' },
        { value: 'live', label: 'Live' },
        { value: 'successful', label: 'Success' },
        { value: 'failed', label: 'Failed' }
    ])

    useEffect(async () => {
        await resetStates()
        if (isMianPageOpen) {
            await doFetchPresales(0)
        }
    }, [preSaleType, signer, isMianPageOpen, connectedNetwork, user])

    const resetStates = async () => {
        setPage(0)
        setAllProducts([])
        setFilteredProducts([])
        setSearchString('')
        setStatusFilter('')
        setActiveTab(1)
        setMyLength(0)
        setTotalLength(0)
    }

    const resetSearch = (tab = 1, resetData = false) => {
        setFilteredProducts([])
        setSearchString('')
        setActiveTab(tab)
        if (!resetData) {
            let mine = 0
            allProducts.forEach((value) => {
                if (value?.userAddress == user?.address) {
                    mine = mine + 1
                }
            })
            setMyLength(mine)
            setTotalLength(allProducts?.length)
            setTimeout(() => {
                setFilteredProducts(allProducts)
            }, 100)
        }
    }

    const fetchSearchedPresales = async () => {
        if (searchString?.length <= 2) {
            return
        }
        setLoading(true)
        setFilteredProducts([])
        await axios
            .get(`${api}/pre_sale/search?q=${searchString}&fair=${preSaleType.isFair}&private=${preSaleType.isPrivate}&chain=${connectedNetwork?.chainId}`)
            .then(async (response) => {
                setLoading(false)
                if (response.data.status) {
                    let listOfSearchItems = []
                    let mine = 0
                    response.data.data.forEach((value) => {
                        if (value?.userAddress == user?.address) {
                            mine = mine + 1
                        }
                        listOfSearchItems.push(value)
                    })
                    setMyLength(mine)
                    setTotalLength(response?.data?.data?.length)
                    setFilteredProducts(listOfSearchItems)
                } else {
                    showError('Something went wrong. Please try again later.')
                }
            })
            .catch(function (error) {
                setLoading(false)
                showError(error.message)
            })
    }

    const doFetchPresales = async (page) => {
        setLoading(true)
        await axios
            .post(`${api}/pre_sale/view_all`, {
                isFairSale: preSaleType.isFair,
                isPrivateSale: preSaleType.isPrivate,
                chainId: connectedNetwork?.chainId,
                page: page,
                limit: limit
            })
            .then(async (response) => {
                setLoading(false)
                if (response.data.status) {
                    setTotalDbItems(response?.data?.data?.count)
                    let listOfAllLaunchpads = []
                    let mine = 0
                    response?.data?.data?.rows?.forEach((value) => {
                        if (value?.userAddress == user?.address) {
                            mine = mine + 1
                        }
                        value.isDataLoaded = false
                        listOfAllLaunchpads.push(value)
                    })
                    setMyLength(page > 0 ? myLength + mine : mine)
                    setTotalLength(page > 0 ? totalLength + response?.data?.data?.rows?.length : response?.data?.data?.rows?.length)
                    setAllProducts(page > 0 ? allProducts?.concat(listOfAllLaunchpads) : listOfAllLaunchpads)
                    setFilteredProducts(page > 0 ? filteredProducts?.concat(listOfAllLaunchpads) : listOfAllLaunchpads)
                } else {
                    showError('Something went wrong. Please try again later.')
                }
            })
            .catch(function (error) {
                setLoading(false)
                showError(error.message)
            })
    }

    const handleLaunchpadFilter = async (tab, status) => {
        setIsFilteringInProcess(true)
        resetSearch(tab, true)
        setTimeout(() => {
            let _filteredProducts = []
            let now = moment().unix()
            if (status == 'upcoming') {
                allProducts?.filter((_product) => {
                    if (moment(parseInt(_product?.startTime) * 1000).unix() > now) {
                        _filteredProducts.push(_product)
                    }
                })
            } else if (status == 'live') {
                allProducts?.filter((_product) => {
                    let stime = moment(parseInt(_product?.startTime) * 1000).unix()
                    let etime = moment(parseInt(_product?.endTime) * 1000).unix()
                    let isHardCapReached = !isNaN(parseFloat(_product?.totalCollected)) ? parseFloat(_product?.totalCollected) == parseFloat(_product?.hardCapInWei) : 0
                    if (stime < now && now < etime && !isHardCapReached) {
                        _filteredProducts.push(_product)
                    }
                })
            } else if (status == 'successful') {
                allProducts?.filter((_product) => {
                    if (_product?.isSuccessful == true) {
                        _filteredProducts.push(_product)
                    }
                })
            } else if (status == 'failed') {
                allProducts?.filter((_product) => {
                    let etime = moment(parseInt(_product?.endTime) * 1000).unix()
                    if (etime <= now && _product?.isSuccessful == false) {
                        _filteredProducts.push(_product)
                    }
                })
            } else {
                _filteredProducts = allProducts
            }
            let _myFilteredProducts = []
            _filteredProducts?.filter((_product) => {
                if (_product?.userAddress == user?.address) {
                    _myFilteredProducts.push(_product)
                }
            })
            setMyLength(_myFilteredProducts?.length)
            setTotalLength(_filteredProducts?.length)
            setFilteredProducts(tab == 1 ? _filteredProducts : _myFilteredProducts)
            setIsFilteringInProcess(false)
        }, 200)
        return true
    }

    const updatePresale = async (presale) => {
        for (let index = 0; index < allProducts?.length; index++) {
            if (allProducts[index].launchpadAddress == presale.launchpadAddress) {
                allProducts[index] = presale
                // filteredProducts[index] = presale
                break
            }
        }
        for (let index = 0; index < filteredProducts?.length; index++) {
            if (filteredProducts[index].launchpadAddress == presale.launchpadAddress) {
                filteredProducts[index] = presale
                break
            }
        }
    }

    return (
        <>
            {isMianPageOpen ? (
                <Wrapper>
                    <>
                        {loading && (
                            <LoadingPanelContent>
                                <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                            </LoadingPanelContent>
                        )}
                        <CustomRow>
                            <Container>
                                <Row>
                                    <Col>
                                        <Grid>
                                            <Heading isDarkTheme={isDarkTheme}>{activeTab == 1 ? `All ${preSaleType.title}s` : activeTab == 2 ? `My ${preSaleType.title}s` : `All ${preSaleType.title}s`}</Heading>
                                            <Flexed direction="row" justify="flex-end">
                                                <DropWrapper>
                                                    <Dropdown
                                                        disabled={isFilteringInProcess}
                                                        isReset={statusFilter === '' ? true : false}
                                                        firstSelected={option[0].label}
                                                        name="collection"
                                                        hasChanged={(value) => {
                                                            setStatusFilter(value)
                                                            handleLaunchpadFilter(activeTab, value)
                                                        }}
                                                        options={option}
                                                    />
                                                    <a target="_blank" href={DocumentLinks(`viewAll${preSaleType.title}`)}>
                                                        <InfoIcon isDarkTheme={isDarkTheme} position={'relative'} marginBottom={0} title="View all presale docs" />
                                                    </a>
                                                </DropWrapper>
                                            </Flexed>
                                        </Grid>
                                    </Col>
                                </Row>
                            </Container>
                            <Col>
                                <STabs>
                                    <DataWrapper isDarkTheme={isDarkTheme}>
                                        <STabList>
                                            <TabHeading
                                                isDarkTheme={isDarkTheme}
                                                onClick={() => {
                                                    setActiveTab(1)
                                                    handleLaunchpadFilter(1, statusFilter)
                                                }}
                                                $active={activeTab == 1}>
                                                All {preSaleType.title}s ({totalLength})
                                            </TabHeading>
                                            <TabHeading
                                                disabled={advanceMode == 'block'}
                                                isDarkTheme={isDarkTheme}
                                                onClick={() => {
                                                    setActiveTab(2)
                                                    handleLaunchpadFilter(2, statusFilter)
                                                }}
                                                $active={activeTab == 2}>
                                                My {preSaleType.title}s ({myLength})
                                            </TabHeading>
                                        </STabList>
                                        <ViewContent direction="row">
                                            {advanceMode == 'list' ? (
                                                <ListView
                                                    isDarkTheme={isDarkTheme}
                                                    onClick={() => {
                                                        setAdvanceMode('block')
                                                    }}
                                                />
                                            ) : (
                                                <GridView
                                                    isDarkTheme={isDarkTheme}
                                                    onClick={() => {
                                                        setAdvanceMode('list')
                                                    }}
                                                />
                                            )}
                                        </ViewContent>
                                    </DataWrapper>
                                    <STabPanel>
                                        <TabContent>
                                            <CustomContainer>
                                                <Row>
                                                    <Column>
                                                        <InputText
                                                            isDarkTheme={isDarkTheme}
                                                            value={searchString}
                                                            placeholder="Enter presale title, presale address, token name, or user address"
                                                            onChange={(e) => {
                                                                setSearchString(e.target.value?.replaceAll('^\\s+', ''))
                                                                if (e.target.value?.length == 0) {
                                                                    resetSearch()
                                                                }
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    fetchSearchedPresales()
                                                                }
                                                            }}
                                                        />
                                                        <SearchIcon onClick={fetchSearchedPresales} isDarkTheme={isDarkTheme} />
                                                        <CloseIcon
                                                            isDarkTheme={isDarkTheme}
                                                            onClick={() => {
                                                                if (searchString.length > 0) {
                                                                    resetSearch()
                                                                }
                                                            }}
                                                        />
                                                    </Column>
                                                    <>
                                                        {advanceMode === 'block' ? (
                                                            <>
                                                                {filteredProducts?.map((value, index) => {
                                                                    return <PresaleList key={index + '_presale'} id={index + '_presale'} _value={value} setLaunchpadInfo={setLaunchpadInfo} updatePresale={updatePresale} />
                                                                })}
                                                                {filteredProducts?.length == 0 && <NotFoundText>{loading ? `Fetching ${preSaleType.title}s` : searchString?.length > 2 ? 'No presale found' : isFilteringInProcess ? 'Filtering Presales' : `${`No ${statusFilter} ${preSaleType.title} found`}`}</NotFoundText>}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Col>
                                                                    <Table bordered variant="dark" responsive>
                                                                        <thead>
                                                                            <TableHead>
                                                                                <Th isDarkTheme={isDarkTheme}>Title</Th>
                                                                                <Th isDarkTheme={isDarkTheme}>Softcap</Th>
                                                                                <Th isDarkTheme={isDarkTheme}>Hardcap</Th>
                                                                                <Th isDarkTheme={isDarkTheme}>KYC</Th>
                                                                                <Th isDarkTheme={isDarkTheme}>Status</Th>
                                                                                <Th isDarkTheme={isDarkTheme}>Social</Th>
                                                                                <Th isDarkTheme={isDarkTheme}>Countdown</Th>
                                                                                <Th isDarkTheme={isDarkTheme}>Action</Th>
                                                                            </TableHead>
                                                                        </thead>

                                                                        <tbody>
                                                                            {filteredProducts?.map((value, index) => {
                                                                                return <TableViewItem key={index + '_presale'} id={index + '_presale'} _value={value} setLaunchpadInfo={setLaunchpadInfo} updatePresale={updatePresale} />
                                                                            })}
                                                                        </tbody>
                                                                    </Table>
                                                                    {filteredProducts?.length == 0 && <NotFoundText>{loading ? `Fetching ${preSaleType.title}s` : searchString?.length > 2 ? 'No presale found' : isFilteringInProcess ? 'Filtering Presales' : `${`No ${statusFilter} ${preSaleType.title} found`}`}</NotFoundText>}
                                                                    <Spacer height={2} />
                                                                </Col>
                                                            </>
                                                        )}
                                                    </>
                                                </Row>
                                                {!loading && statusFilter?.length == 0 && filteredProducts?.length > 0 && allProducts?.length % limit == 0 && searchString?.length == 0 && activeTab == 1 && totalLength < totalDbItems && (
                                                    <Row justifyContent={'center'}>
                                                        <Button
                                                            isDarkTheme={isDarkTheme}
                                                            ifClicked={() => {
                                                                setPage(page + 1)
                                                                setTimeout(() => {
                                                                    doFetchPresales(page + 1)
                                                                }, 120)
                                                            }}
                                                            label={'Load More Presales'}
                                                        />
                                                    </Row>
                                                )}
                                            </CustomContainer>
                                        </TabContent>
                                    </STabPanel>
                                </STabs>
                            </Col>
                        </CustomRow>
                    </>
                    <Spacer height={1} />
                </Wrapper>
            ) : launchpadInfo?.isFair == true ? (
                <FairSaleDetails _launchpadInfo={launchpadInfo} />
            ) : launchpadInfo?.isPrivate == true ? (
                <PrivateSaleDetails _launchpadInfo={launchpadInfo} />
            ) : (
                <PresaleDetails _launchpadInfo={launchpadInfo} />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    ${media.lg`
        width: 90%;
    `};
    ${media.xl`
        width: 90%;
    `};
`

const CustomContainer = styled(Container)`
    padding: 0;
`

const CustomRow = styled(Row)`
    justify-content: center;
`

const STabs = styled(Tab)`
    font-size: 1.2rem;
    width: 100%;
    color: black;
    list-style: none;
    // cursor: pointer;
`

const STabList = styled(TabList)`
    list-style-type: none;
    padding: 0.3rem 0rem;
    display: flex;
    justify-content: center;
    margin: 0 0.938rem;
    border-bottom: 1px solid #eee;
    position: relative;
`

STabList.tabsRole = 'TabList'

TabHeading.tabsRole = 'Tab'

const STabPanel = styled.div`
    min-height: 40vh;
`

const TabContent = styled.div`
    min-height: 40vh;
    padding: 2rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
`

STabPanel.tabsRole = 'TabPanel'

const DataWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    align-items: center;
    ${media.xs`
        grid-template-columns: 1fr;
    `}
    ${media.sm`
        grid-template-columns: 1fr;
    `}
    ${media.md`
        grid-template-columns: 1fr 1fr;
    `}
`

const NotFoundText = styled(Col)`
    width: 100% !important;
    display: flex;
    align-items: center;
    color: ${palette.dark_gray};
    justify-content: center;
    padding-top: 5rem;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
`

const DropWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 2rem;
    width: 16rem;
    right: 0;
    bottom: 0.5rem;
    gap: 0.5rem;
`

const ViewContent = styled(Flexed)`
    position: absolute;
    right: 0;
`

const ListView = styled(FaThLarge)`
    color: ${({ isDarkTheme }) => (isDarkTheme ? ` ${palette.aqua_blue}` : ` ${palette.blue}`)};
    cursor: pointer;
`

const GridView = styled(FaList)`
    color: ${({ isDarkTheme }) => (isDarkTheme ? ` ${palette.aqua_blue}` : ` ${palette.blue}`)};
    cursor: pointer;
`

const Column = styled(Col)`
    margin-bottom: 1rem;
`

const SearchIcon = styled(AiOutlineSearch)`
    position: absolute;
    right: 3.2rem;
    top: 0.6rem;
    cursor: pointer;
    color: ${({ isDarkTheme }) => (isDarkTheme ? ` ${palette.aqua_blue}` : ` ${palette.blue}`)};
`

const CloseIcon = styled(AiOutlineCloseCircle)`
    position: absolute;
    right: 1.6rem;
    top: 0.6rem;
    cursor: pointer;
    color: ${({ isDarkTheme }) => (isDarkTheme ? ` ${palette.aqua_blue}` : ` ${palette.blue}`)};
`

const TableHead = styled.tr`
    background: linear-gradient(90deg, #00b2cc 5.72%, rgba(0, 177, 204, 0.6039215686274509) 95.59%) !important;
    color: #fff !important;
`

const Th = styled.th`
    color: ${({ isDarkTheme }) => (isDarkTheme ? ` ${palette.white}` : ` ${palette.white}`)};
    font-size: 0.9rem;
    font-weight: bold !important;
`
export default ViewAllPresales
