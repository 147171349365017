import React, {useState, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../assets/common'
import styled from 'styled-components'
import {Flexed, Heading, TabHeading} from '../../styles/shared'
import {TabList, Tab} from 'react-tabs'
import LockList from '../../components/LockList'
import {useDispatch, useSelector} from 'react-redux'

import axios from 'axios'
import {api} from '../../config'
import LockDetails from '../../components/LockDetails'
import {ethers} from 'ethers'
import {LoadingPanelContent, LoadingPanel, InfoIcon} from '../../styles/shared'
import {DocumentLinks, showError, getJsonRpcProvider, getNetworkById} from '../../utils/index'
import Button from '../../components/Button'
import {openMainPage} from '../../actions/authActions'
import {useParams} from 'react-router-dom'

const ViewAllLocks = ({lockType}) => {
    const _dispatch = useDispatch()
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const user = useSelector((state) => state.auth.user)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    const [activeTab, setActiveTab] = useState(1)
    const [allProducts, setAllProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])

    const [loading, setLoading] = useState(false)
    const [lockInfo, setLockInfo] = useState('')

    const [totalLength, setTotalLength] = useState(0)
    const [myLength, setMyLength] = useState(0)
    const [page, setPage] = useState(0)
    const [totalDbLength, setTotalDbLength] = useState(0)

    const limit = 8

    useEffect(async () => {
        await resetStates()
        if (isMianPageOpen) {
            await doFetchAllLocks(page)
        }
    }, [lockType, isMianPageOpen, connectedNetwork, user])

    const resetStates = async () => {
        setPage(0)
        setAllProducts([])
        setFilteredProducts([])
        setActiveTab(1)
        setMyLength(0)
        setTotalLength(0)
    }

    const doFetchAllLocks = async (page) => {
        setLoading(true)
        axios
            .post(`${api}/lock/view_all`, {
                chainId: connectedNetwork?.chainId,
                page: page,
                limit: limit
            })
            .then(async (response) => {
                setLoading(false)
                if (response.data.status) {
                    setTotalDbLength(response?.data?.data?.count)
                    let listOfAllLocks = []
                    let mine = 0
                    response.data.data?.rows?.forEach((value) => {
                        if (value?.userAddress == user?.address) {
                            mine = mine + 1
                        }
                        listOfAllLocks.push(value)
                    })
                    setMyLength(page > 0 ? myLength + mine : mine)
                    setTotalLength(page > 0 ? totalLength + response?.data?.data?.rows?.length : response?.data?.data?.rows?.length)
                    setAllProducts(page > 0 ? allProducts?.concat(listOfAllLocks) : listOfAllLocks)
                    setFilteredProducts(page > 0 ? filteredProducts?.concat(listOfAllLocks) : listOfAllLocks)
                } else {
                    showError('Something went wrong. Please try again later.')
                }
            })
            .catch(function (error) {
                setLoading(false)
                showError(error.message)
            })
    }

    const handleFilter = async (tab) => {
        setFilteredProducts([])
        setTimeout(() => {
            let _filteredProducts = []
            let _myFilteredProducts = []

            _filteredProducts = allProducts

            _filteredProducts?.filter((_product) => {
                if (_product?.userAddress == user?.address) {
                    _myFilteredProducts.push(_product)
                }
            })

            setMyLength(_myFilteredProducts.length)
            setTotalLength(_filteredProducts.length)
            setFilteredProducts(tab == 1 ? _filteredProducts : _myFilteredProducts)
        }, 200)
        return true
    }

    const updateLock = async (lock) => {
        for (let index = 0; index < allProducts.length; index++) {
            if (allProducts[index].lockAddress == lock.lockAddress) {
                allProducts[index] = lock
                break
            }
        }
    }

    return (
        <>
            {isMianPageOpen ? (
                <>
                    {loading && (
                        <LoadingPanelContent>
                            <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                        </LoadingPanelContent>
                    )}
                    <Wrapper>
                        <Row>
                            <Col lg={6} md={6} sm={6} xs={8}>
                                <Heading margin="0rem 0rem 1.25rem 0rem" isDarkTheme={isDarkTheme}>
                                    Locked Tokens
                                </Heading>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={4}>
                                <Flexed direction="row" align="center" justify="flex-end">
                                    <a target="_blank" href={DocumentLinks('viewAllLocks')}>
                                        <InfoIcon isDarkTheme={isDarkTheme} position={'relative'} marginBottom={0} />
                                    </a>
                                </Flexed>
                            </Col>
                        </Row>
                        <CustomRow>
                            <Col>
                                <STabs>
                                    <DataWrapper isDarkTheme={isDarkTheme}>
                                        <STabList>
                                            <TabHeading
                                                isDarkTheme={isDarkTheme}
                                                onClick={() => {
                                                    setActiveTab(1)
                                                    handleFilter(1)
                                                }}
                                                $active={activeTab == 1}>
                                                All Locked Tokens ({totalLength})
                                            </TabHeading>
                                            <TabHeading
                                                isDarkTheme={isDarkTheme}
                                                onClick={() => {
                                                    setActiveTab(2)
                                                    handleFilter(2)
                                                }}
                                                $active={activeTab == 2}>
                                                My Locked Tokens ({myLength})
                                            </TabHeading>
                                        </STabList>
                                    </DataWrapper>
                                    <STabPanel>
                                        <TabContent>
                                            <CustomContainer>
                                                <Row>
                                                    {filteredProducts?.map((value, index) => {
                                                        return <LockList key={index + '_lock'} id={index + '_lock'} _value={value} setLockInfo={setLockInfo} updateLock={updateLock} />
                                                    })}

                                                    {filteredProducts?.length == 0 && <NotFoundText>{loading ? 'Fetching locked tokens' : 'No locked token found'}</NotFoundText>}
                                                </Row>
                                                {!loading && filteredProducts?.length > 0 && allProducts?.length % limit === 0 && activeTab == 1 && totalLength < totalDbLength && (
                                                    <Row justifyContent={'center'}>
                                                        <Button
                                                            isDarkTheme={isDarkTheme}
                                                            ifClicked={() => {
                                                                setPage(page + 1)
                                                                setTimeout(() => {
                                                                    doFetchAllLocks(page + 1)
                                                                }, 120)
                                                            }}
                                                            label={'Load More'}
                                                        />
                                                    </Row>
                                                )}
                                            </CustomContainer>
                                        </TabContent>
                                    </STabPanel>
                                </STabs>
                            </Col>
                        </CustomRow>
                    </Wrapper>
                </>
            ) : (
                <LockDetails _lockInfo={lockInfo} />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    ${media.lg`
        width: 90%;
    `};
    ${media.xl`
        width: 90%;
    `};
`
const CustomContainer = styled(Container)`
    padding: 0;
`

const CustomRow = styled(Row)`
    justify-content: center;
`

const DataWrapper = styled.div`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    // border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    // height: 5rem;
    // border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

const STabs = styled(Tab)`
    font-size: 1.2rem;
    width: 100%;
    list-style: none;
    // cursor: pointer;
`

const STabList = styled(TabList)`
    list-style-type: none;
    padding: 0.3rem 0rem;
    display: flex;
    justify-content: center;
    margin: 0 0.938rem;
    border-bottom: 0.5px solid #eee;
    position: relative;
`

STabList.tabsRole = 'TabList'

TabHeading.tabsRole = 'Tab'

const STabPanel = styled.div`
    min-height: 40vh;
`

const TabContent = styled.div`
    min-height: 40vh;
    padding: 2rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
`

STabPanel.tabsRole = 'TabPanel'

const NotFoundText = styled(Col)`
    width: 100 %;
    display: flex;
    align-items: center;
    color: ${palette.dark_gray};
    justify-content: center;
    padding-top: 5rem;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
`

export default ViewAllLocks
