import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { palette } from '../assets/common'
import { useSelector } from 'react-redux'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

const Dropdown = ({ isReset, firstSelected, options, name, hasChanged, error, disabled, width }) => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const myRef = useRef()

    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(firstSelected)

    Dropdown['handleClickOutside_' + name] = () => {
        setIsOpen(false)
    }

    const toggling = () => {
        if (options?.length > 0) {
            setIsOpen(!isOpen)
        }
    }

    useEffect(async () => {
        if (isReset) {
            setSelectedOption(firstSelected == 'All' ? firstSelected : '')
        }
    }, [isReset])

    const onOptionClicked = (option) => () => {
        setSelectedOption(option.label)
        hasChanged(option.value)
        setIsOpen(false)
    }

    const handleClickOutside = (e) => {
        if (!myRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    })

    return (
        <DropDownContainer ref={myRef} width={width} isdisabled={disabled || options?.length == 0} isDarkTheme={isDarkTheme} error={disabled || options?.length == 0 ? false : error}>
            <DropDownHeader onClick={() => toggling()}>
                {selectedOption || 'Select value'} {isOpen ? <BsChevronUp /> : <BsChevronDown />}
            </DropDownHeader>
            {isOpen && (
                <DropDownListContainer isdisabled={disabled}>
                    <DropDownList isDarkTheme={isDarkTheme}>
                        {options?.map((option) => (
                            <ListItem isDarkTheme={isDarkTheme} onClick={onOptionClicked(option)} key={Math.random()}>
                                {option.label}
                            </ListItem>
                        ))}
                    </DropDownList>
                </DropDownListContainer>
            )}
        </DropDownContainer>
    )
}

const DropDownContainer = styled('button')`
    color: ${({ isDarkTheme, isdisabled }) => (isdisabled ? `${palette.charcol}` : isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    border: 1px solid ${({ error, isDarkTheme }) => (error ? `${palette.red}` : isDarkTheme ? `${palette.charcol}` : `${palette.blue}`)};
    background: ${({ isDarkTheme, isdisabled }) => (isDarkTheme ? (isdisabled ? `#565656` : 'transparent') : isdisabled ? 'transparent' : 'transparent')};
    border-radius: 0.47rem;
    padding: 0.2rem;
    letter-spacing: 0.1em;
    cursor: ${({ isdisabled }) => (isdisabled ? `no-drop` : `pointer`)};
    position: relative;
    font-size: 0.8rem;
    width: ${({ width }) => (width ? `${width}rem` : '100%')};
    text-align: left;
    &:hover {
        box-shadow: 0 0 0.31rem ${({ error, isdisabled }) => (isdisabled ? 'none' : error ? `${palette.red}` : 'rgba(0, 0, 0, 0.25)')};
    }
    &:focus {
        border: 1px solid ${({ error, isDarkTheme, isdisabled }) => (isdisabled ? 'none' : error ? `${palette.red}` : isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    }
    &:focus #icon {
        color: ${({ isDarkTheme, isdisabled }) => (isdisabled ? `${palette.charcol}` : isDarkTheme ? `${palette.aqua_blue}` : `${palette.dark_gray}`)};
    }
`

const Icon = styled.i`
    color: ${palette.charcol};
    font-size: 1rem;
    margin-left: 1rem;
`

const DropDownHeader = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem;
`

const DropDownListContainer = styled('div')`
    display: ${({ isdisabled }) => (isdisabled ? `none` : `block`)};
    position: absolute;
    left: 0;
    right: 0;
    top: 2.5rem;
    z-index: 99;
`

const DropDownList = styled('ul')`
    padding: 0;
    border-radius: 0.5rem;
    text-align: left;
    margin: 0;
    background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    box-sizing: border-box;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.pure_black}`)};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.8rem;
    line-height: 1.54rem;
    font-weight: 500;
    height: auto;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    letter-spacing: 0.1em;

    /* width */
    ::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #eee;
        border-radius: 1rem;
        border: 0.01rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
        border-width: 0rem 0.1rem 0rem 0rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 1rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const ListItem = styled('li')`
	list-style: none;
	white-space: nowrap;
	padding: 0.2rem;
    &:not(:last-child){
		border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    }
	&:hover {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.gray_gradient}` : `${palette.white}`)};
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.blue}`)};
	}
	&:last-child {
		border - bottom: 0;
	}
`

export default Dropdown
