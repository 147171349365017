import React, {useState, useEffect} from 'react'
import './App.css'
import styled from 'styled-components'
import {ThemeProvider} from 'styled-components'
import Layout from './components/Layout'
import Home from './pages/Home'
import CreatePresale from './pages/normalSale/CreatePresale'
import ViewAllPresales from './pages/normalSale/ViewAllPresales'
import ViewAllAirdrops from './pages/airdrop/ViewAllAirdrops'
import CreateAirdrop from './pages/airdrop/CreateAirdrop'
import Footer from './components/Footer'
import {Route, Routes} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.css'
import {GlobalStyle} from './styles/globalStyles'
import {darkTheme, lightTheme} from './styles/theme'
import CreateLock from './pages/locks/CreateLock'
import CreatePrivateSale from './pages/privateSale/CreatePrivateSale'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import ViewAllLocks from './pages/locks/ViewAllLocks'
import CreateFairSale from './pages/fairSale/CreateFairSale'
import CreateToken from './pages/token/CreateToken'
import ViewAllMyTokens from './pages/token/ViewAllMyTokens'
import Leaderboard from './pages/leaderboard'
import {PresaleTypes} from './utils'
import LockDetails from './components/LockDetails'
import AirdropDetails from './components/AirdropDetails'
import PrivateSaleDetails from './pages/privateSale/PrivateSaleDetails'
import FairSaleDetails from './pages/fairSale/FairSaleDetails'
import PresaleDetails from './pages/normalSale/PresaleDetails'
import {useSelector} from 'react-redux'

export const ThemeContext = React.createContext(null)

function App() {
    const [theme, setTheme] = useState('light')
    const themeStyle = theme === 'light' ? lightTheme : darkTheme
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    useEffect(() => {
        if (isDarkTheme) {
            document.body.style.backgroundColor = '#09080a'
        } else {
            document.body.style.backgroundColor = '#ffffff'
        }
    }, [isDarkTheme])

    return (
        <ThemeContext.Provider value={{setTheme, theme}}>
            <ThemeProvider theme={themeStyle}>
                <GlobalStyle />
                <Layout>
                    <AppContent className="App">
                        <Routes>
                            <Route path="/" element={<Home />}></Route>
                            <Route path="/create-presale" element={<CreatePresale />}></Route>
                            <Route path="/view-presales" element={<ViewAllPresales preSaleType={PresaleTypes.Normal} />}></Route>
                            <Route path="/create-fair-sale" element={<CreateFairSale />}></Route>
                            <Route path="/view-fair-sales" element={<ViewAllPresales preSaleType={PresaleTypes.Fair} />}></Route>
                            <Route path="/create-private-sale" element={<CreatePrivateSale />}></Route>
                            <Route path="/view-private-sales" element={<ViewAllPresales preSaleType={PresaleTypes.Private} />}></Route>
                            <Route path="/create-lock" element={<CreateLock />}></Route>
                            <Route path="/view-all-locks/" element={<ViewAllLocks lockType={'token'} />}></Route>
                            <Route path="/create-airdrop" element={<CreateAirdrop />}></Route>
                            <Route path="/airdrop-list" element={<ViewAllAirdrops airdropType={'token'} />}></Route>
                            <Route path="/create-token" element={<CreateToken />}></Route>
                            <Route path="/my-tokens" element={<ViewAllMyTokens />}></Route>
                            <Route path="/leaderboard" element={<Leaderboard />}></Route>
                            <Route path="/presale-details" element={<PresaleDetails />}></Route>
                            <Route path="/fair-sale-details" element={<FairSaleDetails />}></Route>
                            <Route path="/private-sale-details" element={<PrivateSaleDetails />}></Route>
                            <Route path="/lock-details" element={<LockDetails />}></Route>
                            <Route path="/airdrop-details" element={<AirdropDetails />}></Route>
                        </Routes>
                        <Footer />
                        <ToastContainer position="top-center" autoClose={9000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                    </AppContent>
                </Layout>
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}

const AppContent = styled.div`
    padding-top: 6rem;
`

export default App
