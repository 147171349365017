import styled from 'styled-components'
import { palette } from '../assets/common'
import DateTimePicker from 'react-datetime-picker'
import { Container, Col, media } from 'styled-bootstrap-grid'
import { Tab } from 'react-tabs'
import { AiFillTwitterSquare, AiOutlineInfoCircle } from 'react-icons/ai'
import { BsTelegram, BsGlobe2, BsDiscord, BsFileEarmarkText, BsFillShareFill } from 'react-icons/bs'
import { Tooltip } from 'react-bootstrap'

export const HeadingColord = styled.h1`
    font-size: 1.25rem;
    margin: ${({ margin }) => (margin ? `${margin}` : '0')};
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.heading}` : `${palette.pure_black}`)};
    text-align: ${({ isCentered }) => (isCentered ? `center` : 'inherit')};
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background: ${({ isDarkTheme }) => (isDarkTheme ? `linear-gradient(90deg, #2596be 3.42%, #00dfff 101.5%)` : `${palette.blue}`)};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`

export const Heading = styled.h1`
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '1.25rem')};
    margin: ${({ margin }) => (margin ? `${margin}` : '0')};
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.heading}` : `${palette.pure_black}`)};
    text-align: ${({ isCentered }) => (isCentered ? `center` : 'inherit')};
    // background: -webkit-linear-gradient(45deg, #0085ff, #00dfff 50%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    word-break: ${({ wordBreak }) => (wordBreak ? `${wordBreak}` : 'break-all')};
    line-height: ${({ lineHeight }) => `${lineHeight}`};
    letter-spacing: ${({ letterSpacing }) => `${letterSpacing}`};
    & > span {
        color: ${palette.aqua_blue};
        font-family: inherit;
    }
`
export const Text = styled.p`
    // line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '2.16rem')};
    line-height: ${({ small, medium, xsmall }) => (small ? '1.54rem' : medium ? '1.85rem' : xsmall ? '1.16rem' : '2.16rem')};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    font-size: ${({ xsmall, small, medium }) => (small ? '1rem' : medium ? '1.231rem' : xsmall ? '0.875rem' : '1.39rem')};
    font-family: ${({ font }) => font};
    // color: ${({ color }) => palette[color]};
    color: ${({ color, isDarkTheme }) => (color ? palette[color] : isDarkTheme ? palette.off_white : palette.pure_black)};
    margin: ${({ margin }) => `${margin}`};
    text-align: ${({ isCentered }) => (isCentered ? `center` : 'inherit')};
    cursor: ${({ pointer }) => (pointer ? `pointer` : 'inherit')};
`

export const Flexed = styled.div`
    display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
    flex-direction: column;
    flex-direction: ${({ direction }) => direction};
    align-items: ${({ align }) => align};
    justify-content: ${({ justify }) => justify};
    margin: ${({ margin }) => `${margin}`};
`

export const Divider = styled.hr`
    border: 0;
    border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.gray}` : `${palette.gray_light}`)};

    margin: ${({ margin }) => `${margin}`};
`

export const Spacer = styled.div`
    height: ${({ height }) => `${height}rem`};
`

export const InputText = styled.input`
    outline: none;
    disabled: ${({ disabled }) => (disabled === true ? true : false)};
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

export const TextArea = styled.textarea`
    outline: none;
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

export const RadioBox = styled.input`
    border: 2px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    box-shadow: 0 0 0 1px ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    appearance: none;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    // transition:all ease-in 0.2s;
    cursor: ${({ disabled }) => (disabled ? `no-drop` : `pointer`)};
    &:checked {
        background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    }
`

export const CheckLabel = styled.label`
    font-size: 0.9rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.description_Text}` : `${palette.pure_black}`)};
    margin-left: 0.5rem;
    cursor: ${({ disabled }) => (disabled ? `no-drop` : `pointer`)};
    opacity: ${({ disabled }) => (disabled ? `0.5` : `1`)};
    & span {
        color: ${palette.red};
    }
`

export const Select = styled.select`
    outline: none;
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    option {
        color: black;
        background: white;
        display: flex;
        white-space: pre;
        min-height: 20px;
        padding: 0px 2px 1px;
    }
`

export const Label = styled.p`
    margin-bottom: 0.5rem;
    min-width: fit-content !important;
    font-size: 0.9rem;
    font-weight: bold;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
    & span {
        color: ${palette.red};
    }
`

export const Info = styled.p`
    width: max-content;
    font-size: 0.8rem;
    width: 100%;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    margin: 0.2rem 0rem;
    // background: -webkit-linear-gradient(45deg , #0085ff 26%, #00dfff);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
`

export const Alblur = styled.p`
    width: 100%;
    font-size: 0.8rem;
    color: red;
    margin: ${({ margin }) => (margin ? margin : ``)};
`

export const Para = styled.p`
    width: 100%;
    opacity: 0.5rem;
    font-size: 0.9rem;
    line-height: 1.5715;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.dark_gray}`)};
    padding: 0rem 2rem 0rem 2.5rem;
    margin: 0;
    ${media.xs`
    font-size: 0.48rem;
  `}
    ${media.sm`
    font-size: 0.65rem;
    padding: 0rem 0rem 0rem 2rem;
  `}
    ${media.md`
    font-size: 0.9rem;
    padding: 0rem 0rem 0rem 2.5rem;
  `}
  ${media.lg`
    font-size: 0.9rem;
    padding: 0rem 2rem 0rem 2.5rem;
  `}
`
export const TabHeading = styled(Tab)`
    &:not(:last-child){
        margin-right:2rem;
    }
    font-family: 'bicubik';
	letter-spacing: 0.05em;
    margin-bottom: -6.5px;
    text-align: center;
    font-size: 0.9rem;
    padding: 0.3rem 0rem 0.5rem 0rem;
    user-select: none;
    cursor: pointer;
    border-bottom: ${({ $active, isDarkTheme }) => ($active ? (isDarkTheme ? `0.2rem solid ${palette.aqua_blue} !important` : `0.2rem solid ${palette.blue}`) : ``)};
    color ${({ $active, isDarkTheme }) => (isDarkTheme ? ($active ? `${palette.aqua_blue}` : `${palette.off_white}`) : $active ? `${palette.blue}` : `${palette.pure_black}`)};
    // background: -webkit-linear-gradient(45deg , #0085ff 26%, #00dfff);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
`

export const ProfileImageDiv = styled.div`
    width: 3.5rem;
    height: 3.5rem;
    background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.blue_light}`)};
    background-size: cover;
    background-position: center;
    border: 0.1rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 3rem;
    margin-right: 1rem;
    cursor: pointer;
`

export const Status = styled.div`
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 0.8rem;
    line-height: 1.38rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    padding: 0.3rem 0rem 0.3rem 1rem;
    border-radius: 2rem;
    color: ${({ status, isDarkTheme }) => (status == 'Live' ? `${palette.green}` : status == 'Upcoming' ? (isDarkTheme ? `${palette.yellow}` : `${palette.binance}`) : `${palette.red}`)};
    ${media.xs`
      margin-top:1rem;
    `};
    ${media.sm`
      display: flex;
      justify-content:flex-end;
    `};
`
export const Dot = styled.span`
    background-color: ${({ status, isDarkTheme }) => (status == 'Live' ? `${palette.green}` : status == 'Upcoming' ? (isDarkTheme ? `${palette.yellow}` : `${palette.binance}`) : `${palette.red}`)};
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    margin-right: 0.3rem;
`

export const NetworkContent = styled.p`
    color: ${({ chainId, isDarkTheme }) => (chainId == '1' || chainId == '5' ? (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`) : chainId == '56' || chainId == '97' ? `${palette.binance}` : `${palette.polygon}`)};
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: ${({ smaller }) => (smaller ? '0.8rem' : '0.875rem')};
    text-align: right;
`

export const StatusContent = styled.p`
    color: ${({ status, isDarkTheme }) => (status == 'upcoming' ? (isDarkTheme ? `${palette.yellow}` : `${palette.binance}`) : status == 'inprogress' ? `${palette.yellow}` : status == 'failed' ? `${palette.red}` : isDarkTheme ? `${palette.green}` : `${palette.green_text}`)};
    // font-size: 0.875rem;
    font-size: 15px;
`

export const Bar = styled.div`
    margin: 0.5rem 0rem;
    width: 17rem;
    overflow: hidden;
    border-radius: 1rem;
    background: ${palette.charcol_light};
    height: 0.8rem;
`

export const ProgressBar = styled.div`
    width: ${({ width }) => `${width}%`};
    overflow: hidden;
    border-radius: 1rem;
    background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    height: 0.8rem;
`

export const BarContent = styled.p`
    width: 17rem;
    margin-left: 0.2rem;
    text-align: right;
    color: ${({ isDarkTheme, color }) => (color ? `${palette[color]}` : isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    font-size: 0.8rem;
`

export const LoadingPanelContent = styled.div`
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    background: ${palette.overlay};
`

export const LoadingPanel = styled.img`
    width: 20%;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    filter: ${({ isDarkTheme }) => (isDarkTheme ? `invert(61%) sepia(26%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)` : `invert(61%) sepia(2%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)`)};
    // filter: ${({ isDarkTheme }) => (isDarkTheme ? `invert(100%) sepia(16%) saturate(7463%) hue-rotate(290deg) brightness(119%) contrast(115%)` : `invert(61%) sepia(2%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)`)};
`

export const StepperHeading = styled.div`
    font-size: 1rem;
    z-index: 2;
    padding: 0rem 1rem 0rem 0.5rem;
    background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.pie_chart_bg}` : `${palette.pure_white}`)};
    color: ${({ active, isDarkTheme }) => (active ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.dark}`) : `${palette.dark_gray}`)};
    ${media.xs`
    font-size: 0.5rem;
  `}
    ${media.sm`
    font-size: 0.7rem;
  `}
    ${media.md`
    font-size: 0.9rem;
  `}
  ${media.lg`
    font-size: 0.9rem;
  `}
`

export const Step = styled.div`
    width: 2rem;
    background: ${({ active, isDarkTheme }) => (active ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.black}` : `${palette.white}`)};
    color: ${({ active, isDarkTheme }) => (active ? (isDarkTheme ? `${palette.black}` : `${palette.pure_white}`) : isDarkTheme ? `${palette.dark_gray}` : `${palette.dark}`)};
    border: 1px solid ${({ active, isDarkTheme }) => (active ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : `${palette.gray_light}`)};
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    cursor: pointer;
    // box-shadow: 0 0 4px rgb(230 230 230), 0 0 7px rgb(255 255 255);
    z-index: 3;
`

export const AddressText = styled.a`
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    word-wrap: break-word;
    &:hover {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
        opacity: 0.66;
    }
`

export const DetailLabel = styled.span`
    width: ${({ width }) => (width ? `${width}rem` : 'max-content;')};
    // font-size: 0.875rem;
    font-size: 15px;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
`

export const Content = styled.p`
    margin-left: 0.2rem;
    // font-size: 0.875rem;
    font-size: 15px;
    text-align: right;
    color: ${({ isDarkTheme, color }) => (color ? `${palette[color]}` : isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    word-break: break-all;
`

export const SocialIcons = styled.img`
    cursor: pointer;
    width: 1.5rem;
    &:hover {
        opacity: 0.66;
        // filter: ${({ isDarkTheme }) => (isDarkTheme ? 'brightness(10000%)' : 'brightness(30%)')};
    }
    ${media.xs`
    width: 1.2rem;
    height: 1.2rem;
    `}
`

export const textLoadingWrapper = (comparator, length, height, left) => {
    return (
        !comparator &&
        `width: ${length ? length : '5rem'};
    height: ${height ? height : '1rem'};
    left: ${left ? left : '0'};
    border-radius: 3rem;
    background-image: repeating-linear-gradient(90deg, ${palette.charcol_light}, gray,gray 100%);
    background-repeat-x: repeat;
    background-size: 500% 100%;
    margin-bottom:1ch;
    overflow: hidden;
    position: relative;
    animation-fill-mode: forwards;
    animation: loading linear 2s infinite;
    opacity:0.4;`
    )
}

export const TextPlaceHolder = styled.div`
    ${({ width, height, left }) => textLoadingWrapper(undefined, width, height, left)}
`

export const InfoIcon = styled(BsFileEarmarkText)`
    color: ${({ isDarkTheme }) => (isDarkTheme ? ` ${palette.aqua_blue}` : ` ${palette.blue}`)};
    right: ${({ right }) => `${right}`};
    top: ${({ top }) => `${top}`};
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1;
    &:hover {
        opacity: 0.7;
    }
    ${media.xs`
    position: ${({ xsPosition }) => (xsPosition ? `${xsPosition}` : 'relative')};
        margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}` : '1rem')};
    `}
    ${media.sm`
        position: ${({ position }) => (position ? `${position}` : 'absolute')};
    `}
`

export const ShareIconContainer = styled.div`
    position: absolute;
    right: ${({ right }) => (right ? `${right}` : '6rem')};
    top: 1.2rem;
`

export const ShareIcon = styled(BsFillShareFill)`
    color: ${({ isDarkTheme }) => (isDarkTheme ? ` ${palette.aqua_blue}` : ` ${palette.blue}`)};
    right: ${({ right }) => `${right}`};
    top: ${({ top }) => `${top}`};
    font-size: 1.25rem;
    cursor: pointer;
    z-index: 1;
    &:hover {
        opacity: 0.7;
    }
    ${media.xs`
    position: ${({ xsPosition }) => (xsPosition ? `${xsPosition}` : 'relative')};
        margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}` : '1rem')};
    `}
    ${media.sm`
        position: ${({ position }) => (position ? `${position}` : 'absolute')};
    `}
`

export const FlexedEnd = styled(Col)`
    display: flex;
    padding: 0;
    justify-content: end;
`

export const FlexedStart = styled(Col)`
    display: flex;
    padding: 0;
    justify-content: start;
`

export const Blocked = styled.div`
    width: ${({ width }) => (width ? `${width}%` : '100%')};
    // width: 100% !important;
    display: block;
`

export const Form = styled(Container)`
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 1rem;
    position: relative;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`
export const BarBadgeWrapper = styled.div`
    position: absolute;
    right: ${({ testNetBadge }) => (testNetBadge ? `` : `0rem`)};
    top: ${({ testNetBadge }) => (testNetBadge ? `` : `-0.188rem`)};
    transform: ${({ testNetBadge }) => (testNetBadge ? `rotate(0deg)` : `rotate(90deg)`)};
`

export const BarBadge = styled.img`
    width: ${({ testNetBadge }) => (testNetBadge ? `100%` : `4.5rem`)};
`

export const BarBadgeText = styled.p`
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.pure_white}`)};
    font-size: 0.8rem;
    position: absolute;
    top: 1.4rem;
    font-weight: 600;
    left: 0.19rem;
    transform: rotate(313deg);
`

export const FailedBarBadgeText = styled.p`
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.red}`)};
    font-size: 0.8rem;
    position: absolute;
    display:flex;
    align-items: center;
    top: 1.45rem;
    font-weight: 600;
    left: 0.7rem;
    transform: rotate(313deg);
`

export const Badge = styled.img`
    width: 2.5rem;
    position: absolute;
    left: 0rem;
    top: -2px;
`

export const TooltipBox = styled(Tooltip)`
    border-radius: 1rem;
    padding: 0.05rem 0.2rem;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px;
`

export const renderTooltip = (props) => <TooltipBox {...props}>{props}</TooltipBox>

export const CreateTokenLabel = styled(Label)`
    cursor: pointer;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    color: ${palette?.white};
    margin-bottom: 0.15rem !important;
    border-radius: 0.25rem;
    padding: 0 0.25rem;
`
