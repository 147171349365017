import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../assets/common'
import {useDispatch, useSelector} from 'react-redux'
import {ethers} from 'ethers'
import {connect} from 'react-redux'
import {api, kycUrl, url} from '../../config/index'
import {LpTokensContract} from '../../contracts/LpTokens'
import {FairPresaleContract} from '../../contracts/FairPresaleContract'
import moment from 'moment'
import CountdownTimer from '../../components/CountdownTimer'
import {useNavigate} from 'react-router-dom'
import {useCountdown, getPassedTime} from '../../hooks'
import Button from '../../components/Button'
import {Flexed, NetworkContent, StatusContent, ProfileImageDiv, Status, Dot, Bar, ProgressBar, BarContent, LoadingPanelContent, Alblur, LoadingPanel, AddressText, Heading, DetailLabel, Content, SocialIcons, TextPlaceHolder, InfoIcon, Blocked, FlexedEnd, BarBadgeWrapper, BarBadge, BarBadgeText, Badge, ShareIcon, ShareIconContainer, renderTooltip} from '../../styles/shared'
import Chart from 'react-google-charts'
import {updatePresale, getNetworkById, DocumentLinks, showConnectionError, showError, showSuccess, preventNegativeValues, getJsonRpcProvider} from '../../utils'
import {img_url_regex, startsWithNumber, url_regex} from '../../utils/regex'
import {Disqussion} from '../../components/Disqussion'
import KYCRequest from '../../components/KYCRequest'
import {timeAfterMinutes} from '../../utils/timeAfterMinutes'
import {openMainPage} from '../../actions/authActions'

import axios from 'axios'
import {DatetimePicker} from '../../components/DatePicker'
import {OverlayTrigger} from 'react-bootstrap'
import {Copy} from '../../components/CopyTooltip'
import {ERC20} from '../../contracts/ERC20'

const FairSaleDetails = ({_launchpadInfo}) => {
    let {chain, address} = Object.fromEntries(new URLSearchParams(location.search))
    address = address?.substring(0, 42)
    const [launchpadInfo, setLaunchpadInfo] = useState(_launchpadInfo == undefined ? {launchpadAddress: address, chainId: chain} : _launchpadInfo)

    const _dispatch = useDispatch()

    const signer = useSelector((state) => state.auth.signer)
    const user = useSelector((state) => state.auth.user)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)
    const openSideMenu = useSelector((state) => state.auth.openSideMenu)

    const [tokenPrice, setTokenPrice] = useState(null)
    const [totalTokens, setTotalTokens] = useState(null)
    const [uniLiquidityPercentageAllocation, setUniLiquidityPercentageAllocation] = useState()
    const [uniLPTokensLockDurationInDays, setUniLPTokensLockDurationInDays] = useState()

    const [softCapInWei, setSoftCapInWei] = useState(null)
    const [closeTime, setCloseTime] = useState()
    const [openTime, setOpneTime] = useState()

    const [investAmount, setInvestAmount] = useState('')
    const [investerCount, setInvesterCount] = useState(null)
    const [totalInvestedAmount, setTotalInvestedAmount] = useState(null)
    const [userInvestment, setUserInvestment] = useState(null)
    const [preSaleCreatorAddress, setPreSaleCreatorAddress] = useState('')
    const [isDeveloper, setIsDeveloper] = useState(false)
    const [fairlaunchContract, setFairlaunchContract] = useState(null)
    const [lpTokensContract, setLpTokensContract] = useState(null)
    const [tokenAddress, setTokenAddress] = useState(null)
    const [liqLockAddress, setLiqLockAddress] = useState(null)
    const [status, setStatus] = useState(null)
    const [closingTime, setClosingTime] = useState(null)
    const [startingTime, setStartingTime] = useState(null)
    const [editOpenTime, setEditOpenTime] = useState(new Date())
    const [editCloseTime, setEditCLoseTime] = useState(new Date())
    const [launchpadDbInfo, setLaunchpadDbInfo] = useState(null)
    const [updateSocials, setUpdateSocials] = useState(null)

    const [editOpenTimeErr, setEditOpenTimeErr] = useState(false)
    const [editCloseTimeErr, setEditCloseTimeErr] = useState(false)
    const [lpDurationErr, setLpDurationErr] = useState(false)

    const [isRequestPage, setIsRequestPage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [timeSpinner, setTimeSpinner] = useState(true)

    const [isBlockchainUpdating, setIsBlockchainUpdating] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isAddingLiquidity, setIsAddingLiquidity] = useState(false)
    const [isCollectingFunds, setIsCollectingFunds] = useState(false)
    const [isClaimingTokens, setIsClaimingTokens] = useState(false)
    const [isUpdatingLpTokens, setIsUpdatingLpTokens] = useState(false)
    const [isInvestmentInfoFetching, setIsInvestmentInfoFetching] = useState(true)

    const isFairlaunchStarted = useCountdown(startingTime, false)
    let isFairlaunchClosed = useCountdown(closingTime, false, status)
    const [isFairlaunchInfoFetched, setIsFairlaunchInfoFetched] = useState(false)
    const [preSaleNetwork, setPresaleNetwork] = useState('')

    const [isValidationCompleted, setIsValidationCompleted] = useState(true)
    const [show, setShow] = useState(false)
    const target = useRef(null)

    const [pieData, setPieData] = useState([
        ['Task', 'Hours per Day'],
        ['Liquidty', 0],
        ['Min Cap', 0],
        ['Total Investors', 0],
        ['Total Investment', 0]
    ])

    const pieOptions = {
        title: '',
        is3D: true,
        width: 350,
        height: 320,
        chartArea: {width: '100%', height: '80%'},
        legend: {
            position: 'bottom',
            textStyle: {
                fontSize: 9,
                color: isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`
            }
        },
        ...(isDarkTheme ? {backgroundColor: `${palette.pie_chart_bg}`} : {backgroundColor: `${palette.pure_white}`}),
        ...(isDarkTheme ? {legendTextStyle: {color: `${palette.off_white}`}} : {legendTextStyle: {color: `${palette.pure_black}`}})
    }

    useEffect(async () => {
        if (user == null) {
            const pesaleNetwork = await getNetworkById(launchpadInfo?.chainId)
            setPresaleNetwork(pesaleNetwork)

            const provider = await getJsonRpcProvider(launchpadInfo?.chainId)
            const _fairlaunchContract = new ethers.Contract(launchpadInfo?.launchpadAddress, FairPresaleContract.abi, provider)
            setFairlaunchContract(_fairlaunchContract)
        }
    }, [])

    useEffect(async () => {
        if (user != null) {
            if (launchpadInfo?.chainId != connectedNetwork?.chainId) {
                _dispatch(openMainPage(true))
                return
            }
            setIsDeveloper(false)
            let lpTokensAddress = null
            for (let i = 0; i < user.presales.length; i++) {
                if (launchpadInfo?.launchpadAddress == user.presales[i].launchpadAddress) {
                    setIsDeveloper(true)
                    lpTokensAddress = user.presales[i].lpTokensAddress
                }
            }
            if (lpTokensAddress) {
                const _lpTokensContract = new ethers.Contract(lpTokensAddress, LpTokensContract.abi, signer)
                setLpTokensContract(_lpTokensContract)
            }
            const pesaleNetwork = await getNetworkById(launchpadInfo?.chainId)
            setPresaleNetwork(pesaleNetwork)
            const _fairlaunchContract = new ethers.Contract(launchpadInfo?.launchpadAddress, FairPresaleContract.abi, signer)
            setFairlaunchContract(_fairlaunchContract)
        } else {
            setIsDeveloper(false)
        }
    }, [user])

    useEffect(async () => {
        if (fairlaunchContract) {
            await readFairlaunchInfo()
        }
    }, [fairlaunchContract, connectedNetwork])

    const getUserMaxBalance = async () => {
        try {
            let _balance = await signer.getBalance()
            _balance = ethers.utils.formatEther(_balance)
            setInvestAmount(_balance)
        } catch (error) {
            console.log(error)
        }
    }

    const getLaunchpadDbInfo = async () => {
        setLoading(true)
        await axios
            .post(`${api}/pre_sale/find`, {
                launchpadAddress: launchpadInfo.launchpadAddress
            })
            .then((response) => {
                if (response.data.status) {
                    setLaunchpadDbInfo(response?.data?.data)
                    setUpdateSocials({
                        logoUrl: {val: response?.data?.data?.logoUrl, edit: false},
                        website: {val: response?.data?.data?.website, edit: false},
                        saleTitle: {val: response?.data?.data?.saleTitle, edit: false},
                        twitter: {val: response?.data?.data?.twitter, edit: false},
                        discord: {val: response?.data?.data?.discord, edit: false},
                        telegram: {val: response?.data?.data?.telegram, edit: false},
                        description: {val: response?.data?.data?.description, edit: false}
                    })
                }
                setLoading(false)
            })
            .catch(function (error) {
                showError(error.message)
                setLoading(false)
            })
    }

    const readFairlaunchInfo = async () => {
        setLoading(true)
        const _tokenAddress = await fairlaunchContract.token()
        setTokenAddress(_tokenAddress)

        //todo..recheck. avoid this call
        const provider = await getJsonRpcProvider(launchpadInfo?.chainId)
        const erc20 = new ethers.Contract(_tokenAddress, ERC20.abi, provider)
        let decimals = await erc20.decimals()

        await getLaunchpadDbInfo()
        await fetchStatus()

        const _presaleCreatorAddress = await fairlaunchContract.presaleCreatorAddress()
        setPreSaleCreatorAddress(_presaleCreatorAddress)

        const _liqLockAddress = await fairlaunchContract.LiqLockAddress()
        setLiqLockAddress(_liqLockAddress)

        //todo.. if liqudity added then show price
        let _tokenPriceInWei = await fairlaunchContract.tokenPriceInWei()
        _tokenPriceInWei = ethers.utils.formatEther(_tokenPriceInWei)
        setTokenPrice(_tokenPriceInWei)

        let _totalTokens = await fairlaunchContract.totalTokens()
        _totalTokens = ethers.utils.formatUnits(_totalTokens, decimals)
        setTotalTokens(_totalTokens)

        let _softCapInWei = await fairlaunchContract.softCapInWei()
        _softCapInWei = ethers.utils.formatEther(_softCapInWei)
        setSoftCapInWei(_softCapInWei)

        const _totalInvestorsCount = await fairlaunchContract.totalInvestorsCount()
        setInvesterCount(_totalInvestorsCount)

        let _totalCollectedWei = await fairlaunchContract.totalCollectedWei()
        _totalCollectedWei = ethers.utils.formatEther(_totalCollectedWei)
        setTotalInvestedAmount(_totalCollectedWei)

        if (user?.address) {
            let _userInvestment = await fairlaunchContract.investments(user?.address)
            _userInvestment = ethers.utils.formatEther(_userInvestment)
            setUserInvestment(_userInvestment)
        } else {
            setUserInvestment('0.0')
        }

        let _uniLiquidityPercentageAllocation = await fairlaunchContract.uniLiquidityPercentageAllocation()
        setUniLiquidityPercentageAllocation(_uniLiquidityPercentageAllocation)

        const _startTime = await fairlaunchContract.openTime()
        setStartingTime(_startTime * 1000)

        const _endTime = await fairlaunchContract.closeTime()
        setClosingTime(_endTime * 1000)
        setTimeSpinner(false)

        const _startDate = moment.unix(_startTime).format('dddd, MMMM Do, YYYY h:mm a')
        setOpneTime(_startDate)
        setEditOpenTime(new Date(moment.unix(_startTime).format('ddd MMM D YYYY HH:mm')))

        const _endDate = moment.unix(_endTime).format('dddd, MMMM Do, YYYY h:mm a')
        setCloseTime(_endDate)
        setEditCLoseTime(new Date(moment.unix(_endTime).format('ddd MMM D YYYY HH:mm')))

        const _uniLPTokensLockDurationInDays = await fairlaunchContract.uniLPTokensLockDurationInDays()
        setUniLPTokensLockDurationInDays(_uniLPTokensLockDurationInDays)

        const pieData = [
            ['Task', 'Hours per Day'],
            ['Liquidity', parseFloat(_uniLiquidityPercentageAllocation)],
            ['Min Cap', parseFloat(_softCapInWei)],
            ['Total Investors', parseFloat(_totalInvestorsCount)],
            ['Total Investment', parseFloat(_totalCollectedWei)]
        ]
        setPieData(pieData)
        setIsInvestmentInfoFetching(false)
        setIsFairlaunchInfoFetched(true)
        setLoading(false)
    }

    const fetchStatus = async () => {
        const _isFairlaunchClosed = await fairlaunchContract.checkStatus()
        if (_isFairlaunchClosed) {
            setStatus('Closed')
        } else {
            setStatus('Upcoming')
        }
        return true
    }

    const fetchInvestorsInfo = async () => {
        let _totalInvestorsCount = await fairlaunchContract.totalInvestorsCount()
        setInvesterCount(_totalInvestorsCount)

        let _totalCollectedWei = await fairlaunchContract.totalCollectedWei()
        _totalCollectedWei = ethers.utils.formatEther(_totalCollectedWei)
        setTotalInvestedAmount(_totalCollectedWei)

        let _userInvestment = await fairlaunchContract.investments(user.address)
        _userInvestment = ethers.utils.formatEther(_userInvestment)
        setUserInvestment(_userInvestment)
        setIsInvestmentInfoFetching(false)
    }

    const investInLaunchpad = async () => {
        if (!connectedNetwork) {
            showConnectionError()
            return
        }

        if (investAmount.trim() == '') {
            showError('Please enter the investment amount')
            return
        } else if (parseFloat(investAmount) <= parseFloat(0)) {
            showError('Investment amount should be greater than 0')
            return
        }
        setIsInvestmentInfoFetching(true)
        try {
            let investTx = await fairlaunchContract.invest({
                value: ethers.utils.parseEther(investAmount)
            })
            investTx = await investTx.wait()
            if (investTx) {
                showSuccess('Invested successfully')
            }
            setInvestAmount('')
            await fetchInvestorsInfo()
            await fetchStatus()
        } catch (error) {
            console.log('InvestInLaunchpad Error: ', error)
            setInvestAmount('')
            await fetchInvestorsInfo()
            await fetchStatus()
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error.data.message)
            }
        }
    }

    const getRefund = async () => {
        if (!connectedNetwork) {
            showConnectionError()
            return
        }
        try {
            setIsInvestmentInfoFetching(true)
            let getRefundTx = await fairlaunchContract.getRefund()
            getRefundTx = await getRefundTx.wait()
            if (getRefundTx) {
                showSuccess('Refund is successful')
            }
            await fetchInvestorsInfo()
        } catch (error) {
            console.log('GetRefund Error: ', error)
            await fetchInvestorsInfo()
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error.data.message)
            }
        }
    }

    const claimTokens = async () => {
        if (!connectedNetwork) {
            showConnectionError()
            return
        }
        try {
            setIsInvestmentInfoFetching(true)
            let claimTokensTx = await fairlaunchContract.claimTokens()
            claimTokensTx = await claimTokensTx.wait()
            if (claimTokensTx) {
                showSuccess('Tokens claimed successfully')
            }
            await fetchInvestorsInfo()
        } catch (error) {
            console.log('ClaimTokens Error: ', error)
            await fetchInvestorsInfo()
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error.data.message)
            }
        }
    }

    const addLiquidityAndLockLPTokens = async () => {
        if (!connectedNetwork) {
            showConnectionError()
            return
        }
        try {
            setIsAddingLiquidity(true)
            let addLiquidityAndLockLPTokensTx = await fairlaunchContract.addLiquidityAndLockLPTokens()
            addLiquidityAndLockLPTokensTx = await addLiquidityAndLockLPTokensTx.wait()
            if (addLiquidityAndLockLPTokensTx) {
                showSuccess('Liquidity added successfully')
            }
            setIsAddingLiquidity(false)
        } catch (error) {
            console.log('AddLiquidityAndLockLPTokens Error: ', error)
            setIsAddingLiquidity(false)
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error.data.message)
            }
        }
    }

    const collectFundsRaised = async () => {
        if (!connectedNetwork) {
            showConnectionError()
            return
        }
        try {
            setIsCollectingFunds(true)
            let collectFundsTx = await fairlaunchContract.collectFundsRaised()
            collectFundsTx = await collectFundsTx.wait()
            if (collectFundsTx) {
                showSuccess('Funds collected successfully')
            }
            setIsCollectingFunds(false)
        } catch (error) {
            console.log('CollectFundsRaised Error: ', error)
            setIsCollectingFunds(false)
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error.data.message)
            }
        }
    }

    const claimLpTokens = async () => {
        if (!connectedNetwork || !lpTokensContract) {
            showConnectionError()
            return
        }
        try {
            setIsClaimingTokens(true)
            let releaseTokensTx = await lpTokensContract.release()
            releaseTokensTx = await releaseTokensTx.wait()
            if (releaseTokensTx) {
                showSuccess('Lp tokens claimed successfully')
            }
            setIsClaimingTokens(false)
        } catch (error) {
            console.log('ClaimLpTokens Error: ', error)
            setIsClaimingTokens(false)
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error.data.message)
            }
        }
    }

    const editLpTokensTime = async () => {
        if (!connectedNetwork || !lpTokensContract) {
            showConnectionError()
            return
        }
        try {
            setIsUpdatingLpTokens(true)
            let releaseTimeTx = await lpTokensContract.updateReleaseTime(uniLPTokensLockDurationInDays)
            releaseTimeTx = await releaseTimeTx.wait()
            if (releaseTimeTx) {
                showSuccess('Lp tokens time updated successfully')
            } else {
                showError('Error in updating lp tokens time. Please try again later.')
            }
            setIsUpdatingLpTokens(false)
        } catch (error) {
            console.log('EditLpTokensTime Error: ', error)
            setIsUpdatingLpTokens(false)
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error.data.message)
            }
        }
    }

    const editBlockchainPresale = async () => {
        if (blockchainValiation()) {
            try {
                setLoading(true)
                setIsBlockchainUpdating(true)
                const editInfoPresale = await fairlaunchContract.editInfoPresaleDev(moment(editOpenTime).unix().toString(), moment(editCloseTime).unix().toString(), uniLPTokensLockDurationInDays, uniLiquidityPercentageAllocation)
                const response = await editInfoPresale.wait()
                if (response) {
                    const _updateDbData = {
                        launchpadAddress: launchpadDbInfo.launchpadAddress,
                        startTime: moment(editOpenTime).unix().toString(),
                        endTime: moment(editCloseTime).unix().toString(),
                        lpTokensLockDurationInDays: uniLPTokensLockDurationInDays?.toString(),
                        liquidityPercentageAllocation: uniLiquidityPercentageAllocation?.toString(),
                        whitelistedAddresses: []
                    }
                    const _dbResponse = await updatePresale(_updateDbData, user.token).then((res) => {
                        return res
                    })
                    if (_dbResponse.data.status) {
                        showSuccess('Presale updated successfully')
                    } else {
                        showError('Error in presale updation. Please try again later.')
                    }
                    await readFairlaunchInfo()
                } else {
                    showError('Error in updating blockchain presale. Please try again later.')
                }
                setIsBlockchainUpdating(false)
                setLoading(false)
            } catch (error) {
                console.log('EditBlockchainPresale Error: ', error)
                setIsBlockchainUpdating(false)
                setLoading(false)
                if (error.reason) {
                    showError(error.reason)
                } else {
                    showError(error.data.message)
                }
            }
        }
    }

    const blockchainValiation = () => {
        let _isValid = true
        if (editOpenTime == '') {
            _isValid = false
            setEditOpenTimeErr('Start time is required')
        } else if (moment(editOpenTime, 'DD/MM/YYYY HH:mm').diff(moment(timeAfterMinutes(5), 'DD/MM/YYYY HH:mm')) < 0) {
            _isValid = false
            setEditOpenTimeErr(`Start time must be greater than ${timeAfterMinutes(5, true)}. For safe side we need atleast 5 minutes for the transaction`)
        } else {
            setEditOpenTimeErr(false)
        }
        if (editCloseTime == '') {
            _isValid = false
            setEditCloseTimeErr('End time is required')
        } else {
            setEditCloseTimeErr(false)
        }
        if (editCloseTime <= editOpenTime) {
            _isValid = false
            setEditCloseTimeErr('End time should be greater than start time')
        } else {
            setEditCloseTimeErr(false)
        }
        //todo..purpose of this...complete this one ?
        // if (uniLPTokensLockDurationInDays.trim() == '') {
        //     _isValid = false
        //     setLpDurationErr('Liquidity lockup duration is required')
        // } else if (parseFloat(uniLPTokensLockDurationInDays) <= parseFloat(0)) {
        //     _isValid = false
        //     setLpDurationErr('Liquidity lockup duration must be greater than 0 day')
        // } else if ((uniLPTokensLockDurationInDays.trim().indexOf('.') == -1) == false && uniLPTokensLockDurationInDays.trim() !== '') {
        //     _isValid = false
        //     setLpDurationErr('Invaid Liquidity lockup. Must be a whole number')
        // } else {
        //     setLpDurationErr(false)
        // }
        return _isValid
    }

    function validateImageURl(url) {
        setUpdateSocials({...updateSocials, logoUrl: {...updateSocials?.logoUrl, val: url}, logoUrlErr: false})
        setIsValidationCompleted(false)
        if (url) {
            if (!img_url_regex.test(url.trim())) {
                setUpdateSocials({...updateSocials, logoUrl: {...updateSocials?.logoUrl, val: url}, logoUrlErr: 'Only supported image extensions are allowed'})
                setIsValidationCompleted(true)
            } else if (url?.length > 255) {
                setUpdateSocials({...updateSocials, logoUrl: {...updateSocials?.logoUrl, val: url}, logoUrlErr: 'Maximum 255 characters allowed'})
                setIsValidationCompleted(true)
            } else {
                let img = new Image()
                img.src = url
                img.onload = function () {
                    setUpdateSocials({...updateSocials, logoUrl: {...updateSocials?.logoUrl, val: url}, logoUrlErr: false})
                    setIsValidationCompleted(true)
                }
                img.onerror = function () {
                    setUpdateSocials({...updateSocials, logoUrl: {...updateSocials?.logoUrl, val: url}, logoUrlErr: 'Only supported image extensions are allowed'})
                    setIsValidationCompleted(true)
                }
            }
        } else {
            //todo..what if user enter details..there is error and
        }
    }

    const socialValiation = () => {
        let _isValid = true
        let titleErr = false
        let websiteErr = false
        let twitterErr = false
        let telegramErr = false
        let discordErr = false
        let descriptionErr = false

        if (updateSocials?.saleTitle?.val?.length === 0) {
            _isValid = false
            titleErr = 'Sale title must start with a letter, minimum of three characters and special character not allowed'
        } else if (updateSocials?.saleTitle?.val?.length < 3 || startsWithNumber(updateSocials?.saleTitle?.val)) {
            _isValid = false ///todo why it should start with a number ?
            titleErr = 'Sale title must start with a letter, minimum of three characters and special character not allowed'
        } else {
            titleErr = false
        }

        if (updateSocials?.website?.val?.length > 0) {
            if (!url_regex.test(updateSocials?.website?.val)) {
                _isValid = false
                websiteErr = 'Invalid website url'
            } else if (updateSocials?.website?.val?.length > 255) {
                _isValid = false
                websiteErr = 'Maximum 255 characters allowed'
            } else {
                websiteErr = false
            }
        } else {
            websiteErr = false
        }

        if (updateSocials?.twitter?.val?.length > 0) {
            if (!url_regex.test(updateSocials?.twitter?.val)) {
                _isValid = false
                twitterErr = 'Invalid twitter url'
            } else if (updateSocials?.twitter?.val?.length > 255) {
                _isValid = false
                twitterErr = 'Maximum 255 characters allowed'
            } else {
                twitterErr = false
            }
        }

        if (updateSocials?.telegram?.val?.length > 0) {
            if (!url_regex.test(updateSocials?.telegram?.val)) {
                _isValid = false
                telegramErr = 'Invalid telegram url'
            } else if (updateSocials?.telegram?.val?.length > 255) {
                _isValid = false
                telegramErr = 'Maximum 255 characters allowed'
            } else {
                telegramErr = false
            }
        } else {
            telegramErr = false
        }

        if (updateSocials?.discord?.val?.length > 0) {
            if (!url_regex.test(updateSocials?.discord?.val)) {
                _isValid = false
                discordErr = 'Invalid discord url'
            } else if (updateSocials?.discord?.val?.length > 255) {
                _isValid = false
                discordErr = 'Maximum 255 characters allowed'
            } else {
                discordErr = false
            }
        } else {
            discordErr = false
        }

        if (updateSocials?.description?.val?.length > 0 && updateSocials?.description?.val?.length < 128) {
            _isValid = false
            descriptionErr = 'Description must be at least 128 characters long'
        } else if (updateSocials?.description?.val?.length >= 1000) {
            _isValid = false
            descriptionErr = 'Maximum 1000 characters allowed'
        } else {
            descriptionErr = false
        }
        setUpdateSocials({...updateSocials, titleErr: titleErr, websiteErr: websiteErr, twitterErr: twitterErr, telegramErr: telegramErr, discordErr: discordErr, descriptionErr: descriptionErr})

        return _isValid
    }

    const handleOpenKYC = async () => {
        setIsRequestPage(true)
    }

    const handleCloseKYC = async () => {
        setIsRequestPage(false)
        await getLaunchpadDbInfo()
    }

    const updateDbInfo = async () => {
        if (socialValiation() && !updateSocials?.logoUrlErr && isValidationCompleted) {
            try {
                setLoading(true)
                setIsUpdating(true)
                const _updateData = {
                    launchpadAddress: launchpadDbInfo.launchpadAddress,
                    saleTitle: updateSocials?.saleTitle?.val,
                    logoUrl: updateSocials?.logoUrl?.val,
                    website: updateSocials?.website?.val,
                    twitter: updateSocials?.twitter?.val,
                    telegram: updateSocials?.telegram?.val,
                    discord: updateSocials?.discord?.val,
                    description: updateSocials?.description?.val,
                    whitelistedAddresses: []
                }
                const _dbResponse = await updatePresale(_updateData, user.token).then((res) => {
                    return res
                })
                if (_dbResponse.data.status) {
                    showSuccess('Presale social info updated successfully')
                } else {
                    showError('Error in presale updation. Please try again later.')
                }
                setIsUpdating(false)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setIsUpdating(false)
                showError(error.message)
            }
        }
    }

    let percentTimePassed
    if (launchpadDbInfo) {
        if (!isFairlaunchStarted) {
            percentTimePassed = getPassedTime(moment(launchpadDbInfo.createdAt).unix() * 1000, startingTime)
        } else {
            percentTimePassed = getPassedTime(startingTime, closingTime)
        }
    }

    const shareUrl = `${url}/fair-sale-details?chain=${launchpadInfo?.chainId}&address=${launchpadInfo?.launchpadAddress}/`

    return (
        <>
            {!isRequestPage ? (
                <Wrapper>
                    {loading && (
                        <LoadingPanelContent>
                            <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                        </LoadingPanelContent>
                    )}
                    {fairlaunchContract && (
                        <>
                            <MainRow>
                                <Col lg={12} xl={7} xxl={8}>
                                    <Form isDarkTheme={isDarkTheme}>
                                        <Row>
                                            <div>
                                                {totalInvestedAmount < softCapInWei ? (
                                                    ''
                                                ) : (
                                                    <>
                                                        {totalInvestedAmount && (
                                                            <BarBadgeWrapper>
                                                                {preSaleNetwork?.title === 'Binance Smart Chain Testnet' || preSaleNetwork?.title === 'Binance Smart Chain Mainnet' ? <BarBadge src="/images/bar-binance-color.svg" /> : preSaleNetwork?.title === 'Polygon Testnet Mumbai' || preSaleNetwork?.title === 'Polygon Mainnet' ? <BarBadge src="/images/bar-polygon-color.svg" /> : preSaleNetwork?.title === 'Ethereum Mainnet' || preSaleNetwork.title === 'Ethereum Testnet Goerli' ? <BarBadge src="/images/bar-ether-color.svg" /> : null}
                                                                <BarBadgeText isDarkTheme={isDarkTheme}>Success</BarBadgeText>
                                                            </BarBadgeWrapper>
                                                        )}
                                                    </>
                                                )}
                                                {(launchpadDbInfo?.kycStatus == 2 || launchpadDbInfo?.kycStatus == 4) && (
                                                    <a href={kycUrl + launchpadDbInfo?.launchpadAddress} target="_blank">
                                                        <Badge src="/images/badge-blue.png" />
                                                    </a>
                                                )}
                                            </div>

                                            <ShareIconContainer right={totalInvestedAmount < softCapInWei ? '6rem' : totalInvestedAmount ? '8rem' : '6rem'}>
                                                <OverlayTrigger placement="top" overlay={renderTooltip(show ? 'Copied' : 'Copy')}>
                                                    {({placement, arrowProps, show: _show, popper, ...props}) => (
                                                        <div
                                                            {...props}
                                                            style={{
                                                                ...props.style
                                                            }}>
                                                            <ShareIcon
                                                                isDarkTheme={isDarkTheme}
                                                                ref={target}
                                                                xsPosition="relative"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(shareUrl)
                                                                    setShow(!show)
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </OverlayTrigger>
                                            </ShareIconContainer>

                                            <a target="_blank" href={DocumentLinks('fairSaleDetails')}>
                                                <InfoIcon isDarkTheme={isDarkTheme} right={totalInvestedAmount < softCapInWei ? '1.85rem' : totalInvestedAmount ? '4.5rem' : '1.85rem'} top={'1rem'} xsPosition="absolute" />
                                            </a>
                                            <ProfileContent>
                                                <Flexed direction="row" align="center">
                                                    <div>
                                                        <ProfileImageDiv
                                                            isDarkTheme={isDarkTheme}
                                                            src={updateSocials != null ? updateSocials?.logoUrl?.val : ''}
                                                            onClick={() => {
                                                                if (isDeveloper && !isFairlaunchStarted) {
                                                                    setUpdateSocials({...updateSocials, logoUrl: {...updateSocials?.logoUrl, edit: !updateSocials?.logoUrl?.edit}})
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <Heading isDarkTheme={isDarkTheme}>{updateSocials?.saleTitle?.val}</Heading>
                                                </Flexed>
                                                <Flexed direction="row" align="center">
                                                    {isFairlaunchInfoFetched ? (
                                                        <Status isDarkTheme={isDarkTheme} status={isFairlaunchStarted && !isFairlaunchClosed ? 'Live' : isFairlaunchClosed ? 'Closed' : 'Upcoming'}>
                                                            <Dot isDarkTheme={isDarkTheme} status={isFairlaunchStarted && !isFairlaunchClosed ? 'Live' : isFairlaunchClosed ? 'Closed' : 'Upcoming'}></Dot>Sale {isFairlaunchStarted && !isFairlaunchClosed ? 'Live' : isFairlaunchClosed ? 'Closed' : 'Upcoming'}
                                                        </Status>
                                                    ) : (
                                                        <TextPlaceHolder />
                                                    )}
                                                </Flexed>
                                            </ProfileContent>
                                            <ColumnNoBorder isDarkTheme={isDarkTheme} hideBottomLine={true}>
                                                {updateSocials !== null ? (
                                                    <DescriptionContent>
                                                        <Link href={updateSocials !== null ? updateSocials?.website?.val : ''} target={updateSocials !== null && updateSocials?.website?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                            <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Web.png' : '/svg/WebW.svg'} alt="Web" />
                                                        </Link>
                                                        <Link href={updateSocials !== null ? updateSocials?.discord?.val : ''} target={updateSocials !== null && updateSocials?.discord?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                            <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Discord.png' : '/svg/DiscordW.svg'} alt="Discord" />
                                                        </Link>
                                                        <Link href={updateSocials !== null ? updateSocials?.telegram?.val : ''} target={updateSocials !== null && updateSocials?.telegram?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                            <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Telegram.png' : '/svg/TelegramW.svg'} alt="Telegram" />
                                                        </Link>
                                                        <Link href={updateSocials !== null ? updateSocials?.twitter?.val : ''} target={updateSocials !== null && updateSocials?.twitter?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                            <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Twitter.png' : '/svg/TwitterW.svg'} alt="Twitter" />
                                                        </Link>
                                                    </DescriptionContent>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </ColumnNoBorder>
                                            {updateSocials?.description?.val?.length > 0 && <Column isDarkTheme={isDarkTheme}>{updateSocials !== null ? <LeftContent isDarkTheme={isDarkTheme}>{updateSocials?.description?.val}</LeftContent> : <TextPlaceHolder />}</Column>}
                                            <AddressColumn isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Fair Presale Address</DetailLabel>
                                                <Content isDarkTheme={isDarkTheme}>
                                                    {preSaleNetwork ? (
                                                        <Flexed direction="row" align="center">
                                                            <AddressText isDarkTheme={isDarkTheme} href={`${preSaleNetwork.networkUrl}/${launchpadInfo?.launchpadAddress}`} target="_blank" rel="noopener noreferrer">
                                                                {launchpadInfo?.launchpadAddress?.toString()}
                                                            </AddressText>
                                                            <Copy text={`${preSaleNetwork.networkUrl}/${launchpadInfo?.launchpadAddress}`} isDarkTheme={isDarkTheme} />
                                                        </Flexed>
                                                    ) : (
                                                        <TextPlaceHolder />
                                                    )}
                                                </Content>
                                            </AddressColumn>
                                            <AddressColumn isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Token Address</DetailLabel>
                                                {tokenAddress ? (
                                                    <Flexed direction="row" align="center">
                                                        <AddressText isDarkTheme={isDarkTheme} href={`${preSaleNetwork.tokenUrl}/${tokenAddress}`} target="_blank" rel="noopener noreferrer">
                                                            {tokenAddress?.toString()}
                                                        </AddressText>
                                                        <Copy text={`${preSaleNetwork.networkUrl}/${tokenAddress}`} isDarkTheme={isDarkTheme} />
                                                    </Flexed>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </AddressColumn>
                                            <AddressColumn isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Liquidity Lock Address</DetailLabel>
                                                {liqLockAddress ? (
                                                    <Flexed direction="row" align="center">
                                                        <AddressText isDarkTheme={isDarkTheme} href={`${preSaleNetwork.networkUrl}/${liqLockAddress}`} target="_blank" rel="noopener noreferrer">
                                                            {liqLockAddress}
                                                        </AddressText>
                                                        <Copy text={`${preSaleNetwork.networkUrl}/${liqLockAddress}`} isDarkTheme={isDarkTheme} />
                                                    </Flexed>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </AddressColumn>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Token Price</DetailLabel>
                                                {tokenPrice ? (
                                                    <Content isDarkTheme={isDarkTheme}>
                                                        {tokenPrice?.toString()} {preSaleNetwork.symbol}
                                                    </Content>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Total Tokens</DetailLabel>
                                                {totalTokens && launchpadDbInfo !== null ? <Content isDarkTheme={isDarkTheme}>{totalTokens?.toString() + ' ' + launchpadDbInfo?.tokenSymbol}</Content> : <TextPlaceHolder />}
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Softcap</DetailLabel>
                                                {softCapInWei ? (
                                                    <Content isDarkTheme={isDarkTheme}>
                                                        {softCapInWei?.toString()} {preSaleNetwork.symbol}
                                                    </Content>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Total Investors</DetailLabel>
                                                {investerCount && !isInvestmentInfoFetching ? <Content isDarkTheme={isDarkTheme}>{investerCount?.toString()}</Content> : <TextPlaceHolder />}
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Total Invested Amount</DetailLabel>
                                                {totalInvestedAmount && !isInvestmentInfoFetching ? (
                                                    <Content isDarkTheme={isDarkTheme}>
                                                        {totalInvestedAmount?.toString()} {preSaleNetwork.symbol}
                                                    </Content>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Liquidity (%)</DetailLabel>
                                                {uniLiquidityPercentageAllocation ? <Content isDarkTheme={isDarkTheme}>{uniLiquidityPercentageAllocation?.toString()}</Content> : <TextPlaceHolder />}
                                            </Column>
                                            <AddressColumn isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Presale Start Time</DetailLabel>
                                                {isDeveloper && !isFairlaunchStarted ? (
                                                    <Blocked width="42">
                                                        <DatetimePicker isDarkTheme={isDarkTheme} state={editOpenTime} setState={setEditOpenTime} setError={setEditOpenTimeErr} />
                                                        {editOpenTimeErr && <Alblur>{editOpenTimeErr}</Alblur>}
                                                    </Blocked>
                                                ) : openTime ? (
                                                    <Content isDarkTheme={isDarkTheme}>{openTime?.toString()}</Content>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </AddressColumn>
                                            <AddressColumn isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Presale End Time</DetailLabel>
                                                {isDeveloper && !isFairlaunchStarted ? (
                                                    <Blocked width="42">
                                                        <DatetimePicker isDarkTheme={isDarkTheme} state={editCloseTime} setState={setEditCLoseTime} setError={setEditCloseTimeErr} />
                                                        {editCloseTimeErr && <Alblur>{editCloseTimeErr}</Alblur>}
                                                    </Blocked>
                                                ) : closeTime ? (
                                                    <Content isDarkTheme={isDarkTheme}>{closeTime?.toString()}</Content>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </AddressColumn>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Liquidity Lockup Days</DetailLabel>
                                                {isDeveloper && isFairlaunchClosed ? (
                                                    <Blocked width="42">
                                                        <InputText
                                                            type="number"
                                                            min={1}
                                                            isDarkTheme={isDarkTheme}
                                                            // width="42"
                                                            value={uniLPTokensLockDurationInDays}
                                                            onChange={(e) => {
                                                                setUniLPTokensLockDurationInDays(e.target.value)
                                                                setLpDurationErr(false)
                                                            }}
                                                        />
                                                        {lpDurationErr && <Alblur>{lpDurationErr}</Alblur>}
                                                    </Blocked>
                                                ) : uniLPTokensLockDurationInDays ? (
                                                    <Content isDarkTheme={isDarkTheme}>{uniLPTokensLockDurationInDays?.toString()}</Content>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Column>
                                        </Row>
                                        {isFairlaunchInfoFetched == true && isDeveloper == true && (
                                            <>
                                                {!isFairlaunchStarted && (
                                                    <>
                                                        <Row>
                                                            <Flexeding isDarkTheme={isDarkTheme} lg={12}>
                                                                <ButtonContainer>
                                                                    <Button isDarkTheme={isDarkTheme} disabled={isBlockchainUpdating} ifClicked={editBlockchainPresale} label={'Update Presale'} />
                                                                </ButtonContainer>
                                                            </Flexeding>
                                                        </Row>
                                                        <Spacer />
                                                    </>
                                                )}

                                                {isFairlaunchStarted == true && isFairlaunchClosed == true && (
                                                    <>
                                                        <List isDarkTheme={isDarkTheme} justifyContent="center">
                                                            <SecondButtonContainer sm={6} md={6} lg={6} xl={6} xxl={openSideMenu ? 6 : 5}>
                                                                <Button isDarkTheme={isDarkTheme} disabled={isAddingLiquidity} label="Add Liquidity" width={'100%'} ifClicked={addLiquidityAndLockLPTokens} />
                                                            </SecondButtonContainer>
                                                            <SecondButtonContainer sm={6} md={6} lg={6} xl={6} xxl={openSideMenu ? 6 : 5}>
                                                                <Button isDarkTheme={isDarkTheme} disabled={isCollectingFunds} label="Collect Funds" width={'100%'} ifClicked={collectFundsRaised} />
                                                            </SecondButtonContainer>
                                                        </List>
                                                        <List isDarkTheme={isDarkTheme} justifyContent="center">
                                                            <SecondButtonContainer sm={6} md={6} lg={6} xl={6} xxl={openSideMenu ? 6 : 5}>
                                                                <Button isDarkTheme={isDarkTheme} disabled={isClaimingTokens} label="Claim LP Token" width={'100%'} ifClicked={claimLpTokens} />
                                                            </SecondButtonContainer>
                                                            <SecondButtonContainer sm={6} md={6} lg={6} xl={6} xxl={openSideMenu ? 6 : 5}>
                                                                <Button isDarkTheme={isDarkTheme} disabled={isUpdatingLpTokens} label="Update LP Token Time" width={'100%'} ifClicked={editLpTokensTime} />
                                                            </SecondButtonContainer>
                                                        </List>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Form>

                                    {isDeveloper == true && !isFairlaunchStarted && updateSocials != null && (
                                        <Form isDarkTheme={isDarkTheme}>
                                            <Row>
                                                <AddressColumn isDarkTheme={isDarkTheme}>
                                                    <DetailLabel width={8} isDarkTheme={isDarkTheme}>
                                                        Logo URL
                                                    </DetailLabel>
                                                    <Blocked>
                                                        <InputText
                                                            isDarkTheme={isDarkTheme}
                                                            type="text"
                                                            value={updateSocials?.logoUrl?.val}
                                                            onChange={(e) => {
                                                                validateImageURl(e.target.value)
                                                            }}
                                                        />
                                                        {!isValidationCompleted && !updateSocials?.logoUrlErr && updateSocials?.logoUrl?.val?.length > 0 && <Alblur>Processing logo url. Please wait...</Alblur>}
                                                        {updateSocials?.logoUrlErr && <Alblur isDarkTheme={isDarkTheme}>{updateSocials?.logoUrlErr}</Alblur>}
                                                    </Blocked>
                                                </AddressColumn>
                                                <AddressColumn isDarkTheme={isDarkTheme}>
                                                    <DetailLabel width={8} isDarkTheme={isDarkTheme}>
                                                        Sale Title
                                                    </DetailLabel>
                                                    <Blocked>
                                                        <InputText
                                                            isDarkTheme={isDarkTheme}
                                                            type="text"
                                                            value={updateSocials?.saleTitle?.val}
                                                            onChange={(e) => {
                                                                setUpdateSocials({...updateSocials, saleTitle: {...updateSocials?.saleTitle, val: e.target.value}, titleErr: false})
                                                            }}
                                                        />{' '}
                                                        {updateSocials?.titleErr && <Alblur isDarkTheme={isDarkTheme}>{updateSocials?.titleErr}</Alblur>}
                                                    </Blocked>
                                                </AddressColumn>
                                                <AddressColumn isDarkTheme={isDarkTheme}>
                                                    <DetailLabel isDarkTheme={isDarkTheme}>Description</DetailLabel>
                                                    <FlexedEnd>
                                                        <Blocked>
                                                            <EditDescription
                                                                width={97}
                                                                rows={3}
                                                                isDarkTheme={isDarkTheme}
                                                                value={updateSocials?.description?.val}
                                                                placeholder="Minimum of 128 characters"
                                                                onChange={(e) => {
                                                                    setUpdateSocials({...updateSocials, description: {...updateSocials.description, val: e.target.value}, descriptionErr: false})
                                                                }}
                                                            />{' '}
                                                            {updateSocials?.descriptionErr && <Alblur>{updateSocials?.descriptionErr}</Alblur>}
                                                        </Blocked>
                                                    </FlexedEnd>
                                                </AddressColumn>
                                                <Column2 isDarkTheme={isDarkTheme} gap={'0.3rem'}>
                                                    <Blocked>
                                                        <InputText
                                                            isDarkTheme={isDarkTheme}
                                                            width="100"
                                                            type="text"
                                                            value={updateSocials.website.val}
                                                            placeholder="https://yoursite.com"
                                                            onChange={(e) => {
                                                                setUpdateSocials({...updateSocials, website: {...updateSocials.website, val: e.target.value}, websiteErr: false})
                                                            }}
                                                        />
                                                        {updateSocials?.websiteErr && <Alblur>{updateSocials?.websiteErr}</Alblur>}
                                                    </Blocked>
                                                    <Blocked>
                                                        <InputText
                                                            isDarkTheme={isDarkTheme}
                                                            width="100"
                                                            type="text"
                                                            value={updateSocials.discord.val}
                                                            placeholder="https://discord.com"
                                                            onChange={(e) => {
                                                                setUpdateSocials({...updateSocials, discord: {...updateSocials.discord, val: e.target.value}, discordErr: false})
                                                            }}
                                                        />{' '}
                                                        {updateSocials?.discordErr && <Alblur>{updateSocials?.discordErr}</Alblur>}
                                                    </Blocked>
                                                </Column2>
                                                <Column isDarkTheme={isDarkTheme} gap={'0.3rem'}>
                                                    <Blocked>
                                                        <InputText
                                                            isDarkTheme={isDarkTheme}
                                                            width="100"
                                                            type="text"
                                                            value={updateSocials.twitter.val}
                                                            placeholder="https://twitter.com"
                                                            onChange={(e) => {
                                                                setUpdateSocials({...updateSocials, twitter: {...updateSocials.twitter, val: e.target.value}, twitterErr: false})
                                                            }}
                                                        />
                                                        {updateSocials?.twitterErr && <Alblur>{updateSocials?.twitterErr}</Alblur>}
                                                    </Blocked>
                                                    <Blocked>
                                                        <InputText
                                                            isDarkTheme={isDarkTheme}
                                                            width="100"
                                                            type="text"
                                                            value={updateSocials.telegram.val}
                                                            placeholder="https://telegram.com"
                                                            onChange={(e) => {
                                                                setUpdateSocials({...updateSocials, telegram: {...updateSocials.telegram, val: e.target.value}, telegramErr: false})
                                                            }}
                                                        />
                                                        {updateSocials?.telegramErr && <Alblur>{updateSocials?.telegramErr}</Alblur>}
                                                    </Blocked>
                                                </Column>
                                                <Flexeding isDarkTheme={isDarkTheme} lg={12}>
                                                    <ButtonContainer>
                                                        <Button isDarkTheme={isDarkTheme} disabled={isUpdating} ifClicked={updateDbInfo} label={'Update Brand Info'} />
                                                    </ButtonContainer>
                                                </Flexeding>
                                            </Row>
                                        </Form>
                                    )}
                                    <Disqussion isDarkTheme={isDarkTheme} title={'Fair Presale Discussion'} shareUrl={shareUrl} />
                                </Col>
                                <Col lg={12} xl={5} xxl={4}>
                                    {isFairlaunchInfoFetched == true && !isFairlaunchClosed && (
                                        <Form isDarkTheme={isDarkTheme}>
                                            {!timeSpinner ? (
                                                <>
                                                    <Row justifyContent="center">
                                                        {!isFairlaunchStarted ? (
                                                            <>
                                                                <ColAlign isDarkTheme={isDarkTheme}>Remaining time for presale opening</ColAlign>
                                                                <Col>
                                                                    <CountdownTimer targetDate={startingTime} />
                                                                </Col>
                                                                <Col>
                                                                    <Flexed align="center">
                                                                        <Bar>
                                                                            <ProgressBar isDarkTheme={isDarkTheme} width={percentTimePassed} />
                                                                        </Bar>
                                                                    </Flexed>
                                                                </Col>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ColAlign isDarkTheme={isDarkTheme}>Remaining time for presale closing</ColAlign>
                                                                <Col>
                                                                    <CountdownTimer isLaunchpadClosed={isFairlaunchClosed} targetDate={closingTime} />
                                                                </Col>
                                                                <Col>
                                                                    <Flexed align="center">
                                                                        <Bar>
                                                                            <ProgressBar isDarkTheme={isDarkTheme} width={percentTimePassed} />
                                                                        </Bar>
                                                                    </Flexed>
                                                                </Col>
                                                            </>
                                                        )}
                                                    </Row>
                                                </>
                                            ) : (
                                                <Flexed direction="row" justify="center">
                                                    <TextPlaceHolder />
                                                </Flexed>
                                            )}
                                        </Form>
                                    )}
                                    {isFairlaunchInfoFetched == true && isDeveloper == true && !isFairlaunchClosed && (
                                        <Form isDarkTheme={isDarkTheme}>
                                            <Row justifyContent="center">
                                                <Col>
                                                    <Button active isDarkTheme={isDarkTheme} width={'100% !important'} label={launchpadDbInfo?.kycStatus === 1 ? 'Pending Approval' : launchpadDbInfo?.kycStatus === 2 ? 'KYC Approved' : launchpadDbInfo?.kycStatus === 3 ? 'KYC Rejected' : launchpadDbInfo?.kycStatus === 4 ? 'kyc documents public' : 'Request KYC'} ifClicked={handleOpenKYC} />
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                    {isFairlaunchInfoFetched == true && isFairlaunchStarted == true && (
                                        <>
                                            {!isFairlaunchClosed && (
                                                <Form isDarkTheme={isDarkTheme}>
                                                    <Row justifyContent="center">
                                                        <Col>
                                                            <InputContent>
                                                                <InputText
                                                                    min="0"
                                                                    type="number"
                                                                    disabled={isInvestmentInfoFetching}
                                                                    placeholder="0"
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={investAmount}
                                                                    onChange={(e) => {
                                                                        setInvestAmount(e.target.value)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                />
                                                                <MaxButton isDarkTheme={isDarkTheme} onClick={getUserMaxBalance}>
                                                                    MAX
                                                                </MaxButton>
                                                            </InputContent>
                                                            <InfoText isDarkTheme={isDarkTheme}>
                                                                <SmallText isDarkTheme={isDarkTheme}>You Invested: {userInvestment + ' ' + preSaleNetwork?.symbol}</SmallText>
                                                            </InfoText>

                                                            <Button
                                                                active
                                                                margin="0rem 0rem 1.5rem 0rem"
                                                                isDarkTheme={isDarkTheme}
                                                                width={'100% !important'}
                                                                label={'Invest'}
                                                                disabled={isInvestmentInfoFetching}
                                                                ifClicked={(e) => {
                                                                    investInLaunchpad()
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                            {isFairlaunchClosed == true && (
                                                <Form isDarkTheme={isDarkTheme}>
                                                    <Row justifyContent="center">{totalInvestedAmount < softCapInWei ? <Button isDarkTheme={isDarkTheme} label="Get Refund" ifClicked={getRefund} disabled={isInvestmentInfoFetching} /> : <Button isDarkTheme={isDarkTheme} label="Collect Tokens" ifClicked={claimTokens} disabled={isInvestmentInfoFetching} />}</Row>
                                                </Form>
                                            )}
                                        </>
                                    )}
                                    <Form isDarkTheme={isDarkTheme}>
                                        <Row>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Presale Type</DetailLabel>
                                                <Content isDarkTheme={isDarkTheme}>Fair</Content>
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Presale Status</DetailLabel>
                                                {!isInvestmentInfoFetching ? (
                                                    <StatusContent isDarkTheme={isDarkTheme} status={!isFairlaunchStarted ? 'upcoming' : isFairlaunchStarted && !isFairlaunchClosed ? 'inprogress' : totalInvestedAmount < softCapInWei ? 'failed' : 'successful'}>
                                                        {!isFairlaunchStarted ? 'Upcoming' : isFairlaunchStarted && !isFairlaunchClosed ? 'In Progress' : totalInvestedAmount < softCapInWei ? 'Failed' : 'Successful'}
                                                    </StatusContent>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>KYC Status</DetailLabel>
                                                <Content isDarkTheme={isDarkTheme}>{launchpadDbInfo?.kycStatus === 1 ? 'Pending Approval' : launchpadDbInfo?.kycStatus === 2 ? 'Approved' : launchpadDbInfo?.kycStatus === 3 ? 'Rejected' : launchpadDbInfo?.kycStatus === 4 ? 'Public' : 'To be requested'}</Content>
                                            </Column>
                                            <AddressColumn isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Listing On</DetailLabel>
                                                <Content isDarkTheme={isDarkTheme}>
                                                    <AddressText isDarkTheme={isDarkTheme} href={`${preSaleNetwork.swapUrl}/${launchpadInfo?.launchpadAddress}`} target="_blank" rel="noopener noreferrer">
                                                        {preSaleNetwork?.exchange}
                                                    </AddressText>
                                                </Content>
                                            </AddressColumn>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Network</DetailLabel>
                                                <NetworkContent isDarkTheme={isDarkTheme} chainId={preSaleNetwork.chainId}>
                                                    {preSaleNetwork?.title}
                                                </NetworkContent>
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>You Invested</DetailLabel>
                                                {userInvestment && !isInvestmentInfoFetching ? (
                                                    <Content isDarkTheme={isDarkTheme}>
                                                        {userInvestment} {preSaleNetwork.symbol}
                                                    </Content>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Column>
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Unsold Tokens</DetailLabel>
                                                <Content isDarkTheme={isDarkTheme}>Burn</Content>
                                            </Column>
                                        </Row>
                                    </Form>
                                    <Form isDarkTheme={isDarkTheme}>
                                        <Row justifyContent="center">{isFairlaunchInfoFetched ? <Chart width={'350px'} height={'320px'} chartType="PieChart" loader={<div>Loading Chart</div>} data={pieData} options={isDarkTheme ? pieOptions : pieOptions} rootProps={{'data-testid': '3'}} /> : <TextPlaceHolder />}</Row>
                                    </Form>
                                </Col>
                            </MainRow>
                            <Spacer />
                        </>
                    )}
                </Wrapper>
            ) : (
                <KYCRequest data={launchpadDbInfo} onCloseCB={handleCloseKYC} />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    // padding: 0rem 3rem 0rem 3rem;
`

const MainRow = styled(Row)`
    ${media.lg`margin-left:-5px`};
    ${media.xl`margin-left:-5px`};
    ${media.xxl`margin-left:-15px`};
`

const Form = styled(Container)`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 1rem;
    padding: 2rem;
    position: relative;
    margin-bottom: 2rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`

const ButtonContainer = styled.div`
    display: flex;
    margin-left: 1rem;
`

const ProfileContent = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    ${media.xs`
      display: block;
    `};
    ${media.sm`
      display: flex;
    `};
`

const Column = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    // cursor: pointer;
    border-bottom: ${({isDarkTheme, hideBottomLine}) => (hideBottomLine ? `` : ` 0.063rem solid ${palette.off_dark}`)};
    gap: ${({gap}) => (gap ? `${gap}` : '')};
`

const AddressColumn = styled(Column)`
    ${media.xs`
      display: block;
    `};
    ${media.sm`
      display: flex;
    `};
`
const List = styled(Row)`
    border-bottom: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_dark}` : `${palette.gray}`)};
`

const SecondButtonContainer = styled(Col)`
    margin-top: 0.5rem;
    justify-content: center;
    margin-bottom: 0rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
`

const DescriptionContent = styled.div`
    display: flex;
    align-items: center;
    & a {
        margin-left: 0.5rem;
    }
`

const Flexeding = styled(Column)`
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-bottom: 0rem;
`

const Spacer = styled.div`
    height: 0.75rem;
`
const InputContent = styled.div`
    position: relative;
    margin: 0.5rem 0rem;
`
const InputText = styled.input`
    outline: none;
    height: 2.3rem;
    width: ${({width}) => (width ? `${width}%` : '100%')};
    font-size: 0.9rem;
    padding: 0.4rem;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    ::placeholder {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const MaxButton = styled.a`
    padding-left: 1rem;
    position: absolute;
    text-align: center;
    margin: ${({isDarkTheme}) => (isDarkTheme ? `0.3rem` : `0.3rem 2rem 0 0`)} !important;
    font-weight: 500;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)} !important;
    border-radius: 0.4rem;
    border: none;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    align-items: center;
    right: 0px;
    top: 0px;
    background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.max_button_bg}` : ``)} !important; ;
`

const ColAlign = styled(Col)`
    text-align: center;
    margin-bottom: 1rem;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
    font-family: 'Montserrat-Regular', sans-serif;
`

const BlockItems = styled.div`
    display: block;
    width: 9.5rem;
`

const Link = styled.a`
    cursor: pointer;
`
const EditDescription = styled.textarea`
    outline: none;
    width: ${({width}) => (width ? `${width}%` : '100%')};
    font-size: 0.9rem;
    margin: 0 0 0 1rem;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    padding: 0.2rem;
    border-radius: 0.3rem;
    box-sizing: border-box;
    & input {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    }
    & > div > button > svg {
        stroke: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)} !important;
    }
    & div {
        border: 0rem !important;
    }
    ::placeholder {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const Column2 = styled(Column)`
    border-bottom: none;
    padding-bottom: 0;
`

const KYCLink = styled.a`
    text-decoration: none;
`

const LeftContent = styled(Content)`
    text-align: left;
`

const ColumnNoBorder = styled(Column)`
    border-bottom: 0;
`

const InfoText = styled(Column)`
    border-bottom: none;
    padding: 0;
    justify-content: center;
    padding-bottom: 0.5rem !important;
`

const SmallText = styled.p`
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)} !important;
    font-size: 0.75rem;
`

const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps, null)(FairSaleDetails)
