import React, {useState, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../assets/common'
import styled from 'styled-components'
import {TabList, Tab} from 'react-tabs'
import {Heading, TabHeading, LoadingPanelContent, LoadingPanel, InfoIcon, Flexed} from '../../styles/shared'
import {useSelector} from 'react-redux'
import MyTokenList from '../../components/MyTokenList'
import MyTokenDetails from '../../components/MyTokenDetails'
import {DocumentLinks, showError, showSuccess} from '../../utils/index'

import axios from 'axios'
import {api} from '../../config/index'
import {toast} from 'react-toastify'

// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const ViewAllMyTokens = () => {
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    const signer = useSelector((state) => state.auth.signer)
    const user = useSelector((state) => state.auth.user)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const [tokenDetails, setTokenDetails] = useState('')
    const [loading, setLoading] = useState(false)

    const [allMyTokens, setAllMyTokens] = useState([])

    useEffect(() => {
        doFetchAllMyTokens()
    }, [signer, user, connectedNetwork])

    const doFetchAllMyTokens = async () => {
        if (signer && user?.address) {
            setLoading(true)
            axios
                .post(
                    `${api}/tokens/my_tokens`,
                    {
                        userAddress: user?.address,
                        chainId: connectedNetwork?.chainId
                    },
                    {
                        headers: {
                            authorization: `bearer ${user?.token}`
                        }
                    }
                )
                .then(async (response) => {
                    if (response.data.status) {
                        setAllMyTokens(response?.data?.data)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        showSuccess('Something went wrong. Please try again later.')
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    showError(error.message)
                })
        }
    }

    return (
        <>
            {isMianPageOpen ? (
                <>
                    {loading && (
                        <LoadingPanelContent>
                            <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                        </LoadingPanelContent>
                    )}
                    <Wrapper>
                        <Row>
                            <Col lg={6} md={6} sm={6} xs={8}>
                                <Heading margin="0rem 0rem 1.25rem 0rem" isDarkTheme={isDarkTheme}>
                                    My Tokens
                                </Heading>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={4}>
                                <Flexed direction="row" align="center" justify="flex-end">
                                    <a target="_blank" href={DocumentLinks('viewAllTokens')}>
                                        <InfoIcon isDarkTheme={isDarkTheme} position={'relative'} marginBottom={0} />
                                    </a>
                                </Flexed>
                            </Col>
                        </Row>
                        <CustomRow>
                            <Col>
                                <CustomContainer>
                                    <Row>
                                        {allMyTokens?.length > 0 ? (
                                            allMyTokens?.map((value, index) => {
                                                return <MyTokenList id={index + 'mytoken'} value={value} connectedNetwork={connectedNetwork} setTokenDetails={setTokenDetails} />
                                            })
                                        ) : (
                                            <NotFoundText>{loading ? 'Fetching tokens' : 'No token found'}</NotFoundText>
                                        )}
                                    </Row>
                                </CustomContainer>
                            </Col>
                        </CustomRow>
                    </Wrapper>
                </>
            ) : (
                <MyTokenDetails tokenDetails={tokenDetails} />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    ${media.lg`
        width: 90%;
    `};
    ${media.xl`
        width: 90%;
    `};
`

const CustomContainer = styled(Container)`
    padding: 0;
`

const CustomRow = styled(Row)`
    justify-content: center;
`

const DataWrapper = styled.div`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    // border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    // height: 5rem;
    // border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

const STabs = styled(Tab)`
    font-size: 1.2rem;
    width: 100%;
    list-style: none;
    // cursor: pointer;
`

const STabList = styled(TabList)`
    list-style-type: none;
    padding: 0.3rem 0rem;
    display: flex;
    justify-content: center;
    margin: 0 0.938rem;
    border-bottom: 0.5px solid #eee;
    position: relative;
`
STabList.tabsRole = 'TabList'

TabHeading.tabsRole = 'Tab'

const STabPanel = styled.div`
    min-height: 40vh;
`

const TabContent = styled.div`
    min-height: 40vh;
    padding: 2rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
`

STabPanel.tabsRole = 'TabPanel'

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    align-items: center;
    ${media.xs`
        grid-template-columns: 1fr;
    `}
    ${media.sm`
        grid-template-columns: 1fr;
    `}
    ${media.md`
        grid-template-columns: 1fr 1fr;
    `}
`

const DropWrapper = styled.div`
    display: flex;
    height: 2rem;
    width: 16rem;
    right: 0;
    bottom: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
`
const NotFoundText = styled(Col)`
    width: 100 %;
    display: flex;
    align-items: center;
    color: ${palette.dark_gray};
    justify-content: center;
    padding-top: 5rem;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
`

export default ViewAllMyTokens
