import React, {useEffect, useState} from 'react'
import {palette} from '../assets/common'
import styled from 'styled-components'
import CountdownTimer from './CountdownTimer'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {openMainPage} from '../actions/authActions'
import {Flexed, TextPlaceHolder, Text, SocialIcons} from '../styles/shared'
import Tooltip from 'react-bootstrap/Tooltip'
import {getJsonRpcProvider, getNetworkById} from '../utils/index'
import {ethers} from 'ethers'
import {BsXCircle, BsCheckCircle} from 'react-icons/bs'
import {PresaleContract} from '../contracts/PresaleContract'

const TableViewItem = ({id, _value, setLaunchpadInfo, updatePresale}) => {
    const _dispatch = useDispatch()
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)

    const [softCapInWei, setMinCap] = useState(null)
    const [hardCapInWei, setMaxCap] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [totalCollected, setTotalCollected] = useState(null)
    const [isLaunchpadClosed, setIsLaunchpadClosed] = useState(null)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const [value, setValue] = useState({
        chainId: _value.chainId,
        launchpadAddress: _value.launchpadAddress,
        userAddress: _value.userAddress,
        tokenAddress: _value.tokenAddress,
        saleTitle: _value.saleTitle,
        isSuccessful: _value?.isSuccessful,
        isLaunchpadClosed: _value?.isLaunchpadClosed,
        isFairSale: _value?.isFairSale,
        softCapInWei: _value?.softCapInWei,
        hardCapInWei: _value?.hardCapInWei,
        startTime: _value?.startTime,
        endTime: _value?.endTime,
        isDataLoaded: _value?.isDataLoaded,
        preSaleNetwork: _value?.preSaleNetwork,
        logoUrl: _value?.logoUrl,
        kycStatus: _value?.kycStatus,
        isPrivateSale: _value?.isPrivateSale,
        totalCollected: _value?.totalCollected,
        twitter: _value?.twitter,
        telegram: _value?.telegram,
        discord: _value?.discord,
        website: _value?.website
    })
    const [isPreSaleStarted, setIsPreSaleStarted] = useState(value?.startTime < moment().unix())

    useEffect(async () => {
        if (!value?.isDataLoaded) {
            await fetchPresalepadDetail(value)
        } else {
            setIsLaunchpadClosed(value?.isLaunchpadClosed)
            setMinCap(value?.softCapInWei)
            setMaxCap(value?.hardCapInWei)
            setTotalCollected(value?.totalCollected)
            setIsSuccessful(value?.isSuccessful)
            setStartTime(value?.startTime)
            setEndTime(value?.endTime)
        }
    }, [value])

    const fetchPresalepadDetail = async (launchpad) => {
        const provider = await getJsonRpcProvider(launchpad.chainId)
        try {
            launchpad.preSaleNetwork = await getNetworkById(launchpad.chainId)
            const investementPreSale = new ethers.Contract(launchpad.launchpadAddress, PresaleContract.abi, provider)
            const _isLaunchpadClosed = await investementPreSale.checkStatus()
            launchpad.isLaunchpadClosed = _isLaunchpadClosed
            setIsLaunchpadClosed(_isLaunchpadClosed)
            setMinCap(launchpad.softCapInWei)
            if (!launchpad.isFairSale) {
                setMaxCap(launchpad.hardCapInWei)
            }
            let _totalCollectedWei = await investementPreSale.totalCollectedWei()
            _totalCollectedWei = ethers.utils.formatEther(_totalCollectedWei)
            launchpad.totalCollected = _totalCollectedWei
            setTotalCollected(_totalCollectedWei)
            if (_totalCollectedWei >= launchpad.softCapInWei) {
                launchpad.isSuccessful = true
                setIsSuccessful(true)
            } else {
                launchpad.isSuccessful = false
            }
            setStartTime(launchpad.startTime)
            setEndTime(launchpad.endTime) //it means data is loaded
            launchpad.isDataLoaded = true
            setValue(launchpad)
            updatePresale(launchpad)
        } catch (error) {
            console.log(error)
        }
    }

    const renderTooltip = (...props) => <TooltipBox {...props}>{props?.[1] + ': ' + props?.[0]}</TooltipBox>

    const updateSale = async () => {
        setIsPreSaleStarted(moment(parseInt(value?.startTime) * 1000).unix() < moment().unix())
        setIsLaunchpadClosed(moment(parseInt(value?.endTime) * 1000).unix() < moment().unix())
    }

    return (
        <>
            <TR id={id} key={id} isDarkTheme={isDarkTheme}>
                <Td isDarkTheme={isDarkTheme}>
                    <Flexed direction="row" align="center">
                        <TitleText xsmall isDarkTheme={isDarkTheme}>
                            {value?.saleTitle}
                        </TitleText>
                    </Flexed>
                </Td>
                <Td isDarkTheme={isDarkTheme}>
                    {softCapInWei ? (
                        <Text xsmall isDarkTheme={isDarkTheme}>
                            {softCapInWei} {value?.preSaleNetwork?.symbol}
                        </Text>
                    ) : (
                        <TextPlaceHolder width="100%" />
                    )}
                </Td>
                <Td isDarkTheme={isDarkTheme}>
                    {hardCapInWei ? (
                        <Text xsmall isDarkTheme={isDarkTheme}>
                            {hardCapInWei} {value?.preSaleNetwork?.symbol}
                        </Text>
                    ) : (
                        <TextPlaceHolder width="100%" />
                    )}
                </Td>
                <Td isDarkTheme={isDarkTheme}>
                    <Flexed direction="row" align="center" justify="space-evenly">
                        {value?.kycStatus == 2 || value?.kycStatus == 4 ? <Tick isDarkTheme={isDarkTheme} /> : <Cross isDarkTheme={isDarkTheme} />}
                    </Flexed>
                </Td>
                <Td isDarkTheme={isDarkTheme}>
                    {endTime ? (
                        <Status isDarkTheme={isDarkTheme} status={isPreSaleStarted && !isLaunchpadClosed ? 'Live' : isLaunchpadClosed ? (isSuccessful ? 'Successful' : 'Failed') : 'Upcoming'}>
                            {/* {moment.unix(value.startTime).format() >= moment().format() ? 'Upcoming' : value.isLaunchpadClosed == true ? (value?.isSuccessful ? 'Successful' : 'Failed') : 'Live'} */}
                            {isPreSaleStarted && !isLaunchpadClosed ? 'Live' : isLaunchpadClosed ? (isSuccessful ? 'Successful' : 'Failed') : 'Upcoming'}
                        </Status>
                    ) : (
                        <TextPlaceHolder width="100%" />
                    )}
                </Td>
                <Td isDarkTheme={isDarkTheme}>
                    {' '}
                    <SocialNetwork direction="row" align="center" justify="space-evenly">
                        {endTime ? (
                            <>
                                {value?.website && (
                                    <Link href={value?.website} target={value?.website ? '_blank' : '_self'} rel="noopener noreferrer">
                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Web.png' : '/svg/Web.svg'} alt="Web" />
                                    </Link>
                                )}
                                {value?.discord && (
                                    <Link href={value?.discord} target={value?.discord ? '_blank' : '_self'} rel="noopener noreferrer">
                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Discord.png' : '/svg/Discord.svg'} alt="Discord" />
                                    </Link>
                                )}
                                {value?.telegram && (
                                    <Link href={value?.telegram} target={value?.telegram ? '_blank' : '_self'} rel="noopener noreferrer">
                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Telegram.png' : '/svg/Telegram.svg'} alt="Telegram" />
                                    </Link>
                                )}
                                {value?.twitter && (
                                    <Link href={value?.twitter} target={value?.twitter ? '_blank' : '_self'} rel="noopener noreferrer">
                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Twitter.png' : '/svg/Twitter.svg'} alt="Twitter" />
                                    </Link>
                                )}
                            </>
                        ) : (
                            <TextPlaceHolder width="100%" />
                        )}
                    </SocialNetwork>
                </Td>
                <Td isDarkTheme={isDarkTheme}>
                    <div>
                        {endTime ? (
                            <>
                                {isPreSaleStarted && !isLaunchpadClosed ? (
                                    <>
                                        <div id="minitimer">
                                            <CountdownTimer targetDate={endTime * 1000} updateList={updateSale} />
                                        </div>
                                    </>
                                ) : isLaunchpadClosed ? null : (
                                    <>
                                        <div id="minitimer">
                                            <CountdownTimer targetDate={startTime * 1000} updateList={updateSale} />
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <TextPlaceHolder width="100%" />
                        )}
                    </div>
                </Td>
                <Td isDarkTheme={isDarkTheme}>
                    {endTime ? (
                        <ActionText
                            xsmall
                            isDarkTheme={isDarkTheme}
                            pointer
                            color="aqua_blue"
                            bold
                            onClick={() => {
                                let _launchpadInfo = {}
                                _launchpadInfo.launchpadAddress = value?.launchpadAddress
                                _launchpadInfo.preSaleNetwork = value?.preSaleNetwork
                                _launchpadInfo.chainId = value?.chainId
                                _launchpadInfo.isFair = value?.isFairSale ? true : false
                                _launchpadInfo.isPrivate = value?.isPrivateSale ? true : false
                                _launchpadInfo.status = isPreSaleStarted && !isLaunchpadClosed ? 'live' : isLaunchpadClosed ? 'ended' : 'upcoming'
                                setLaunchpadInfo(_launchpadInfo)
                                _dispatch(openMainPage(false))
                            }}>
                            View Presale
                        </ActionText>
                    ) : (
                        <TextPlaceHolder width="100%" />
                    )}
                </Td>
            </TR>
        </>
    )
}

const TooltipBox = styled(Tooltip)`
    border-radius: 1rem;
    padding: 0.1rem 0.4rem;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px;
`

const ActionText = styled(Text)`
    &:hover {
        opacity: 0.5;
    }
`

const TitleText = styled(Text)`
    width: 8rem;
    white-space: initial;
    word-wrap: break-word;
`

const Cross = styled(BsXCircle)`
    color: ${({isDarkTheme}) => (isDarkTheme ? palette.off_white : palette.off_dark)};
    cursor: pointer;
`

const Tick = styled(BsCheckCircle)`
    color: ${({isDarkTheme}) => (isDarkTheme ? palette.green : palette.dark_green)};
    cursor: pointer;
`

const SocialNetwork = styled(Flexed)`
    // font-size: 1.2rem;
`

const Status = styled.div`
    width: 100%;
    font-size: 0.875rem;
    text-align: center;
    color: ${({status, isDarkTheme}) => (status == 'Live' ? `${palette.green}` : status == 'Upcoming' ? (isDarkTheme ? `${palette.yellow}` : `${palette.binance}`) : status == 'Successful' ? `${palette.aqua_blue}` : `${palette.red}`)};
`

const Link = styled.a`
    cursor: pointer;
    &:not(:last-child) {
        margin-right: 0.2rem;
    }
`

const TR = styled.tr`
    &:hover td {
        background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.table_hover_dark_mode}` : `${palette.table_hover_light_mode}`)} !important;
    }
    &:hover td div > p {
        font-weight: bold !important;
    }
`
const Td = styled.td`
    font-size: 0.875rem !important;
    background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.pure_white}`)} !important;
    vertical-align: middle !important;
`

export default TableViewItem
