import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { palette } from '../assets/common'
import moment from 'moment'

export const DatetimePicker = ({ isDarkTheme, state, setState, setError, endtime }) => {
    return (
        <Input
            isDarkTheme={isDarkTheme}
            selected={state}
            onChange={(date) => {
                setState(date)
                if (setError) {
                    setError(false)
                }
            }}
            minDate={endtime && new Date(endtime)}
            showTimeSelect
            dateFormat="MM/dd/yyyy HH:mm"
            timeIntervals={1}
            onKeyDown={(e) => e.preventDefault()}
        />
    )
}

const Input = styled(DatePicker)`
    outline: none;
    width: 100%;
    font-size: 1rem;
    padding: 0.4rem 0.2rem;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    & input {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    }
    & > div > button > svg {
        stroke: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)} !important;
    }
    & div {
        border: 0rem !important;
    }
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`
