import * as types from '../actions/types'

const initialState = {
    isDarkTheme: false,
    user: null,
    presaleFactoryContract: null,
    fairPresaleFactoryContract: null,
    privatePresaleFactoryContract: null,
    lockFactoryContract: null,
    airdropFactoryContract: null,
    airdropPrivateFactoryContract: null,
    tokenStandardFactoryContract: null,
    tokenLiquidityFactoryContract: null,
    connectedNetwork: null,
    signer: null,
    openSideMenu: true,
    openMainParentPage: true
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case types.TRIGGER_CONNECT:
            return {
                ...state
            }
        case types.CONNECTED_SUCCESS:
            return {
                ...state,
                ...action.value,
                address: action.value.address
            }
        case types.DISCONNECTED_SUCCESS:
            return {
                ...initialState,
                isDarkTheme: !state?.isDarkTheme ? action.value : initialState?.isDarkTheme
            }
        case types.SAVE_USER:
            return {
                ...state,
                user: action.value
            }
        case types.SWITCH_THEME: {
            return {
                ...state,
                isDarkTheme: action.value
            }
        }
        case types.OPEN_SIDE_MENU: {
            return {
                ...state,
                openSideMenu: action.value
            }
        }
        case types.OPEN_MAIN_PAGE: {
            return {
                ...state,
                openMainParentPage: action.value
            }
        }

        default:
            return state
    }
}
