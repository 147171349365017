import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {palette} from '../assets/common'
import {useSelector} from 'react-redux'
import {Spacer, Flexed, RadioBox, CheckLabel, Info} from '../styles/shared'
import {Label} from '../styles/shared'
import {Col} from 'styled-bootstrap-grid'
import {NetworksTypes} from '../utils'

const NetworksDropdown = () => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    return (
        <>
            <Col>
                <Spacer height={2} />
                <Label isDarkTheme={isDarkTheme}>Selected Network</Label>
            </Col>
            <Col>
                <Flexed margin="0.5rem 0rem 0.5rem 0rem" direction="row" align="center">
                    <RadioBox isDarkTheme={isDarkTheme} disabled={connectedNetwork?.symbol !== 'BNB'} id="binance" name="binance" type="radio" value={'binance'} checked={connectedNetwork?.symbol == 'BNB'} readOnly />
                    <CheckLabel htmlFor="binance" isDarkTheme={isDarkTheme} disabled={connectedNetwork?.symbol !== 'BNB'}>
                        {connectedNetwork?.mainnet ? NetworksTypes.BinanceMainNet.title : NetworksTypes.BinanceTestNet.title}
                    </CheckLabel>
                </Flexed>
                <Flexed margin="0rem 0rem 0.5rem 0rem" direction="row" align="center">
                    <RadioBox isDarkTheme={isDarkTheme} disabled={connectedNetwork?.symbol !== 'ETH'} id="ether" name="ether" type="radio" value={'ether'} checked={connectedNetwork?.symbol == 'ETH'} readOnly></RadioBox>
                    <CheckLabel htmlFor="ether" isDarkTheme={isDarkTheme} disabled={connectedNetwork?.symbol !== 'ETH'}>
                        {connectedNetwork?.mainnet ? NetworksTypes.EthereumMainNet.title : NetworksTypes.EthereumTestNet.title}
                    </CheckLabel>
                </Flexed>
                <Flexed margin="0rem 0rem 0.5rem 0rem" direction="row" align="center">
                    <RadioBox isDarkTheme={isDarkTheme} disabled={connectedNetwork?.symbol !== 'Matic'} id="polygon" name="polygon" type="radio" value={'polygon'} checked={connectedNetwork?.symbol == 'Matic'} readOnly></RadioBox>
                    <CheckLabel htmlFor="polygon" isDarkTheme={isDarkTheme} disabled={connectedNetwork?.symbol !== 'Matic'}>
                        {connectedNetwork?.mainnet ? NetworksTypes.PolygonMainNet.title : NetworksTypes.PolygonTestNet.title}
                    </CheckLabel>
                </Flexed>
                {connectedNetwork && (
                    <Info isDarkTheme={isDarkTheme}>
                        Token will be swap with <SelectedCurrency>{connectedNetwork?.symbol}</SelectedCurrency>. You have to add liquidity to list the token on <SelectedCurrency>{connectedNetwork?.exchange}</SelectedCurrency> if softcap is reached.
                    </Info>
                )}
                {/* <Spacer height={1} /> */}
            </Col>
        </>
    )
}

export default NetworksDropdown

const SelectedCurrency = styled.span`
    text-transform: uppercase;
`
