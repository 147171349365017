import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Label, Info, Alblur, Para, CreateTokenLabel } from '../../styles/shared'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../../assets/common'
import { ethers } from 'ethers'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import { saveUser, openMainPage } from '../../actions/authActions'
import { api } from '../../config/index'
import axios from 'axios'
import { ERC20 } from '../../contracts/ERC20'
import Button from '../../components/Button'
import { Spacer, InputText, TextArea, Flexed, RadioBox, CheckLabel, LoadingPanelContent, LoadingPanel, StepperHeading, Step, InfoIcon } from '../../styles/shared'
import { token_regex, url_regex, img_url_regex, startsWithNumber } from '../../utils/regex'
import PrivateSaleDetails from './PrivateSaleDetails'
import NetworksDropdown from '../../components/NetworksDropdown'
import { PrivatePresaleFactoryContract } from '../../contracts/PrivatePresaleFactoryContract'
import { DocumentLinks, formatCurrency, fetchTokenDetails, showConnectionError, showError, showSuccess, preventNegativeValues, getUserTokenBalance, parseTo18 } from '../../utils/index'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import TokenDetails from '../../components/TokenDetails'
import { timeAfterMinutes } from '../../utils/timeAfterMinutes'
import SuccessModal from '../../components/SuccesModal'
import { DatetimePicker } from '../../components/DatePicker'
import CreateFeeOptions from '../../components/CreateFeeOptions'
import CreateTokenModal from '../../components/Modals/CreateTokenModal'
import { maxTokensAllowed } from '../../constants'

// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const CreatePrivateSale = ({ user, saveUserHandler }) => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    let signer = useSelector((state) => state.auth.signer)
    const privatePresaleFactoryContract = useSelector((state) => state.auth.privatePresaleFactoryContract)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)
    const [loading, setLoading] = useState(false)
    const [openSuccessModel, setOpenSuccessModel] = useState(false)
    const [successFull, setSuccessFull] = useState(false)
    const [launchpadInfo, setLaunchpadInfo] = useState()
    const [tokenAddress, setTokenAddress] = useState('')
    const [tokenAddressError, setTokenAddressError] = useState(false)
    const [activeStep, setActiveStep] = useState(1)
    const [stepOne, setStepOne] = useState(true)
    const [stepTwo, setStepTwo] = useState(false)
    const [stepThree, setStepThree] = useState(false)
    const [stepFour, setStepFour] = useState(false)
    const [whitelist, setWhitelist] = useState([])
    const [whiteListString, setWhitelistString] = useState('')
    const [whitelistError, setWhitelistError] = useState(false)
    const [tokenPrice, setTokenPrice] = useState('')
    const [totalTokens, setTotalTokens] = useState('')
    const [tokenPriceError, setTokenPriceError] = useState(false)
    const [softCap, setSoftCap] = useState('')
    const [softCapError, setSoftCapError] = useState(false)
    const [hardCap, setHardCap] = useState('')
    const [hardCapError, setHardCapError] = useState(false)
    const [minimum, setMinimum] = useState('')
    const [minimumError, setMinimumError] = useState(false)
    const [maximum, setMaximum] = useState('')
    const [maximumError, setMaximumError] = useState(false)
    const [liquidity, setLiquidity] = useState('')
    const [liquidityError, setLiquidityError] = useState(false)
    const [listingPrice, setListingPrice] = useState('')
    const [startTime, setStartTime] = useState(new Date())
    const [endTime, setEndTime] = useState(new Date())
    const [listingPriceError, setListingPriceError] = useState(false)
    const [liquidityLockup, setLiquidityLockup] = useState(new Date())
    const [liquidityLockupError, setLiquidityLockupError] = useState(false)
    const [lpTokensDurationInDays, setLpTokensDurationInDays] = useState('')
    const [lpTokensDurationInDaysError, setLpTokensDurationInDaysError] = useState(false)
    const [endTimeError, setEndTimeError] = useState(false)
    const [startTimeError, setStartTimeError] = useState(false)
    const [endTimeLessError, setEndTimeLessError] = useState(false)
    const [liquidityLockupLessError, setLiquidityLockupLessError] = useState(false)
    const [isValidationCompleted, setIsValidationCompleted] = useState(false)

    const [telegramLink, setTelegramLink] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [logoUrlError, setLogoUrlError] = useState('')
    const [discord, setDiscord] = useState('')
    const [twitter, setTwitter] = useState('')
    const [website, setWebsite] = useState('')
    const [discription, setDiscription] = useState('')
    const [descriptionError, setDescriptionError] = useState(false)
    const [saleTitle, setSaleTitle] = useState('')
    const [saleTitleError, setSaleTitleError] = useState(false)
    const [telegramLinkError, setTelegramLinkError] = useState(false)
    const [discordError, setDiscordError] = useState(false)
    const [twitterError, setTwitterError] = useState(false)
    const [websiteError, setWebsiteError] = useState(false)

    const [feeOption, setFeeOption] = useState('fixed')
    const [isBegin, setIsBegin] = useState(true)
    const [onFinish, setOnFinish] = useState(false)
    const [tokenDetails, setTokenDetails] = useState(null)

    const [openCreateTokenModal, setOpenCreateTokenModal] = useState(false)

    useEffect(() => {
        if (user != null) {
            if (connectedNetwork) {
                goToStep(1)
                getTokenDetails(tokenAddress)
                setTokenAddressError(false)
            } else {
                setTokenDetails(null)
            }
            calculateTokens()
        } else {
            goToStep(1)
            setTokenDetails(null)
        }
    }, [user, connectedNetwork])

    const checkTokenPrice = async () => {
        if (tokenPrice.trim() === '') {
            setTokenPriceError('Token price must be greater than 0')
        } else if (parseFloat(tokenPrice) == parseFloat(0)) {
            setTokenPriceError('Token price must be greater than 0')
        } else {
            setTokenPriceError(false)
        }
        setTokenPrice(tokenPrice)
        calculateTokens()
    }

    const checkSoftCap = async () => {
        if (softCap.trim() === '') {
            setSoftCapError('Softcap is required')
        } else if (parseFloat(softCap) == parseFloat(0)) {
            setSoftCapError('Softcap must be greater than 0')
        } else {
            setSoftCapError(false)
        }

        setSoftCap(softCap)
        calculateTokens()
    }

    const checkHardCap = async () => {
        if (hardCap.trim() === '') {
            setHardCapError('Hardcap is required')
        } else if (parseFloat(hardCap) == parseFloat(0)) {
            setHardCapError('Hardcap must be greater than 0')
        } else if (parseFloat(hardCap) <= parseFloat(softCap)) {
            setHardCapError('Invalid hardcap. Must be greater than softcap')
        } else {
            setHardCapError(false)
        }

        setHardCap(hardCap)
        calculateTokens()
    }

    const checkMinimumPurchase = async () => {
        if (minimum.trim() === '') {
            setMinimumError('Minimum purchase by user is required')
        } else if (parseFloat(minimum) == parseFloat(0)) {
            setMinimumError('Minimum purchase must be greater than 0')
        } else {
            setMinimumError(false)
        }

        setMinimum(minimum)
    }

    const checkMaximumPurchase = async () => {
        if (maximum.trim() === '') {
            setMaximumError('Maximum purchase must be greater than 0')
        } else if (parseFloat(maximum) == parseFloat(0)) {
            setMaximumError('Maximum purchase must be greater than 0')
        } else if (parseFloat(hardCap) <= parseFloat(softCap)) {
            setMaximumError('Maximum purchase must be greater than minimum purchase')
        } else {
            setMaximumError(false)
        }

        setMaximum(maximum)
    }

    const checkLiquidity = () => {
        if (liquidity.trim() == '') {
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else if (parseFloat(liquidity) < parseFloat(1) || parseFloat(liquidity) > parseFloat(100)) {
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else {
            setLiquidityError(false)
        }
        calculateTokens()
    }

    const checkLpTokensDurationInDays = () => {
        if (lpTokensDurationInDays.trim() == '') {
            setLpTokensDurationInDaysError('Liquidity lockup time must be greater than 0 day')
        } else if (parseFloat(lpTokensDurationInDays) <= parseFloat(0)) {
            setLpTokensDurationInDaysError('Liquidity lockup time must be greater than 0 day')
        } else if ((lpTokensDurationInDays.trim().indexOf('.') == -1) == false && lpTokensDurationInDays.trim() !== '') {
            setLpTokensDurationInDaysError('Liquidity lockup time must be in day(s)')
        } else {
            setLpTokensDurationInDaysError(false)
        }
    }

    const checkListingPrice = async () => {
        if (listingPrice.trim() === '') {
            setListingPriceError('Listing price must be greater than 0')
        } else if (parseFloat(listingPrice) <= parseFloat(0)) {
            setListingPriceError('Listing price must be greater than 0')
        } else {
            setListingPriceError(false)
        }

        setListingPrice(listingPrice)
        calculateTokens()
    }

    const checkLiquidityLockup = () => {
        if (liquidityLockup === '') {
            //todo.. it should be in minutes or days
            setLiquidityLockupError(`LockUp time must be greater than 5 seconds`)
        } else {
            setLiquidityLockupError(false)
        }
    }

    const goToStep = (index) => {
        if (index === 1) {
            stepOne === false ? setStepOne(true) : ''
        } else {
            setStepOne(false)
        }
        if (index === 2) {
            stepTwo === false ? setStepTwo(true) : ''
        } else {
            setStepTwo(false)
        }
        if (index === 3) {
            stepThree === false ? setStepThree(true) : ''
        } else {
            setStepThree(false)
        }
        if (index === 4) {
            stepFour === false ? setStepFour(true) : ''
        } else {
            setStepFour(false)
        }
    }

    const scrollToStepFirst = () => {
        setTimeout(scrollFirst, 100)
    }

    const scrollToStepSecond = () => {
        setTimeout(scrollSecond, 100)
    }

    const scrollToStepThird = () => {
        setTimeout(scrollThird, 100)
    }

    const scrollFirst = () => {
        document.getElementById('firstStep').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
    }

    const scrollSecond = () => {
        document.getElementById('secondStep').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
    }

    const scrollThird = () => {
        document.getElementById('thirdStep').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
    }

    const stepTwoValiation = () => {
        let _isValid = true
        if (tokenPrice.trim() == '') {
            _isValid = false
            setTokenPriceError('Token price must be greater than 0')
        } else if (parseFloat(tokenPrice) == parseFloat(0)) {
            _isValid = false
            setTokenPriceError('Token price must be greater than 0')
        } else {
            setTokenPriceError(false)
        }
        if (softCap.trim() == '') {
            _isValid = false
            setSoftCapError('Softcap must be greater than 0')
        } else if (parseFloat(softCap) == parseFloat(0)) {
            _isValid = false
            setSoftCapError('Softcap must be greater than 0')
        } else {
            setSoftCapError(false)
        }
        if (hardCap.trim() == '') {
            _isValid = false
            setHardCapError('Hardcap must be greater than 0')
        } else if (parseFloat(hardCap) == parseFloat(0)) {
            _isValid = false
            setHardCapError('Hardcap must be greater than 0')
        } else if (parseFloat(hardCap) <= parseFloat(softCap)) {
            _isValid = false
            setHardCapError('Hardcap must be greater than softcap')
        } else {
            setHardCapError(false)
        }
        if (minimum.trim() == '') {
            _isValid = false
            setMinimumError('Minimum purchase must be greater than 0')
        } else if (parseFloat(minimum) == parseFloat(0)) {
            _isValid = false
            setMinimumError('Minimum purchase by user must be greater than 0')
        } else if (parseFloat(hardCap) < parseFloat(minimum)) {
            _isValid = false
            setHardCapError('Hardcap must be greater than minimum purchase')
        } else {
            setMinimumError(false)
        }
        if (maximum.trim() == '') {
            _isValid = false
            setMaximumError('Maximum purchase must be greater than 0')
        } else if (parseFloat(maximum) == parseFloat(0)) {
            _isValid = false
            setMaximumError('Maximum purchase must be greater than 0')
        } else if (parseFloat(maximum) <= parseFloat(minimum)) {
            _isValid = false
            setMaximumError('Maximum purhcase must be greater than minimum purchase')
        } else if (parseFloat(hardCap) < parseFloat(maximum)) {
            _isValid = false
            setHardCapError('Hardcap must be greater than maximum purchase')
        } else {
            setMaximumError(false)
        }
        if (liquidity.trim() == '') {
            _isValid = false
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else if (parseFloat(liquidity) < parseFloat(1) || parseFloat(liquidity) > parseFloat(100)) {
            _isValid = false
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else if ((liquidity.trim().indexOf('.') == -1) == false && liquidity.trim() !== '') {
            _isValid = false
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else {
            setLiquidityError(false)
        }
        if (listingPrice.trim() == '') {
            _isValid = false
            setListingPriceError('Listing price must be greater than 0')
        } else if (parseFloat(listingPrice) <= parseFloat(0)) {
            _isValid = false
            setListingPriceError('Listing price must be greater than 0')
        } else {
            setListingPriceError(false)
        }
        if (liquidityLockup == '') {
            _isValid = false
            setLiquidityLockupError('LockUp time must be greater than 5 seconds')
        } else {
            setLiquidityLockupError(false)
        }
        if (lpTokensDurationInDays.trim() == '') {
            _isValid = false
            setLpTokensDurationInDaysError('Liquidity lockup time must be greater than 0 day')
        } else if (parseFloat(lpTokensDurationInDays) <= parseFloat(0)) {
            _isValid = false
            setLpTokensDurationInDaysError('Liquidity lockup time must be greater than 0 day')
        } else if ((lpTokensDurationInDays.trim().indexOf('.') == -1) == false && lpTokensDurationInDays.trim() !== '') {
            _isValid = false
            setLpTokensDurationInDaysError('Liquidity lockup time must be in day(s)')
        } else {
            setLpTokensDurationInDaysError(false)
        }
        if (startTime == '') {
            _isValid = false
            setStartTimeError('Start time must be less than end time')
        } else if (moment(startTime, 'DD/MM/YYYY HH:mm').diff(moment(timeAfterMinutes(5), 'DD/MM/YYYY HH:mm')) < 0) {
            _isValid = false
            setStartTimeError(`Start time must be greater than ${timeAfterMinutes(5, true)}. For safe side we need atleast 5 minutes for the transaction`)
        } else {
            setStartTimeError(false)
        }
        if (endTime == '') {
            _isValid = false
            setEndTimeError(true)
        } else {
            setEndTimeError(false)
        }
        if (endTime <= startTime) {
            _isValid = false
            setEndTimeLessError(true)
        } else {
            setEndTimeLessError(false)
        }
        if (liquidityLockup <= endTime) {
            _isValid = false
            setLiquidityLockupLessError(true)
        } else {
            setLiquidityLockupLessError(false)
        }
        if (whitelist.length == 0) {
            _isValid = false
            setWhitelistError('Atleast one whitelist address is required')
        }

        return _isValid
    }

    function validateImageURl(url) {
        if (url) {
            if (!img_url_regex.test(url.trim())) {
                setLogoUrlError('Only supported image extensions are allowed')
            } else if (url?.length > 255) {
                setLogoUrlError('Maximum 255 characters allowed')
            } else {
                let img = new Image()
                img.src = url
                img.onload = function () {
                    setLogoUrl(url)
                    setIsValidationCompleted(true)
                    setLogoUrlError(false)
                }
                img.onerror = function () {
                    setLogoUrlError('Only supported image extensions are allowed')
                    setIsValidationCompleted(true)
                }
            }
        } else {
            setLogoUrlError(false)
            setLogoUrl('')
        }
    }

    const socialValiation = () => {
        let _isValid = true
        if (saleTitle?.length === 0) {
            _isValid = false
            setSaleTitleError('Sale title must start with a letter, minimum of three characters and special character not allowed')
        } else if (saleTitle?.length < 3 || startsWithNumber(saleTitle)) {
            _isValid = false
            setSaleTitleError('Sale title must start with a letter, minimum of three characters and special character not allowed')
        } else {
            setSaleTitleError(false)
        }

        if (website) {
            if (!url_regex.test(website)) {
                _isValid = false
                setWebsiteError('Invalid website url')
            } else if (website?.length > 255) {
                setWebsiteError('Maximum 255 characters allowed')
                _isValid = false
            } else {
                setWebsiteError(false)
            }
        } else {
            setWebsiteError(false)
        }

        if (twitter) {
            if (!url_regex.test(twitter)) {
                _isValid = false
                setTwitterError('Invalid twitter url')
            } else if (twitter?.length > 255) {
                _isValid = false
                setTwitterError('Maximum 255 characters allowed')
            } else {
                setTwitterError(false)
            }
        }
        if (telegramLink) {
            if (!url_regex.test(telegramLink)) {
                _isValid = false
                setTelegramLinkError('Invalid telegram url')
            } else if (telegramLink?.length > 255) {
                _isValid = false
                setTelegramLinkError('Maximum 255 characters allowed')
            } else {
                setTelegramLinkError(false)
            }
        } else {
            setTelegramLinkError(false)
        }

        if (discord) {
            if (!url_regex.test(discord)) {
                _isValid = false
                setDiscordError('Invalid discord url')
            } else if (discord?.length > 255) {
                _isValid = false
                setDiscordError('Maximum 255 characters allowed')
            } else {
                setDiscordError(false)
            }
        } else {
            setDiscordError(false)
        }

        if (discription?.length > 0 && discription?.length < 128) {
            _isValid = false
            setDescriptionError('Description must be at least 128 characters long')
        } else if (discription?.length >= 1000) {
            _isValid = false
            setDescriptionError('Maximum 1000 characters allowed')
        } else {
            setDescriptionError(false)
        }
        return _isValid
    }

    const getTokenDetails = async (_tokeAddress) => {
        if (connectedNetwork && _tokeAddress) {
            setLoading(true)
            const _tokenDetails = await fetchTokenDetails(_tokeAddress, signer, user.address)
            setTokenDetails(_tokenDetails)
            setLoading(false)
        }
    }

    const calculateTokens = async () => {
        const maxEthPoolTokenAmount = (hardCap * liquidity) / 100
        const maxLiqPoolTokenAmount = maxEthPoolTokenAmount / listingPrice
        const maxTokensToBeSold = hardCap / tokenPrice
        let _requiredTokenAmountInWei = maxLiqPoolTokenAmount + maxTokensToBeSold
        _requiredTokenAmountInWei = Math.ceil(_requiredTokenAmountInWei)
        _requiredTokenAmountInWei = _requiredTokenAmountInWei.toString()
        setTotalTokens(_requiredTokenAmountInWei)
    }

    const getTokensApproval = async () => {
        try {
            const userTokenscount = await getUserTokenBalance(tokenAddress, signer, user.address)
            const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)

            if (parseFloat(totalTokens) > maxTokensAllowed) {
                showError('Total token amount exceeds the max limit')
            } else if (parseFloat(userTokenscount) >= parseFloat(totalTokens)) {
                const tokenApproval = await erc20.approve(PrivatePresaleFactoryContract.contractId(connectedNetwork.chainId), parseTo18(totalTokens, erc20))
                await tokenApproval.wait()
                return tokenApproval
            } else {
                showError('You do not have enough tokens')
                return false
            }
        } catch (error) {
            console.log('GetTokensApproval Error: ', error)
            showError('Please approve token first')
            setOpenSuccessModel(false)
            setSuccessFull(false)
            return false
        }
    }

    const createMyTokenPreSale = async () => {
        if (!privatePresaleFactoryContract) {
            showConnectionError()
            return
        }
        setLoading(true)
        setOpenSuccessModel(true)
        let addresslist = []
        whitelist?.map((i) => addresslist.push(i?.address))
        const isTokenApproved = await getTokensApproval()
        const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
        let decimals = await erc20.decimals()

        if (isTokenApproved) {
            let tokensTuple = {
                tokenAddress: tokenAddress,
                whitelistedAddresses: addresslist,
                tokenPriceInWei: parseTo18(tokenPrice),
                hardCapInWei: parseTo18(hardCap),
                softCapInWei: parseTo18(softCap),
                maxInvestInWei: parseTo18(maximum),
                minInvestInWei: parseTo18(minimum),
                openTime: moment(startTime).unix().toString(),
                closeTime: moment(endTime).unix().toString(),
                fixedPresale: feeOption == 'fixed' ? true : false,
                decimals: decimals
            }
            let infoTuple = {
                listingPriceInWei: parseTo18(listingPrice),
                liquidityAddingTime: moment(liquidityLockup).unix().toString(),
                lpTokensLockDurationInDays: lpTokensDurationInDays,
                liquidityPercentageAllocation: liquidity
            }
            try {
                let createPresale = null
                if (feeOption == 'fixed') {
                    createPresale = await privatePresaleFactoryContract.createPresale(tokensTuple, infoTuple, { value: parseTo18('0.01') })
                } else {
                    createPresale = await privatePresaleFactoryContract.createPresaleP(tokensTuple, infoTuple)
                }
                const response = await createPresale.wait()
                const launchpadAddress = response?.events[2]?.args != undefined ? response?.events[2]?.args[1] : response.events[3].args[1]
                const lpTokensAddress = response?.events[2]?.args != undefined ? response?.events[2]?.args[2] : response.events[3].args[2]

                let _launchpadInfo = {}
                _launchpadInfo.launchpadAddress = launchpadAddress
                _launchpadInfo.chainId = connectedNetwork.chainId
                _launchpadInfo.isFair = false
                _launchpadInfo.isPrivateSale = true
                setLaunchpadInfo(_launchpadInfo)
                if (launchpadAddress) {
                    axios
                        .post(
                            `${api}/pre_sale/add`,
                            {
                                userAddress: user.address,
                                tokenAddress: tokenAddress,
                                launchpadAddress: launchpadAddress,
                                lpTokensAddress: lpTokensAddress,
                                isPrivateSale: true,
                                isFairSale: false,
                                saleTitle: saleTitle,
                                logoUrl: logoUrl,
                                website: website,
                                twitter: twitter,
                                telegram: telegramLink,
                                discord: discord,
                                description: discription,
                                chainId: connectedNetwork.chainId,
                                tokenName: tokenDetails?.name,
                                tokenSymbol: tokenDetails?.symbol,
                                kycStatus: 0,
                                whitelistedAddresses: tokensTuple?.whitelistedAddresses,
                                tokenPriceInWei: tokenPrice.toString(),
                                hardCapInWei: hardCap.toString(),
                                softCapInWei: softCap.toString(),
                                maxCap: maximum.toString(),
                                minCap: minimum.toString(),
                                startTime: tokensTuple?.openTime,
                                endTime: tokensTuple?.closeTime,
                                fixedPresale: tokensTuple?.fixedPresale,
                                listingPriceInWei: listingPrice?.toString(),
                                liquidityAddingTime: infoTuple?.liquidityAddingTime,
                                lpTokensLockDurationInDays: infoTuple?.lpTokensLockDurationInDays,
                                liquidityPercentageAllocation: infoTuple?.liquidityPercentageAllocation
                            },
                            {
                                headers: {
                                    authorization: `bearer ${user.token}`
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.status) {
                                user.presales.push(response.data.data)
                                saveUserHandler(user)
                                resetCreatePrivateSale()
                                showSuccess('Private presale created successfully')
                                setSuccessFull(true)
                            }
                            setLoading(false)
                        })
                        .catch(function (error) {
                            setLoading(false)
                            setOpenSuccessModel(false)
                            setSuccessFull(false)
                            showError(error.message)
                        })
                } else {
                    setLoading(false)
                    setOpenSuccessModel(false)
                    showError('Presale creation failed')
                }
            } catch (error) {
                console.log(error)
                setLoading(false)
                setOpenSuccessModel(false)
                setSuccessFull(false)
                showError(error.reason)
            }
        } else {
            setLoading(false)
            setOpenSuccessModel(false)
        }
    }

    const completeFirstStep = () => {
        if (!connectedNetwork) {
            setTokenAddressError('Metamask is not connected')
        } else if (!tokenDetails) {
            setTokenAddressError(`Token not found on ${connectedNetwork.title}`)
        } else if (!token_regex.test(tokenAddress)) {
            setTokenAddressError('Invalid token address')
        } else if (connectedNetwork && tokenAddress?.length === 0) {
            setTokenAddressError('Token address is required')
        } else {
            setTokenAddressError(false)
            setStepOne(false)
            setStepTwo(true)
            scrollToStepSecond()
            goToStep(2)
            setActiveStep(2)
        }

        // if (connectedNetwork && tokenAddress?.length === 0) {
        //     setTokenAddressError(true)
        // } else if (tokenAddress.trim() != '' && token_regex.test(tokenAddress) && tokenDetails) {
        //     setStepOne(false)
        //     setStepTwo(true)
        //     scrollToStepSecond()
        //     goToStep(2)
        //     setActiveStep(2)
        // } else {
        //     setTokenAddressError(true)
        // }
    }

    const completeSecondStep = () => {
        if (stepTwoValiation()) {
            setStepTwo(false)
            setStepThree(true)
            scrollToStepThird()
            goToStep(3)
            setActiveStep(3)
        }
    }

    const addWhiteListAddress = (addVal, keyPressed) => {
        if (addVal?.length < 42 && keyPressed === 'Enter') {
            setWhitelistError('Invalid address or quantity')
            return
        }
        setWhitelistError(false)
        setWhitelistString(addVal)
        if ((keyPressed !== null && keyPressed === 'Enter') || addVal?.length > 42) {
            let _whiteList = []
            if (addVal?.includes(',')) {
                _whiteList = addVal.split(',')
            } else {
                _whiteList = [addVal.slice(0, 42), addVal.slice(addVal % 42 === 0)]
            }
            _whiteList = _whiteList.map((_item) => {
                if (token_regex.test(_item) === true) {
                    if (whitelist.find((x) => x.address === _item)) {
                        setWhitelistError('Address already added')
                    } else {
                        setWhitelistError(false)
                        setWhitelistString('')
                        return { address: _item, isValid: true }
                    }
                } else {
                    setWhitelistError('Invalid address')
                    return { address: _item, isValid: false }
                }
            })
            whitelist.map((_item) => _whiteList.push(_item))
            _whiteList = _whiteList.filter((i) => i?.isValid === true)
            _whiteList = _whiteList?.filter((value, index, self) => index === self.findIndex((t) => t.address === value.address))
            setWhitelist(_whiteList)
        } else {
            if (addVal?.length <= 42) {
                if (addVal?.length === 0) {
                    setWhitelistError(false)
                } else if (addVal?.length === 42 && token_regex.test(addVal) === true) {
                    let _whiteList = whitelist
                    if (whitelist.find((x) => x.address === addVal)) {
                        setWhitelistError('Address already added')
                    } else {
                        _whiteList.push({ address: addVal, isValid: true })
                        _whiteList = _whiteList.filter((i) => i?.isValid === true)
                        _whiteList = _whiteList?.filter((value, index, self) => index === self.findIndex((t) => t.address === value.address))
                        setWhitelist(_whiteList)
                        setWhitelistString('')
                        setWhitelistError(false)
                    }
                } else {
                    setWhitelistError('Invalid address')
                }
            }
        }
    }

    const removeWhiteListAddress = (removeVal) => {
        if (whitelist?.length > 0) {
            let _whitelist = whitelist.filter((_item) => {
                return _item !== removeVal
            })
            setWhitelist(_whitelist)
        }
    }

    const resetCreatePrivateSale = () => {
        setActiveStep(1)
        setStepOne(true)
        setStepTwo(false)
        setStepThree(false)
        setTokenAddress('')
        getTokenDetails(null)
        setWhitelist([])
        setTokenDetails(null)
        setTokenPrice('')
        setSoftCap('')
        setHardCap('')
        setMinimum('')
        setMaximum('')
        setLiquidity('')
        setListingPrice('')
        setStartTime(new Date())
        setEndTime(new Date())
        setLiquidityLockup(new Date())
        setLpTokensDurationInDays('')
        setSaleTitle('')
        setLogoUrl('')
        setWebsite('')
        setTwitter('')
        setTelegramLink('')
        setDiscord('')
        setDiscription('')
    }

    return (
        <>
            {isMianPageOpen ? (
                <Wrapper>
                    <CustomRow>
                        <Col>
                            <Flexed direction="row" justify="flex-end">
                                <a target="_blank" href={DocumentLinks('createPrivateSale')}>
                                    <InfoIconAlign isDarkTheme={isDarkTheme} />
                                </a>
                            </Flexed>
                        </Col>
                        <Container>
                            <StapperRow>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List onClick={() => (isBegin ? goToStep(1) : null)}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 0 || activeStep > 4}>
                                                    1
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 0 || activeStep > 4}>
                                                    Token & Network
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Enter the token address</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 2 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List
                                        onClick={() => {
                                            isBegin ? completeFirstStep() : null
                                        }}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4}>
                                                    2
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4}>
                                                    Presale Info
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Enter the private presale information that you want to raise</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 3 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List onClick={() => (isBegin ? completeSecondStep() : null)}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 2 || activeStep > 4}>
                                                    3
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 2 || activeStep > 4}>
                                                    Social Info
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Let the crypto family know who you are</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                            </StapperRow>

                            <Row>
                                <Col>
                                    <Stepper>
                                        {stepOne && (
                                            <Item id="firstStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <Col>
                                                                <Flexed direction="row" align="center" justify="space-between">
                                                                    <Label isDarkTheme={isDarkTheme}>
                                                                        Token Address <span>*</span>
                                                                    </Label>
                                                                    <CreateTokenLabel isDarkTheme={isDarkTheme} onClick={() => (!connectedNetwork ? showConnectionError() : setOpenCreateTokenModal(true))}>
                                                                        Create token
                                                                    </CreateTokenLabel>
                                                                </Flexed>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={tokenAddress}
                                                                    onChange={(e) => {
                                                                        setTokenAddress(e.target.value.toLowerCase())
                                                                        getTokenDetails(e.target.value.toLowerCase())
                                                                        setTokenAddressError(false)
                                                                    }}
                                                                />
                                                                {tokenAddressError && <Alblur>{tokenAddressError}</Alblur>}
                                                                <Info isDarkTheme={isDarkTheme}>Private Sale Creation Fee: 3.72 BNB</Info>
                                                            </Col>
                                                            <TokenDetails tokenDetails={tokenDetails} />
                                                            <NetworksDropdown />
                                                            <CreateFeeOptions feeOption={feeOption} setFeeOption={setFeeOption} />
                                                            <Col>
                                                                {(activeStep > 0 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button isDarkTheme={isDarkTheme} ifClicked={completeFirstStep} label={'Next'} />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}
                                        {stepTwo && (
                                            <Item id="secondStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <CustomCol lg={12}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Whitelist Addresses (Count: {whitelist?.length}) <span>*</span>
                                                                </Label>
                                                                <AddressesContainer isDarkTheme={isDarkTheme} justifyContent={'start'}>
                                                                    <Col>
                                                                        <ScrollingContent justifyContent={'space-between'}>
                                                                            {whitelist.map((item, index) => {
                                                                                return (
                                                                                    <MiniTextContainer xl={6} lg={6} md={12} sm={12} xs={12} isDarkTheme={isDarkTheme} key={index}>
                                                                                        <AddressLabel isDarkTheme={isDarkTheme}>{item?.address}</AddressLabel>
                                                                                        <CloseIcon
                                                                                            onClick={() => {
                                                                                                removeWhiteListAddress(item)
                                                                                            }}
                                                                                        />
                                                                                    </MiniTextContainer>
                                                                                )
                                                                            })}
                                                                        </ScrollingContent>
                                                                    </Col>
                                                                    <SmallInput
                                                                        width={'auto'}
                                                                        border={'none'}
                                                                        isDarkTheme={isDarkTheme}
                                                                        value={whiteListString}
                                                                        placeholder={user != null ? user?.address : '0x0000000000000000000000000000000000000000' + ':100'}
                                                                        onChange={(e) => {
                                                                            addWhiteListAddress(e.target.value.toLowerCase(), null)
                                                                        }}
                                                                        onKeyDown={(e) => addWhiteListAddress(whiteListString, e?.key)}
                                                                    />
                                                                </AddressesContainer>
                                                                {whitelistError && <Alblur>{whitelistError}</Alblur>}
                                                            </CustomCol>
                                                            <Col>
                                                                <Flexed direction="row"></Flexed>
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Token Price {formatCurrency(connectedNetwork)} <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={tokenPrice}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setTokenPrice(e.target.value)
                                                                        setTokenPriceError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkTokenPrice}
                                                                />
                                                                {tokenPriceError && <Alblur>{tokenPriceError}</Alblur>}
                                                                <Info isDarkTheme={isDarkTheme}>Can't be changed later</Info>
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Total Tokens ({tokenDetails?.symbol})<span>*</span>
                                                                </Label>
                                                                <InputText isDarkTheme={isDarkTheme} disabled value={totalTokens} type="number" onKeyDown={preventNegativeValues} />
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Softcap {formatCurrency(connectedNetwork)} <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={softCap}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setSoftCap(e.target.value)
                                                                        setSoftCapError(false)
                                                                        // setHardCapError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkSoftCap}
                                                                />
                                                                {softCapError && <Alblur>{softCapError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    HardCap {formatCurrency(connectedNetwork)} <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={hardCap}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setHardCap(e.target.value)
                                                                        setHardCapError(false)
                                                                        // setSoftCapError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkHardCap}
                                                                />
                                                                {hardCapError && <Alblur>{hardCapError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>

                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Minimum Buy {formatCurrency(connectedNetwork)} <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={minimum}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setMinimum(e.target.value)
                                                                        setMinimumError(false)
                                                                        // setMaximumError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkMinimumPurchase}
                                                                />
                                                                {minimumError && <Alblur>{minimumError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Maximum Buy {formatCurrency(connectedNetwork)} <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={maximum}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setMaximum(e.target.value)
                                                                        setMaximumError(false)
                                                                        // setMinimumError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkMaximumPurchase}
                                                                />
                                                                {maximumError && <Alblur>{maximumError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Liquidity (%) <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={liquidity}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setLiquidity(e.target.value)
                                                                        setLiquidityError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkLiquidity}
                                                                />
                                                                {liquidityError && <Alblur>{liquidityError}</Alblur>}
                                                                <Info isDarkTheme={isDarkTheme}>Can't be changed later</Info>
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Listing Price {formatCurrency(connectedNetwork)}
                                                                    <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={listingPrice}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setListingPrice(e.target.value)
                                                                        setListingPriceError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkListingPrice}
                                                                />
                                                                {listingPriceError && <Alblur>{listingPriceError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>

                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Presale Start Time (UTC) <span>*</span>
                                                                </Label>
                                                                <DatetimePicker isDarkTheme={isDarkTheme} state={startTime} setState={setStartTime} setError={setStartTimeError} />
                                                                {startTimeError && <Alblur>{startTimeError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Presale End Time (UTC) <span>*</span>
                                                                </Label>
                                                                <DatetimePicker isDarkTheme={isDarkTheme} state={endTime} setState={setEndTime} setError={setEndTimeError} />
                                                                <br />
                                                                {endTimeLessError == true && <Alblur>End time should be greater than start time</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Liquidity Adding Time <span>*</span>
                                                                </Label>
                                                                <div>
                                                                    <DatetimePicker isDarkTheme={isDarkTheme} state={liquidityLockup} setState={setLiquidityLockup} onBlur={checkLiquidityLockup} setError={setLiquidityLockupError} />
                                                                    <br />
                                                                    {liquidityLockupLessError == true && <Alblur>Liquidity adding time should be greater than presale end time</Alblur>}
                                                                </div>
                                                                {liquidityLockupError && <Alblur>{liquidityLockupError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Liquidity Lockup Days <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    type="number"
                                                                    min={1}
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={lpTokensDurationInDays}
                                                                    onChange={(e) => {
                                                                        setLpTokensDurationInDays(e.target.value)
                                                                        setLpTokensDurationInDaysError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkLpTokensDurationInDays}
                                                                />
                                                                {lpTokensDurationInDaysError && <Alblur>{lpTokensDurationInDaysError}</Alblur>}
                                                                {/* {lpTokensDurationInDaysError && <Alblur>Lock lpToken Duration must be {'>'} endTime</Alblur>} */}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col>
                                                                {(activeStep > 1 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Back'}
                                                                            margin={'0rem 1rem 0rem 0rem'}
                                                                            ifClicked={() => {
                                                                                scrollToStepFirst()
                                                                                setStepOne(true)
                                                                                setStepTwo(false)
                                                                                setActiveStep(1)
                                                                            }}
                                                                        />
                                                                        <Button isDarkTheme={isDarkTheme} label={'Next'} ifClicked={completeSecondStep} />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}

                                        {stepThree && (
                                            <Item id="thirdStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Private Sale Title <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    value={saleTitle}
                                                                    isDarkTheme={isDarkTheme}
                                                                    type={'text'}
                                                                    onChange={(e) => {
                                                                        setSaleTitle(e.target.value)
                                                                        setSaleTitleError(false)
                                                                    }}
                                                                />
                                                                {saleTitleError && <Alblur>{saleTitleError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Logo URL</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={logoUrl}
                                                                    onChange={(e) => {
                                                                        setLogoUrlError(false)
                                                                        setLogoUrl(e.target.value)
                                                                        validateImageURl(e.target.value)
                                                                    }}
                                                                    placeholder="Supported image extensions: png, jpg, jpeg or gif"
                                                                />
                                                                {!isValidationCompleted && logoUrl?.length > 0 && logoUrl?.endsWith('png') && <Alblur>Processing logo url</Alblur>}
                                                                {logoUrlError && <Alblur>{logoUrlError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Website</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={website}
                                                                    onChange={(e) => {
                                                                        setWebsite(e.target.value)
                                                                        setWebsiteError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {websiteError && <Alblur>{websiteError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Twitter </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={twitter}
                                                                    onChange={(e) => {
                                                                        setTwitter(e.target.value)
                                                                        setTwitterError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {twitterError && <Alblur>{twitterError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Telegram</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={telegramLink}
                                                                    onChange={(e) => {
                                                                        setTelegramLink(e.target.value)
                                                                        setTelegramLinkError(false)
                                                                    }}
                                                                    placeholder=""
                                                                />
                                                                {telegramLinkError && <Alblur>{telegramLinkError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Discord </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={discord}
                                                                    onChange={(e) => {
                                                                        setDiscord(e.target.value)
                                                                        setDiscordError(false)
                                                                    }}
                                                                    placeholder=""
                                                                />
                                                                {discordError && <Alblur>{discordError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col>
                                                                <Label isDarkTheme={isDarkTheme}>Description</Label>
                                                                <TextArea
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={discription}
                                                                    rows={3}
                                                                    onChange={(e) => {
                                                                        setDiscription(e.target.value)
                                                                        setDescriptionError(false)
                                                                    }}
                                                                    row="3"
                                                                    placeholder="Minimum of 128 characters"
                                                                />
                                                                {descriptionError && <Alblur>{descriptionError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col>
                                                                {(activeStep > 2 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Back'}
                                                                            margin={'0rem 1rem 0rem 0rem'}
                                                                            ifClicked={() => {
                                                                                scrollToStepSecond()
                                                                                setStepTwo(true)
                                                                                setStepThree(false)
                                                                                setActiveStep(2)
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Finish'}
                                                                            ifClicked={() => {
                                                                                if (socialValiation() && !logoUrlError) {
                                                                                    if (connectedNetwork) {
                                                                                        createMyTokenPreSale()
                                                                                    } else {
                                                                                        showConnectionError()
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}
                                    </Stepper>
                                </Col>
                            </Row>
                        </Container>
                        <Spacer height={3} />
                    </CustomRow>
                </Wrapper>
            ) : (
                <PrivateSaleDetails _launchpadInfo={launchpadInfo} />
            )}
            {loading && (
                <LoadingPanelContent>
                    <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                </LoadingPanelContent>
            )}
            {openSuccessModel && (
                <SuccessModal
                    successFull={successFull}
                    openSuccessModel={openSuccessModel}
                    userLogo={logoUrl}
                    onClose={() => {
                        setOpenSuccessModel(false)
                        setSuccessFull(false)
                    }}
                />
            )}
            {openCreateTokenModal && (
                <CreateTokenModal
                    user={user}
                    open={openCreateTokenModal}
                    setTokenAddress={setTokenAddress}
                    setTokenDetails={setTokenDetails}
                    onCloseModal={() => {
                        setOpenCreateTokenModal(false)
                    }}
                />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    padding-top: 2rem;
    ${media.lg`
    width: 90%;
    `};
    ${media.xl`
    width: 90%;
    `};
    ${media.xs`
    padding-top: 0rem;
    `}
    ${media.sm`
    padding-top: 2rem;
    `}
`

const StapperRow = styled(Row)`
    ${media.xs`
    display:none;
    `}
`

const Form = styled(Row)`
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`

const CustomRow = styled(Row)`
    justify-content: center;

    ${media.sm`
    width: 100%;
    `};
`

const CardCol = styled(Col)`
    &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 20%;
        left: 11rem;
        right: 0rem;
        z-index: 1;
        border-top: 1px solid ${({ active, isDarkTheme }) => (active ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_dark}` : `${palette.gray_light}`)};
        ${media.xs`
        left: 4rem;
        top: 34%;

      `}
        ${media.sm`
        left: 6rem;
        top: 26%;
      `}
      @media screen and (min-width: 0px) and (max-width: 669px) {
            display: none !important;
        }
        ${media.md`
        top: 20%;
        left: 11rem;
      `}
        ${media.lg`
        left: 11rem;
      `}
      ${media.xl`
        left: 11rem;
      `}
    }
`

const List = styled.div`
    display: flex;
    margin: 1rem 0rem;
    cursor: pointer;
`

const Card = styled.div`
    position: relative;
    border-radius: 0.2rem;
    border: none;
    font-size: 1rem;
    width: 12rem;
    height: 3rem;
    ${media.xs`
        width: 16rem;
        height: 4rem;
    `}
    ${media.sm`
        width: 15rem;
        height: 6rem;
    `}
    ${media.md`
        width: 14rem;
        height: 8rem;
    `}
    ${media.lg`
        width: 13rem;
        height: 8rem;
    `}
    ${media.xl`
        width: 14rem;
        height: 8rem;
    `}
`

const Stepper = styled.ul`
    padding: 0;
`

const Item = styled.li`
    list-style: none;
    margin-bottom: 2rem;
`

const StepperBody = styled.div``

const StepperFooter = styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`
const Discription = styled.div`
    display: flex;
    flex-direction: column;
`

const AddressesContainer = styled(Row)`
    height: auto;
    margin: 0;
    border-radius: 0.3rem;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    background-color: transparent;
`

const ScrollingContent = styled(Row)`
    max-height: 8rem;
    overflow: hidden;
    overflow-y: scroll;
`

const MiniTextContainer = styled(Col)`
    min-width: 48%;
    display: flex;
    justify-content: space-between;
    border-radius: 0.5rem;
    margin: 0.25rem 0;
    padding: 0;
`

const AddressLabel = styled(Label)`
    margin: 0.5rem;
    font-size: 0.8rem;
`

const CloseIcon = styled(AiOutlineCloseCircle)`
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    cursor: pointer;
    margin: 0.2rem 1rem 0 0;
`

const SmallInput = styled(InputText)`
    border: none;
`

const InfoIconAlign = styled(InfoIcon)`
    right: 0.938rem;
    ${media.xs`right:0rem;`}
    ${media.sm`right:0.938rem;`}
`

const CustomCol = styled(Col)`
    padding: 0rem 1rem;
    box-sizing: border-box;
`

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePrivateSale)
