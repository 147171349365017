import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Flexed, SocialIcons, InfoIcon } from '../styles/shared'
import { DocumentLinks } from '../utils'

const TearmAndConditions = () => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const displayFooter = useSelector((state) => state.auth.isFooterDisable)
    const { pathname } = useLocation()
    return (
        <>
            <Wrapper>
                <Row justifyContent="center">
                    <CustomCol>
                        <Text isDarkTheme={isDarkTheme} href="https://www.economicsale.finance/" target="_blank">
                            Privacy Policy
                        </Text>
                        <Text isDarkTheme={isDarkTheme} href="https://www.economicsale.finance/" target="_blank">
                            Contact Us
                        </Text>
                        <Text isDarkTheme={isDarkTheme} href="https://www.economicsale.finance/" target="_blank">
                            Terms & Conditions
                        </Text>
                    </CustomCol>
                    <Col>
                        <SocialContent direction="row" justify="center" margin="1rem 0rem 0rem 0rem">
                            <DescriptionContent>
                                <Link target="_blank" href={DocumentLinks('projectDetails')}>
                                    <InfoIcon position="relative" marginBottom={'0rem'} />
                                </Link>
                                <Link href="https://discord.gg/NfUCZSpU" target="_blank" rel="noopener noreferrer">
                                    <SocialIcons isDarkTheme={isDarkTheme} src="/images/Discord.png" alt="Discord" />
                                </Link>
                                <Link href="https://www.economicsale.finance/" target="_blank" rel="noopener noreferrer">
                                    <SocialIcons isDarkTheme={isDarkTheme} src="/images/Telegram.png" alt="Telegram" />
                                </Link>
                                <Link href="https://twitter.com/economic_sale" target="_blank" rel="noopener noreferrer">
                                    <SocialIcons isDarkTheme={isDarkTheme} src="/images/Twitter.png" alt="Twitter" />
                                </Link>
                            </DescriptionContent>
                        </SocialContent>
                    </Col>
                </Row>
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Container)`
    margin-top: auto;
    border-top: 0.1rem solid ${palette.gray_light};
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: 100%;
`

const CustomCol = styled(Col)`
    display: flex;
    justify-content: center;
    @media screen and (min-width: 200px) and (max-width: 450px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    } ;
`

const Text = styled.a`
    font-family: 'bicubik', sans-serif;
    letter-spacing: 0.1em;
    cursor: pointer;
    font-size: 0.8rem;
    display: inline-block;
    &:not(:last-child) {
        margin-right: 1rem;
    }
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    text-decoration: none;
    &:hover {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
        opacity: 0.7;
    }
`

const SocialContent = styled(Flexed)`
    @media screen and (min-width: 0px) and (max-width: 9999px) {
        display: none;
    }
`

const DescriptionContent = styled.div`
    display: flex;
    align-items: center;
    & a {
        margin-left: 0.5rem;
    }
`

const Link = styled.a`
    cursor: pointer;
`

export default TearmAndConditions
