import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../assets/common'
import {useDispatch, useSelector} from 'react-redux'
import {TabList, Tab} from 'react-tabs'
import {api} from '../../config/index'
import moment from 'moment'
import Button from '../../components/Button'
import {TabHeading, LoadingPanelContent, Alblur, LoadingPanel, Label} from '../../styles/shared'
import FairSaleDetails from '../fairSale/FairSaleDetails'
import PrivateSaleDetails from '../privateSale/PrivateSaleDetails'
import PresaleDetails from '../normalSale/PresaleDetails'
import {openMainPage} from '../../actions/authActions'
import {FiMoreHorizontal} from 'react-icons/fi'
import axios from 'axios'
import LeaderboardItem from '../../components/LeaderboardItem'
import {nFormatter} from '../../utils/timeAfterMinutes'
import {showError} from '../../utils/index'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const Leaderboard = () => {
    const _dispatch = useDispatch()
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)

    const user = useSelector((state) => state.auth.user)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    let _currentWeekNum = moment(new Date(), 'MMDDYYYY').isoWeek()

    const [selectedWeek, setSelectedWeek] = useState(_currentWeekNum)
    const [selectedWeekDate, setSelectedWeekDate] = useState()
    const [fetchedRecordsCount, setFetchedRecordsCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    const [ethPrice, setEthPrice] = useState(0)
    const [leaderboardData, setLeaderBoardData] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isLaunchpadDataFetched, setIsLaunchpadDataFetched] = useState(false)
    const [itemToView, setItemToView] = useState(null)
    const [openDropdowPopup, setOpenDropdownPopup] = useState(false)
    const [page, setPage] = useState(0)
    const [totalRaisedThisWeek, setTotalRaisedThisWeek] = useState(0)

    const limit = 20
    const dropdownRef = useRef(null)

    let weeksNumbersArray = []
    for (let i = _currentWeekNum; i > 0; i--) {
        weeksNumbersArray.push(`${i}/${new Date()?.getFullYear()}`)
    }
    for (let i = 52; i >= _currentWeekNum > 0 ? _currentWeekNum - 17 : 0; i--) {
        weeksNumbersArray.push(`${i}/${new Date()?.getFullYear() - 1}`)
    }

    useEffect(() => {
        let now = new Date()
        let currentWeekNum = moment(now, 'MMDDYYYY').isoWeek()
        let dated = now.valueOf()
        let arr = []
        if (typeof selectedWeek === 'string') {
            arr = selectedWeek?.split('/')
        }
        if (arr?.length > 0) {
            if (now.getFullYear() === parseInt(arr[1])) {
                if (currentWeekNum !== parseInt(arr[0])) {
                    dated = new Date(moment().subtract(7 * (currentWeekNum - parseInt(arr[0])), 'days')).valueOf()
                }
            } else {
                dated = new Date(moment().subtract(7 * (currentWeekNum - parseInt(arr[0]) + 52), 'days')).valueOf()
            }
        } else {
            if (currentWeekNum !== selectedWeek) {
                dated = new Date(moment().subtract(7 * (currentWeekNum - selectedWeek), 'days')).valueOf()
            }
        }
        setSelectedWeekDate(dated)
        getLeaderboardData(dated, connectedNetwork?.chainId)
        setPage(0)
        setLeaderBoardData([])
        setCurrentData([])
    }, [selectedWeek, connectedNetwork])

    useEffect(() => {
        getEthToUsd()
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenDropdownPopup(false)
        }
    }

    const getLeaderboardData = async (dated, chainId, p = 0) => {
        setLoading(true)
        axios
            .get(`${api}/leaderboard/weekly?dated=${dated}&chain=${chainId}&page=${p}&limit=${limit}`, {
                headers: {
                    authorization: `bearer ${user?.token}`
                }
            })
            .then(async (response) => {
                if (response.data.status) {
                    let _raised = 0
                    let leaderBoardList = []
                    response?.data?.data?.rows?.forEach((value) => {
                        // _raised = _raised + (value?.totalRaisedInUSD !== null ? parseInt(value?.totalRaisedInUSD) : 0)
                        leaderBoardList.push(value)
                    })
                    setTotalRaisedThisWeek(_raised)
                    setLeaderBoardData(p > 0 ? leaderboardData?.concat(leaderBoardList) : leaderBoardList)
                    setCurrentData(leaderBoardList)
                    setFetchedRecordsCount(p >= page ? fetchedRecordsCount + response?.data?.data?.rows?.length : fetchedRecordsCount - response?.data?.data?.rows?.length)
                    setTotalCount(response?.data?.data?.count)
                    setLoading(false)
                }
            })
            .catch(function (error) {
                setLoading(false)
                showError(error.message)
            })
    }

    const handleViewItem = (data) => {
        _dispatch(openMainPage(false))
        setItemToView(data)
    }

    const fetchPreviousPage = () => {
        setPage(page - 1)
        // console.log('fetchPreviousPage', page, leaderboardData?.length, (page - 1) * limit, (page - 1) * limit + limit)
        // console.log((page - 1) * limit, leaderboardData[(page - 1) * limit], leaderboardData[(page - 1) * limit + limit - 1])
        let _newCurrentData = []
        for (let i = (page - 1) * limit; i < (page - 1) * limit + limit; i++) {
            _newCurrentData.push(leaderboardData[i])
        }
        // console.log('_newCurrentData', _newCurrentData)
        setCurrentData(_newCurrentData)
        // getLeaderboardData(selectedWeekDate, connectedNetwork?.chainId, page - 1)
    }

    const fetchNextPage = () => {
        setPage(page + 1)
        // console.log('fetchNextPage', leaderboardData?.length > (page + 1) * limit + limit)
        if (leaderboardData?.length > (page + 1) * limit) {
            // console.log('fetch from current', (page + 1) * limit, (page + 1) * limit + limit)
            let _newCurrentData = []
            for (let i = (page + 1) * limit; i < (page + 1) * limit + limit; i++) {
                if (leaderboardData[i]) {
                    _newCurrentData.push(leaderboardData[i])
                }
            }
            setCurrentData(_newCurrentData)
        } else {
            getLeaderboardData(selectedWeekDate, connectedNetwork?.chainId, page + 1)
        }
    }

    const getEthToUsd = async () => {
        // let url1 = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum'
        let url2 = 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD'
        const data = await axios
            .get(url2)
            .then((res) => {
                // console.log('data::', res?.data)
                return res?.data
            })
            .catch((err) => console.log('ERR: ', err))

        // Etherium price: 1337.27 last_updated: 2022-12-14T15:35:19.355Z
        // console.log('Etherium price:', data[0]?.current_price, 'last_updated:', data[0]?.last_updated)
        // setEthPrice(data[0]?.current_price)
        setEthPrice(data?.USD)
        return data
    }

    const updateItem = async (item) => {
        for (let index = 0; index < leaderboardData.length; index++) {
            if (leaderboardData[index].launchpadAddress == item.launchpadAddress) {
                leaderboardData[index] = item
                currentData[index] = item
                break
            }
        }
    }

    return (
        <Wrapper>
            {isMianPageOpen ? (
                <MainRow>
                    {loading && (
                        <LoadingPanelContent>
                            <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                        </LoadingPanelContent>
                    )}
                    <Col lg={12} xl={12} xxl={12}>
                        <Form isDarkTheme={isDarkTheme}>
                            <Row>
                                <Col lg={6}>
                                    <MiniHeading isDarkTheme={isDarkTheme}>Total success this week</MiniHeading>
                                    <MiniHeading isDarkTheme={isDarkTheme}>{totalCount}</MiniHeading>
                                </Col>
                                <Col lg={6}>
                                    <MiniHeading isDarkTheme={isDarkTheme}>Total raised this week</MiniHeading>
                                    <MiniHeading isDarkTheme={isDarkTheme}>{'$' + (totalRaisedThisWeek > 0 ? (totalRaisedThisWeek > 1000 ? nFormatter(totalRaisedThisWeek, 1) : totalRaisedThisWeek) : 0) * ethPrice}</MiniHeading>
                                </Col>
                            </Row>
                        </Form>
                        <Form isDarkTheme={isDarkTheme}>
                            <Row>
                                <Col>
                                    <WeeklyTabs>
                                        {weeksNumbersArray?.map((num) => {
                                            return (
                                                <TabMiniHeading
                                                    id={num}
                                                    key={num}
                                                    isDarkTheme={isDarkTheme}
                                                    onClick={() => {
                                                        setSelectedWeek(num)
                                                    }}
                                                    $active={selectedWeek === num || selectedWeek == num?.split('/')[0]}>
                                                    Week {num}
                                                </TabMiniHeading>
                                            )
                                        })}
                                    </WeeklyTabs>
                                </Col>
                            </Row>
                            <More isDarkTheme={isDarkTheme} onClick={() => setOpenDropdownPopup(!openDropdowPopup)}>
                                <MoreIcon isDarkTheme={isDarkTheme}></MoreIcon>
                            </More>
                            {openDropdowPopup && (
                                <DropDownPopup isDarkTheme={isDarkTheme} ref={dropdownRef}>
                                    {weeksNumbersArray?.map((num) => {
                                        return (
                                            <ScrollBarTab
                                                isDarkTheme={isDarkTheme}
                                                onClick={() => {
                                                    setSelectedWeek(num)
                                                    setOpenDropdownPopup(false)
                                                    document.getElementById(num).scrollIntoView({behavior: 'smooth', block: 'center'})
                                                }}
                                                $active={selectedWeek === num || selectedWeek == num?.split('/')[0]}>
                                                Week {num}
                                            </ScrollBarTab>
                                        )
                                    })}
                                </DropDownPopup>
                            )}
                            <STabs>
                                <STabPanel>
                                    <TabContent>
                                        <CustomContainer>
                                            <Row>
                                                <Col>
                                                    {currentData?.length > 0 ? (
                                                        currentData?.map((value, index) => {
                                                            return <LeaderboardItem id={index + 'item'} page={page} limit={limit} data={value} isDarkTheme={isDarkTheme} connectedNetwork={connectedNetwork} viewItem={handleViewItem} loading={loading} updateItem={updateItem} />
                                                        })
                                                    ) : (
                                                        <NotFoundText>{loading ? 'Fetching leaderboard data ' : 'No leaderboard data'}</NotFoundText>
                                                    )}
                                                </Col>
                                            </Row>
                                        </CustomContainer>
                                    </TabContent>
                                </STabPanel>
                            </STabs>
                            <PaginationBtns justifyContent="center">
                                {page > 0 && <Button isDarkTheme={isDarkTheme} ifClicked={() => fetchPreviousPage()} label={'Previous'} />}
                                {totalCount > 0 && page !== parseInt(Math.ceil(totalCount / limit - 1)) && <Button isDarkTheme={isDarkTheme} ifClicked={() => fetchNextPage()} label={'Next'} />}
                            </PaginationBtns>
                        </Form>
                    </Col>
                </MainRow>
            ) : (
                <>{itemToView?.isFairSale == true ? <FairSaleDetails _launchpadInfo={itemToView} /> : itemToView?.isPrivateSale == true ? <PrivateSaleDetails _launchpadInfo={itemToView} /> : <PresaleDetails _launchpadInfo={itemToView} />}</>
            )}
        </Wrapper>
    )
}

const Wrapper = styled(Container)`
    // padding: 0rem 3rem 0rem 3rem;
`

const MainRow = styled(Row)`
    ${media.lg`margin-left:-5px`};
    ${media.xl`margin-left:-5px`};
    ${media.xxl`margin-left:-15px`};
`

const Form = styled(Container)`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    position: relative;
    border-radius: 1rem;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
        padding:  1.5rem 1rem;
    `};
    ${media.sm`
        padding: 1.5rem 1rem;
    `};
    ${media.md`
        padding: 2rem;
    `};
`

const MiniHeading = styled(Label)`
    text-align: center;
    text-transform: uppercase;
`

const STabs = styled(Tab)`
    list-style: none;
    // cursor: pointer;
`

const STabList = styled(TabList)`
    list-style-type: none;
    // padding: 0.3rem 0rem;
    // display: inline-flex;
    // justify-content: start;
    // border-bottom: 0.5px solid #eee;
`

STabList.tabsRole = 'TabList'

TabHeading.tabsRole = 'Tab'

const WeeklyTabs = styled.div`
    width: 96%;
    overflow-y: hidden !important;
    overflow-x: scroll;
    display: flex;
    // flex-wrap: nowrap;
    padding-bottom: 0.5rem;

    /* width */
    ::-webkit-scrollbar {
        display: none !important;
        // height: 3px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: black !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #00dfff !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #00dfff !important;
    }
    ${media.xs`
        width: 90%;
    `}
    ${media.sm`
        width: 93%;
    `}
    ${media.md`
        width: 96%;
    `}
`

const TabMiniHeading = styled(Label)`
    // display: inline-block;
    font-size: 0.9rem;
    margin: 0 1.5rem 0 0;
    padding: 0.5rem 0;
    cursor: pointer !important;
    border-bottom: ${({$active, isDarkTheme}) => ($active ? (isDarkTheme ? `0.2rem solid ${palette.aqua_blue} !important` : `0.2rem solid ${palette.blue}`) : ``)};
    color ${({$active, isDarkTheme}) => (isDarkTheme ? ($active ? `${palette.aqua_blue}` : `${palette.off_white}`) : $active ? `${palette.blue}` : `${palette.pure_black}`)};
`

const ScrollBarTab = styled(Label)`
    // display: inline-block;
    font-size: 0.9rem;
    margin: 0 0.8rem;
    padding: 0.5rem 0;
    cursor: pointer !important;
    border-bottom: ${({$active, isDarkTheme}) => ($active ? (isDarkTheme ? `0.2rem solid ${palette.aqua_blue} !important` : `0.2rem solid ${palette.blue}`) : ``)};
    color ${({$active, isDarkTheme}) => (isDarkTheme ? ($active ? `${palette.aqua_blue}` : `${palette.off_white}`) : $active ? `${palette.blue}` : `${palette.pure_black}`)};
`

const STabPanel = styled.div`
    min-height: 40vh;
`

const TabContent = styled.div`
    min-height: 40vh;
    // padding: 2rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
`

STabPanel.tabsRole = 'TabPanel'

const NotFoundText = styled(Col)`
    width: 100 %;
    display: flex;
    align-items: center;
    color: ${palette.dark_gray};
    justify-content: center;
    padding-top: 5rem;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
    margin: 2rem 0 0 -2rem;
`

const CustomContainer = styled(Container)`
    padding: 0;
    overflow: hidden;
`

const More = styled.div`
    height: 2.4rem;
    width: 3rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.pie_chart_bg}` : `${palette.pure_white}`)};
    // box-shadow: -4px 0px 4px 1px ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
`

const MoreIcon = styled(FiMoreHorizontal)`
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
    // padding: 1.25rem;
`

const DropDownPopup = styled.div`
    height: 16rem;
    width: 9rem;
    background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.pure_white}`)};
    box-shadow: ${({isDarkTheme}) => (isDarkTheme ? `0 0 4px #2596be, 0 0 7px #2596be` : `0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%)`)};
    position: absolute;
    right: 1rem;
    top: 4rem;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 2;
`

const PaginationBtns = styled(Row)`
    margin-top: 1rem;
    gap: 1rem;
`

export default Leaderboard
