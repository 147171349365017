import React, {useState, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../assets/common'
import {Label, Info, Alblur} from '../../styles/shared'
import styled from 'styled-components'
import {Spacer, InputText, Flexed, Heading, LoadingPanelContent, LoadingPanel, InfoIcon} from '../../styles/shared'
import Button from '../../components/Button'
import {useDispatch, useSelector} from 'react-redux'
import {openMainPage} from '../../actions/authActions'
import Select from '../../components/Select'
import {saveUser} from '../../actions/authActions'
import axios from 'axios'
import {api} from '../../config/index'
import {connect} from 'react-redux'
import MyTokenDetails from '../../components/MyTokenDetails'
import NetworksDropdown from '../../components/NetworksDropdown'
import {DocumentLinks, showConnectionError, showError, showSuccess, preventNegativeValues} from '../../utils/index'
import {digits_regex, numeric_regex, string_regex} from '../../utils/regex'
import {ethers} from 'ethers'
import {minLGTokensAllowed, maxLGTokensAllowed, maxTokensAllowed} from '../../constants'

// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const CreateToken = ({user}) => {
    const _dispatch = useDispatch()
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    const tokenStandardFactoryContract = useSelector((state) => state.auth.tokenStandardFactoryContract)
    const tokenLiquidityFactoryContract = useSelector((state) => state.auth.tokenLiquidityFactoryContract)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const [tokenDetails, setTokenDetails] = useState('')
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    const [option, setOption] = useState([
        {value: 'Standard', label: 'Standard Token'},
        {value: 'Liquidity', label: 'Liquidity Generator Token'}
    ])

    const [loading, setLoading] = useState(false)
    const [tokenType, setTokenType] = useState('Standard')
    const [name, setName] = useState('')
    const [symbol, setSymbol] = useState('')
    const [decimals, setDecimals] = useState('')
    const [supply, setSupply] = useState('')

    const [router, setRouter] = useState('Pancakeswap')
    const [yieldTransactionFee, setYieldTransactionFee] = useState('')
    const [liquidityTransactionFee, setLiquidityTransactionFee] = useState('')
    const [charityMarketingAddress, setCharityMarketingAddress] = useState('')
    const [charityMarketingPercent, setCharityMarketingPercent] = useState('')

    const [rewardToken, setRewardToken] = useState('')
    const [minTokenBalanceForDividends, setMinTokenBalanceForDividends] = useState('')
    const [tokenRewardsFee, setTokenRewardsFee] = useState('')
    const [autoAddliquidityFee, setAutoAddLiquidityFee] = useState('')
    const [marketingFee, setMarketingFee] = useState('')
    const [marketingWallet, setMarketingWallet] = useState('')

    const [liquidityFee, setLiquidityFee] = useState('')
    const [buybackFee, setBuybackFee] = useState('')
    const [reflectionFee, setReflectionFee] = useState('')

    const [nameError, setNameError] = useState(false)
    const [symbolError, setSymbolError] = useState(false)
    const [decimalsError, setDecimalsError] = useState(false)
    const [supplyError, setSupplyError] = useState(false)

    const [yieldTransactionFeeError, setYieldTransactionFeeError] = useState(false)
    const [liquidityTransactionFeeError, setLiquidityTransactionFeeError] = useState(false)
    const [charityMarketingAddressError, setCharityMarketingAddressError] = useState(false)
    const [charityMarketingPercentError, setCharityMarketingPercentError] = useState(false)

    const [rewardTokenError, setRewardTokenError] = useState(false)
    const [minTokenBalanceForDividendsError, setMinTokenBalanceForDividendsError] = useState(false)
    const [tokenRewardsFeeError, setTokenRewardsFeeError] = useState(false)
    const [autoAddliquidityFeeError, setAutoAddLiquidityFeeError] = useState(false)
    const [marketingFeeError, setMarketingFeeError] = useState(false)
    const [marketingWalletError, setMarketingWalletError] = useState(false)

    const [liquidityFeeError, setLiquidityFeeError] = useState(false)
    const [buybackFeeError, setBuybackFeeError] = useState(false)
    const [reflectionFeeError, setReflectionFeeError] = useState(false)

    useEffect(() => {
        if (!isMianPageOpen) {
            setLoading(false)
            setTokenType('Standard')
            setName('')
            setSymbol('')
            setDecimals('')
            setSupply('')
        }
    }, [isMianPageOpen])

    const validation = async () => {
        let _isValid = true
        if (name.trim().length < 2) {
            _isValid = false
            setNameError('Token name must contains at least 2 characters')
        } else {
            setNameError(false)
        }
        if (symbol.trim().length < 3 || symbol.trim().length > 5 || !string_regex.test(symbol)) {
            _isValid = false
            setSymbolError('Token symbol must be between 3 and 5 characters')
        } else {
            setSymbolError(false)
        }
        if (supply.trim() == '') {
            _isValid = false
            setSupplyError('Total supply is required')
        } else if (tokenType != 'Liquidity' && supply < 1) {
            _isValid = false
            setSupplyError('Minimum supply is 1 token')
        } else if (tokenType === 'Liquidity' && (supply < minLGTokensAllowed || supply > maxLGTokensAllowed)) {
            _isValid = false
            setSupplyError(`Token supply must be between ${minLGTokensAllowed} and ${maxLGTokensAllowed}`)
        } else {
            setSupplyError(false)
        }
        if (tokenType === 'Standard' && (supply.length <= 1 || parseFloat(supply) > maxTokensAllowed)) {
            _isValid = false
            setSupplyError(`Token supply must be between 1 and ${maxTokensAllowed}`)
        }

        //yeild = reward = distuted among holders
        //liquduty fee = goes to liquduty pool
        if (tokenType === 'Liquidity') {
            if (yieldTransactionFee.trim() == '') {
                _isValid = false
                setYieldTransactionFeeError('Transaction fee to generate yield is required')
            } else if ((yieldTransactionFee.trim().indexOf('.') == -1) == false && yieldTransactionFee.trim() !== '') {
                _isValid = false
                setYieldTransactionFeeError('Transaction fee to generate yield is required')
            } else if (yieldTransactionFee.trim() > 25 && yieldTransactionFee.trim() !== '') {
                _isValid = false
                setYieldTransactionFeeError('Transaction fee must be less than or equal to 25')
            } else if (yieldTransactionFee.trim() < 0.01 && yieldTransactionFee.trim() !== '') {
                _isValid = false
                setYieldTransactionFeeError('Transaction fee must be greater than or equal to 0.01')
            } else {
                setYieldTransactionFeeError(false)
            }
            if (liquidityTransactionFee.trim() == '') {
                _isValid = false
                setLiquidityTransactionFeeError('Transaction fee to generate liquidity is required')
            } else if ((liquidityTransactionFee.trim().indexOf('.') == -1) == false && liquidityTransactionFee.trim() !== '') {
                _isValid = false
                setLiquidityTransactionFeeError('Liquidity fees must be between 0.01 and 25')
            } else if (liquidityTransactionFee.trim() > 25 && liquidityTransactionFee.trim() !== '') {
                _isValid = false
                setLiquidityTransactionFeeError('Liquidity fees must be less than or equal to 25')
            } else if (liquidityTransactionFee.trim() < 0.01 && liquidityTransactionFee.trim() !== '') {
                _isValid = false
                setLiquidityTransactionFeeError('Liquidity fees must be greater than or equal to 0.01')
            } else {
                setLiquidityTransactionFeeError(false)
            }

            // if (charityMarketingPercent.trim() > 25 && charityMarketingPercent.trim() !== '') {
            //     _isValid = false
            //     setCharityMarketingPercentError('charityBps must be less than or equal to 25')
            // } else if (charityMarketingPercent.trim() < 1 && charityMarketingPercent.trim() !== '') {
            //     _isValid = false
            //     setCharityMarketingPercentError('charityBps must be greater than or equal to 1')
            // } else {
            //     setCharityMarketingPercentError(false)
            // }

            // if (charityMarketingAddress.trim() !== '' && reg_expression.test(charityMarketingAddress) == false) {
            //     _isValid = false
            //     setCharityMarketingAddressError(true)
            // } else {
            //     setCharityMarketingAddressError(false)
            // }
        } else {
            if (decimals.toString().includes('.')) {
                _isValid = false
                setDecimalsError('Token decimal can not be a fractional number')
            } else if (decimals.trim() > 64) {
                _isValid = false
                setDecimalsError('Token decimal must be between 2 and 64')
            } else if (decimals.trim() < 2) {
                _isValid = false
                setDecimalsError('Token decimal must be between 2 and 64')
            } else {
                setDecimalsError(false)
            }
        }
        /*
        if (tokenType === 'baby' || tokenType === 'buyback') {
            if (rewardToken.trim() == '' || reg_expression.test(rewardToken) == false) {
                _isValid = false
                setRewardTokenError(true)
            } else {
                setRewardTokenError(false)
            }

            if (marketingFee.trim() == '') {
                _isValid = false
                setMarketingFeeError('Marketing fee is a required field')
            } else {
                if (marketingFee.trim() > 100) {
                    _isValid = false
                    setMarketingFeeError('Marketing fee must be less than or equal to 100')
                } else if (marketingFee.trim() < 0.01) {
                    _isValid = false
                    setMarketingFeeError('Marketing fee must be greater than or equal to 0.01')
                } else {
                    setMarketingFeeError(false)
                }
            }
        }
        if (tokenType === 'baby') {
            if (minTokenBalanceForDividends.trim() == '') {
                _isValid = false
                setMinTokenBalanceForDividendsError('Minimum token balance for dividends is a required field')
            } else {
                if (minTokenBalanceForDividends.trim() > 100) {
                    _isValid = false
                    setMinTokenBalanceForDividendsError('marketingFee must be less than or equal to 100')
                } else if (minTokenBalanceForDividends.trim() < 1) {
                    _isValid = false
                    setMinTokenBalanceForDividendsError('marketingFee must be greater than or equal to 1')
                } else {
                    setMinTokenBalanceForDividendsError(false)
                }
            }
            if (tokenRewardsFee.trim() == '') {
                _isValid = false
                setTokenRewardsFeeError('Token reward fee is a required field')
            } else {
                if (tokenRewardsFee.trim() > 100) {
                    _isValid = false
                    setTokenRewardsFeeError('Token reward must be less than or equal to 100')
                } else if (tokenRewardsFee.trim() < 0.01) {
                    _isValid = false
                    setTokenRewardsFeeError('Token reward must be greater than or equal to 0.01')
                } else {
                    setTokenRewardsFeeError(false)
                }
            }
            if (autoAddliquidityFee.trim() == '') {
                _isValid = false
                setAutoAddLiquidityFeeError('Auto add liquidity is a required field')
            } else {
                if (autoAddliquidityFee.trim() > 100) {
                    _isValid = false
                    setAutoAddLiquidityFeeError('iquidityFee must be less than or equal to 100')
                } else if (autoAddliquidityFee.trim() < 0.01) {
                    _isValid = false
                    setAutoAddLiquidityFeeError('liquidityFee must be greater than or equal to 0.01')
                } else {
                    setAutoAddLiquidityFeeError(false)
                }
            }
            if (marketingWallet.trim() == '' || reg_expression.test(marketingWallet) == false) {
                _isValid = false
                setMarketingWalletError('Address is invalid')
            } else {
                if (marketingWallet == address) {
                    _isValid = false
                    setMarketingWalletError('Owner and marketing wallet cannot be the same')
                } else {
                    setMarketingWalletError(false)
                }
            }
        }
        if (tokenType === 'buyback') {
            if (liquidityFee.trim() > 100 && liquidityFee.trim() !== '') {
                _isValid = false
                setLiquidityFeeError('liquidityFee must be less than or equal to 100')
            } else if (liquidityFee.trim() < 0.01 && liquidityFee.trim() !== '') {
                _isValid = false
                setLiquidityFeeError('liquidityFee must be greater than or equal to 0.01')
            } else {
                setLiquidityFeeError(false)
            }

            if (buybackFee.trim() > 100 && buybackFee.trim() !== '') {
                _isValid = false
                setBuybackFeeError('buybackFee must be less than or equal to 100')
            } else if (buybackFee.trim() < 0.01 && buybackFee.trim() !== '') {
                _isValid = false
                setBuybackFeeError('buybackFee must be greater than or equal to 0.01')
            } else {
                setBuybackFeeError(false)
            }

            if (reflectionFee.trim() > 100 && reflectionFee.trim() !== '') {
                _isValid = false
                setReflectionFeeError('Reflection Fee must be less than or equal to 100')
            } else if (reflectionFee.trim() < 0.01 && reflectionFee.trim() !== '') {
                _isValid = false
                setReflectionFeeError('Eeflection Fee must be greater than or equal to 0.01')
            } else {
                setReflectionFeeError(false)
            }
        }*/
        return _isValid
    }

    const createNewToken = async () => {
        if (!tokenStandardFactoryContract) {
            showConnectionError()
            return false
        }
        const isValidRequest = await validation()
        if (isValidRequest) {
            setLoading(true)
            if (tokenType === 'Standard') {
                let tokenTuple = {
                    name: name,
                    symbol: symbol,
                    decimals: decimals,
                    quantity: supply
                }
                try {
                    const createTokenTx = await tokenStandardFactoryContract.createToken(tokenTuple)
                    const response = await createTokenTx.wait()
                    const tokenAddress = response.events[0].address
                    tokenTuple.tokenAddress = tokenAddress
                    tokenTuple.type = tokenType
                    if (response) {
                        axios
                            .post(
                                `${api}/tokens/add`,
                                {
                                    userAddress: user.address,
                                    tokenAddress: tokenAddress,
                                    type: tokenType,
                                    title: name,
                                    chainId: connectedNetwork.chainId
                                },
                                {
                                    headers: {
                                        authorization: `bearer ${user.token}`
                                    }
                                }
                            )
                            .then((response) => {
                                setLoading(false)
                                if (response.data.status) {
                                    // user.tokens.push(response.data.data)
                                    showSuccess('Standard token created successfully')
                                    tokenTuple = {...tokenTuple, chainId: connectedNetwork?.chainId}
                                    setTokenDetails(tokenTuple)
                                    _dispatch(openMainPage(false))
                                }
                            })
                            .catch(function (error) {
                                setLoading(false)
                                showError(error.message)
                            })
                    } else {
                        showError('Token creation failed')
                    }
                } catch (error) {
                    console.log(error)
                    setLoading(false)
                    showError(error.reason)
                }
            } else {
                let tokenTuple = {
                    name: name,
                    symbol: symbol,
                    quantity: supply,
                    taxFee: yieldTransactionFee,
                    liquidityFee: liquidityTransactionFee
                }
                try {
                    const createTokenTx = await tokenLiquidityFactoryContract.createToken(tokenTuple)
                    const response = await createTokenTx.wait()
                    const tokenAddress = response.events[0].address
                    tokenTuple.tokenAddress = tokenAddress
                    tokenTuple.type = tokenType
                    if (response) {
                        axios
                            .post(
                                `${api}/tokens/add`,
                                {
                                    userAddress: user.address,
                                    tokenAddress: tokenAddress,
                                    type: tokenType,
                                    title: name,
                                    chainId: connectedNetwork.chainId
                                },
                                {
                                    headers: {
                                        authorization: `bearer ${user.token}`
                                    }
                                }
                            )
                            .then((response) => {
                                if (response.data.status) {
                                    showSuccess('Token created successfully')
                                    tokenTuple = {...tokenTuple, chainId: connectedNetwork?.chainId}
                                    setTokenDetails(tokenTuple)
                                    _dispatch(openMainPage(false))
                                }
                                setLoading(false)
                            })
                            .catch(function (error) {
                                setLoading(false)
                                showError(error.message)
                            })
                    } else {
                        setLoading(false)
                        showError('Token creation failed')
                    }
                } catch (error) {
                    console.log(error)
                    setLoading(false)
                    showError(error.reason)
                }
            }
        } else {
            setLoading(false)
            return false
        }
    }

    return (
        <>
            {isMianPageOpen ? (
                <Wrapper>
                    <Row>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={8}>
                                    <Heading margin="0rem 0rem 1.25rem 0rem" isDarkTheme={isDarkTheme}>
                                        Create Token
                                    </Heading>
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={4}>
                                    <Flexed direction="row" justify="flex-end">
                                        <a target="_blank" href={DocumentLinks('createToken')}>
                                            <InfoIconAlign isDarkTheme={isDarkTheme} />
                                        </a>
                                    </Flexed>
                                </Col>
                            </Row>
                            <Col>
                                <Form isDarkTheme={isDarkTheme}>
                                    <Col md={6} lg={6}>
                                        <Label isDarkTheme={isDarkTheme}>
                                            Type <span>*</span>
                                        </Label>
                                        <Select
                                            firstSelected={option[0].label}
                                            name="type"
                                            hasChanged={(value) => {
                                                setTokenType(value)
                                                setNameError(false)
                                                setSymbolError(false)
                                                setDecimalsError(false)
                                                setSupplyError(false)
                                                setYieldTransactionFeeError(false)
                                                setLiquidityFeeError(false)
                                                setLiquidityTransactionFeeError(false)
                                            }}
                                            options={option}
                                        />
                                        <Info isDarkTheme={isDarkTheme}>Token Creatieon Fee: 0.01 BNB</Info>
                                        <Spacer height={1} />
                                    </Col>
                                    <Col md={6} lg={6}>
                                        <Label isDarkTheme={isDarkTheme}>
                                            Name <span>*</span>
                                        </Label>
                                        <InputText
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value)
                                                setNameError(false)
                                            }}
                                            isDarkTheme={isDarkTheme}
                                            type="text"
                                            placeholder=""
                                        />
                                        {nameError ? <Alblur>{nameError}</Alblur> : ''}
                                        <Spacer height={1} />
                                    </Col>
                                    <Col md={6} lg={6}>
                                        <Label isDarkTheme={isDarkTheme}>
                                            Symbol <span>*</span>
                                        </Label>
                                        <InputText
                                            value={symbol}
                                            onChange={(e) => {
                                                setSymbol(e.target.value)
                                                setSymbolError(false)
                                            }}
                                            isDarkTheme={isDarkTheme}
                                            type="text"
                                            placeholder=""
                                        />
                                        {symbolError && <Alblur>{symbolError}</Alblur>}
                                        <Spacer height={1} />
                                    </Col>
                                    {tokenType === 'Standard' && (
                                        <Col md={6} lg={6}>
                                            <Label isDarkTheme={isDarkTheme}>
                                                Decimals <span>*</span>
                                            </Label>
                                            <InputText
                                                value={decimals}
                                                onChange={(e) => {
                                                    if (!e.target.value.toString().includes('.')) {
                                                        setDecimals(e.target.value)
                                                        setDecimalsError(false)
                                                    }
                                                }}
                                                onKeyDown={preventNegativeValues}
                                                isDarkTheme={isDarkTheme}
                                                type="number"
                                                min="0"
                                                placeholder="18"
                                            />
                                            {decimalsError ? <Alblur>{decimalsError}</Alblur> : ''}
                                            <Spacer height={1} />
                                        </Col>
                                    )}
                                    <Col md={6} lg={6}>
                                        <Label isDarkTheme={isDarkTheme}>
                                            Total Supply <span>*</span>
                                        </Label>
                                        <InputText
                                            value={supply}
                                            onChange={(e) => {
                                                let val = e.target.value
                                                if (digits_regex.test(e.target.value)) {
                                                    val = e.target.value?.replace(/\D/g, '')
                                                }
                                                setSupply(val)
                                                setSupplyError(false)
                                            }}
                                            onKeyDown={preventNegativeValues}
                                            isDarkTheme={isDarkTheme}
                                            // type="number"
                                            min="0"
                                            placeholder={tokenType === 'Liquidity' ? '1000000000' : '1'}
                                        />
                                        {supplyError ? <Alblur>{supplyError}</Alblur> : ''}
                                        <Spacer height={1} />
                                    </Col>
                                    {tokenType === 'Liquidity' && (
                                        <>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>Transaction fee to generate tax (%)</Label>
                                                <InputText
                                                    value={yieldTransactionFee}
                                                    onChange={(e) => {
                                                        setYieldTransactionFee(e.target.value)
                                                        setYieldTransactionFeeError(false)
                                                    }}
                                                    onKeyDown={preventNegativeValues}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    min="0"
                                                    placeholder="0"
                                                />
                                                {yieldTransactionFeeError && <Alblur>{yieldTransactionFeeError}</Alblur>}
                                                <Spacer height={1} />
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>Transaction fee to generate liquidity (%)</Label>
                                                <InputText
                                                    value={liquidityTransactionFee}
                                                    onChange={(e) => {
                                                        setLiquidityTransactionFee(e.target.value)
                                                        setLiquidityTransactionFeeError(false)
                                                    }}
                                                    onKeyDown={preventNegativeValues}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    min="0"
                                                    placeholder="0"
                                                />
                                                {liquidityTransactionFeeError && <Alblur>{liquidityTransactionFeeError}</Alblur>}
                                                <Spacer height={1} />
                                            </Col>
                                            {/* <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>Charity/Marketing address</Label>
                                                <InputText
                                                    value={charityMarketingAddress}
                                                    onChange={(e) => {
                                                        setCharityMarketingAddress(e.target.value.toLowerCase())
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="text"
                                                    placeholder=""
                                                />
                                                {charityMarketingAddressError ? <Alblur>Address is invalid</Alblur> : ''}
                                                <Spacer height={1} />
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>Charity/Marketing percent (%)</Label>
                                                <InputText
                                                    value={charityMarketingPercent}
                                                    onChange={(e) => {
                                                        setCharityMarketingPercent(e.target.value)
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="text"
                                                    placeholder=""
                                                />
                                                {charityMarketingPercentError ? <Alblur>{charityMarketingPercentError}</Alblur> : ''}
                                                <Spacer height={1} />
                                            </Col> */}
                                        </>
                                    )}
                                    {/* {(tokenType === 'baby' || tokenType === 'buyback') && (
                                        <>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>
                                                    Reward token <span>*</span>
                                                </Label>
                                                <InputText
                                                    value={rewardToken}
                                                    onChange={(e) => {
                                                        setRewardToken(e.target.value.toLowerCase())
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="text"
                                                    placeholder="Ex: 0x..."
                                                />
                                                {rewardTokenError ? <Alblur>Address is invalid</Alblur> : ''}
                                                <Info isDarkTheme={isDarkTheme}>If you want to reward DOGE, please enter</Info>
                                                <Info isDarkTheme={isDarkTheme}>0xba2ae424d960c26247dd6c32edc70b295c744c43.</Info>
                                                <Spacer height={1} />
                                            </Col>
                                        </>
                                    )} */}

                                    {/* {tokenType === 'baby' && (
                                        <>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>
                                                    Minimum token balance for dividends <span>*</span>
                                                </Label>
                                                <InputText
                                                    value={minTokenBalanceForDividends}
                                                    onChange={(e) => {
                                                        setMinTokenBalanceForDividends(e.target.value)
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    placeholder="Ex: 100000000000"
                                                />
                                                {minTokenBalanceForDividendsError ? <Alblur>{minTokenBalanceForDividendsError}</Alblur> : ''}
                                                <Info isDarkTheme={isDarkTheme}>Min hold each wallet must be over $50 to receive rewards.</Info>
                                                <Spacer height={1} />
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>
                                                    Token reward fee (%) <span>*</span>
                                                </Label>
                                                <InputText
                                                    value={tokenRewardsFee}
                                                    onChange={(e) => {
                                                        setTokenRewardsFee(e.target.value)
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    placeholder="1 - 100"
                                                />
                                                {tokenRewardsFeeError ? <Alblur>{tokenRewardsFeeError}</Alblur> : ''}
                                                <Spacer height={1} />
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>
                                                    Auto add liquidity (%) <span>*</span>
                                                </Label>
                                                <InputText
                                                    value={autoAddliquidityFee}
                                                    onChange={(e) => {
                                                        setAutoAddLiquidityFee(e.target.value)
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    placeholder="1 - 100"
                                                />
                                                {autoAddliquidityFeeError ? <Alblur>{autoAddliquidityFeeError}</Alblur> : ''}
                                                <Spacer height={1} />
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>
                                                    Marketing wallet <span>*</span>
                                                </Label>
                                                <InputText
                                                    value={marketingWallet}
                                                    onChange={(e) => {
                                                        setMarketingWallet(e.target.value.toLowerCase())
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="text"
                                                    placeholder="Ex: 0x...."
                                                />
                                                {marketingWalletError && <Alblur>{marketingWalletError}</Alblur>}
                                                <Spacer height={1} />
                                            </Col>
                                        </>
                                    )} */}

                                    {/* {tokenType === 'buyback' && (
                                        <>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>Liquidity Fee (%)</Label>
                                                <InputText
                                                    value={liquidityFee}
                                                    onChange={(e) => {
                                                        setLiquidityFee(e.target.value)
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    placeholder="1 - 100"
                                                />
                                                {liquidityFeeError ? <Alblur>{liquidityFeeError}</Alblur> : ''}
                                                <Spacer height={1} />
                                            </Col>

                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>Buyback Fee (%)</Label>
                                                <InputText
                                                    value={buybackFee}
                                                    onChange={(e) => {
                                                        setBuybackFee(e.target.value)
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    placeholder="1 - 100"
                                                />
                                                {buybackFeeError ? <Alblur>{buybackFeeError}</Alblur> : ''}
                                                <Spacer height={1} />
                                            </Col>

                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>Reflection Fee (%)</Label>
                                                <InputText
                                                    value={reflectionFee}
                                                    onChange={(e) => {
                                                        setReflectionFee(e.target.value)
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    placeholder="1 - 100"
                                                />
                                                {reflectionFeeError ? <Alblur>{reflectionFeeError}</Alblur> : ''}
                                                <Spacer height={1} />
                                            </Col>
                                        </>
                                    )} */}

                                    {/* {(tokenType === 'baby' || tokenType === 'buyback') && (
                                        <>
                                            <Col md={6} lg={6}>
                                                <Label isDarkTheme={isDarkTheme}>
                                                    Marketing fee (%) <span>*</span>
                                                </Label>
                                                <InputText
                                                    value={marketingFee}
                                                    onChange={(e) => {
                                                        setMarketingFee(e.target.value)
                                                    }}
                                                    isDarkTheme={isDarkTheme}
                                                    type="number"
                                                    placeholder="1 - 100"
                                                />
                                                {marketingFeeError ? <Alblur>{marketingFeeError}</Alblur> : ''}
                                                <br />
                                                <Alblur>Liquidity Fee + Buyback Fee + Reflection Fee + Marketing Fee must be less than 25%</Alblur>
                                                <Spacer height={1} />
                                            </Col>
                                        </>
                                    )} */}

                                    {/* <CustomCol>
                            <CheckBox
                                isDarkTheme={isDarkTheme}
                                type="checkbox"
                                checked={applyAntiBotSystem}
                                onChange={(e) => {
                                    setApplyAntiBotSystem(e.target.checked)
                                }}
                            />
                            <CheckLabel isDarkTheme={isDarkTheme}>Implement Pink Anti-Bot System?</CheckLabel>
                        </CustomCol> */}
                                    <NetworksDropdown />
                                    <ActionContent>
                                        <Spacer height={1.5} />
                                        <Flexed direction="row">
                                            <Button
                                                isDarkTheme={isDarkTheme}
                                                label={'Create Token'}
                                                ifClicked={() => {
                                                    createNewToken()
                                                }}
                                            />
                                        </Flexed>
                                    </ActionContent>
                                </Form>
                            </Col>
                        </Container>
                    </Row>
                </Wrapper>
            ) : (
                <MyTokenDetails tokenDetails={tokenDetails} />
            )}
            {loading && (
                <LoadingPanelContent>
                    <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                </LoadingPanelContent>
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    padding-top: 2rem;
    ${media.lg`
width: 90%;
`};
    ${media.xl`
width: 90%;
`};
    ${media.xs`
padding-top: 0rem;
`}
    ${media.sm`
padding-top: 2rem;
`}
`

const Form = styled(Row)`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`

const ActionContent = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const CheckLabel = styled.label`
    font-size: 0.9rem;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
    margin-left: 0.5rem;
`

const CustomCol = styled(Col)`
    display: flex;
    align-items: center;
`

const CheckBox = styled.input`
    border: 2px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    // box-shadow:0 0 0 1px ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    // appearance:none;
    width: 14px;
    height: 14px;
    accent-color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    cursor: pointer;
    &:checked {
        accent-color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    }
`
const InfoIconAlign = styled(InfoIcon)`
    right: 0.938rem;
    ${media.xs`right:0rem;`}
    ${media.sm`right:0.938rem;`}
`

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateToken)
