import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { useSelector } from 'react-redux'
import { Flexed, Spacer } from '../styles/shared'
import { Heading } from '../styles/shared'

const Networks = () => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)

    return (
        <Container>
            <Spacer height="1" />
            <Row>
                <Col lg={12} xl={12} xxl={12}>
                    <Heading isCentered margin={'0rem 0rem 1rem 0rem'} isDarkTheme={isDarkTheme}>
                        Available Networks
                    </Heading>
                </Col>
            </Row>
            <Spacer height="2" />
            <Row alignItems="center">
                <Box xs={12} sm={12} md={4} lg={4}>
                    <NetworkContent isDarkTheme={isDarkTheme} align="center">
                        <NetworkIconMobile src="/images/binance.png" />
                        <Text fontSize={1.1} isDarkTheme={isDarkTheme}>
                            Binance Smart Chain
                        </Text>
                        <Spacer height="0.5" />
                        <Coin direction="row" align="center">
                            <NetworkIcon src="/images/binance.png" />
                            <Text isDarkTheme={isDarkTheme}>BNB</Text>
                        </Coin>
                    </NetworkContent>
                </Box>
                <Box xs={12} sm={12} md={4} lg={4}>
                    <NetworkContent isDarkTheme={isDarkTheme} align="center">
                        <NetworkIconMobile src="/images/ethereum.png" />
                        <Text fontSize={1.1} isDarkTheme={isDarkTheme}>
                            Ethereum
                        </Text>
                        <Spacer height="0.5" />
                        <Coin direction="row" align="center">
                            <NetworkIcon src="/images/ethereum.png" />
                            <Text isDarkTheme={isDarkTheme}>ETH</Text>
                        </Coin>
                    </NetworkContent>
                </Box>
                <Box xs={12} sm={12} md={4} lg={4}>
                    <NetworkContent isDarkTheme={isDarkTheme} align="center">
                        <NetworkIconMobile src="/images/polygon.png" />
                        <Text fontSize={1.1} isDarkTheme={isDarkTheme}>
                            Polygon
                        </Text>
                        <Spacer height="0.5" />
                        <Coin direction="row" align="center">
                            <NetworkIcon src="/images/polygon.png" />
                            <Text isDarkTheme={isDarkTheme}>Matic</Text>
                        </Coin>
                    </NetworkContent>
                </Box>
            </Row>
        </Container>
    )
}

const Text = styled.p`
    // font-family: 'bicubik', sans-serif;
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}rem` : `0.9rem`)};
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.lightAqua}` : `${palette.pure_black}`)};
    text-align: center;
    // font-weight: ${({ fontSize }) => (fontSize ? `bold` : ``)};
`

const Box = styled(Col)`
    padding: 0;
    position: relative;
    margin-bottom: 3rem;
    &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: -0.5rem;
        margin: auto;
        height: 5rem;
        width: 0.15rem;
        right: 0;
        transform: rotate(24deg);
        border-radius: 1rem;
        z-index: 1;
        background-image: linear-gradient(#45e8ff, #0085ff 100%);
    }
    ${media.xs`
    display:flex;
    justify-content:center;
    &::after{
        display:none !important;
    }
    `}
    ${media.sm`
     display:flex;
    justify-content:center;
    &::after{
        display:none !important;
    }
    `}
    ${media.md`
    &::after{
        display:block !important;
    }
    `}
`

const NetworkContent = styled(Flexed)`
${media.xs`flex-direction: row;`}
${media.sm`flex-direction: row;width:38%;`}
${media.md`flex-direction: column;width:100%;`}
@media screen and (min-width: 0px) and (max-width: 328px) {
    width: 100%; !important;
}
@media screen and (min-width: 329px) and (max-width: 357px) {
    width: 80%; !important;
}
@media screen and (min-width: 358px) and (max-width: 415px) {
    width: 73%; !important;
}
@media screen and (min-width: 416px) and (max-width: 464px) {
    width: 62%; !important;
}
@media screen and (min-width: 465px) and (max-width: 542px) {
    width:55%; !important;
}
@media screen and (min-width: 542px) and (max-width: 609px) {
    width:48%; !important;
}
@media screen and (min-width: 610px) and (max-width: 667px) {
    width:42%; !important;
}
`

const NetworkIcon = styled.img`
    width: 2rem;
    border-radius: 2rem;
    margin-right: 0.5rem;
`

const NetworkIconMobile = styled(NetworkIcon)`
    width: 2rem;
    border-radius: 2rem;
    margin-right: 0.5rem;
    ${media.md`
    display:none !important;
`}
    ${media.lg`
    display:none !important;
`}
`

const Coin = styled(Flexed)`
    ${media.xs`
    display:none !important;
`}
    ${media.sm`
    display:none !important;
`}
${media.md`
    display:flex !important;
`}
`

export default Networks
