import {NetworksTypes} from '../utils'

export const TokenStandardFactoryContract = {
    contractId: (chainId) => {
        if (chainId == NetworksTypes.EthereumMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.EthereumTestNet.chainId) {
            return '0x71Fb09645cb9A9004B9835C5C17EC9b13F1F9Fda'
        } else if (chainId == NetworksTypes.BinanceMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.BinanceTestNet.chainId) {
            return '0x457e275711be3b6A35936067912531a6966CB7Dd'
        } else if (chainId == NetworksTypes.PolygonMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.PolygonTestNet.chainId) {
            return '0xE736bd4DaEec516214e6cb9F172e689629a32a4C'
        }
    },
    abi: [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: '_tokenCreator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: '_tokenAddress',
                    type: 'address'
                }
            ],
            name: 'tokenCreated',
            type: 'event'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'string',
                            name: 'name',
                            type: 'string'
                        },
                        {
                            internalType: 'string',
                            name: 'symbol',
                            type: 'string'
                        },
                        {
                            internalType: 'uint256',
                            name: 'decimals',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'quantity',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct CreateTokenFactory.info',
                    name: '_info',
                    type: 'tuple'
                }
            ],
            name: 'createToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
}
