export const LockContract = {
    id: '',
    abi: [
        {
            inputs: [
                {
                    internalType: 'contract IERC20',
                    name: '_token',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'lockCreator',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: '_releaseTime',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            inputs: [],
            name: 'beneficiary',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'checkStatus',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'release',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'releaseTime',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'token',
            outputs: [
                {
                    internalType: 'contract IERC20',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'tokenBalance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'unlocked',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_days',
                    type: 'uint256'
                }
            ],
            name: 'updateReleaseTime',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
}
