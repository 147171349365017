import React from 'react'
import styled from 'styled-components'
import {palette} from '../assets/common'
import {useSelector} from 'react-redux'
import {Spacer, Flexed, RadioBox, CheckLabel} from '../styles/shared'
import {Label} from '../styles/shared'
import {Col} from 'styled-bootstrap-grid'

const CreateFeeOptions = ({feeOption, setFeeOption}) => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)

    return (
        <>
            <Col>
                <Spacer height={2} />
                <Label isDarkTheme={isDarkTheme}>Fee Options</Label>
            </Col>
            <Col>
                <Flexed margin="0.5rem 0rem 0.5rem 0rem" direction="row" align="center">
                    <RadioBox isDarkTheme={isDarkTheme} id="fixed" name="fixed" type="radio" value={'fixed'} checked={feeOption == 'fixed'} onChange={(e) => setFeeOption(e.target.value)} />
                    <CheckLabel htmlFor="fixed" isDarkTheme={isDarkTheme}>
                        Fixed (<Span>Recommended</Span>)
                    </CheckLabel>
                </Flexed>
                <Flexed margin="0rem 0rem 0.5rem 0rem" direction="row" align="center">
                    <RadioBox isDarkTheme={isDarkTheme} id="percent" name="percent" type="radio" value={'percent'} checked={feeOption == 'percent'} onChange={(e) => setFeeOption(e.target.value)}></RadioBox>
                    <CheckLabel htmlFor="percent" isDarkTheme={isDarkTheme}>
                        5% BNB raised only
                    </CheckLabel>
                </Flexed>
                {/* {connectedNetwork && (
                    <Info isDarkTheme={isDarkTheme}>
                        Token will be swap with <SelectedCurrency>{connectedNetwork?.symbol}</SelectedCurrency>. You have to add liquidity to list the token on <SelectedCurrency>{connectedNetwork?.exchange}</SelectedCurrency> if softcap is reached.
                    </Info>
                )} */}
                <Spacer height={1} />
            </Col>
        </>
    )
}

const Span = styled.span`
    color: ${palette?.aqua_blue} !important;
`

export default CreateFeeOptions
