import React, { useEffect, useState } from 'react'
import { palette } from '../assets/common'
import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid'
import Button from './Button'
import CountdownTimer from './CountdownTimer'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { media } from 'styled-bootstrap-grid'
import { openMainPage } from '../actions/authActions'
import { Bar, Flexed, ProgressBar, TextPlaceHolder, BarBadgeWrapper, BarBadge, BarBadgeText, Badge, FailedBarBadgeText } from '../styles/shared'
import { kycUrl } from '../config'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useCountdown } from '../hooks'
import { DocumentLinks, showError, getJsonRpcProvider, getNetworkById } from '../utils/index'
import { ethers } from 'ethers'
import { PresaleContract } from '../contracts/PresaleContract'
import { FairPresaleContract } from '../contracts/FairPresaleContract'
import { PrivatePresaleContract } from '../contracts/PrivatePresaleContract'
import { Copy } from './CopyTooltip'

const PresaleList = ({ id, _value, setLaunchpadInfo, updatePresale }) => {
    const _dispatch = useDispatch()
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const [softCapInWei, setSoftCap] = useState(null)
    const [hardCapInWei, setHardCap] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [totalCollected, setTotalCollected] = useState(null)
    const [isLaunchpadClosed, setIsLaunchpadClosed] = useState(null)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const [value, setValue] = useState({
        chainId: _value.chainId,
        launchpadAddress: _value.launchpadAddress,
        userAddress: _value.userAddress,
        tokenAddress: _value.tokenAddress,
        saleTitle: _value.saleTitle,
        isSuccessful: _value?.isSuccessful,
        isLaunchpadClosed: _value?.isLaunchpadClosed,
        isFairSale: _value?.isFairSale,
        softCapInWei: _value?.softCapInWei,
        hardCapInWei: _value?.hardCapInWei,
        startTime: _value?.startTime,
        endTime: _value?.endTime,
        isDataLoaded: _value?.isDataLoaded,
        preSaleNetwork: _value?.preSaleNetwork,
        logoUrl: _value?.logoUrl,
        kycStatus: _value?.kycStatus,
        isPrivateSale: _value?.isPrivateSale,
        totalCollected: _value?.totalCollected,
        twitter: _value?.twitter,
        telegram: _value?.telegram,
        discord: _value?.discord,
        website: _value?.website
    })
    const [isPreSaleStarted, setIsPreSaleStarted] = useState(value?.startTime < moment().unix())

    useEffect(async () => {
        value.preSaleNetwork = await getNetworkById(value.chainId)
        if (!value?.isDataLoaded) {
            await fetchPresalepadDetail(value)
        } else {
            setIsLaunchpadClosed(value?.isLaunchpadClosed)
            setSoftCap(value?.softCapInWei)
            setHardCap(value?.hardCapInWei)
            setTotalCollected(value?.totalCollected)
            setIsSuccessful(value?.isSuccessful)
            setStartTime(value?.startTime)
            setEndTime(value?.endTime)
        }
    }, [value])

    const fetchPresalepadDetail = async (launchpad) => {
        const provider = await getJsonRpcProvider(launchpad.chainId)
        try {
            launchpad.preSaleNetwork = await getNetworkById(launchpad.chainId)
            const investementPreSale = new ethers.Contract(launchpad.launchpadAddress, launchpad.isFairSale ? FairPresaleContract.abi : launchpad.isPrivateSale ? PrivatePresaleContract.abi : PresaleContract.abi, provider)
            const _isLaunchpadClosed = await investementPreSale.checkStatus()
            launchpad.isLaunchpadClosed = _isLaunchpadClosed
            setIsLaunchpadClosed(_isLaunchpadClosed)
            setSoftCap(launchpad.softCapInWei)
            if (!launchpad.isFairSale) {
                setHardCap(launchpad.hardCapInWei)
            }
            let _totalCollectedWei = await investementPreSale.totalCollectedWei()
            _totalCollectedWei = ethers.utils.formatEther(_totalCollectedWei)
            launchpad.totalCollected = _totalCollectedWei
            setTotalCollected(_totalCollectedWei)
            if (_totalCollectedWei >= launchpad.softCapInWei) {
                launchpad.isSuccessful = true
                setIsSuccessful(true)
            } else {
                launchpad.isSuccessful = false
            }
            setStartTime(launchpad.startTime)
            setEndTime(launchpad.endTime) //it means data is loaded
            launchpad.isDataLoaded = true
            setValue(launchpad)
            updatePresale(launchpad)
        } catch (error) {
            console.log(error)
        }
    }

    const renderTooltip = (...props) => <TooltipBox {...props}>{props?.[1] + ': ' + props?.[0]}</TooltipBox>

    const updateSale = async () => {
        setIsPreSaleStarted(moment(parseInt(value?.startTime) * 1000).unix() < moment().unix())
        setIsLaunchpadClosed(moment(parseInt(value?.endTime) * 1000).unix() < moment().unix())
    }

    return (
        <CustomCol id={id} key={id} xxl={4} xl={6} lg={6} md={6}>
            <Card isDarkTheme={isDarkTheme} disabled={endTime ? false : true}>
                <Profile>
                    <ProfileBlur src={value?.logoUrl ? value?.logoUrl : `/images/economicsale.png`} />
                    <Flexed justify="center" align="center">
                        <BadgesContent style={{ zIndex: '2' }}>
                            {isSuccessful == true ? (
                                <BarBadgeWrapper>
                                    {value?.preSaleNetwork?.title === 'Binance Smart Chain Testnet' || value?.preSaleNetwork?.title === 'Binance Smart Chain Mainnet' ? <BarBadge src="/images/bar-binance-color.svg" /> : value?.preSaleNetwork?.title === 'Polygon Testnet Mumbai' || value?.preSaleNetwork?.title === 'Polygon Mainnet' ? <BarBadge src="/images/bar-polygon-color.svg" /> : value?.preSaleNetwork?.title === 'Ethereum Mainnet' || value?.preSaleNetwork?.title === 'Ethereum Testnet Goerli' ? <BarBadge src="/images/bar-ether-color.svg" /> : null}
                                    <BarBadgeText isDarkTheme={isDarkTheme}>Success</BarBadgeText>
                                </BarBadgeWrapper>
                            ) : isSuccessful == false && isPreSaleStarted && isLaunchpadClosed && value?.preSaleNetwork?.title && <BarBadgeWrapper>
                                {value?.preSaleNetwork?.title === 'Binance Smart Chain Testnet' || value?.preSaleNetwork?.title === 'Binance Smart Chain Mainnet' ? <BarBadge src="/images/bar-binance-color.svg" /> : value?.preSaleNetwork?.title === 'Polygon Testnet Mumbai' || value?.preSaleNetwork?.title === 'Polygon Mainnet' ? <BarBadge src="/images/bar-polygon-color.svg" /> : value?.preSaleNetwork?.title === 'Ethereum Mainnet' || value?.preSaleNetwork?.title === 'Ethereum Testnet Goerli' ? <BarBadge src="/images/bar-ether-color.svg" /> : null}
                                <FailedBarBadgeText isDarkTheme={isDarkTheme}>Failed</FailedBarBadgeText>
                            </BarBadgeWrapper>}
                            {(value?.kycStatus == 2 || value?.kycStatus == 4) && (
                                <a href={kycUrl + value?.launchpadAddress} target="_blank">
                                    <Badge src="/images/badge-blue.png" />
                                </a>
                            )}
                        </BadgesContent>
                        <Name isDarkTheme={isDarkTheme}>{value?.saleTitle}</Name>
                        <ProfileContent>
                            <ProfileImage isDarkTheme={isDarkTheme} src={value?.logoUrl} />
                            {value?.preSaleNetwork?.icon ? <NetworkIcon src={value?.preSaleNetwork?.icon} /> : ''}
                        </ProfileContent>
                    </Flexed>
                    <Content isDarkTheme={isDarkTheme} directionColumn>
                        <CoverLabel>Presale Address:</CoverLabel>
                        {value?.preSaleNetwork ? (
                            <Flexed direction="row" align="center">
                                <AddressText isDarkTheme={isDarkTheme} href={`${value?.preSaleNetwork?.networkUrl}/${value?.launchpadAddress}`} target="_blank">
                                    {value?.launchpadAddress}
                                </AddressText>
                                <Copy text={`${value.preSaleNetwork?.networkUrl}/${value?.launchpadAddress}`} isDarkTheme={isDarkTheme} />
                            </Flexed>
                        ) : (
                            <TextPlaceHolder />
                        )}
                    </Content>
                </Profile>
                <CardBody>
                    <BlockItems>
                        {value?.isFairSale == false && value?.isPrivateSale == false && <Status status={'normal'}>Normal Sale</Status>}
                        {value?.isFairSale == true && <Status status={'fair'}>Fair Presale</Status>}
                        {value?.isPrivateSale == true && <Status status={'private'}>Private Presale</Status>}
                        {endTime ? (
                            <Status shape={value?.isPrivateSale == true || value?.isFairSale == true || (value?.isFairSale == false && value?.isPrivateSale == false)} status={isPreSaleStarted && !isLaunchpadClosed ? 'live' : isLaunchpadClosed ? 'closed' : 'upcoming'}>
                                Sale {isPreSaleStarted && !isLaunchpadClosed ? 'Live' : isLaunchpadClosed ? 'Closed' : 'Upcoming'}
                            </Status>
                        ) : (
                            <TextPlaceHolder height={'1.5rem'} width={'6rem'} />
                        )}
                    </BlockItems>
                    <Content isDarkTheme={isDarkTheme} directionColumn>
                        <Label isDarkTheme={isDarkTheme}>Token Address:</Label>
                        {value?.preSaleNetwork ? (
                            <Flexed direction="row" align="center">
                                <AddressText isDarkTheme={isDarkTheme} href={`${value?.preSaleNetwork?.networkUrl}/${value?.tokenAddress}`} target="_blank">
                                    {value?.tokenAddress}
                                </AddressText>
                                <Copy text={`${value.preSaleNetwork?.networkUrl}/${value?.tokenAddress}`} isDarkTheme={isDarkTheme} />
                            </Flexed>
                        ) : (
                            <TextPlaceHolder />
                        )}
                    </Content>
                    <Flexed align="center" direction="row" justify="end" margin="0.3rem 0rem 0rem 0rem">
                        {totalCollected ? (
                            <>
                                {value?.isFairSale == true ? (
                                    <CompleteBar>
                                        <ProgressBar isDarkTheme={isDarkTheme} width={(parseFloat(totalCollected) / parseFloat(softCapInWei)) * 100} />
                                    </CompleteBar>
                                ) : (
                                    <>
                                        <CompleteBarA>
                                            <ProgressBarA isDarkTheme={isDarkTheme} width={(parseFloat(totalCollected) / parseFloat(softCapInWei)) * 100} />
                                        </CompleteBarA>
                                        <CompleteBarB>
                                            <ProgressBarB isDarkTheme={isDarkTheme} width={parseFloat(totalCollected) <= parseFloat(softCapInWei) ? 0 : (parseFloat(totalCollected) / parseFloat(hardCapInWei)) * 100} />
                                        </CompleteBarB>
                                    </>
                                )}
                            </>
                        ) : (
                            <TextPlaceHolder width={'34rem'} />
                        )}
                    </Flexed>

                    <Flexed align="center" direction="row" justify="end">
                        <Numbers align="center" direction="row" justify="space-between">
                            {value?.isFairSale ? (
                                <>
                                    {/* {totalCollected ? ( */}
                                    <OverlayTrigger placement="top" overlay={renderTooltip((totalCollected ? totalCollected : '-') + ' ' + value?.preSaleNetwork?.symbol, 'Total collected')}>
                                        <TextTooltip isDarkTheme={isDarkTheme}>
                                            {totalCollected && (totalCollected ? totalCollected : '-')} {value?.preSaleNetwork?.symbol}
                                        </TextTooltip>
                                    </OverlayTrigger>
                                    {/* </Text> */}
                                    {/* ) : (
                                        <TextPlaceHolder />
                                    )}
                                    {minCap ? ( */}
                                    <OverlayTrigger placement="top" overlay={renderTooltip((softCapInWei ? softCapInWei : '-') + ' ' + value?.preSaleNetwork?.symbol, 'Softcap')}>
                                        <TextTooltip isDarkTheme={isDarkTheme}>
                                            {/* <Text isDarkTheme={isDarkTheme}> */}
                                            {softCapInWei && (softCapInWei ? softCapInWei : '-')} {value?.preSaleNetwork?.symbol}
                                            {/* </Text> */}
                                        </TextTooltip>
                                    </OverlayTrigger>
                                    {/* ) : (
                                        <TextPlaceHolder />
                                    )} */}
                                </>
                            ) : (
                                <>
                                    {/* {minCap ? ( */}
                                    <OverlayTrigger placement="top" overlay={renderTooltip(0 + ' ' + value?.preSaleNetwork?.symbol, 'Begin')}>
                                        <TextTooltip isDarkTheme={isDarkTheme}>
                                            {softCapInWei && (softCapInWei ? 0 : '-')} {value?.preSaleNetwork?.symbol}
                                        </TextTooltip>
                                    </OverlayTrigger>
                                    {/* ) : (
                                        <TextPlaceHolder />
                                    )}
                                    {minCap ? ( */}
                                    <OverlayTrigger placement="top" overlay={renderTooltip((softCapInWei ? softCapInWei : '-') + ' ' + value?.preSaleNetwork?.symbol, 'MinCap')}>
                                        <TextTooltip isDarkTheme={isDarkTheme}>
                                            {softCapInWei && (softCapInWei ? softCapInWei : '-')} {value?.preSaleNetwork?.symbol}
                                        </TextTooltip>
                                    </OverlayTrigger>
                                    {/* ) : (
                                        <TextPlaceHolder />
                                    )}
                                    {maxCap ? ( */}
                                    <OverlayTrigger placement="top" overlay={renderTooltip((hardCapInWei ? hardCapInWei : '-') + ' ' + value?.preSaleNetwork?.symbol, 'MaxCap')}>
                                        <TextTooltip isDarkTheme={isDarkTheme}>
                                            {hardCapInWei && (hardCapInWei ? hardCapInWei : '-')} {value?.preSaleNetwork?.symbol}
                                        </TextTooltip>
                                    </OverlayTrigger>
                                    {/* ) : (
                                        <TextPlaceHolder />
                                    )} */}
                                </>
                            )}
                        </Numbers>
                    </Flexed>
                    <Flex>
                        <PreSaleStatus>
                            {/* {endTime ? ( */}
                            <>
                                {isPreSaleStarted && !isLaunchpadClosed && moment.unix(endTime).format() >= moment().format() ? (
                                    <>
                                        <Text isCentered isDarkTheme={isDarkTheme}>
                                            Sale Ends In:
                                        </Text>
                                        <div id="minitimer">
                                            <CountdownTimer targetDate={endTime * 1000} updateList={updateSale} />
                                        </div>
                                    </>
                                ) : isLaunchpadClosed ? null : (
                                    moment.unix(startTime).format() >= moment().format() && (
                                        <>
                                            <Text isCentered isDarkTheme={isDarkTheme}>
                                                Sale Starts In:
                                            </Text>
                                            <div id="minitimer">
                                                <CountdownTimer targetDate={startTime * 1000} updateList={updateSale} />
                                            </div>
                                        </>
                                    )
                                )}
                            </>
                            {/* ) : (
                                <TextPlaceHolder />
                            )} */}
                        </PreSaleStatus>
                    </Flex>
                    <Flex>
                        <PreSaleStatus margin="0.75rem 0rem 0rem 0rem">
                            {/* {endTime ? ( */}
                            <Button
                                isDarkTheme={isDarkTheme}
                                label={'View Presale'}
                                ifClicked={() => {
                                    let _launchpadInfo = {}
                                    _launchpadInfo.launchpadAddress = value?.launchpadAddress
                                    _launchpadInfo.preSaleNetwork = value?.preSaleNetwork
                                    _launchpadInfo.chainId = value?.chainId
                                    _launchpadInfo.isFair = value?.isFairSale ? true : false
                                    _launchpadInfo.isPrivate = value?.isPrivateSale ? true : false
                                    //todo..fix status
                                    _launchpadInfo.status = isPreSaleStarted && !isLaunchpadClosed ? 'live' : isLaunchpadClosed ? 'ended' : 'upcoming'
                                    setLaunchpadInfo(_launchpadInfo)
                                    _dispatch(openMainPage(false))
                                }}></Button>
                            {/* ) : (
                                <TextPlaceHolder />
                            )} */}
                        </PreSaleStatus>
                    </Flex>
                </CardBody>
            </Card>
        </CustomCol>
    )
}

const TooltipBox = styled(Tooltip)`
    border-radius: 1rem;
    padding: 0.1rem 0.4rem;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px;
`

const CustomCol = styled(Col)`
    margin-bottom: 1rem;
    // cursor: pointer;
`

const Profile = styled.div`
    display: flex;
    // align-items: center;
    margin-bottom: 1rem;
    // justify-content: center;
    flex-direction: column;
    padding: 0.2rem 0.3rem 1.5rem 0.3rem;
    background: ${palette.dark};
    position: relative;
`

const ProfileBlur = styled.div`
    background: ${({ src }) => (src ? `url(${src}) no-repeat` : `${palette.dark}`)};
    background-size: cover;
    background-position: center;
    filter: blur(1rem);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
`

const ProfileContent = styled.div`
    position: relative;
    margin: 0.2rem 0rem 0.5rem 0rem;
    z-index: 2;
`

const BadgesContent = styled.div`
    z-index: 2;
`

const ProfileImage = styled.div`
    width: 6.3rem;
    height: 6.3rem;
    background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.charcol_light}` : `${palette.blue_light}`)};
    background-size: cover;
    background-position: center;
    border: 0.1rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.blue}`)};
    border-radius: 3rem;
    z-index: 2;
`

const BlockItems = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: -1.9rem;
`

const Status = styled.div`
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 0.8rem;
    line-height: 1.38rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    padding: 0.2rem 0.5rem;
    width: fit-content;
    border-top-left-radius: ${({ shape }) => (shape ? `0.4rem` : `0rem`)};
    border-bottom-left-radius: ${({ shape }) => (shape ? `0.4rem` : `0rem`)};
    border-top-right-radius: ${({ shape }) => (shape ? `0rem` : `0.4rem`)};
    border-bottom-right-radius: ${({ shape }) => (shape ? `0rem` : `0.4rem`)};
    text-transform: uppercase;
    color: ${palette.white};
    z-index: 2;
    background: ${({ status }) => (status == 'live' ? `${palette.green_gradient}` : status == 'upcoming' ? `${palette.yellow_gradient}` : status == 'normal' ? `${palette.green_gradient}` : status == 'fair' ? `${palette.blue_gradient_list}` : status == 'private' ? `${palette.theme_Text}` : `${palette.red_gradient}`)};
`

const Dot = styled.span`
    background-color: ${({ status }) => (status == 'live' ? `${palette.green}` : status == 'upcoming' ? `${palette.yellow}` : status == 'fair' ? `${palette.aqua_blue}` : status == 'private' ? `${palette.theme_Text}` : `${palette.red}`)};
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    margin-right: 0.3rem;
`

const Card = styled.div`
    overflow: hidden;
    position: relative;
    border-radius: 1rem;
    box-sizing: border-box;
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    cursor: auto;
    transition: all 0.4s ease-in-out;
    &:hover {
        box-shadow: ${({ isDarkTheme }) => (isDarkTheme ? `0 0 4px ${palette.dark}, 0 0 7px ${palette.dark_gray}` : `0 0 1px rgb(0 0 0 / 17%), 0 8px 8px rgb(0 0 0 / 8%), 0 10px 12px rgb(0 0 0 / 0%), 0 14px 16px rgb(0 0 0 / 2%)`)};
    }
`

const Content = styled.span`
    display: flex;
    flex-direction: ${({ directionColumn }) => (directionColumn ? 'column' : 'row')};
    font-size: 1rem;
    z-index: 2;
`

const Label = styled.span`
    font-size: 0.93rem;
    font-weight: bold;
    display: block;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
`

const CoverLabel = styled(Label)`
    color: ${palette.off_white};
`

const CardBody = styled.div`
    position: relative;
    padding: 0.5rem 2rem 2rem 2rem;
    ${media.xs`
    padding: 0.5rem 1rem 1rem 1rem;
    `};
    ${media.sm`
    padding: 0.5rem 2rem 2rem 2rem;
    `};
`

const Flex = styled.div`
    // border-top: 0.1rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: ${({ margin }) => (margin ? `${margin}` : '0')};
`

const PreSaleStatus = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${({ margin }) => (margin ? `${margin}` : '0')};
`

const Text = styled.div`
    font-size: 14px;
    line-height: 1.5;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
    display: inline-block;
    // width: calc(100% - 3rem);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: ${({ isCentered }) => (isCentered ? `center` : `left`)};
`

const Name = styled(Text)`
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_white}`)};
    width: 16rem;
    text-align: center;
    white-space: initial;
    word-wrap: break-word;
    z-index: 2;
`

const AddressText = styled.a`
    font-size: 14px;
    line-height: 1.5;
    margin-left: ${({ margin }) => (margin ? `${'0.5rem'}` : '0')};
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    z-index: 2;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    word-wrap: break-word;
    &:hover {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
        opacity: 0.8;
    }
`

const Numbers = styled(Flexed)`
    width: 100%;
`

const CompleteBar = styled(Bar)`
    width: 100%;
`

const NetworkIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    bottom: 0;
    right: 0.5rem;
    border-radius: 2rem;
    position: absolute;
`

const TextTooltip = styled(Text)`
    cursor: pointer;
`

const CompleteBarA = styled(CompleteBar)`
    border-top-right-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
`

const CompleteBarB = styled(CompleteBar)`
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
`

const ProgressBarA = styled(ProgressBar)`
    // background: green;
    border-top-right-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
`

const ProgressBarB = styled(ProgressBar)`
    // background: yellow;
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
`

export default PresaleList
