import React, {useState, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../assets/common'
import styled from 'styled-components'
import {Heading, TabHeading, Flexed, InfoIcon, LoadingPanelContent, LoadingPanel} from '../../styles/shared'
import {TabList, Tab} from 'react-tabs'
import AirdropList from '../../components/AirdropList'
import {useSelector, useDispatch} from 'react-redux'
import axios from 'axios'
import {api} from '../../config'
import AirdropDetails from '../../components/AirdropDetails'
import {ethers, Signer} from 'ethers'
import Dropdown from '../../components/Dropdown'
import {DocumentLinks, showError, getJsonRpcProvider, getNetworkById} from '../../utils/index'
import Button from '../../components/Button'

const ViewAllAirdrops = ({airdropType}) => {
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const user = useSelector((state) => state.auth.user)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    const [activeTab, setActiveTab] = useState(1)
    const [allProducts, setAllProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])

    const [loading, setLoading] = useState(false)
    const [airdropInfo, setAirdropInfo] = useState('')

    const [totalLength, setTotalLength] = useState(0)
    const [myLength, setMyLength] = useState(0)
    const [isFilteringInProcess, setIsFilteringInProcess] = useState(false)
    const [statusFilter, setStatusFilter] = useState('')
    const [option, setOption] = useState([
        {value: '', label: 'All'},
        {value: 'public', label: 'Public'},
        {value: 'private', label: 'Private'}
    ])
    const [page, setPage] = useState(0)
    const [totalDbLength, setTotalDbLength] = useState(0)

    const limit = 8

    useEffect(async () => {
        await resetStates()
        if (isMianPageOpen) {
            await doFetchAllAirdrops(page)
        }
    }, [airdropType, isMianPageOpen, connectedNetwork, user])

    const resetStates = async () => {
        setPage(0)
        setAllProducts([])
        setFilteredProducts([])
        setStatusFilter('')
        setActiveTab(1)
        setMyLength(0)
        setTotalLength(0)
    }

    const doFetchAllAirdrops = async (page) => {
        setLoading(true)
        axios
            .post(`${api}/air_drop/view_all`, {
                chainId: connectedNetwork?.chainId,
                page: page,
                limit: limit
            })
            .then(async (response) => {
                setLoading(false)
                if (response.data.status) {
                    setTotalDbLength(response?.data?.data?.count)
                    let listOfAllAirdrops = []
                    let mine = 0
                    response?.data?.data?.rows?.forEach((value) => {
                        if (value?.userAddress == user?.address) {
                            mine = mine + 1
                        }
                        listOfAllAirdrops.push(value)
                    })
                    setMyLength(page > 0 ? myLength + mine : mine)
                    setTotalLength(page > 0 ? totalLength + response?.data?.data?.rows?.length : response?.data?.data?.rows?.length)
                    setAllProducts(page > 0 ? allProducts?.concat(listOfAllAirdrops) : listOfAllAirdrops)
                    setFilteredProducts(page > 0 ? filteredProducts?.concat(listOfAllAirdrops) : listOfAllAirdrops)
                } else {
                    showError('Something went wrong. Please try again later.')
                }
            })
            .catch(function (error) {
                setLoading(false)
                showError(error.message)
            })
    }

    const handleFilter = async (tab, status) => {
        setIsFilteringInProcess(true)
        setFilteredProducts([])
        setTimeout(() => {
            let _filteredProducts = []
            let _myFilteredProducts = []

            if (status == 'public') {
                allProducts?.filter((_product) => {
                    !_product?.isPrivate && _filteredProducts.push(_product)
                })
            } else if (status == 'private') {
                allProducts?.filter((_product) => {
                    _product?.isPrivate && _filteredProducts.push(_product)
                })
            } else {
                _filteredProducts = allProducts
            }

            _filteredProducts?.filter((_product) => {
                if (_product?.userAddress == user?.address) {
                    _myFilteredProducts.push(_product)
                }
            })

            setMyLength(_myFilteredProducts.length)
            setTotalLength(_filteredProducts.length)
            setFilteredProducts(tab == 1 ? _filteredProducts : _myFilteredProducts)
            setIsFilteringInProcess(false)
        }, 200)
        return true
    }

    const updateAirdrop = async (airdrop) => {
        for (let index = 0; index < allProducts.length; index++) {
            if (allProducts[index].airdropAddress == airdrop.airdropAddress) {
                allProducts[index] = airdrop
                break
            }
        }
    }

    return (
        <>
            {isMianPageOpen ? (
                <>
                    {loading && (
                        <LoadingPanelContent>
                            <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                        </LoadingPanelContent>
                    )}
                    <Wrapper>
                        <Row>
                            <Col>
                                <Grid>
                                    <Heading margin="0rem 0rem 1.25rem 0rem" isDarkTheme={isDarkTheme}>
                                        Airdrops
                                    </Heading>
                                    <Flexed direction="row" justify="flex-end">
                                        <DropWrapper>
                                            <Dropdown
                                                disabled={isFilteringInProcess}
                                                isReset={statusFilter === '' ? true : false}
                                                firstSelected={option[0].label}
                                                name="collection"
                                                hasChanged={(value) => {
                                                    setStatusFilter(value)
                                                    handleFilter(activeTab, value)
                                                }}
                                                options={option}
                                            />
                                            <a target="_blank" href={DocumentLinks('viewAllAirDrop')}>
                                                <InfoIcon isDarkTheme={isDarkTheme} position={'relative'} marginBottom={0} />
                                            </a>
                                        </DropWrapper>
                                    </Flexed>
                                </Grid>
                            </Col>
                        </Row>
                        <CustomRow>
                            <Col>
                                <STabs>
                                    <DataWrapper isDarkTheme={isDarkTheme}>
                                        <STabList>
                                            <TabHeading
                                                isDarkTheme={isDarkTheme}
                                                onClick={() => {
                                                    setActiveTab(1)
                                                    handleFilter(1, statusFilter)
                                                }}
                                                $active={activeTab == 1}>
                                                All Airdrops ({totalLength})
                                            </TabHeading>
                                            <TabHeading
                                                disabled={loading}
                                                isDarkTheme={isDarkTheme}
                                                onClick={() => {
                                                    if (!loading) {
                                                        setActiveTab(2)
                                                        handleFilter(2, statusFilter)
                                                    } else {
                                                        showError('Loading in process...')
                                                    }
                                                }}
                                                $active={activeTab == 2}>
                                                My Airdrops ({myLength})
                                            </TabHeading>
                                        </STabList>
                                    </DataWrapper>
                                    <STabPanel>
                                        <TabContent>
                                            <CustomContainer>
                                                <Row>
                                                    {filteredProducts?.map((value, index) => {
                                                        return <AirdropList key={index + '_airdrop'} id={index + '_airdrop'} _value={value} setAirdropInfo={setAirdropInfo} updateAirdrop={updateAirdrop} />
                                                    })}

                                                    {filteredProducts?.length == 0 && <NotFoundText>{!loading && 'No airdrop found'}</NotFoundText>}
                                                </Row>
                                                {!loading && statusFilter.length == 0 && filteredProducts?.length > 0 && allProducts.length % limit == 0 && activeTab == 1 && totalLength < totalDbLength && (
                                                    <Row justifyContent={'center'}>
                                                        <Button
                                                            isDarkTheme={isDarkTheme}
                                                            ifClicked={() => {
                                                                setPage(page + 1)
                                                                setTimeout(() => {
                                                                    doFetchAllAirdrops(page + 1)
                                                                }, 120)
                                                            }}
                                                            label={'Load More'}
                                                        />
                                                    </Row>
                                                )}
                                            </CustomContainer>
                                        </TabContent>
                                    </STabPanel>
                                </STabs>
                            </Col>
                        </CustomRow>
                    </Wrapper>
                </>
            ) : (
                <AirdropDetails _airdropInfo={airdropInfo} />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    ${media.lg`
        width: 90%;
    `};
    ${media.xl`
        width: 90%;
    `};
`
const CustomContainer = styled(Container)`
    padding: 0;
`

const CustomRow = styled(Row)`
    justify-content: center;
`

const DataWrapper = styled.div`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    // border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    // height: 5rem;
    // border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

const STabs = styled(Tab)`
    font-size: 1.2rem;
    width: 100%;
    list-style: none;
    // cursor: pointer;
`

const STabList = styled(TabList)`
    list-style-type: none;
    padding: 0.3rem 0rem;
    display: flex;
    justify-content: center;
    margin: 0 0.938rem;
    border-bottom: 0.5px solid #eee;
    position: relative;
`
STabList.tabsRole = 'TabList'

TabHeading.tabsRole = 'Tab'

const STabPanel = styled.div`
    min-height: 40vh;
`

const TabContent = styled.div`
    min-height: 40vh;
    padding: 2rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
`

STabPanel.tabsRole = 'TabPanel'

const NotFoundText = styled(Col)`
    width: 100 %;
    display: flex;
    align-items: center;
    color: ${palette.dark_gray};
    justify-content: center;
    padding-top: 5rem;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
`

const DropWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 2rem;
    width: 16rem;
    right: 0;
    bottom: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    align-items: center;
    ${media.xs`
        grid-template-columns: 1fr;
    `}
    ${media.sm`
        grid-template-columns: 1fr;
    `}
    ${media.md`
        grid-template-columns: 1fr 1fr;
    `}
`

export default ViewAllAirdrops
