export const token_regex = /^(0x)?[0-9a-f]{40}$/
export const url_regex = /^(ftp|http|https):\/\/[^ "]+$/
export const img_url_regex = /([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i
export const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
export const string_regex = /^[a-zA-Z]+$/
export const numeric_regex = /^[0-9]$/
export const digits_regex = /\D/g
export function startsWithNumber(str) {
    return /^\d/.test(str)
}
