import React, {useEffect, useRef, useState} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import WalletLink from 'walletlink'
import {ethers} from 'ethers'
import {AiOutlineLoading3Quarters, AiOutlineMenuUnfold} from 'react-icons/ai'
import CryptoJS from 'crypto-js'
import {connect} from 'react-redux'
import {BsXLg} from 'react-icons/bs'
import {AiFillCopy} from 'react-icons/ai'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {BsSunFill, BsMoonFill} from 'react-icons/bs'
import axios from 'axios'
import {Flexed, SocialIcons, BarBadgeWrapper, BarBadge, AddressText} from '../styles/shared'
import {Copy} from './CopyTooltip'

import {PresaleFactoryContract} from '../contracts/PresaleFactoryContract'
import {PrivatePresaleFactoryContract} from '../contracts/PrivatePresaleFactoryContract'
import {FairPresaleFactoryContract} from '../contracts/FairPresaleFactoryContract'

import {AirdropFactoryContract} from '../contracts/AirdropFactoryContract'
import {AirdropPrivateFactoryContract} from '../contracts/AirdropPrivateFactoryContract'
import {LockFactoryContract} from '../contracts/LockFactoryContract'
import {TokenStandardFactoryContract} from '../contracts/TokenStandardFactoryContract'
import {TokenLiquidityFactoryContract} from '../contracts/TokenLiquidityFactoryContract'

import useWindowSize from '../hooks/useWindowSize'
import {api, docBaseUrl, isTestNet, kycUrl} from '../config/index'
import {palette} from '../assets/common'
import {getNetworkById, showConnectionError, showError, showSuccess, DocumentLinks} from '../utils'
import {handleBackgroundType, handleBorderType} from './Button'
import {setConnected, setDisconnected, openSideMenu, switchTheme} from '../actions/authActions'

// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const Header = (props) => {
    let _navigate = useNavigate()
    let _dispatch = useDispatch()
    const connectBtnRef = useRef()

    const {width, height} = useWindowSize()
    const [toolTipText, setToolTipText] = useState(false)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const isSideMenuOpen = useSelector((state) => state.auth.openSideMenu)
    let user = useSelector((state) => state.auth.user)
    const [address, setAddress] = useState('')
    const [connectedNetwork, setConnectedNetwork] = useState(null)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [buttonLoader, setButtonLoader] = useState(false)

    useEffect(() => {
        if (user == null) {
            setConnectedNetwork(null)
        }
    }, [user])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true})
    }, [])

    let accChangeCount = 0
    let chainChangeCount = 0

    const handleScroll = () => {
        const _position = window.pageYOffset
        setScrollPosition(_position)
    }

    const handleConnect = async () => {
        try {
            setButtonLoader(true)
            const {default: MewConnectImport} = await import('@myetherwallet/mewconnect-web-client')
            const infuraId = process.env.REACT_APP_INFURA_ID
            const providerOptions = {
                walletconnect: {
                    package: WalletConnectProvider,
                    options: {
                        infuraId
                    }
                },
                mewconnect: {
                    package: MewConnectImport,
                    options: {
                        infuraId
                    }
                },
                'custom-coinbase': {
                    display: {
                        logo: '/images/coinbase_wallet.png',
                        name: 'Coinbase',
                        description: 'Scan with WalletLink to connect'
                    },
                    package: WalletLink,
                    options: {
                        appName: 'Economic Sale',
                        networkUrl: `https://mainnet.infura.io/v3/${infuraId}`,
                        chainId: 1
                    },
                    connector: async (_, options) => {
                        const {appName, networkUrl, chainId} = options
                        const walletLink = new WalletLink({
                            appName
                        })
                        const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
                        await provider.enable()
                        return provider
                    }
                }
            }

            const web3Modal = new Web3Modal({
                network: 'mainnet',
                cacheProvider: true,
                providerOptions
            })

            const web3 = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(web3)
            const signer = provider.getSigner()
            let address = await signer.getAddress()
            address = address.toLowerCase()
            const chainId = await signer.getChainId()
            const _connectedNetwork = await getNetworkById(chainId)
            if (_connectedNetwork) {
                let nonce = await doPreLogin(address, chainId)
                if (nonce) {
                    const signature = await signer.signMessage(nonce.toString())
                    user = await doLogin(address, chainId, signature)
                    if (user) {
                        const presaleFactoryContract = new ethers.Contract(PresaleFactoryContract.contractId(chainId), PresaleFactoryContract.abi, signer)
                        const privatePresaleFactoryContract = new ethers.Contract(PrivatePresaleFactoryContract.contractId(chainId), PrivatePresaleFactoryContract.abi, signer)
                        const fairPresaleFactoryContract = new ethers.Contract(FairPresaleFactoryContract.contractId(chainId), FairPresaleFactoryContract.abi, signer)
                        const lockFactoryContract = new ethers.Contract(LockFactoryContract.contractId(chainId), LockFactoryContract.abi, signer)
                        const airdropFactoryContract = new ethers.Contract(AirdropFactoryContract.contractId(chainId), AirdropFactoryContract.abi, signer)
                        const airdropPrivateFactoryContract = new ethers.Contract(AirdropPrivateFactoryContract.contractId(chainId), AirdropPrivateFactoryContract.abi, signer)
                        const tokenStandardFactoryContract = new ethers.Contract(TokenStandardFactoryContract.contractId(chainId), TokenStandardFactoryContract.abi, signer)
                        const tokenLiquidityFactoryContract = new ethers.Contract(TokenLiquidityFactoryContract.contractId(chainId), TokenLiquidityFactoryContract.abi, signer)
                        setAddress(address)
                        setConnectedNetwork(_connectedNetwork)
                        props.setConnected({
                            connectedNetwork: _connectedNetwork,
                            user: user,
                            signer: signer,
                            presaleFactoryContract: presaleFactoryContract,
                            privatePresaleFactoryContract: privatePresaleFactoryContract,
                            lockFactoryContract: lockFactoryContract,
                            airdropFactoryContract: airdropFactoryContract,
                            airdropPrivateFactoryContract: airdropPrivateFactoryContract,
                            fairPresaleFactoryContract: fairPresaleFactoryContract,
                            tokenStandardFactoryContract: tokenStandardFactoryContract,
                            tokenLiquidityFactoryContract: tokenLiquidityFactoryContract
                        })

                        web3.on('accountsChanged', (accounts) => {
                            handleSwitchAccount(accounts[0])
                        })

                        web3.on('chainChanged', (chainId) => {
                            handleSwitchNetwork(chainId)
                        })

                        web3.on('disconnect', (error) => {
                            handleDisconnect()
                        })
                        setButtonLoader(false)
                    } else {
                        setButtonLoader(false)

                        showError('Server login error. Please try again later.')
                        props.setConnected({
                            connectedNetwork: null
                        })
                    }
                } else {
                    setButtonLoader(false)
                    showError('Server connectivity error. Please try again later.')
                    props.setConnected({
                        connectedNetwork: null
                    })
                }
            } else {
                setButtonLoader(false)
                showError('Please connect to chain id 97, 80001 or ...')
                props.setConnected({
                    connectedNetwork: null
                })
            }
        } catch (error) {
            setButtonLoader(false)
            console.log(error)
            props.setConnected({
                connectedNetwork: null
            })
        }
    }

    const handleSwitchAccount = (account) => {
        if (account != address) {
            setAddress('')
            props.setConnected({
                user: null
            })
            if (chainChangeCount === 0) {
                setTimeout(() => {
                    connectBtnRef?.current?.click()
                }, 1000)
            }
            chainChangeCount = chainChangeCount + 1
        }
    }

    const handleSwitchNetwork = (chainId) => {
        chainId = parseInt(chainId?.toString())
        if (chainId == 5 || chainId == 97 || chainId == 80001) {
            // const _connectedNetwork = await getNetworkById(chainId)
            setConnectedNetwork(null)
            props.setConnected({
                connectedNetwork: null,
                user: null
            })
            if (chainChangeCount === 0) {
                setTimeout(() => {
                    connectBtnRef?.current?.click()
                }, 1000)
            }
            chainChangeCount = chainChangeCount + 1
        } else {
            props.setDisconnected()
        }
    }

    const handleDisconnect = async () => {
        setConnectedNetwork(null)
        props.setDisconnected()
    }

    const doPreLogin = async (address, chainId) => {
        const response = await axios.post(`${api}/user/pre_login`, {address: address, chainId: chainId})
        if (response.data.status) {
            const nonce = response.data.data
            const bytes = CryptoJS.AES.decrypt(nonce, process.env.REACT_APP_SECRET)
            const decrypted = bytes.toString(CryptoJS.enc.Utf8)
            return decrypted
        } else {
            return false
        }
    }

    const doLogin = async (address, chainId, signature) => {
        const response = await axios.post(`${api}/user/login`, {address: address, chainId: chainId, signature: signature})
        if (response.data.status) {
            showSuccess('Wallet Connected Successfully')
            return response.data.data
        } else {
            return false
        }
    }

    return (
        <Main
            isDarkTheme={isDarkTheme}
            fluid
            styled={{
                boxShadow: '0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%)'
            }}
            scroll={scrollPosition}>
            <Nav>
                <LogoContent isSideMenuOpen={isSideMenuOpen}>
                    {isTestNet && (
                        <BadgeWrapper testNetBadge={true} id="ddd">
                            <BarBadge src="/images/testnet.svg" testNetBadge={true} />
                        </BadgeWrapper>
                    )}
                    <Logo
                        src="/images/es_logo.png"
                        onClick={() => {
                            _navigate('/')
                        }}
                    />
                    <LogoText>
                        <BrandName isDarkTheme={isDarkTheme}>
                            Economic&nbsp;<span>Sale</span>
                        </BrandName>
                        <TagLine isDarkTheme={isDarkTheme}>
                            Defi <span>Launchpad</span>
                        </TagLine>
                    </LogoText>
                </LogoContent>

                <FlexRight>
                    <SocialContent direction="row">
                        <DescriptionContent>
                            <Link href="https://discord.gg/eUE2DaG6rj" target="_blank" rel="noopener noreferrer">
                                <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Discord.png' : '/svg/DiscordW.svg'} alt="Discord" />
                            </Link>
                            <Link href="https://www.economicsale.finance/" target="_blank" rel="noopener noreferrer">
                                <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Telegram.png' : '/svg/TelegramW.svg'} alt="Telegram" />
                            </Link>
                            <Link href="https://twitter.com/economic_sale" target="_blank" rel="noopener noreferrer">
                                <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Twitter.png' : '/svg/TwitterW.svg'} alt="Twitter" />
                            </Link>
                            <Link href={DocumentLinks('projectDetails')} target="_blank" rel="noopener noreferrer">
                                <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Document.png' : '/svg/DocumentButtonW.svg'} alt="Documentation" />
                            </Link>
                            <Link href={DocumentLinks('kyc')} target="_blank" rel="noopener noreferrer">
                                <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Kyc.png' : '/svg/KYCbuttonW.svg'} alt="Kyc" />
                            </Link>
                        </DescriptionContent>
                        <Cover
                            isDarkTheme={isDarkTheme}
                            isActive={!isDarkTheme}
                            onClick={() => {
                                if (isDarkTheme) {
                                    _dispatch(switchTheme(false))
                                } else {
                                    _dispatch(switchTheme(true))
                                }
                            }}>
                            {isDarkTheme ? <ModeIcon src="/svg/DayMode.svg" /> : <ModeIcon src="/svg/DarkModW.svg" />}
                        </Cover>
                    </SocialContent>
                    <FlexItem>
                        <DropMenu isConnected={connectedNetwork}>
                            <ButtonWrapper isDarkTheme={isDarkTheme}>
                                <ConnectButton ref={connectBtnRef} connectedNetwork={connectedNetwork} isDarkTheme={isDarkTheme} disabled={buttonLoader == true ? true : false} onClick={handleConnect}>
                                    {buttonLoader == false ? (
                                        !connectedNetwork ? (
                                            'Connect'
                                        ) : (
                                            <>
                                                <NetworkIcon src={connectedNetwork.icon} />
                                                {width > 1100 ? `${connectedNetwork.title}` : 'Connected'}
                                            </>
                                        )
                                    ) : (
                                        <span>
                                            <Processing /> &nbsp;Connecting...
                                        </span>
                                    )}
                                </ConnectButton>
                            </ButtonWrapper>
                            <Drop id="dropdown" isDarkTheme={isDarkTheme}>
                                <DropList isDarkTheme={isDarkTheme}>
                                    <Address>{address}</Address>
                                    &nbsp;
                                    <Copy text={address} isDarkTheme={isDarkTheme} top={'0.75rem'} />
                                </DropList>
                                <DropList2 isDarkTheme={isDarkTheme}>
                                    {user !== null && (
                                        <DisconnectButton isDarkTheme={isDarkTheme} onClick={handleDisconnect}>
                                            Disconnect
                                        </DisconnectButton>
                                    )}
                                </DropList2>{' '}
                            </Drop>
                        </DropMenu>
                        {!isSideMenuOpen ? (
                            <OpenSideNav
                                isDarkTheme={isDarkTheme}
                                onClick={() => {
                                    _dispatch(openSideMenu(true))
                                }}>
                                <AiOutlineMenuUnfold />
                            </OpenSideNav>
                        ) : (
                            <CloseSideNav
                                isDarkTheme={isDarkTheme}
                                onClick={() => {
                                    _dispatch(openSideMenu(false))
                                }}>
                                <BsXLg />
                            </CloseSideNav>
                        )}
                    </FlexItem>
                </FlexRight>
            </Nav>
        </Main>
    )
}

const Main = styled(Container)`
    // box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    position: fixed;
    // background: ${({scroll, isDarkTheme}) => (isDarkTheme ? (scroll == 0 ? 'rgba(4, 6, 9, 1)' : 'rgb(4, 6 , 9, 0.8)') : scroll == 0 ? '#fff' : '#f0f0f0e0')};
    background: ${({scroll, isDarkTheme}) => (isDarkTheme ? (scroll == 0 ? 'rgba(4, 6, 9, 1)' : 'rgb(4, 6 , 9, 0.8)') : scroll == 0 ? '#fff' : '#fff')};
    box-shadow: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_mode_shadow}` : `${palette.white_mode_shadow}`)};
    z-index: 4;
`

const FlexItem = styled.div`
    display: flex;
    justify-content: end;
`

const DropMenu = styled.div`
    order: 1;
    position: relative;
    &:hover #dropdown {
        display: ${({isConnected}) => (isConnected ? `block` : `none`)};
    }
    // ${media.xs`order:1 !important;`}
`

const Drop = styled.div`
    display: none;
    position: absolute;
    background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    width: 18rem;
    z-index: 1;
    right: 0;
    border-radius: 1.2rem;
    padding-bottom: 0.75rem;
`
const DropList = styled.div`
    display: flex;
`
const Address = styled(AddressText)`
    width: 88% !important;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
    background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border-radius: 1.2rem;
    padding: 0.5rem;
    text-decoration: none;
    display: block;
    position: relative;
    text-align: left;
    white-space: pre-line;
    word-wrap: break-word;
    & p {
        // color: ${({isDarkTheme, isActive}) => (isActive ? (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
        cursor: pointer;
    }

    &:hover {
        background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.gray_gradient}` : `${palette.white}`)};
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.blue}`)};
        opacity: 0.7;
        cursor: pointer;
    }
    &:hover p {
        // color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
        cursor: pointer;
    }
    &:not(:last-child) {
        margin-bottom: 0.2rem;
    }
`

const Nav = styled(Row)`
    align-items: center;
    justify-content: center;
    height: 5rem;
    padding: 0rem 3rem 0rem 3rem;
    ${media.xs`
    justify-content:start;
    padding: 0rem 1rem 0rem 1rem;
  `}
    ${media.sm`
    justify-content:start;
    padding: 0rem 1rem 0rem 1rem;
  `}
    ${media.md`
    justify-content:start;
  `}
  ${media.lg`
    justify-content:center;
  `}
`

const FlexRight = styled.div`
    position: absolute;
    right: 1%;
    display: flex;
    align-items: center;
    ${media.xs`display:inline-block;`}
`

const LogoContent = styled.div`
    position: absolute;
    left: 0.5rem;
    padding: 0rem;
    display: flex;
    align-items: center;

    ${media.lg`
    left: ${({isSideMenuOpen}) => (isSideMenuOpen ? '13.5rem' : '3.5rem')};
    `}
`
const Logo = styled.img`
    width: 5.5rem;
    padding: 0rem;
    cursor: pointer;
    ${media.xs`width: 5rem;`}
`

const LogoText = styled.span`
    margin-left: 0.5rem;
    @media screen and (min-width: 0px) and (max-width: 992px) {
        display: none;
    }
`

const BadgeWrapper = styled(BarBadgeWrapper)`
    left: -11px;
    ${media.lg`left:auto !important`};
`

const BrandName = styled.p`
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.black}`)};
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'bicubik';
    display: flex;
    justify-content: center;
    alignc-items: center;
    letter-spacing: 0.1rem;
    margin-top: -0.3rem;
    & span {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
        font-family: 'bicubik';
    }
`
const TagLine = styled.p`
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    font-family: 'bicubik';
    font-size: 0.64rem;
    margin-top: -1.2rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    text-align: center;
    & span {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.black}`)};
    }
`

const ButtonWrapper = styled.div`
    position: relative;
    transition: all 0.5s ease-in-out;
    width: fit-content;
    &::before {
        position: absolute;
        transform: matrix(1, 0.05, 0.01, 1, 0, 0);
        content: '';
        display: block;
        width: ${({isDarkTheme}) => (isDarkTheme ? `100%` : `80%`)};
        height: ${({isDarkTheme}) => (isDarkTheme ? `100%` : `72%`)};
        top: ${({isDarkTheme}) => (isDarkTheme ? `0px` : `9px`)};
        left: ${({isDarkTheme}) => (isDarkTheme ? `0px` : `9px`)};
        background: ${palette.blue_gradient_shadow};
        filter: ${({isDarkTheme}) => (isDarkTheme ? `blur(7.60982px)` : `blur(7.60982px)`)};
        border-radius: 2rem;
    }
    &:hover {
        &::before {
            background: none;
            filter: blur(7.60982px);
            border-radius: 2rem;
        }
    }
`

const ConnectButton = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    font-family: 'bicubik';
    letter-spacing: 0.15em;
    font-size: 0.8rem;
    background: ${({type, isDarkTheme}) => (isDarkTheme ? `${palette.black2}` : handleBackgroundType(type))};
    display: inline-block;
    margin: 0.4rem 0.5rem 0 0.5rem;
    padding: 0rem 0.8rem 0 0.8rem;
    height: 40px;
    line-height: 40px;
    font-weight: 600;
    border: 1px solid ${({type}) => handleBorderType(type)};
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.white}` : `${palette.white}`)};
    border-radius: 2rem;
    text-align: center;
    disabled: ${({disabled}) => (disabled ? true : false)};
    cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
    // text-overflow: ellipsis;
    overflow: hidden;
    min-width: 8.75rem;
    // max-width: 16.72rem;
    white-space: nowrap;
    transition: all 0.5s ease-in-out;
    &:hover {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_black}` : `${palette.white}`)};
        background: ${({isDarkTheme, type}) => (isDarkTheme ? handleBackgroundType(type) : `${palette.black2}`)};
    }
    ${media.xs`
        max-width: 13rem;
        font-size: 0.42rem;
        min-width: 5rem;
        margin: 0.4rem 0.3rem 0 0.3rem;
        padding: 0rem 0.4rem 0 0.4rem;
        height: 34px;
    line-height: 34px;
    `}
    ${media.sm`
    min-width: 8.75rem;
    `}
`

const spinnerAnimation = keyframes`
	to { -webkit-transform: rotate(360deg); }
`

const Processing = styled(AiOutlineLoading3Quarters)`
    animation: ${spinnerAnimation} 1.5s linear infinite;
    margin-left: 0.2rem;
`

const OpenSideNav = styled.div`
    order: 4;
    display: none;
    margin: 0.25rem 0 0 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    ${media.xs`margin-left: 0.2rem;`}
    @media screen and (min-width: 0px) and (max-width: 992px) {
        display: block;
    }
`

const CloseSideNav = styled(OpenSideNav)`
    font-size: 1.2rem;
`

const NetworkIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 2rem;
    ${media.xs`
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
    `}
    ${media.sm`
    width: 1rem;
    height: 1rem;
    `}
    ${media.md`
    width: 1.5rem;
    height: 1.5rem;
    `}
`

const DescriptionContent = styled.div`
    display: flex;
    align-items: center;
    & a {
        margin-left: 0.5rem;
    }
    ${media.xs`
    & a {
        margin-left: 0.35rem;
    }
    `}
`

const Link = styled.a`
    cursor: pointer;
`

const SocialContent = styled(Flexed)`
    // order: 1 !important;
    display: flex;
    margin: 0 0.5rem 0rem;
    ${media.xs`
        margin:0 0.2rem 0rem;
        // order:3 !important;
    `}
`

const Cover = styled.div`
    // filter: ${({isDarkTheme}) => (isDarkTheme ? 'brightness(10000%)' : 'brightness(30%)')};

    padding: 0.04rem 0.5rem;
    border-radius: 0.2rem;
    color: ${({isDarkTheme, isActive}) => (isDarkTheme ? (isActive ? `${palette.white}` : `${palette.yellow}`) : isActive ? `${palette.off_dark}` : `${palette.off_dark}`)};
    background: ${({isDarkTheme, isActive}) => (isDarkTheme ? (isActive ? `${palette.blue_gradient}` : `${palette.black}`) : isActive ? `${palette.blue_light}` : `${palette.white}`)};
    cursor: pointer;
    font-size: 1.25rem;
    ${media.xs`padding: 0.04rem 0.2rem;`}
`

export const ModeIcon = styled.img`
    cursor: pointer;
    width: 1.5rem;
    &:hover {
        opacity: 0.7;
        // filter: ${({isDarkTheme}) => (isDarkTheme ? 'brightness(10000%)' : 'brightness(30%)')};
    }
    ${media.xs`
        width: 1.2rem;
        height: 1.2rem;
    `}
`

const DropList2 = styled(DropList)`
    &:hover {
        background: ${({isDarkTheme}) => (isDarkTheme ? `transparent` : `${palette.white}`)};
    }
`

const DisconnectButton = styled(ConnectButton)`
    width: 94% !important;
`

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    setConnected: (data) => dispatch(setConnected(data)),
    setDisconnected: (data) => dispatch(setDisconnected(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
