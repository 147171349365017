import React, { useState, useRef, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components'
import ParticleEffectButton from 'react-particle-effect-button'
import Confetti from 'react-confetti'
import { isMobile } from 'react-device-detect'
import { Col, media } from 'styled-bootstrap-grid'
import { Modal } from 'react-responsive-modal'
import { Heading, Text, Flexed } from '../styles/shared'
import useWindowSize from '../hooks/useWindowSize'
import Button from './Button'
import { useSelector, useDispatch } from 'react-redux'
import { openMainPage } from '../actions/authActions'
import 'react-responsive-modal/styles.css'
import { palette } from '../assets/common'

const SuccessModal = ({ onClose, successFull, userLogo }) => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)
    const _dispatch = useDispatch()
    const { width, height } = useWindowSize()
    const [minting, setMinting] = useState(false)
    const [success, setSuccess] = useState(false)
    const [fadeOut, setFadeOut] = useState(false)
    const [complete, setComplete] = useState(false)
    const [failed, setFailed] = useState(false)
    const [errorText, setErrorText] = useState('Something went wrong.')
    const [userCoinLogo, setUserCoinLogo] = useState(null)

    const videoRef = useRef()

    const mintSuccessful = () => {
        setSuccess(true)
        setTimeout(() => {
            setFadeOut(true)
            videoRef?.current?.play()
        }, 1750)
        setTimeout(() => {
            setComplete(true)
            setFadeOut(false)
        }, 3150)
    }

    useEffect(() => {
        setMinting(true)
        setUserCoinLogo(userLogo)
    }, [])

    useEffect(() => {
        if (successFull) {
            mintSuccessful()
        }
    }, [successFull])

    return (
        <Modal
            open={true}
            center
            classNames={{
                overlay: 'customOverlay',
                modal: isDarkTheme ? 'successModalDark' : 'successModalWhite'
            }}
            closeOnOverlayClick={false}
            showCloseIcon={false}>
            <MintContainer minting={minting}>
                <StatusHeading isDarkTheme={isDarkTheme} fadeOut={fadeOut} failed={successFull}>
                    {successFull ? 'Congratulations' : 'Creating Presale'}
                </StatusHeading>
                <VideoContainer minting={minting}>
                    <FailedText failed={failed}>{errorText}</FailedText>
                    <CoverTop minting={minting} success={success} failed={failed} src={`${userCoinLogo ? userCoinLogo : '/images/es_logo.png'}`} ImgValid={userCoinLogo} alt="cover" />
                </VideoContainer>

                <VideoContainer minting={minting}>
                    <FailedText failed={failed}>{errorText}</FailedText>
                    <Cover minting={minting} success={success} failed={failed} src={`${connectedNetwork?.icon ? connectedNetwork?.icon : '/images/es_logo.png'}?fm=jpg&fl=progressive&w=400`} ImgValid={connectedNetwork?.icon} alt="cover" />
                </VideoContainer>

                <OverflowTop minting={minting} isDarkTheme={isDarkTheme} />
                <Overflow minting={minting} isDarkTheme={isDarkTheme} />
                <GratzText isDarkTheme={isDarkTheme} complete={complete}>Presale Created</GratzText>

                <ButtonContainer minting={minting} id="particleButton">
                    {complete && (
                        <Button
                            isDarkTheme={isDarkTheme}
                            ifClicked={() => {
                                onClose()
                                _dispatch(openMainPage(false))
                            }}
                            label={'Okay'}
                        />
                    )}
                </ButtonContainer>
            </MintContainer>

            {complete && <Confetti width={width} height={height} />}
        </Modal>
    )
}

const MintContainer = styled.div`
    position: relative;
    height: ${({ minting }) => (minting ? '660px' : '520px')};
    padding: 4rem 3rem 1rem;
    overflow: hidden;
    transition: height 1.5s ease-in-out;
    transition-delay: 0.5s;
    ${media.md`
		height: ${({ minting }) => (minting ? '660px' : '660px')};
		padding: 3rem 5.25rem 0;
	`}
`

const VideoContainer = styled.div`
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: ${({ minting }) => (minting ? 1 : 0)};
    transition: opacity 1s ease-in-out;
    transition-delay: 2s;
    pointer-events: none;
    z-index: 1;
`

const print1FromTop = keyframes`
    0% { transform: translateY(100%); }
	50% { transform: translateY(240%); }
	100% { transform: translateY(100%); }
`
const print2FromTop = keyframes`
0% { transform: translateY(0%); }
    15% { transform: translateY(0%); }
    20% { transform: translateY(0%); }
    25% { transform: translateY(0%); }
    45% { transform: translateY(0%); }
    50% { transform: translateY(0%); }
    75% { transform: translateY(0%); }
    100% { transform: translateY(0%); }
`
const print3FromTop = keyframes`
0% { transform: translateY(0%); }
15% { transform: translateY(15%); }
20% { transform: translateY(15%); }
25% { transform: translateY(15%); }
45% { transform: translateY(50%); }
50% { transform: translateY(75%); }
100% { transform: translateY(100%); }
`

const CoverTop = styled.img`
    position: absolute;
    border-radius: 10rem;
    object-fit: ${({ ImgValid }) => (ImgValid ? 'cover' : 'contain')};
    animation: ${({ minting }) =>
        minting
            ? css`
                  ${print1FromTop} 3s forwards ease-in infinite;
              `
            : undefined};
    animation: ${({ pending }) =>
        pending
            ? css`
                  ${print2FromTop} 5s forwards ease-in 5s;
              `
            : undefined};
    animation: ${({ success }) =>
        success
            ? css`
                  ${print3FromTop} 2s forwards ease-in;
              `
            : undefined};
    transition: width 0.35s ease-in-out;
    filter: ${({ failed }) => (failed ? 'grayscale(1)' : 'grayscale(0)')};
    z-index: 2;
    top: ${({ success }) => (success ? '-70px' : '-70px')};
    width: ${({ success }) => (success ? '130px' : '140px')};
    height: ${({ success }) => (success ? '130px' : '140px')};
`

const print1 = keyframes`
    0% { transform: translateY(-100%);}
    50% { transform: translateY(-240%); }
    100% { transform: translateY(-100%); }
`

const print2 = keyframes`
    0% { transform: translateY(0%); }
    15% { transform: translateY(0%); }
    20% { transform: translateY(0%); }
    25% { transform: translateY(0%); }
    45% { transform: translateY(0%); }
    50% { transform: translateY(0%); }
    75% { transform: translateY(0%); }
    100% { transform: translateY(0%); }
`

const print3 = keyframes`
0% { transform: translateY(0%); }
15% { transform: translateY(-15%); }
20% { transform: translateY(-15%); }
25% { transform: translateY(-15%); }
45% { transform: translateY(-50%); }
50% { transform: translateY(-75%); }
100% { transform: translateY(-100%); }
`

const Cover = styled.img`
    position: absolute;
    top: 409px;
    border-radius: 10rem;
    object-fit: ${({ ImgValid }) => (ImgValid ? 'cover' : 'contain')};
    animation: ${({ minting }) =>
        minting
            ? css`
                  ${print1} 3s forwards ease-in infinite;
              `
            : undefined};
    animation: ${({ pending }) =>
        pending
            ? css`
                  ${print2} 5s forwards ease-in 5s;
              `
            : undefined};
    animation: ${({ success }) =>
        success
            ? css`
                  ${print3} 2s forwards ease-in;
              `
            : undefined};
    transition: width 0.35s ease-in-out;
    // transition-delay: 2s;
    filter: ${({ failed }) => (failed ? 'grayscale(1)' : 'grayscale(0)')};
    z-index: 2;
    top: 409px;
    width: ${({ success }) => (success ? '130px' : '140px')};
    height: ${({ success }) => (success ? '130px' : '140px')};
    ${media.xs`
        top: 392px;
        `}
`

const StatusHeading = styled.h1`
    text-align: center;
    font-size: 1.75rem;
    font-family: 'bicubik';
    opacity: ${({ fadeOut }) => (fadeOut ? 0 : 1)};
    transition: opacity 0.25s ease-in-out;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.pure_white}` : `${palette.black}`)};
    z-index: 6;
    margin: auto;
    position: absolute;
    right: 0;
    left: 0;
    ${media.xs`
    font-size: 1rem;
    `}
`

const ButtonContainer = styled.div`
    position: absolute;
    bottom: 40px;
    transform: translateX(-50%);
    left: 50%;
    z-index: 3;
`

const MintButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 48px;
    background: linear-gradient(180deg, #ef2548 0%, #c91534 100%);
    border: 1px solid;
    border-color: #f2f2f2;
    border-radius: 6px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    cursor: pointer;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    transition: opacity 0.25s ease-in-out;
    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
    ${media.md`
		width: 478px;
		height: 52px;
	`}
`

const hideText = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

const GratzText = styled.p`
    position: absolute;
    bottom: 132px;
    left: 50%;
    width: 100%;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.pure_white}` : `${palette.black}`)};
    text-align: center;
    transform: translateX(-50%);
    opacity: ${({ complete }) => (complete ? 1 : 0)};
    font-size: 1.75rem;
    font-family: 'bicubik';
    letter-spacing: 0.02em;
    transition: opacity 0.75s ease-in-out;
    transition-delay: 0.75s;
    z-index: 6;
    ${media.xs`
    font-size: 1rem;
    `}
`

const FailedText = styled.p`
    width: 100%;
    max-width: 480px;
    opacity: ${({ failed }) => (failed ? 1 : 0)};
    transition: opacity 0.25s ease-in-out;
    margin-top: 2rem;
    font-size: 1.35rem;
    text-align: center;
`

const DividerTop = styled.div`
    position: absolute;
    top: ${({ minting }) => (minting ? '6.6rem' : '6.6rem')};
    left: 50%;
    transform: translateX(-50%);
    width: ${({ minting }) => (minting ? '200px' : '308px')};
    height: ${({ minting }) => (minting ? '3px' : '1px')};
    opacity: ${({ success }) => (success ? 0 : 1)};
    background-color: #e5e5e5;
    transition: all 1s ease-in-out;
    transition-delay: 0.5s;
    z-index: 4;
    ${media.md`
		width: ${({ minting }) => (minting ? '400px' : '478px')};
	`}
`
const Divider = styled.div`
    position: absolute;
    bottom: ${({ minting }) => (minting ? '202px' : '202px')};
    left: 50%;
    transform: translateX(-50%);
    width: ${({ minting }) => (minting ? '200px' : '308px')};
    height: ${({ minting }) => (minting ? '3px' : '1px')};
    opacity: ${({ success }) => (success ? 0 : 1)};
    background-color: #e5e5e5;
    transition: all 1s ease-in-out;
    transition-delay: 0.5s;
    z-index: 4;
    ${media.md`
		width: ${({ minting }) => (minting ? '400px' : '478px')};
	`}
`

const Overflow = styled.div`
    position: absolute;
    bottom: ${({ minting }) => (minting ? '202px' : '130px')};
    transform: translateY(100%);
    width: 80%;
    height: 100%;
    background: ${({ isDarkTheme }) => (isDarkTheme ? 'black' : `${palette.white}`)};
    z-index: 2;
`
const OverflowTop = styled.div`
    position: absolute;
    top: ${({ minting }) => (minting ? '0rem' : '130px')};
    transform: translateY(0%);
    width: 80%;
    height: 16%;
    background: ${({ isDarkTheme }) => (isDarkTheme ? 'black' : `${palette.white}`)};
    z-index: 2;
`

export default SuccessModal
