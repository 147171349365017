import {NetworksTypes} from '../utils'

export const PrivatePresaleFactoryContract = {
    contractId: (chainId) => {
        if (chainId == NetworksTypes.EthereumMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.EthereumTestNet.chainId) {
            return '0xD8d008aD24A1745Ec91378e9057664c4f920B33e'
        } else if (chainId == NetworksTypes.BinanceMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.BinanceTestNet.chainId) {
            return '0xa09f9205d7CA63CEDcAC6339e082C3bE8eE954F0'
        } else if (chainId == NetworksTypes.PolygonMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.PolygonTestNet.chainId) {
            return '0x71Fb09645cb9A9004B9835C5C17EC9b13F1F9Fda'
        }
    },
    abi: [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_InfoAddress',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'Id',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'presalecontractaddress',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'liquiditylockaddress',
                    type: 'address'
                }
            ],
            name: 'PresaleCreated',
            type: 'event'
        },
        {
            inputs: [],
            name: 'SSS',
            outputs: [
                {
                    internalType: 'contract InvestmentsInfo',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenAddress',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'decimals',
                            type: 'uint256'
                        },
                        {
                            internalType: 'address[]',
                            name: 'whitelistedAddresses',
                            type: 'address[]'
                        },
                        {
                            internalType: 'uint256',
                            name: 'tokenPriceInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'hardCapInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'softCapInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'maxInvestInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'minInvestInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'openTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'closeTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'bool',
                            name: 'fixedPresale',
                            type: 'bool'
                        }
                    ],
                    internalType: 'struct PrivateInvestmentsFactory.PresaleInfo',
                    name: '_info',
                    type: 'tuple'
                },
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'listingPriceInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidityAddingTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'lpTokensLockDurationInDays',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidityPercentageAllocation',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct PrivateInvestmentsFactory.PresaleUniswapInfo',
                    name: '_uniInfo',
                    type: 'tuple'
                }
            ],
            name: 'createPresale',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenAddress',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'decimals',
                            type: 'uint256'
                        },
                        {
                            internalType: 'address[]',
                            name: 'whitelistedAddresses',
                            type: 'address[]'
                        },
                        {
                            internalType: 'uint256',
                            name: 'tokenPriceInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'hardCapInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'softCapInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'maxInvestInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'minInvestInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'openTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'closeTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'bool',
                            name: 'fixedPresale',
                            type: 'bool'
                        }
                    ],
                    internalType: 'struct PrivateInvestmentsFactory.PresaleInfo',
                    name: '_info',
                    type: 'tuple'
                },
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'listingPriceInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidityAddingTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'lpTokensLockDurationInDays',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidityPercentageAllocation',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct PrivateInvestmentsFactory.PresaleUniswapInfo',
                    name: '_uniInfo',
                    type: 'tuple'
                }
            ],
            name: 'createPresaleP',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
}
