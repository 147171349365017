import React, { useState, useEffect } from 'react'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { Label, Info, Alblur, Para, CreateTokenLabel } from '../../styles/shared'
import { palette } from '../../assets/common'
import styled from 'styled-components'
import { Spacer, InputText, Flexed, TextArea, LoadingPanelContent, LoadingPanel, StepperHeading, InfoIcon, Step } from '../../styles/shared'
import Button from '../../components/Button'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { ERC20 } from '../../contracts/ERC20'
import { ethers } from 'ethers'
import { connect } from 'react-redux'
import LockDetails from '../../components/LockDetails'
import { openMainPage } from '../../actions/authActions'
import { api } from '../../config/index'
import { saveUser } from '../../actions/authActions'
import { token_regex, url_regex, img_url_regex, startsWithNumber } from '../../utils/regex'
import { LockFactoryContract } from '../../contracts/LockFactoryContract'
import NetworksDropdown from '../../components/NetworksDropdown'
import { DocumentLinks, fetchTokenDetails, showConnectionError, showError, showSuccess, preventNegativeValues, parseTo18 } from '../../utils/index'
import TokenDetails from '../../components/TokenDetails'

import axios from 'axios'
import { timeAfterMinutes } from '../../utils/timeAfterMinutes'
import { DatetimePicker } from '../../components/DatePicker'
import CreateTokenModal from '../../components/Modals/CreateTokenModal'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const CreateLock = ({ saveUserHandler }) => {
    const _dispatch = useDispatch()
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    const signer = useSelector((state) => state.auth.signer)
    const user = useSelector((state) => state.auth.user)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const lockFactoryContract = useSelector((state) => state.auth.lockFactoryContract)

    const [lockInfo, setLockInfo] = useState('')

    const [tokenQuantity, setTokenQuantity] = useState('')
    const [tokenAddress, setTokenAddress] = useState('')
    const [unlockTime, setUnlockTime] = useState(new Date())
    const [beneficiaryAddress, setBeneficiaryAddress] = useState('')
    const [useAnotherOwner, setUseAnotherOwner] = useState(false)
    const [loading, setLoading] = useState(false)
    const [vesting, setVesting] = useState(false)
    const [tokenAddressError, setTokenAddressError] = useState(false)
    const [quantityError, setQuantityError] = useState(false)
    const [unlockTimeError, setUnlockTimeError] = useState(false)
    const [beneficiaryAddressError, setBeneficiaryAddressError] = useState(false)

    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [website, setWebsite] = useState('')
    const [twitter, setTwitter] = useState('')
    const [telegram, setTelegram] = useState('')
    const [discord, setDiscord] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState(false)
    const [logoUrlError, setLogoUrlError] = useState('')
    const [websiteError, setWebsiteError] = useState('')
    const [twitterError, setTwitterError] = useState('')
    const [telegramError, setTelegramError] = useState('')
    const [discordError, setDiscordError] = useState('')
    const [tokenDetails, setTokenDetails] = useState(null)
    const [isValidationCompleted, setIsValidationCompleted] = useState(false)

    const [isBegin, setIsBegin] = useState(true)
    const [onFinish, setOnFinish] = useState(false)
    const [activeStep, setActiveStep] = useState(1)
    const [stepOne, setStepOne] = useState(true)
    const [stepTwo, setStepTwo] = useState(false)

    const [openCreateTokenModal, setOpenCreateTokenModal] = useState(false)

    useEffect(() => {
        if (user != null) {
            if (connectedNetwork) {
                goToStep(1)
                getTokenDetails(tokenAddress)
                setTokenAddressError(false)
            } else {
                setTokenDetails(null)
            }
        } else {
            goToStep(1)
            setTokenDetails(null)
        }
    }, [user, connectedNetwork])

    useEffect(() => {
        if (!useAnotherOwner) {
            setBeneficiaryAddress(user?.address != null ? user?.address : '')
        }
    }, [user])

    const getTokenDetails = async (address) => {
        if (connectedNetwork && address) {
            setLoading(true)
            const _tokenDetails = await fetchTokenDetails(address, signer, user.address)
            setTokenDetails(_tokenDetails)
            setLoading(false)
        }
    }

    const getTokensApproval = async () => {
        try {
            const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
            const tokenApproval = await erc20.approve(LockFactoryContract.contractId(connectedNetwork.chainId), parseTo18(tokenQuantity, erc20))
            await tokenApproval.wait()
            return tokenApproval
        } catch (error) {
            showError('Please approve tokens')
            return false
        }
    }

    const createLock = async () => {
        if (!lockFactoryContract) {
            showConnectionError()
        }
        setLoading(true)
        const isTokenApproved = await getTokensApproval()
        const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
        if (isTokenApproved) {
            let tuple = {
                tokenAddress: tokenAddress,
                beneficiary: beneficiaryAddress,
                unlockTime: moment(unlockTime).unix().toString(),
                quantity: ethers.utils.parseUnits(tokenQuantity.toString(), await erc20.decimals()),
            }
            try {
                const _createLock = await lockFactoryContract.createLock(tuple)
                const response = await _createLock.wait()
                if (response) {
                    let _lockInfo = {}
                    // _lockInfo.lockAddress = response.events[2].args[1]
                    _lockInfo.lockAddress = response?.events[2]?.args != undefined ? response?.events[2]?.args[1] : response.events[3].args[1]
                    _lockInfo.chainId = connectedNetwork.chainId
                    setLockInfo(_lockInfo)
                    const isToken = true //response.events[2].args[1]
                    axios
                        .post(
                            `${api}/lock/add`,
                            {
                                userAddress: beneficiaryAddress,
                                lockAddress: _lockInfo.lockAddress,
                                tokenAddress: tuple?.tokenAddress,
                                isToken: isToken,
                                title: title,
                                logoUrl: logoUrl,
                                website: website,
                                telegram: telegram,
                                discord: discord,
                                twitter: twitter,
                                description: description,
                                chainId: connectedNetwork.chainId,
                                tokenName: tokenDetails?.name,
                                tokenSymbol: tokenDetails?.symbol,
                                beneficiary: tuple?.beneficiary,
                                releaseTime: tuple?.unlockTime,
                                quantity: tokenQuantity?.toString()
                            },
                            {
                                headers: {
                                    authorization: `bearer ${user.token}`
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.status) {
                                user.locks.push(response.data.data)
                                saveUserHandler(user)
                                resetCreateLock()
                                showSuccess('Lock created successfully')
                                _dispatch(openMainPage(false))
                            }
                            setLoading(false)
                        })
                        .catch(function (error) {
                            setLoading(false)
                            showError(error.message)
                        })
                } else {
                    setLoading(false)
                    showError('Lock creation failed')
                }
            } catch (error) {
                console.log(error)
                setLoading(false)
                showError(error.reason)
            }
        } else {
            setLoading(false)
        }
    }

    const validation = () => {
        let _isValid = true

        if (!connectedNetwork) {
            _isValid = false
            setTokenAddressError('Metamask is not connected')
        } else if (!tokenDetails) {
            _isValid = false
            setTokenAddressError(`Token not found on ${connectedNetwork.title}`)
        } else if (!token_regex.test(tokenAddress)) {
            _isValid = false
            setTokenAddressError('Invalid token address')
        } else if (connectedNetwork && tokenAddress?.length === 0) {
            _isValid = false
            setTokenAddressError('Token address is required')
        } else {
            setTokenAddressError(false)
        }
        // if (connectedNetwork && tokenAddress?.length === 0) {
        //     setTokenAddressError(true)
        // } else if (tokenAddress.trim() != '' && token_regex.test(tokenAddress) && tokenDetails) {
        //     setTokenAddressError(false)
        // } else {
        //     _isValid = false
        //     setTokenAddressError(true)
        // }
        if (tokenQuantity == '' || tokenQuantity == 0) {
            _isValid = false
            setQuantityError('Amount is a required field')
            // } else if ((tokenQuantity.trim().indexOf('.') == -1) == false && tokenQuantity.trim() !== '') {
            //     _isValid = false
            //     setQuantityError('Invalid token quantity')
        } else {
            setQuantityError(false)
        }
        if (unlockTime == null || unlockTime === '') {
            _isValid = false
            setUnlockTimeError('Unlock time is required')
        } else if (moment(unlockTime, 'DD/MM/YYYY HH:mm').diff(moment(timeAfterMinutes(5), 'DD/MM/YYYY HH:mm')) < 0) {
            _isValid = false
            setUnlockTimeError(`Unlock time must be greater than ${timeAfterMinutes(5, true)}. For safe side we need atleast 5 minutes for the transaction`)
        } else {
            setUnlockTimeError(false)
        }
        if (beneficiaryAddress.trim() != '' && token_regex.test(beneficiaryAddress) == false) {
            _isValid = false
            setBeneficiaryAddressError(true)
        } else {
            setBeneficiaryAddressError(false)
        }
        return _isValid
    }

    function validateImageURl(url) {
        setIsValidationCompleted(false)
        if (url) {
            if (!img_url_regex.test(url.trim())) {
                setLogoUrlError('Only supported image extensions are allowed')
            } else if (url?.length > 255) {
                setLogoUrlError('Maximum 255 characters allowed')
            } else {
                let img = new Image()
                img.src = url
                img.onload = function () {
                    setLogoUrl(url)
                    setIsValidationCompleted(true)
                    setLogoUrlError(false)
                }
                img.onerror = function () {
                    setLogoUrlError('Only supported image extensions are allowed')
                    setIsValidationCompleted(true)
                }
            }
        } else {
        }
    }

    const socialValiation = () => {
        let _isValid = true
        if (!title) {
            _isValid = false
            setTitleError('Title is required')
        } else if (title?.length < 3 || startsWithNumber(title)) {
            _isValid = false
            setTitleError('Invalid title. Must start with a letter, at least three characters long and no special characters')
        } else {
            setTitleError(false)
        }
        if (website) {
            if (!url_regex.test(website)) {
                _isValid = false
                setWebsiteError('Invalid website url')
            } else if (website?.length > 255) {
                _isValid = false
                setWebsiteError('Maximum 255 characters allowed')
            } else {
                setWebsiteError(false)
            }
        } else {
            setWebsiteError(false)
        }
        if (twitter) {
            if (!url_regex.test(twitter)) {
                _isValid = false
                setTwitterError('Invalid twitter url')
            } else if (twitter?.length > 255) {
                _isValid = false
                setTwitterError('Maximum 255 characters allowed')
            } else {
                setTwitterError(false)
            }
        } else {
            setTwitterError(false)
        }
        if (telegram) {
            if (!url_regex.test(telegram)) {
                _isValid = false
                setTelegramError('Invalid telegram url')
            } else if (telegram?.length > 255) {
                _isValid = false
                setTelegramError('Maximum 255 characters allowed')
            } else {
                setTelegramError(false)
            }
        } else {
            setTelegramError(false)
        }
        if (discord) {
            if (!url_regex.test(discord)) {
                _isValid = false
                setDiscordError('Invalid discord url')
            } else if (discord?.length > 255) {
                _isValid = false
                setDiscordError('Maximum 255 characters allowed')
            } else {
                setDiscordError(false)
            }
        } else {
            setDiscordError(false)
        }

        if (description?.length > 0 && description?.length < 128) {
            _isValid = false
            setDescriptionError('Description must be at least 128 characters long')
        } else if (description?.length >= 1000) {
            _isValid = false
            setDescriptionError('Maximum 1000 characters allowed')
        } else {
            setDescriptionError(false)
        }

        return _isValid
    }

    const goToStep = (index) => {
        if (index === 1) {
            stepOne === false ? setStepOne(true) : ''
        } else {
            setStepOne(false)
        }
        if (index === 2) {
            stepTwo === false ? setStepTwo(true) : ''
        } else {
            setStepTwo(false)
        }
    }

    const scrollToStepFirst = () => {
        setTimeout(scrollFirst, 100)
    }

    const scrollToStepSecond = () => {
        setTimeout(scrollSecond, 100)
    }

    const scrollFirst = () => {
        document.getElementById('firstStep')?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
    }

    const scrollSecond = () => {
        document.getElementById('secondStep')?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
    }

    const completeFirstStep = () => {
        if (validation()) {
            setTokenAddressError(false)
            setStepOne(false)
            setStepTwo(true)
            scrollToStepSecond()
            goToStep(2)
            setActiveStep(2)
        }
    }

    const resetCreateLock = () => {
        setActiveStep(1)
        setStepOne(true)
        setStepTwo(false)
        setTokenAddress('')
        getTokenDetails(null)
        setTokenDetails(null)
        setUseAnotherOwner(false)
        if (!useAnotherOwner) {
            setBeneficiaryAddress(user?.address != null ? user?.address : '')
        }
        setTokenQuantity('')
        setUnlockTime(new Date())
        setTitle('')
        setLogoUrl('')
        setWebsite('')
        setTwitter('')
        setTelegram('')
        setDiscord('')
        setDescription('')
    }

    return (
        <>
            {isMianPageOpen ? (
                <Wrapper>
                    <CustomRow>
                        <Col>
                            <Flexed direction="row" justify="flex-end">
                                <a target="_blank" href={DocumentLinks('createLock')}>
                                    <InfoIconAlign isDarkTheme={isDarkTheme} />
                                </a>
                            </Flexed>
                        </Col>
                        <Container>
                            <StapperRow>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List onClick={() => (isBegin ? goToStep(1) : null)}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 0 || activeStep > 4}>
                                                    1
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 0 || activeStep > 4}>
                                                    Token & Network
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Enter the token address</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 2 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List
                                        onClick={() => {
                                            isBegin ? completeFirstStep() : null
                                        }}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4}>
                                                    2
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4}>
                                                    Social Info
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Let the crypto family know who you are</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                            </StapperRow>

                            <Row>
                                <Col>
                                    <Stepper>
                                        {stepOne && (
                                            <Item id="firstStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <Col>
                                                                <Flexed direction="row" align="center" justify="space-between">
                                                                    <Label isDarkTheme={isDarkTheme}>
                                                                        Token or LP Token Address <span>*</span>
                                                                    </Label>
                                                                    <CreateTokenLabel isDarkTheme={isDarkTheme} onClick={() => (!connectedNetwork ? showConnectionError() : setOpenCreateTokenModal(true))}>
                                                                        Create token
                                                                    </CreateTokenLabel>
                                                                </Flexed>
                                                                <InputText
                                                                    value={tokenAddress}
                                                                    onChange={(e) => {
                                                                        setTokenAddress(e.target.value.toLowerCase())
                                                                        getTokenDetails(e.target.value.toLowerCase())
                                                                        setTokenAddressError(false)
                                                                    }}
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    placeholder="Enter token or LP token address"
                                                                />
                                                                {tokenAddressError && <Alblur>{tokenAddressError}</Alblur>}
                                                                <Info isDarkTheme={isDarkTheme}>Lock Creation Fee: 3.72 BNB</Info>
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <TokenDetails tokenDetails={tokenDetails} />
                                                            <CustomCol>
                                                                <CheckBox
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="checkbox"
                                                                    checked={useAnotherOwner}
                                                                    onChange={(e) => {
                                                                        setUseAnotherOwner(e.target.checked)
                                                                        if (!e.target.checked) {
                                                                            setBeneficiaryAddress(user?.address)
                                                                            setBeneficiaryAddressError(false)
                                                                        }
                                                                    }}
                                                                />
                                                                <CheckLabel isDarkTheme={isDarkTheme} isMarginTop={tokenDetails !== null ? true : false}>
                                                                    Use another owner?
                                                                </CheckLabel>
                                                                <Spacer height={0.6} />
                                                            </CustomCol>

                                                            {useAnotherOwner && (
                                                                <Col>
                                                                    <Label isDarkTheme={isDarkTheme}>Owner</Label>
                                                                    <InputText
                                                                        value={beneficiaryAddress}
                                                                        onChange={(e) => {
                                                                            setBeneficiaryAddress(e.target.value.toLowerCase())
                                                                            setBeneficiaryAddressError(false)
                                                                        }}
                                                                        isDarkTheme={isDarkTheme}
                                                                        type="text"
                                                                        placeholder="Enter owner address"
                                                                    />
                                                                    {beneficiaryAddressError ? <Alblur>Invalid address</Alblur> : ''}
                                                                    <Info isDarkTheme={isDarkTheme}>The address you input here will be receive the tokens once they are unlocked</Info>
                                                                    <Spacer height={1} />
                                                                </Col>
                                                            )}
                                                            <Col xs={12} sm={!vesting ? 6 : 12} md={!vesting ? 6 : 12} lg={!vesting ? 6 : 12}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Total Tokens {tokenDetails?.symbol ? '(' + tokenDetails?.symbol + ')' : ''}
                                                                    <span>*</span>
                                                                </Label>
                                                                <AmountContent>
                                                                    <InputText
                                                                        value={tokenQuantity}
                                                                        onChange={(e) => {
                                                                            setTokenQuantity(e.target.value)
                                                                            setQuantityError(false)
                                                                        }}
                                                                        min="0"
                                                                        pattern="\d+"
                                                                        onKeyDown={preventNegativeValues}
                                                                        isDarkTheme={isDarkTheme}
                                                                        type="number"
                                                                        placeholder="Enter total tokens to be locked"
                                                                    />
                                                                    {/* <MaxButton isDarkTheme={isDarkTheme}>max</MaxButton> */}
                                                                </AmountContent>
                                                                {quantityError && <Alblur>{quantityError}</Alblur>}
                                                            </Col>

                                                            {!vesting && (
                                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                                    <Label isDarkTheme={isDarkTheme}>
                                                                        Lock Until (UTC time) <span>*</span>
                                                                    </Label>
                                                                    <DatetimePicker isDarkTheme={isDarkTheme} state={unlockTime} setState={setUnlockTime} setError={setUnlockTimeError} />
                                                                    {unlockTimeError && <Alblur>{unlockTimeError}</Alblur>}
                                                                    <Spacer height={1} />
                                                                </Col>
                                                            )}
                                                            <NetworksDropdown />
                                                            <ActionContent>
                                                                <Flexed direction="row" justify="end">
                                                                    <Button isDarkTheme={isDarkTheme} label={'Next'} ifClicked={completeFirstStep} />
                                                                </Flexed>
                                                            </ActionContent>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}
                                        {stepTwo && (
                                            <Item id="secondStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <CustomCol xs={6} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Title <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    value={title}
                                                                    onChange={(e) => {
                                                                        setTitle(e.target.value)
                                                                        setTitleError(false)
                                                                    }}
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {titleError && <Alblur>{titleError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol xs={6} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Logo URL</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={logoUrl}
                                                                    onChange={(e) => {
                                                                        setLogoUrl(e.target.value)
                                                                        setLogoUrlError(false)
                                                                        validateImageURl(e.target.value)
                                                                    }}
                                                                    placeholder="Supported image extensions: png, jpg, jpeg or gif"
                                                                />
                                                                {logoUrlError ? <Alblur>{logoUrlError}</Alblur> : <>{!isValidationCompleted && logoUrl?.length > 0 && logoUrl?.endsWith('png') && <Alblur>Processing logo url. Please wait...</Alblur>}</>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol xs={6} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Website
                                                                    {/* <span>*</span> */}
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={website}
                                                                    onChange={(e) => {
                                                                        setWebsite(e.target.value)
                                                                        setWebsiteError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {websiteError && <Alblur>{websiteError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Twitter</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={twitter}
                                                                    onChange={(e) => {
                                                                        setTwitter(e.target.value)
                                                                        setTwitterError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {twitterError && <Alblur>{twitterError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Telegram</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={telegram}
                                                                    onChange={(e) => {
                                                                        setTelegram(e.target.value)
                                                                        setTelegramError(false)
                                                                    }}
                                                                    placeholder=""
                                                                />
                                                                {telegramError && <Alblur>{telegramError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Discord </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={discord}
                                                                    onChange={(e) => {
                                                                        setDiscord(e.target.value)
                                                                        setDiscordError(false)
                                                                    }}
                                                                    placeholder=""
                                                                />
                                                                {discordError && <Alblur>{discordError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol>
                                                                <Label isDarkTheme={isDarkTheme}>Description</Label>
                                                                <TextArea
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={description}
                                                                    onChange={(e) => {
                                                                        setDescription(e.target.value)
                                                                        setDescriptionError(false)
                                                                    }}
                                                                    row="3"
                                                                    placeholder="Minimum of 128 characters"
                                                                />
                                                                {descriptionError && <Alblur>{descriptionError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <Col>
                                                                {(activeStep > 1 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Back'}
                                                                            margin={'0rem 1rem 0rem 0rem'}
                                                                            ifClicked={() => {
                                                                                scrollToStepFirst()
                                                                                setStepOne(true)
                                                                                setStepTwo(false)
                                                                                setActiveStep(1)
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            ifClicked={() => {
                                                                                if (socialValiation() && !logoUrlError) {
                                                                                    if (!connectedNetwork) {
                                                                                        showConnectionError()
                                                                                    } else createLock()
                                                                                }
                                                                            }}
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Create Lock'}
                                                                        />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}
                                    </Stepper>
                                </Col>
                            </Row>
                        </Container>
                        <Spacer height={3} />
                    </CustomRow>
                </Wrapper>
            ) : (
                <LockDetails _lockInfo={lockInfo} />
            )}
            {loading && (
                <LoadingPanelContent>
                    <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                </LoadingPanelContent>
            )}
            {openCreateTokenModal && (
                <CreateTokenModal
                    user={user}
                    open={openCreateTokenModal}
                    setTokenAddress={setTokenAddress}
                    setTokenDetails={setTokenDetails}
                    onCloseModal={() => {
                        setOpenCreateTokenModal(false)
                    }}
                />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    padding-top: 2rem;
    ${media.lg`
        width: 90%;
    `};
    ${media.xl`
        width: 90%;
    `};
    ${media.xs`
        padding-top: 0rem;
    `}
    ${media.sm`
        padding-top: 2rem;
    `}
`

const StapperRow = styled(Row)`
    ${media.xs`
        display:none;
    `}
`

const Form = styled(Row)`
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
        padding:  1.5rem 1rem;
    `};
    ${media.sm`
        padding: 1.5rem 1rem;
    `};
    ${media.md`
        padding: 2rem;
    `};
`

const CustomRow = styled(Row)`
    justify-content: center;
    ${media.sm`
        width: 100%;
    `};
`

const CustomCol = styled(Col)`
    padding: 0rem 1rem;
    box-sizing: border-box;
`

const CardCol = styled(Col)`
    &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 20%;
        left: 11rem;
        right: 0rem;
        z-index: 1;
        border-top: 1px solid ${({ active, isDarkTheme }) => (active ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_dark}` : `${palette.gray_light}`)};
        ${media.xs`
            left: 4rem;
            top: 34%;
        `}
        ${media.sm`
            left: 6rem;
            top: 26%;
        `}
        @media screen and (min-width: 0px) and (max-width: 669px) {
            display: none !important;
        }
        ${media.md`
            top: 20%;
            left: 11rem;
        `}
        ${media.lg`
            left: 11rem;
        `}
        ${media.xl`
            left: 11rem;
        `}
    }
`

const List = styled.div`
    display: flex;
    margin: 1rem 0rem;
    cursor: pointer;
`
const Card = styled.div`
    position: relative;
    border-radius: 0.2rem;
    border: none;
    font-size: 1rem;
    width: 12rem;
    height: 3rem;
    ${media.xs`
        width: 16rem;
        height: 4rem;
    `}
    ${media.sm`
        width: 15rem;
        height: 6rem;
    `}
    ${media.md`
        width: 14rem;
        height: 8rem;
    `}
    ${media.lg`
        width: 13rem;
        height: 8rem;
    `}
    ${media.xl`
        width: 14rem;
        height: 8rem;
    `}
`

const Stepper = styled.ul`
    padding: 0;
`

const Item = styled.li`
    list-style: none;
    margin-bottom: 2rem;
`

const StepperBody = styled.div``

const StepperFooter = styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const Discription = styled.div`
    display: flex;
    flex-direction: column;
`

const CheckLabel = styled.label`
    font-size: 0.9rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
    margin-left: 0.5rem;
    margin-top: ${({ isMarginTop }) => (isMarginTop ? `0.8rem` : ``)};
`

const CheckBox = styled.input`
    border: 2px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    // box-shadow:0 0 0 1px ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    // appearance:none;
    width: 14px;
    height: 14px;
    accent-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    cursor: pointer;
    &:checked {
        accent-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    }
`

const AmountContent = styled.div`
    position: relative;
    overflow: hidden;
`

const ActionContent = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: end;
`

const InfoIconAlign = styled(InfoIcon)`
    right: 0.938rem;
    ${media.xs`right:0rem;`}
    ${media.sm`right:0.938rem;`}
`

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateLock)
