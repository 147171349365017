import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { palette } from '../assets/common'
import Button from './Button'
import { Flexed, Heading, HeadingColord } from '../styles/shared'
import { useSelector } from 'react-redux'

const IntroContent = ({ user }) => {
    let _navigate = useNavigate()
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    return (
        <Container>
            <Spacer height={2} />
            <CustomRow>
                <Col lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <MainContent justify="center">
                        {/* <HeadingColord margin={'0rem 0rem 1rem 0rem'} isDarkTheme={isDarkTheme}>
                            Economic
                            Sale
                        </HeadingColord> */}
                        <HeadingColord margin={'0rem 0rem 1rem 0rem'} isDarkTheme={isDarkTheme}>
                            DEFI LAUNCHPAD
                        </HeadingColord>
                        <Content lineHeight="2rem" letterSpacing="0.05em" isDarkTheme={isDarkTheme}>
                            The most economical defi launchpad ever to launch your tokens. Tokens successfully presale on economic sale will be listed on pancakeswap, uniswap & quickswap.
                        </Content>
                        <Spacer height={2} />
                        <List justify="flex-start" align="center">
                            <Button
                                isDarkTheme={isDarkTheme}
                                label={'Create Now'}
                                ifClicked={() => {
                                    _navigate('/create-presale')
                                }}
                            />
                        </List>
                    </MainContent>
                </Col>
                <Col lg={6}>
                    <Flex>{isDarkTheme ? <Image src="/images/defi.gif" /> : <Image src="/images/defi.svg" />}</Flex>
                </Col>
            </CustomRow>
        </Container>
    )
}
const CustomRow = styled(Row)`
    justify-content: center;
`
const Content = styled.h5`
    font-size: 0.9rem;
    font-weight: 400;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.heading}` : `${palette.pure_black}`)};
    // text-align: center;
    line-height: ${({ lineHeight }) => `${lineHeight}`};
    letter-spacing: ${({ letterSpacing }) => `${letterSpacing}`};
    & > span {
        color: ${palette.aqua_blue};
        font-family: inherit;
    }
`
const MainContent = styled(Flexed)`
    width: 100%;
    height: 100%;
    @media screen and (min-width: 0px) and (max-width: 992px) {
        text-align: center;
    }
`
const List = styled(Flexed)`
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (min-width: 0px) and (max-width: 992px) {
        flex-direction: column !important;
    }
    ${media.md`
        flex-direction:row;
    `}
`

const Spacer = styled.div`
    height: ${({ height }) => `${height}rem`};
`

const Flex = styled.div`
    display: flex;
    justify-content: center;
    ${media.xs`
        margin-top:2rem;
    `}
    ${media.sm`
        margin-top:2rem;
    `}
    ${media.md`
        margin-top:2rem;
    `}
    ${media.lg`
        margin-top:0rem;
    `}
`

const Image = styled.img`
    bottom: -3rem;
    height: undefined;
    width: 80%;
    ${media.sm`
        width: 50%;
    `}
    ${media.md`
        width: 50%;
    `}
    ${media.lg`
        width: 90%;
        position:relative;
    `}
`

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

export default connect(mapStateToProps, null)(IntroContent)
