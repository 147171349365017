import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { useDispatch, useSelector } from 'react-redux'
import { ethers } from 'ethers'
import { connect } from 'react-redux'
import { api, url } from '../config/index'
import { LockContract } from '../contracts/LockContract'
import moment from 'moment'
import CountdownTimer from './CountdownTimer'
import Button from './Button'
import { Flexed, NetworkContent, StatusContent, ProfileImageDiv, AddressText, Heading, DetailLabel, Content, SocialIcons, InfoIcon, TextPlaceHolder, Blocked, FlexedEnd, ShareIcon, ShareIconContainer, renderTooltip } from '../styles/shared'
import axios from 'axios'
import { useCountdown, getPassedTime } from '../hooks'
import { openMainPage } from '../actions/authActions'

import { updateLock, DocumentLinks, showConnectionError, showSuccess, showError, getNetworkById, getJsonRpcProvider } from '../utils'
import { img_url_regex, startsWithNumber, url_regex } from '../utils/regex'
import { Disqussion } from './Disqussion'
import { BsFillShareFill } from 'react-icons/bs'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Copy } from './CopyTooltip'
import { ERC20 } from '../contracts/ERC20'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const LockDetails = ({ _lockInfo }) => {
    let { chain, address } = Object.fromEntries(new URLSearchParams(location.search))
    address = address?.substring(0, 42)
    const [lockInfo, setLockInfo] = useState(_lockInfo == undefined ? { lockAddress: address, chainId: chain } : _lockInfo)

    const signer = useSelector((state) => state.auth.signer)
    const user = useSelector((state) => state.auth.user)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    const _dispatch = useDispatch()

    const [totalTokens, setTotalTokens] = useState(0)

    const [lockOwnerAddress, setLockOwnerAddress] = useState('')
    const [isDeveloper, setIsDeveloper] = useState(false)
    const [lockContract, setLockContract] = useState(null)
    const [tokenAddress, setTokenAddress] = useState(null)
    const [closingTime, setClosingTime] = useState(null)
    const [closeDate, setCloseDate] = useState(null)

    const [isLockInfoFetched, setIsLockInfoFetched] = useState(false)
    const [lockDbInfo, setLockDbInfo] = useState(null)
    const [updateSocials, setUpdateSocials] = useState(null)
    const [status, setStatus] = useState(false)
    const [isUnlocked, setIsUnlocked] = useState(false)

    const [isUpdating, setIsUpdating] = useState(false)
    const [isLocking, setIsLocking] = useState(false)
    const [lockNetwork, setLockNetwork] = useState('')
    let isLockClosed = useCountdown(closingTime, false)

    const [isValidationCompleted, setIsValidationCompleted] = useState(true)
    const [show, setShow] = useState(false)
    const target = useRef(null)

    useEffect(async () => {
        if (user == null) {
            const _lockNetwork = await getNetworkById(lockInfo?.chainId)
            setLockNetwork(_lockNetwork)
            const provider = await getJsonRpcProvider(lockInfo?.chainId)
            const _lockContract = new ethers.Contract(lockInfo?.lockAddress, LockContract.abi, provider)
            setLockContract(_lockContract)
        }
    }, [])

    useEffect(async () => {
        if (user != null) {
            if (lockInfo?.chainId != connectedNetwork?.chainId) {
                _dispatch(openMainPage(true))
                return
            }
            setIsDeveloper(false)
            if (user.locks.length > 0) {
                for (let i = 0; i < user.locks.length; i++) {
                    if (lockInfo?.lockAddress == user.locks[i].lockAddress) {
                        setIsDeveloper(true)
                    }
                }
            }
            const _lockNetwork = await getNetworkById(lockInfo?.chainId)
            setLockNetwork(_lockNetwork)
            const _lockContract = new ethers.Contract(lockInfo?.lockAddress, LockContract.abi, signer)
            setLockContract(_lockContract)
        } else {
            setIsDeveloper(false)
        }
    }, [user])

    useEffect(async () => {
        if (lockContract) {
            await readLockInfo()
        }
    }, [lockContract, connectedNetwork])

    const getLockDbInfo = async () => {
        axios
            .post(`${api}/lock/find`, {
                lockAddress: lockInfo?.lockAddress
            })
            .then((response) => {
                if (response.data.status) {
                    setLockDbInfo(response?.data?.data)
                    setUpdateSocials({ logoUrl: { val: response?.data?.data?.logoUrl, edit: false }, website: { val: response?.data?.data?.website, edit: false }, title: { val: response?.data?.data?.title, edit: false }, twitter: { val: response?.data?.data?.twitter, edit: false }, discord: { val: response?.data?.data?.discord, edit: false }, telegram: { val: response?.data?.data?.telegram, edit: false }, description: { val: response?.data?.data?.description, edit: false } })
                }
            })
            .catch(function (error) {
                showError(error.message)
            })
    }

    const readLockInfo = async () => {
        await getLockDbInfo()
        const _token = await lockContract.token()
        setTokenAddress(_token)

        const erc20 = new ethers.Contract(_token, ERC20.abi, signer)
        const decimals = await erc20.decimals()

        let tokens = await lockContract.tokenBalance()
        tokens = ethers.utils.formatUnits(tokens, decimals)
        setTotalTokens(tokens)

        const _lockOwnerAddress = await lockContract.beneficiary()
        setLockOwnerAddress(_lockOwnerAddress)

        const releaseTime = await lockContract.releaseTime()
        setClosingTime(releaseTime * 1000)

        const _endDate = moment.unix(releaseTime).format('dddd, MMMM Do, YYYY h:mm a')
        setCloseDate(_endDate)

        const _isUnlocked = await lockContract.unlocked()
        setIsUnlocked(_isUnlocked)

        setIsLockInfoFetched(true)
    }

    const validateImageURl = (url) => {
        setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: false })
        setIsValidationCompleted(false)
        if (url) {
            if (!img_url_regex.test(url.trim())) {
                setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: 'Only supported image extensions are allowed' })
                setIsValidationCompleted(true)
            } else if (url?.length > 255) {
                setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: 'Maximum 255 characters allowed' })
                setIsValidationCompleted(true)
            } else {
                let img = new Image()
                img.src = url
                img.onload = function () {
                    setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: false })
                    setIsValidationCompleted(true)
                }
                img.onerror = function () {
                    setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: 'Only supported image extensions are allowed' })
                    setIsValidationCompleted(true)
                }
            }
        } else {
        }
    }

    const socialValiation = () => {
        let _isValid = true
        let titleErr = false
        let websiteErr = false
        let twitterErr = false
        let telegramErr = false
        let discordErr = false
        let descriptionErr = false

        if (!updateSocials?.title?.val) {
            _isValid = false
            titleErr = 'Title is required'
        } else if (updateSocials?.title?.val?.length < 3 || startsWithNumber(updateSocials?.title?.val)) {
            _isValid = false
            titleErr = 'Invalid title. Must start with a letter, at least three characters long and no special characters'
        } else {
            titleErr = false
        }

        if (updateSocials?.website?.val) {
            if (!url_regex.test(updateSocials?.website?.val)) {
                _isValid = false
                websiteErr = 'Invalid website url'
            } else if (updateSocials?.website?.val?.length > 255) {
                _isValid = false
                websiteErr = 'Maximum 255 characters allowed'
            } else {
                websiteErr = false
            }
        } else {
            websiteErr = false
        }

        if (updateSocials?.twitter?.val) {
            if (!url_regex.test(updateSocials?.twitter?.val)) {
                _isValid = false
                twitterErr = 'Invalid twitter url'
            } else if (updateSocials?.twitter?.val?.length > 255) {
                _isValid = false
                twitterErr = 'Maximum 255 characters allowed'
            } else {
                twitterErr = false
            }
        } else {
            twitterErr = false
        }

        if (updateSocials?.telegram?.val) {
            if (!url_regex.test(updateSocials?.telegram?.val)) {
                _isValid = false
                telegramErr = 'Invalid telegram url'
            } else if (updateSocials?.telegram?.val?.length > 255) {
                _isValid = false
                telegramErr = 'Maximum 255 characters allowed'
            } else {
                telegramErr = false
            }
        } else {
            telegramErr = false
        }

        if (updateSocials?.discord?.val) {
            if (!url_regex.test(updateSocials?.discord?.val)) {
                _isValid = false
                discordErr = 'Invalid discord url'
            } else if (updateSocials?.discord?.val?.length > 255) {
                _isValid = false
                discordErr = 'Maximum 255 characters allowed'
            } else {
                discordErr = false
            }
        } else {
            discordErr = false
        }

        if (updateSocials?.description?.val?.length > 0 && updateSocials?.description?.val?.length < 128) {
            _isValid = false
            descriptionErr = 'Description must be at least 128 characters long'
        } else {
            descriptionErr = false
        }
        setUpdateSocials({ ...updateSocials, titleErr: titleErr, websiteErr: websiteErr, twitterErr: twitterErr, telegramErr: telegramErr, discordErr: discordErr, descriptionErr: descriptionErr })
        return _isValid
    }

    const unlockTokens = async () => {
        if (!connectedNetwork || !lockContract) {
            showConnectionError()
            return
        }
        setIsLocking(true)
        try {
            let participateTx = await lockContract.release()
            participateTx = await participateTx.wait()
            if (participateTx) {
                showSuccess('Tokens unlocked successfully')
                await readLockInfo()
            }
            setIsUnlocked(true)
            setIsLocking(false)
        } catch (error) {
            console.log('UnlockTokens Error: ', error)
            setIsLocking(false)
            console.log(error)
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error?.data?.message)
            }
        }
    }

    const doUpdateLock = async () => {
        if (connectedNetwork) {
            if (socialValiation() && !updateSocials?.logoUrlErr && isValidationCompleted) {
                setIsUpdating(true)
                const _updateData = {
                    lockAddress: lockDbInfo.lockAddress,
                    title: updateSocials?.title?.val,
                    logoUrl: updateSocials?.logoUrl?.val,
                    website: updateSocials?.website?.val,
                    twitter: updateSocials?.twitter?.val,
                    telegram: updateSocials?.telegram?.val,
                    discord: updateSocials?.discord?.val,
                    description: updateSocials?.description?.val
                }
                const _dbResponse = await updateLock(_updateData, user.token).then((res) => {
                    return res
                })
                if (_dbResponse.data.status) {
                    showSuccess('Lock social info updated successfully')
                } else {
                    showError('Error in lock updation. Please try again later.')
                }
                setIsUpdating(false)
            }
        } else {
            showConnectionError()
        }
    }

    const shareUrl = `${url}/lock-details?chain=${lockDbInfo?.chainId}&address=${lockInfo?.lockAddress}/`

    return (
        <>
            <Wrapper>
                {lockContract && (
                    <>
                        <MainRow>
                            <Col lg={12} xl={7} xxl={8}>
                                <Form isDarkTheme={isDarkTheme}>
                                    <Row>
                                        <ShareIconContainer>
                                            <OverlayTrigger placement="top" overlay={renderTooltip(show ? 'Copied' : 'Copy')}>
                                                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                                                    <div
                                                        {...props}
                                                        style={{
                                                            ...props.style
                                                        }}>
                                                        <ShareIcon
                                                            isDarkTheme={isDarkTheme}
                                                            ref={target}
                                                            xsPosition="relative"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(shareUrl)
                                                                setShow(!show)
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </OverlayTrigger>
                                        </ShareIconContainer>
                                        <a target="_blank" href={DocumentLinks('lockDetails')}>
                                            <InfoIcon isDarkTheme={isDarkTheme} right={'1rem'} top={'1rem'} xsPosition="relative" />
                                        </a>
                                        <ProfileContent>
                                            <Flexed direction="row" align="center">
                                                <div>
                                                    <ProfileImageDiv isDarkTheme={isDarkTheme} src={updateSocials != null ? updateSocials?.logoUrl?.val : ''} />
                                                </div>
                                                <Heading isDarkTheme={isDarkTheme}>{updateSocials?.title?.val}</Heading>
                                            </Flexed>
                                        </ProfileContent>
                                        <Column isDarkTheme={isDarkTheme} hideBottomLine={true}>
                                            {updateSocials !== null ? (
                                                <DescriptionContent>
                                                    <Link href={updateSocials != null ? updateSocials?.website?.val : ''} target={updateSocials !== null && updateSocials?.website?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Web.png' : '/svg/WebW.svg'} alt="Web" />
                                                    </Link>
                                                    <Link href={updateSocials != null ? updateSocials?.discord?.val : ''} target={updateSocials !== null && updateSocials?.discord?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Discord.png' : '/svg/DiscordW.svg'} alt="Discord" />
                                                    </Link>
                                                    <Link href={updateSocials != null ? updateSocials?.telegram?.val : ''} target={updateSocials !== null && updateSocials?.telegram?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Telegram.png' : '/svg/TelegramW.svg'} alt="Telegram" />
                                                    </Link>
                                                    <Link href={updateSocials != null ? updateSocials?.twitter?.val : ''} target={updateSocials !== null && updateSocials?.twitter?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Twitter.png' : '/svg/TwitterW.svg'} alt="Twitter" />
                                                    </Link>
                                                    {/* {isDeveloper && !isPreSaleStarted && <Edit isDarkTheme={isDarkTheme} onClick={() => setUpdateSocials({...updateSocials, website: {...updateSocials?.website, edit: true}, discord: {...updateSocials?.discord, edit: true}, telegram: {...updateSocials?.telegram, edit: true}, twitter: {...updateSocials?.twitter, edit: true}})} />} */}
                                                </DescriptionContent>
                                            ) : (
                                                <TextPlaceHolder />
                                            )}
                                        </Column>
                                        {updateSocials?.description?.val?.length > 0 && (
                                            <Column isDarkTheme={isDarkTheme}>
                                                <LeftContent isDarkTheme={isDarkTheme}>{updateSocials?.description?.val}</LeftContent>
                                            </Column>
                                        )}
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Lock Address</DetailLabel>
                                            <Content isDarkTheme={isDarkTheme}>
                                                {lockNetwork ? (
                                                    <Flexed direction="row" align="center">
                                                        <AddressText isDarkTheme={isDarkTheme} href={`${lockNetwork.networkUrl}/${lockInfo?.lockAddress}`} target="_blank" rel="noopener noreferrer">
                                                            {lockInfo?.lockAddress?.toString()}
                                                        </AddressText>
                                                        <Copy text={`${lockNetwork.networkUrl}/${lockInfo?.lockAddress}`} isDarkTheme={isDarkTheme} />
                                                    </Flexed>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Content>
                                        </AddressColumn>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Token Address:</DetailLabel>
                                            <Content isDarkTheme={isDarkTheme}>
                                                {tokenAddress ? (
                                                    <Flexed direction="row" align="center">
                                                        <AddressText isDarkTheme={isDarkTheme} href={`${lockNetwork.tokenUrl}/${tokenAddress}`} target="_blank" rel="noopener noreferrer">
                                                            {tokenAddress?.toString()}
                                                        </AddressText>
                                                        <Copy text={`${lockNetwork.networkUrl}/${tokenAddress}`} isDarkTheme={isDarkTheme} />
                                                    </Flexed>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Content>
                                        </AddressColumn>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Total Tokens</DetailLabel>
                                            {totalTokens && lockDbInfo ? <Content isDarkTheme={isDarkTheme}>{totalTokens?.toString() + ' ' + lockDbInfo?.tokenSymbol}</Content> : <TextPlaceHolder />}
                                        </Column>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Unlock Time</DetailLabel>
                                            {closeDate ? <Content isDarkTheme={isDarkTheme}>{closeDate?.toString()}</Content> : <TextPlaceHolder />}
                                        </AddressColumn>
                                    </Row>
                                    {isLockInfoFetched && isDeveloper && isLockClosed && !isUnlocked && (
                                        <List isDarkTheme={isDarkTheme} justifyContent="center">
                                            <SecondButtonContainer sm={6} md={4} lg={4} xl={4} xxl={3}>
                                                <Button
                                                    isDarkTheme={isDarkTheme}
                                                    label={'Unlock Tokens'}
                                                    disabled={isLocking}
                                                    ifClicked={(e) => {
                                                        unlockTokens()
                                                    }}
                                                />
                                            </SecondButtonContainer>
                                        </List>
                                    )}
                                </Form>
                                {isDeveloper && updateSocials !== null && !isLockClosed && (
                                    <Form isDarkTheme={isDarkTheme}>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel width={8} isDarkTheme={isDarkTheme}>
                                                Logo URL
                                            </DetailLabel>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    type="text"
                                                    value={updateSocials?.logoUrl?.val}
                                                    onChange={(e) => {
                                                        validateImageURl(e.target.value)
                                                    }}
                                                />
                                                {!isValidationCompleted && !updateSocials?.logoUrlErr && updateSocials?.logoUrl?.val?.length > 0 && <Alblur>Processing logo url. Please wait...</Alblur>}
                                                {updateSocials?.logoUrlErr && <Alblur isDarkTheme={isDarkTheme}>{updateSocials?.logoUrlErr}</Alblur>}
                                            </Blocked>
                                        </AddressColumn>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel width={8} isDarkTheme={isDarkTheme}>
                                                Lock Title
                                            </DetailLabel>
                                            <FlexedEnd>
                                                <Blocked>
                                                    <InputText
                                                        isDarkTheme={isDarkTheme}
                                                        type="text"
                                                        value={updateSocials?.title?.val}
                                                        onChange={(e) => {
                                                            setUpdateSocials({ ...updateSocials, title: { ...updateSocials?.title, val: e.target.value }, titleErr: false })
                                                        }}
                                                    />
                                                    {updateSocials?.titleErr && <Alblur>{updateSocials?.titleErr}</Alblur>}
                                                </Blocked>
                                            </FlexedEnd>
                                        </AddressColumn>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Description</DetailLabel>
                                            <FlexedEnd>
                                                <Blocked>
                                                    <EditDescription
                                                        width={97}
                                                        rows={3}
                                                        isDarkTheme={isDarkTheme}
                                                        value={updateSocials?.description?.val}
                                                        placeholder="Minimum of 128 characters"
                                                        onChange={(e) => {
                                                            setUpdateSocials({ ...updateSocials, description: { ...updateSocials.description, val: e.target.value }, descriptionErr: false })
                                                        }}
                                                    />
                                                    {updateSocials?.descriptionErr && <Alblur>{updateSocials?.descriptionErr}</Alblur>}
                                                </Blocked>
                                            </FlexedEnd>
                                        </AddressColumn>
                                        <Column2 isDarkTheme={isDarkTheme} gap={'0.3rem'}>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    width="100"
                                                    type="text"
                                                    value={updateSocials.website.val}
                                                    placeholder="https://yoursite.com"
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, website: { ...updateSocials.website, val: e.target.value }, websiteErr: false })
                                                    }}
                                                />
                                                {updateSocials?.websiteErr && <Alblur>{updateSocials?.websiteErr}</Alblur>}
                                            </Blocked>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    width="100"
                                                    type="text"
                                                    value={updateSocials.discord.val}
                                                    placeholder="https://discord.com"
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, discord: { ...updateSocials.discord, val: e.target.value }, discordErr: false })
                                                    }}
                                                />
                                                {updateSocials?.discordErr && <Alblur>{updateSocials?.discordErr}</Alblur>}
                                            </Blocked>
                                        </Column2>
                                        <Column isDarkTheme={isDarkTheme} gap={'0.3rem'}>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    width="100"
                                                    type="text"
                                                    value={updateSocials.twitter.val}
                                                    placeholder="https://twitter.com"
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, twitter: { ...updateSocials.twitter, val: e.target.value }, twitterErr: false })
                                                    }}
                                                />
                                                {updateSocials?.twitterErr && <Alblur>{updateSocials?.twitterErr}</Alblur>}
                                            </Blocked>

                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    width="100"
                                                    type="text"
                                                    value={updateSocials.telegram.val}
                                                    placeholder="https://telegram.com"
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, telegram: { ...updateSocials.telegram, val: e.target.value }, telegramErr: false })
                                                    }}
                                                />
                                                {updateSocials?.telegramErr && <Alblur>{updateSocials?.telegramErr}</Alblur>}
                                            </Blocked>
                                        </Column>
                                        {isLockInfoFetched && isDeveloper && !isLockClosed && (
                                            <List isDarkTheme={isDarkTheme} justifyContent="center">
                                                <SecondButtonContainer sm={6} md={4} lg={4} xl={4} xxl={3}>
                                                    <Button
                                                        isDarkTheme={isDarkTheme}
                                                        label={'Update Lock Info'}
                                                        disabled={isUpdating}
                                                        ifClicked={(e) => {
                                                            doUpdateLock()
                                                        }}
                                                    />
                                                </SecondButtonContainer>
                                            </List>
                                        )}
                                    </Form>
                                )}
                                <Disqussion isDarkTheme={isDarkTheme} title={'Lock discussion'} shareUrl={shareUrl} />
                            </Col>
                            <Col lg={12} xl={5} xxl={4}>
                                {isLockInfoFetched && !isLockClosed && (
                                    <Form isDarkTheme={isDarkTheme}>
                                        <Row justifyContent="center">
                                            <ColAlign isDarkTheme={isDarkTheme}>Remaining time for unlocking tokens</ColAlign>
                                            <Col>
                                                <CountdownTimer targetDate={closingTime} />
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                                <Form isDarkTheme={isDarkTheme}>
                                    <Row>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Network</DetailLabel>
                                            <NetworkContent isDarkTheme={isDarkTheme} chainId={lockNetwork?.chainId}>
                                                {lockNetwork?.title}
                                            </NetworkContent>
                                        </Column>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Lock Status</DetailLabel>
                                            {isLockInfoFetched ? (
                                                <StatusContent isDarkTheme={isDarkTheme} status={!isUnlocked ? 'upcoming' : ''}>
                                                    {isUnlocked ? 'Unlocked' : 'Locked'}
                                                </StatusContent>
                                            ) : (
                                                <TextPlaceHolder />
                                            )}
                                        </Column>
                                    </Row>
                                </Form>
                            </Col>
                        </MainRow>
                        <Spacer />
                    </>
                )}
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Container)`
    // padding: 0rem 3rem 0rem 3rem;
`

const MainRow = styled(Row)`
    ${media.lg`margin-left:-5px`};
    ${media.xl`margin-left:-5px`};
    ${media.xxl`margin-left:-15px`};
`

const Form = styled(Container)`
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 1rem;
    position: relative;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`

const ProfileContent = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    ${media.xs`
      display: block;
    `};
    ${media.sm`
      display: flex;
    `};
`

const Column = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    // cursor: pointer;
    border-bottom: ${({ isDarkTheme, hideBottomLine }) => (hideBottomLine ? `` : ` 0.063rem solid ${palette.off_dark}`)};
    gap: ${({ gap }) => (gap ? `${gap}` : '')};
`

const AddressColumn = styled(Column)`
    ${media.xs`
      display: block;
    `};
    ${media.sm`
      display: flex;
    `};
`
const List = styled(Row)`
    border-bottom: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_dark}` : `${palette.gray}`)};
`

const SecondButtonContainer = styled(Col)`
    margin-top: 0.5rem;
    justify-content: center;
    margin-bottom: 0rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
`

const DescriptionContent = styled.div`
    display: flex;
    align-items: center;
    & a {
        margin-left: 0.5rem;
    }
`

const Flexeding = styled(Column)`
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-bottom: 0rem;
`

const Spacer = styled.div`
    height: 0.75rem;
`
const InputContent = styled.div`
    position: relative;
    margin: 0.5rem 0rem;
`
const InputText = styled.input`
    outline: none;
    height: 2.3rem;
    width: ${({ width }) => (width ? `${width}%` : '100%')};
    font-size: 0.9rem;
    padding: 0.4rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const ColAlign = styled(Col)`
    text-align: center;
    margin-bottom: 1rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
`

const Link = styled.a`
    cursor: pointer;
`

const EditDescription = styled.textarea`
    outline: none;
    width: ${({ width }) => (width ? `${width}%` : '100%')};
    font-size: 0.9rem;
    margin: 0 0 0 1rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    padding: 0.2rem;
    border-radius: 0.3rem;
    box-sizing: border-box;
    & input {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    }
    & > div > button > svg {
        stroke: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)} !important;
    }
    & div {
        border: 0rem !important;
    }
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const Column2 = styled(Column)`
    border-bottom: none;
    padding-bottom: 0;
`
const Alblur = styled.p`
    width: fit-content;
    margin-left: 1rem;
    font-size: 0.8rem;
    color: red;
`

const LeftContent = styled(Content)`
    text-align: left;
`

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LockDetails)
