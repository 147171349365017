import IntroContent from '../components/InroContent'
import Banner from '../components/Banner'
import Networks from '../components/Networks'
import Services from './Services'
import { Container, Row, Col } from 'styled-bootstrap-grid'

function Home() {
    return (
        <Container>
            <Row justifyContent="center">
                <Col lg={11}>
                    <IntroContent />
                    <Networks />
                    {/* <Banner /> */}
                    <Services />
                </Col>
            </Row>
        </Container>
    )
}

export default Home
