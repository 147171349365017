import React from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../assets/common'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {Flexed, Spacer} from '../styles/shared'

const Banner = () => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const openSideMenu = useSelector((state) => state.auth.openSideMenu)
    const {pathname} = useLocation()

    return (
        <Container>
            <Spacer height="4" />
            <Row>
                <Box xs={6} sm={6} md={3} lg={3}>
                    <Flexed align="center">
                        <Text fontSize={1.2} isDarkTheme={isDarkTheme}>
                            1k <span>+</span>
                        </Text>
                        <Spacer height="0.5" />
                        <Text isDarkTheme={isDarkTheme} color={'dark_gray'}>
                            Total Projects
                        </Text>
                    </Flexed>
                </Box>
                <BoxTwo xs={6} sm={6} md={3} lg={3}>
                    <Flexed align="center">
                        <Text fontSize={1.2} isDarkTheme={isDarkTheme}>
                            $0
                        </Text>
                        <Spacer height="0.5" />
                        <Text isDarkTheme={isDarkTheme} color={'dark_gray'}>
                            Total Liquidity Raised
                        </Text>
                    </Flexed>
                </BoxTwo>
                <Box xs={6} sm={6} md={3} lg={3}>
                    <Flexed align="center">
                        <Text fontSize={1.2} isDarkTheme={isDarkTheme}>
                            235 <span>+</span>
                        </Text>
                        <Spacer height="0.5" />
                        <Text isDarkTheme={isDarkTheme} color={'dark_gray'}>
                            Total Participants
                        </Text>
                    </Flexed>
                </Box>
                <Box xs={6} sm={6} md={3} lg={3}>
                    <Flexed align="center">
                        <Text fontSize={1.2} isDarkTheme={isDarkTheme}>
                            $396 <span>+</span>
                        </Text>
                        <Spacer height="0.5" />
                        <Text isDarkTheme={isDarkTheme} color={'dark_gray'}>
                            Total Participants
                        </Text>
                    </Flexed>
                </Box>
            </Row>
        </Container>
    )
}

const Wrapper = styled(Container)`
    position: fixed;
    z-index: 3;
    bottom: 0;
    margin-top: auto;
    // border: 1px solid ${palette.dark_gray};
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 100%;
    box-shadow: 0px 3px 15px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(to right, rgba(0, 133, 255, 0.4), rgba(0, 223, 255, 0.4));
    ${media.xs`position:relative;`}
    ${media.sm`position:relative;`}
                ${media.md`width : 100%; position:fixed;`}
                ${media.lg`width : ${({openSideMenu}) => (openSideMenu ? 'calc(100% - 12rem)' : 'calc(100% - 3rem)')};`}
`

const CustomCol = styled(Col)`
    // display: flex;
    justify-content: center;
    @media screen and (min-width: 200px) and (max-width: 450px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    } ;
`

const Text = styled.a`
                font-family: 'bicubik', sans-serif;
                letter-spacing: 0.1em;
                font-size: ${({fontSize}) => (fontSize ? `${fontSize}rem` : `0.8rem`)};
                display: inline-block;
                &:not(:last-child) {
                    margin - right: 1rem;
    }
                font-weight: bold;
                color: ${({isDarkTheme, color}) => (color ? `${palette.dark_gray}` : `${palette.off_white}`)};
                text-decoration: none;
                &:hover {
                    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.blue}`)};
        // opacity:0.8;
    }
    & > span {
                    font - weight: bold;
                line-height: 0;
                font-size: 1.5rem;
                color: #00deff;
    }
                `

const Box = styled(Col)`
    position: relative;
    margin-bottom: 3rem;
    &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: -0.5rem;
        margin: auto;
        height: 5rem;
        width: 0.25rem;
        right: 0;
        transform: rotate(24deg);
        border-radius: 1rem;
        z-index: 1;
        background-image: linear-gradient(#eaebec, #98d5ff 65%);
    }
`

const BoxTwo = styled(Box)`
    ${media.xs`
&::after{
    display:none !important;
}
`}
    ${media.sm`
&::after{
    display:none !important;
}
`}
                ${media.md`
&::after{
    display:block !important;
}
`}
`

export default Banner
