import {NetworksTypes} from '../utils'

export const FairPresaleFactoryContract = {
    contractId: (chainId) => {
        if (chainId == NetworksTypes.EthereumMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.EthereumTestNet.chainId) {
            return '0x457e275711be3b6A35936067912531a6966CB7Dd'
        } else if (chainId == NetworksTypes.BinanceMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.BinanceTestNet.chainId) {
            return '0xad50D45d81C696164150efa6c3334568D17bc257'
        } else if (chainId == NetworksTypes.PolygonMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.PolygonTestNet.chainId) {
            return '0x0CA49f32E2f5f793a1E88e53146f5bae3005355d'
        }
    },
    abi: [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_InfoAddress',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'Id',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'presalecontractaddress',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'liquiditylockaddress',
                    type: 'address'
                }
            ],
            name: 'PresaleCreated',
            type: 'event'
        },
        {
            inputs: [],
            name: 'SSS',
            outputs: [
                {
                    internalType: 'contract InvestmentsInfo',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenAddress',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'decimals',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'tokenQuantity',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'softCapInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'openTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'closeTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'bool',
                            name: 'fixedPresale',
                            type: 'bool'
                        }
                    ],
                    internalType: 'struct FairlaunchFactory.PresaleInfo',
                    name: '_info',
                    type: 'tuple'
                },
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'lpTokensLockDurationInDays',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidityPercentageAllocation',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct FairlaunchFactory.PresaleUniswapInfo',
                    name: '_uniInfo',
                    type: 'tuple'
                }
            ],
            name: 'createPresale',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenAddress',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'decimals',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'tokenQuantity',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'softCapInWei',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'openTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'closeTime',
                            type: 'uint256'
                        },
                        {
                            internalType: 'bool',
                            name: 'fixedPresale',
                            type: 'bool'
                        }
                    ],
                    internalType: 'struct FairlaunchFactory.PresaleInfo',
                    name: '_info',
                    type: 'tuple'
                },
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'lpTokensLockDurationInDays',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidityPercentageAllocation',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct FairlaunchFactory.PresaleUniswapInfo',
                    name: '_uniInfo',
                    type: 'tuple'
                }
            ],
            name: 'createPresaleP',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
}
