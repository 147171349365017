import axios from 'axios'
import { api, docBaseUrl } from '../config/index'
import { toast } from 'react-toastify'
import { ethers } from 'ethers'
import { ERC20 } from '../contracts/ERC20'

export const updatePresale = async (data, token) => {
    return axios
        .post(`${api}/pre_sale/update`, data, {
            headers: {
                authorization: `bearer ${token}`
            }
        })
        .then((response) => {
            return response
        })
        .catch(function (error) {
            return false
        })
}

export const updateAirdrop = async (data, token) => {
    return axios
        .post(`${api}/air_drop/update`, data, {
            headers: {
                authorization: `bearer ${token}`
            }
        })
        .then((response) => {
            return response
        })
        .catch(function (error) {
            return false
        })
}

export const updateLock = async (data, token) => {
    return axios
        .post(`${api}/lock/update`, data, {
            headers: {
                authorization: `bearer ${token}`
            }
        })
        .then((response) => {
            return response
        })
        .catch(function (error) {
            return false
        })
}

export const fetchTokenDetails = async (address, signer, userAddress) => {
    try {
        const erc20 = new ethers.Contract(address, ERC20.abi, signer)
        let tokenDetails = {}
        tokenDetails.name = await erc20.name()
        tokenDetails.symbol = await erc20.symbol()
        tokenDetails.decimals = await erc20.decimals()

        // let totalSupply = await erc20.totalSupply()
        // totalSupply = ethers.utils.formatEther(totalSupply.toString())
        // tokenDetails.totalSupply = totalSupply

        // let balance = await erc20.balanceOf(userAddress)
        // balance = ethers.utils.formatEther(balance.toString())
        // tokenDetails.balance = balance
        return tokenDetails
    } catch (error) {
        return null
    }
}

export const getUserTokenBalance = async (tokenAddress, signer, userAddress) => {
    try {
        const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
        const decimals = await erc20.decimals()
        let balance = await erc20.balanceOf(userAddress)
        balance = ethers.utils.formatUnits(balance.toString(), decimals)
        return balance
    } catch (error) {
        return 0
    }
}

export const getJsonRpcProvider = async (chainId) => {
    let rpcProvider = ''
    if (chainId == '1') {
        rpcProvider = NetworksTypes.EthereumMainNet.rpcProvider
    } else if (chainId == '5') {
        rpcProvider = NetworksTypes.EthereumTestNet.rpcProvider
    } else if (chainId == '56') {
        rpcProvider = NetworksTypes.BinanceMainNet.rpcProvider
    } else if (chainId == '97') {
        rpcProvider = NetworksTypes.BinanceTestNet.rpcProvider
    } else if (chainId == '137') {
        rpcProvider = NetworksTypes.PolygonMainNet.rpcProvider
    } else if (chainId == '80001') {
        rpcProvider = NetworksTypes.PolygonTestNet.rpcProvider
    }
    const jsonRpcProvider = new ethers.providers.JsonRpcProvider(rpcProvider)
    return jsonRpcProvider
}

export const formatCurrency = (connectedNetwork) => {
    return connectedNetwork?.symbol ? '(' + connectedNetwork.symbol + ')' : ''
}

export const getNetworkById = async (chainId) => {
    if (chainId == '1') {
        return NetworksTypes.EthereumMainNet
    } else if (chainId == '5') {
        return NetworksTypes.EthereumTestNet
    } else if (chainId == '56') {
        return NetworksTypes.BinanceMainNet
    } else if (chainId == '97') {
        return NetworksTypes.BinanceTestNet
    } else if (chainId == '137') {
        return NetworksTypes.PolygonMainNet
    } else if (chainId == '80001') {
        return NetworksTypes.PolygonTestNet
    } else {
        return false
    }
}

export const NetworksTypes = Object.freeze({
    EthereumMainNet: {
        title: 'Ethereum Mainnet',
        chainId: '1',
        icon: '/images/ethereum.png',
        mainnet: true,
        symbol: 'ETH',
        networkUrl: '',
        tokenUrl: '',
        swapUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
        exchange: 'UniSwap',
        rpcProvider: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
    },
    EthereumTestNet: {
        // title: 'Ethereum Mainnet',
        title: 'Ethereum Testnet Goerli',
        chainId: '5',
        icon: '/images/ethereum.png',
        mainnet: false,
        symbol: 'ETH',
        networkUrl: 'https://goerli.etherscan.io/address',
        tokenUrl: 'https://goerli.etherscan.io/token',
        swapUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
        exchange: 'UniSwap',
        rpcProvider: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
    },
    BinanceMainNet: {
        title: 'Binance Smart Chain Mainnet',
        chainId: '56',
        icon: '/images/binance.png',
        mainnet: true,
        symbol: 'BNB',
        networkUrl: 'https://bscscan.com/address',
        tokenUrl: 'https://bscscan.com/token',
        swapUrl: 'https://pancakeswap.finance/swap?outputCurrency=',
        exchange: 'PancakeSwap',
        rpcProvider: 'https://bsc-dataseed.binance.org'
    },
    BinanceTestNet: {
        // title: 'Binance Smart Chain Mainnet',
        title: 'Binance Smart Chain Testnet',
        chainId: '97',
        icon: '/images/binance.png',
        mainnet: false,
        symbol: 'BNB',
        networkUrl: 'https://testnet.bscscan.com/address',
        tokenUrl: 'https://testnet.bscscan.com/token',
        swapUrl: 'https://pancake.kiemtienonline360.com/#/swap?outputCurrency=',
        exchange: 'PancakeSwap',
        rpcProvider: 'https://data-seed-prebsc-1-s1.binance.org:8545'
    },
    PolygonMainNet: {
        title: 'Polygon Mainnet',
        chainId: '137',
        icon: '/images/polygon.png',
        mainnet: true,
        symbol: 'Matic',
        networkUrl: '',
        tokenUrl: '',
        swapUrl: 'https://quickswap.exchange/#/swap?outputCurrency=',
        exchange: 'QuickSwap',
        rpcProvider: 'https://polygon-rpc.com'
    },
    PolygonTestNet: {
        // title: 'Polygon Mainnet',
        title: 'Polygon Testnet Mumbai',
        chainId: '80001',
        icon: '/images/polygon.png',
        mainnet: false,
        symbol: 'Matic',
        networkUrl: 'https://mumbai.polygonscan.com/address',
        tokenUrl: 'https://mumbai.polygonscan.com/token',
        swapUrl: 'https://quickswap.exchange/#/swap?outputCurrency=',
        exchange: 'QuickSwap',
        rpcProvider: 'https://rpc-mumbai.maticvigil.com'
    }
})

export const PresaleTypes = Object.freeze({
    Normal: {
        title: 'Presale',
        isPrivate: false,
        isFair: false
    },
    Fair: {
        title: 'Fair Presale',
        isPrivate: false,
        isFair: true
    },
    Private: {
        title: 'Private Presale',
        isPrivate: true,
        isFair: false
    }
})

export const DocumentLinks = (keyword) => {
    if (keyword == 'projectDetails') {
        return `${docBaseUrl}/`
    }
    // header kyc link
    else if (keyword == 'kyc') {
        return `${docBaseUrl}/know-your-customer`
    }
    //creare presale documentation
    else if (keyword == 'createPreSale') {
        return `${docBaseUrl}/presales/create-a-presale`
    } else if (keyword == 'preSaleDetails') {
        //update-owner
        return `${docBaseUrl}/presales/update-a-presale`
    } else if (keyword == 'investorPreSaleDetails') {
        //update-investor
        return `${docBaseUrl}/investing/how-to-buy-a-presale`
    }
    //todo...
    else if (keyword == 'claim') {
        //claim-investor
        return `${docBaseUrl}/investing/how-to-claim-tokens`
    } else if (keyword == 'refund') {
        //refund-investor
        return `${docBaseUrl}/investing/how-to-refund`
    }

    //create fair documentation
    else if (keyword == 'createFairSale') {
        return `${docBaseUrl}/fair-presales/create-a-fair-presale`
    } else if (keyword == 'fairSaleDetails') {
        return `${docBaseUrl}/fair-presales/update-fair-presale`
    } else if (keyword == 'investorPreSaleDetails') {
        //update-investor
        return `${docBaseUrl}/investing/how-to-buy-a-presale`
    }
    //todo...
    else if (keyword == 'claim') {
        //claim-investor
        return `${docBaseUrl}/investing/how-to-claim-tokens`
    } else if (keyword == 'refund') {
        //refund-investor
        return `${docBaseUrl}/investing/how-to-refund`
    }

    //private sale documentation
    else if (keyword == 'createPrivateSale') {
        return `${docBaseUrl}/private-presales/create-a-private-sale`
    } else if (keyword == 'privateSaleDetails') {
        return `${docBaseUrl}/private-presales/update-private-presale`
    } else if (keyword == 'investorPreSaleDetails') {
        //update-investor
        return `${docBaseUrl}/investing/how-to-buy-a-presale`
    }
    //todo...
    else if (keyword == 'claim') {
        //claim-investor
        return `${docBaseUrl}/investing/how-to-claim-tokens`
    } else if (keyword == 'refund') {
        //refund-investor
        return `${docBaseUrl}/investing/how-to-refund`
    }
    //lock documentation
    else if (keyword == 'createLock') {
        return `${docBaseUrl}/lock/create-a-lock`
    } else if (keyword == 'lockDetails') {
        return `${docBaseUrl}/view_all_locks_details`
    }
    //create airdrop documentation
    else if (keyword == 'createAirDrop') {
        return `${docBaseUrl}/airdrop/create-an-airdrop`
    } else if (keyword == 'updateAirDropDetails') {
        return `${docBaseUrl}/airdrop/update`
    } else if (keyword == 'airDropDetails') {
        return `${docBaseUrl}/airdrop/participate`
    }
    //todo.. release
    else if (keyword == 'airDropDetails') {
        return `${docBaseUrl}/airdrop/release-airdrop`
    }
    //create token documentation.. todo remove tokenDetails
    else if (keyword == 'createToken') {
        return `${docBaseUrl}/token/create-a-standard-token`
    } else if (keyword == 'createLiquidityToken') {
        //... todo
        return `${docBaseUrl}/token/create-a-liquidity-generator-token`
    } else {
        return `${docBaseUrl}`
    }
}

export const showSuccess = (message) => {
    toast.success(message)
}

export const showError = (error) => {
    // error = error.replace('execution reverted: ', '')
    // error = error.replace('execution reverted', '')
    // error = error.replace('ERC20:', '')
    toast.error(error)
}

export const showConnectionError = () => {
    toast.error('Please connect to wallet')
}

export const preventNegativeValues = (e) => ['E', 'e', '+', '-'].includes(e.key) && e.preventDefault()

export const parseTo18 = async (value, erc20) => {
    value = value.toString()
    const decimals = erc20 ? await erc20.decimals() : 18
    return ethers.utils.parseUnits(value, decimals).toString()
}

