import React from 'react'
import styled, {keyframes} from 'styled-components'
import {palette} from '../assets/common'
import {AiOutlineLoading3Quarters, AiOutlineMenuUnfold} from 'react-icons/ai'

const Button = ({ifClicked, disabled, label, active, icon, type, hasShadow, hasBorder, large, small, margin, width, isDarkTheme, border}) => {
    return (
        <Wrapper type={type} width={width}>
            <Btn type={type} hasLabel={label} active={active} isDarkTheme={isDarkTheme} disabled={disabled} onClick={ifClicked} large={large} small={small} hasBorder={hasBorder} margin={margin} width={width} border={border} hasShadow={hasShadow}>
                {icon && <Icon hasLabel={label} className={`${icon}`} large={large} />}
                {disabled && <Processing />}
                {label}
            </Btn>
        </Wrapper>
    )
}

export const handleBackgroundType = (type, index) => {
    switch (type) {
        case 'success':
            return palette.green_gradient
        case 'primary':
            return palette.blue_gradient
        case 'secondary':
            return palette.pink_gradient
        case 'orange':
            return palette.orange_gradient
        case 'gray':
            return palette.gray_gradient
        case 'red':
            return palette.red_gradient
        case 'yellow':
            return palette.yellow_gradient
        default:
            return palette.blue_gradient
    }
}

const handleBackgroundShadowType = (type, index) => {
    switch (type) {
        case 'success':
            return palette.green_gradient_shadow
        case 'primary':
            return palette.blue_gradient_shadow
        case 'secondary':
            return palette.pink_gradient_shadow
        case 'orange':
            return palette.orange_gradient_shadow
        case 'gray':
            return palette.gray_gradient_shadow
        case 'red':
            return palette.red_gradient_shadow
        case 'yellow':
            return palette.yellow_gradient_shadow
        default:
            return palette.blue_gradient_shadow
    }
}

export const handleBorderType = (type, index) => {
    switch (type) {
        case 'success':
            return palette.green
        case 'primary':
            return palette.aqua_blue
        case 'secondary':
            return palette.pink
        case 'orange':
            return palette.orange
        case 'gray':
            return palette.white
        case 'red':
            return palette.red
        case 'yellow':
            return palette.yellow
        default:
            return palette.aqua_blue
    }
}

const Wrapper = styled.div`
    position: relative;
    transition: all 0.5s ease-in-out;
    width: ${({width}) => (width ? width : 'fit-content')};
    &::before {
        position: absolute;
        transform: matrix(1, 0.05, 0.01, 1, 0, 0);
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0;
        background: ${({type}) => handleBackgroundShadowType(type)};
        filter: blur(7.60982px);
        border-radius: 2rem;
    }
    &:hover {
        &::before {
            background: none;
            filter: blur(7.60982px);
            border-radius: 2rem;
        }
    }
`

const Btn = styled.button`
    position: relative;
    display: inline-block;
    border-radius: 2rem;
    border: 1px solid ${({type}) => handleBorderType(type)};
    padding: ${({large, small, hasLabel}) => (hasLabel ? (large ? `0 1.23rem` : small ? '0 1.23rem' : '0 1.23rem') : '0 1.23rem')};
    height: ${({large, hasLabel}) => (hasLabel ? (large ? `40px;` : '40px;') : 'auto')};
    color: ${({type, isDarkTheme, active}) => (active ? `${palette.off_black}` : isDarkTheme ? `${palette.white}` : `${palette.white}`)};
    background: ${({type, isDarkTheme, active}) => (active ? handleBackgroundType(type) : isDarkTheme ? `${palette.black2}` : handleBackgroundType(type))};
    margin: ${({margin}) => `${margin}`};
    letter-spacing: 0.15em;
    font-weight: 700;
    // opacity: ${({type}) => (type == 'disable' ? '50%' : '')};
    cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
    // min-width: ${({large, hasLabel}) => (hasLabel ? (large ? `12.93rem` : '12.08rem') : 'auto')};
    min-width: 8.08rem;
    width: ${({width}) => width};
    white-space: nowrap;
    font-size: ${({large}) => (large ? `0.95rem` : '0.75rem')};
    font-family: 'bicubik';
    align-items: center;
    display: flex;
    justify-content: center;
    // z-index: 2;
    transition: all 0.5s ease-in-out;
    &:hover {
        color: ${({active, isDarkTheme}) => (active ? `${palette.white}` : isDarkTheme ? `${palette.off_black}` : `${palette.white}`)};
        background: ${({active, isDarkTheme, type}) => (active ? `${palette.black2}` : isDarkTheme ? handleBackgroundType(type) : `${palette.black2}`)};
    }
`
const Icon = styled.span`
    margin-right: ${({hasLabel}) => (hasLabel ? ` 0.5rem` : 'none')};
    font-size: ${({large, hasLabel}) => (hasLabel ? (large ? `1rem` : '1rem') : '1rem')};
`

const spinnerAnimation = keyframes`
	to { -webkit-transform: rotate(360deg); }
`

const Processing = styled(AiOutlineLoading3Quarters)`
    animation: ${spinnerAnimation} 1.5s linear infinite;
    margin-right: 0.5rem;
`

Btn.defaultProps = {
    type: 'primary',
    ifClicked: () => null
}

export default Button
