import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../assets/common'
import {Label, Info, Alblur, Para, CreateTokenLabel} from '../../styles/shared'
import {ethers} from 'ethers'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {connect} from 'react-redux'
import {saveUser, openMainPage} from '../../actions/authActions'
import axios from 'axios'
import {ERC20} from '../../contracts/ERC20'
import Button from '../../components/Button'
import {Spacer, InputText, TextArea, Flexed, LoadingPanelContent, LoadingPanel, StepperHeading, InfoIcon, Step} from '../../styles/shared'
import {FairPresaleFactoryContract} from '../../contracts/FairPresaleFactoryContract'
import {DocumentLinks, formatCurrency, fetchTokenDetails, showError, showConnectionError, showSuccess, preventNegativeValues, getUserTokenBalance, parseTo18} from '../../utils/index'
import {api} from '../../config/index'
import FairSaleDetails from './FairSaleDetails'
import NetworksDropdown from '../../components/NetworksDropdown'
import {token_regex, url_regex, img_url_regex, startsWithNumber} from '../../utils/regex'
import TokenDetails from '../../components/TokenDetails'
import {timeAfterMinutes} from '../../utils/timeAfterMinutes'
import SuccessModal from '../../components/SuccesModal'
import {DatetimePicker} from '../../components/DatePicker'
import CreateFeeOptions from '../../components/CreateFeeOptions'
import CreateTokenModal from '../../components/Modals/CreateTokenModal'
import {maxTokensAllowed} from '../../constants'

const CreateFairSale = ({user, saveUserHandler}) => {
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    let signer = useSelector((state) => state.auth.signer)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)
    const fairPresaleFactoryContract = useSelector((state) => state.auth.fairPresaleFactoryContract)
    const [loading, setLoading] = useState(false)
    const [openSuccessModel, setOpenSuccessModel] = useState(false)
    const [successFull, setSuccessFull] = useState(false)

    const [launchpadInfo, setLaunchpadInfo] = useState()

    const [tokenAddress, setTokenAddress] = useState('')
    const [tokenAddressError, setTokenAddressError] = useState(false)
    const [activeStep, setActiveStep] = useState(1)
    const [stepOne, setStepOne] = useState(true)
    const [stepTwo, setStepTwo] = useState(false)
    const [stepThree, setStepThree] = useState(false)
    const [tokenQuantity, setTokenQuantity] = useState('')
    const [tokenQuantityError, settokenQuantityError] = useState(false)
    const [softCap, setSoftCap] = useState('')
    const [softCapError, setSoftCapError] = useState(false)
    const [liquidity, setLiquidity] = useState('')
    const [liquidityError, setLiquidityError] = useState(false)
    const [startTime, setStartTime] = useState(new Date())
    const [endTime, setEndTime] = useState(new Date())
    const [lpTokensDurationInMinutes, setLpTokensDurationInMinutes] = useState('')
    const [lpTokensDurationInMinutesError, setLpTokensDurationInMinutesError] = useState(false)
    const [endTimeError, setEndTimeError] = useState(false)
    const [startTimeError, setStartTimeError] = useState(false)
    const [saleTitle, setSaleTitle] = useState('')
    const [telegramLink, setTelegramLink] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [logoUrlError, setLogoUrlError] = useState('')
    const [discord, setDiscord] = useState('')
    const [twitter, setTwitter] = useState('')
    const [website, setWebsite] = useState('')
    const [discription, setDiscription] = useState('')
    const [descriptionError, setDescriptionError] = useState(false)
    const [saleTitleError, setSaleTitleError] = useState(false)
    const [telegramLinkError, setTelegramLinkError] = useState(false)
    const [discordError, setDiscordError] = useState(false)
    const [twitterError, setTwitterError] = useState(false)
    const [websiteError, setWebsiteError] = useState(false)
    const [isValidationCompleted, setIsValidationCompleted] = useState(false)

    const [feeOption, setFeeOption] = useState('fixed')
    const [isBegin, setIsBegin] = useState(true)
    const [onFinish, setOnFinish] = useState(false)
    const [tokenDetails, setTokenDetails] = useState(null)

    const [openCreateTokenModal, setOpenCreateTokenModal] = useState(false)

    useEffect(() => {
        if (user != null) {
            if (connectedNetwork) {
                goToStep(1)
                getTokenDetails(tokenAddress)
                setTokenAddressError(false)
            } else {
                setTokenDetails(null)
            }
        } else {
            goToStep(1)
            setTokenDetails(null)
        }
    }, [user, connectedNetwork])

    const checkSoftCap = async () => {
        if (softCap.trim() === '') {
            setSoftCapError('Softcap must be greater than 0')
        } else if (parseFloat(softCap) == parseFloat(0)) {
            setSoftCapError('Softcap must be greater than 0')
        } else {
            setSoftCapError(false)
        }
    }

    const checkTokenQuantity = () => {
        if (tokenQuantity.trim() === '') {
            settokenQuantityError('Token quantity is required')
        } else if (parseFloat(tokenQuantity) <= 0) {
            settokenQuantityError('Token quantity amount must be greater than 0')
        } else {
            settokenQuantityError(false)
        }
    }

    const checkLiquidity = () => {
        if (liquidity.trim() == '') {
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else if ((liquidity.trim().indexOf('.') == -1) == false && liquidity.trim() !== '') {
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else if (parseFloat(liquidity) < parseFloat(1) || parseFloat(liquidity) > parseFloat(100)) {
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else {
            setLiquidityError(false)
        }
    }

    const checkLpTokensDurationInDays = () => {
        if (lpTokensDurationInMinutes.trim() == '') {
            setLpTokensDurationInMinutesError('Liquidity lockup time must be greater than 0 day')
        } else if (parseFloat(lpTokensDurationInMinutes) <= parseFloat(0)) {
            setLpTokensDurationInMinutesError('Liquidity lockup time must be greater than 0 day')
        } else if ((lpTokensDurationInMinutes.trim().indexOf('.') == -1) == false && lpTokensDurationInMinutes.trim() !== '') {
            setLpTokensDurationInMinutesError('Liquidity lockup time must be in day(s)')
        } else {
            setLpTokensDurationInMinutesError(false)
        }
    }

    const goToStep = (index) => {
        if (index === 1) {
            stepOne === false ? setStepOne(true) : ''
        } else {
            setStepOne(false)
        }
        if (index === 2) {
            stepTwo === false ? setStepTwo(true) : ''
        } else {
            setStepTwo(false)
        }
        if (index === 3) {
            stepThree === false ? setStepThree(true) : ''
        } else {
            setStepThree(false)
        }
    }

    const scrollToStepFirst = () => {
        setTimeout(scrollFirst, 100)
    }

    const scrollToStepSecond = () => {
        setTimeout(scrollSecond, 100)
    }

    const scrollToStepThird = () => {
        setTimeout(scrollThird, 100)
    }

    const scrollFirst = () => {
        document.getElementById('firstStep').scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'})
    }

    const scrollSecond = () => {
        document.getElementById('secondStep').scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'})
    }

    const scrollThird = () => {
        document.getElementById('thirdStep').scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'})
    }

    const stepTwoValiation = () => {
        let _isValid = true
        if (tokenQuantity.trim() === '') {
            _isValid = false
            settokenQuantityError('Token quantity is required')
        } else if (parseFloat(tokenQuantity) <= 0) {
            _isValid = false
            settokenQuantityError('Token quantity amount must be greater than 0')
        } else {
            settokenQuantityError(false)
        }
        if (softCap.trim() == '') {
            _isValid = false
            setSoftCapError('Softcap must be greater than 0')
        } else if (parseFloat(softCap) == parseFloat(0)) {
            _isValid = false
            setSoftCapError('Softcap must be greater than 0')
        } else {
            setSoftCapError(false)
        }
        if (liquidity.trim() == '') {
            _isValid = false
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else if ((liquidity.trim().indexOf('.') == -1) == false && liquidity.trim() !== '') {
            _isValid = false
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else if (parseFloat(liquidity) < parseFloat(1) || parseFloat(liquidity) > parseFloat(100)) {
            _isValid = false
            setLiquidityError('Liquidity must be between 1% and 100%')
        } else {
            setLiquidityError(false)
        }
        if (lpTokensDurationInMinutes.trim() == '') {
            _isValid = false
            setLpTokensDurationInMinutesError('Liquidity lockup time must be greater than 0 day')
        } else if (parseFloat(lpTokensDurationInMinutes) == parseFloat(0)) {
            _isValid = false
            setLpTokensDurationInMinutesError('Liquidity lockup time must be greater than 0 day')
        } else if ((lpTokensDurationInMinutes.trim().indexOf('.') == -1) == false && lpTokensDurationInMinutes.trim() !== '') {
            _isValid = false
            setLpTokensDurationInMinutesError('Liquidity lockup time must be in day(s)')
        } else {
            setLpTokensDurationInMinutesError(false)
        }
        if (startTime == '') {
            _isValid = false
            setStartTimeError('Start time must be less than end time')
        } else if (moment(startTime, 'DD/MM/YYYY HH:mm').diff(moment(timeAfterMinutes(5), 'DD/MM/YYYY HH:mm')) < 0) {
            _isValid = false
            setStartTimeError(`Start time must be greater than ${timeAfterMinutes(5, true)}. For safe side we need atleast 5 minutes for the transaction`)
        } else {
            setStartTimeError(false)
        }
        if (endTime == '') {
            _isValid = false
            setEndTimeError('End time is required')
        } else if (endTime <= startTime) {
            _isValid = false
            setEndTimeError('End time must be greater than start time')
        } else {
            setEndTimeError(false)
        }
        return _isValid
    }

    function validateImageURl(url) {
        if (url) {
            if (!img_url_regex.test(url.trim())) {
                setLogoUrlError('Only supported image extensions are allowed')
            } else if (url?.length > 255) {
                setLogoUrlError('Maximum 255 characters allowed')
            } else {
                let img = new Image()
                img.src = url
                img.onload = function () {
                    setLogoUrl(url)
                    setIsValidationCompleted(true)
                    setLogoUrlError(false)
                }
                img.onerror = function () {
                    setLogoUrlError('Only supported image extensions are allowed')
                    setIsValidationCompleted(true)
                }
            }
        } else {
            setLogoUrlError(false)
            setLogoUrl('')
        }
    }

    const socialValiation = () => {
        let _isValid = true
        if (!saleTitle) {
            _isValid = false
            setSaleTitleError('Sale title must start with a letter, minimum of three characters and special character not allowed')
        } else if (saleTitle?.length < 3 || startsWithNumber(saleTitle)) {
            _isValid = false
            setSaleTitleError('Sale title must start with a letter, minimum of three characters and special character not allowed')
        } else {
            setSaleTitleError(false)
        }

        if (website) {
            if (!url_regex.test(website)) {
                _isValid = false
                setWebsiteError('Invalid website url')
            } else if (website?.length > 255) {
                setWebsiteError('Maximum 255 characters allowed')
                _isValid = false
            } else {
                setWebsiteError(false)
            }
        } else {
            setWebsiteError(false)
        }

        if (twitter) {
            if (!url_regex.test(twitter)) {
                _isValid = false
                setTwitterError('Invalid twitter url')
            } else if (twitter?.length > 255) {
                _isValid = false
                setTwitterError('Maximum 255 characters allowed')
            } else {
                setTwitterError(false)
            }
        } else {
            setTwitterError(false)
        }

        if (telegramLink) {
            if (!url_regex.test(telegramLink)) {
                _isValid = false
                setTelegramLinkError('Invalid telegram url')
            } else if (telegramLink?.length > 255) {
                _isValid = false
                setTelegramLinkError('Maximum 255 characters allowed')
            } else {
                setTelegramLinkError(false)
            }
        } else {
            setTelegramLinkError(false)
        }

        if (discord) {
            if (!url_regex.test(discord)) {
                _isValid = false
                setDiscordError('Invalid discord url')
            } else if (discord?.length > 255) {
                setDiscordError('Maximum 255 characters allowed')
                _isValid = false
            } else {
                setDiscordError(false)
            }
        } else {
            setDiscordError(false)
        }

        if (discription?.length > 0 && discription?.length < 128) {
            _isValid = false
            setDescriptionError('Description must be at least 128 characters long')
        } else if (discription?.length >= 1000) {
            _isValid = false
            setDescriptionError('Maximum 1000 characters allowed')
        } else {
            setDescriptionError(false)
        }

        return _isValid
    }

    const getTokenDetails = async (_tokeAddress) => {
        if (connectedNetwork && _tokeAddress) {
            setLoading(true)
            const _tokenDetails = await fetchTokenDetails(_tokeAddress, signer, user.address)
            setTokenDetails(_tokenDetails)
            setLoading(false)
        }
    }

    const getTokensApproval = async () => {
        try {
            let maxTokenToBeSold = (tokenQuantity * liquidity * 95) / 10000
            let requiredTokenAmount = parseFloat(tokenQuantity) + parseFloat(maxTokenToBeSold)
            requiredTokenAmount = Math.ceil(requiredTokenAmount)
            const userTokenscount = await getUserTokenBalance(tokenAddress, signer, user.address)
            const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
            if (parseFloat(requiredTokenAmount) > maxTokensAllowed) {
                showError('Total token amount exceeds the max limit')
            } else if (parseFloat(userTokenscount) >= parseFloat(requiredTokenAmount)) {
                const _requiredTokenAmount = await parseTo18(requiredTokenAmount, erc20)
                const tokenApproval = await erc20.approve(FairPresaleFactoryContract.contractId(connectedNetwork.chainId), _requiredTokenAmount)
                await tokenApproval.wait()
                return tokenApproval
            } else {
                showError('You do not have enough tokens')
                return false
            }
        } catch (error) {
            console.log('GetTokensApproval Error: ', error)
            showError('Please approve token first')
            setOpenSuccessModel(false)
            setSuccessFull(false)
            return false
        }
    }

    const createMyTokenPreSale = async () => {
        if (!fairPresaleFactoryContract) {
            showConnectionError()
            return false
        }
        setLoading(true)
        setOpenSuccessModel(true)
        const isTokenApproved = await getTokensApproval()
        if (isTokenApproved) {
            const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
            const decimals = await erc20.decimals()
            let tokensTuple = {
                tokenAddress: tokenAddress,
                tokenQuantity: parseTo18(tokenQuantity.toString(), erc20),
                softCapInWei: parseTo18(softCap),
                openTime: moment(startTime).unix().toString(),
                closeTime: moment(endTime).unix().toString(),
                fixedPresale: feeOption == 'fixed' ? true : false,
                decimals: decimals
            }
            let infoTuple = {
                lpTokensLockDurationInDays: lpTokensDurationInMinutes,
                liquidityPercentageAllocation: liquidity
            }
            try {
                let createPresale = null
                if (feeOption == 'fixed') {
                    createPresale = await fairPresaleFactoryContract.createPresale(tokensTuple, infoTuple, {value: parseTo18('0.01')})
                } else {
                    createPresale = await fairPresaleFactoryContract.createPresaleP(tokensTuple, infoTuple)
                }
                const response = await createPresale.wait()
                console.log('response::', response)
                const _launchpadAddress = response?.events[2]?.args != undefined ? response?.events[2]?.args[1] : response.events[3].args[1]
                const lpTokensAddress = response?.events[2]?.args != undefined ? response?.events[2]?.args[2] : response.events[3].args[2]
                let _launchpadInfo = {}
                _launchpadInfo.launchpadAddress = _launchpadAddress
                _launchpadInfo.chainId = connectedNetwork.chainId
                _launchpadInfo.isFair = true
                _launchpadInfo.isPrivateSale = false
                setLaunchpadInfo(_launchpadInfo)
                if (_launchpadAddress) {
                    axios
                        .post(
                            `${api}/pre_sale/add`,
                            {
                                userAddress: user.address,
                                tokenAddress: tokensTuple.tokenAddress,
                                launchpadAddress: _launchpadAddress,
                                lpTokensAddress: lpTokensAddress,
                                isFairSale: true,
                                isPrivateSale: false,
                                saleTitle: saleTitle,
                                logoUrl: logoUrl,
                                website: website,
                                twitter: twitter,
                                telegram: telegramLink,
                                discord: discord,
                                description: discription,
                                chainId: connectedNetwork.chainId,
                                tokenName: tokenDetails?.name,
                                tokenSymbol: tokenDetails?.symbol,
                                kycStatus: 0,
                                whitelistedAddresses: [],
                                // tokenPriceInWei: tokenPrice.toString(),
                                // hardCapInWei: hardCap.toString(),
                                softCapInWei: softCap.toString(),
                                // maxCap: maximum.toString(),
                                // minCap: minimum.toString(),
                                startTime: tokensTuple?.openTime,
                                endTime: tokensTuple?.closeTime,
                                fixedPresale: tokensTuple?.fixedPresale,
                                // listingPriceInWei: listingPrice?.toString(),
                                // liquidityAddingTime: infoTuple?.liquidityAddingTime,
                                lpTokensLockDurationInDays: infoTuple?.lpTokensLockDurationInDays,
                                liquidityPercentageAllocation: infoTuple?.liquidityPercentageAllocation
                            },
                            {
                                headers: {
                                    authorization: `bearer ${user.token}`
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.status) {
                                user.presales.push(response.data.data)
                                saveUserHandler(user)
                                resetCreateFairSale()
                                showSuccess('Fair Presale created successfully')
                                setSuccessFull(true)
                            }
                            setLoading(false)
                        })
                        .catch(function (error) {
                            setLoading(false)
                            setOpenSuccessModel(false)
                            setSuccessFull(false)
                            showError(error.message)
                        })
                } else {
                    setLoading(false)
                    setOpenSuccessModel(false)
                    showError('Presale creation failed')
                }
            } catch (error) {
                console.log(error)
                setLoading(false)
                setOpenSuccessModel(false)
                setSuccessFull(false)
                showError(error.reason)
            }
        } else {
            setOpenSuccessModel(false)
            setLoading(false)
        }
    }

    const completeFirstStep = () => {
        if (!connectedNetwork) {
            setTokenAddressError('Metamask is not connected')
        } else if (!tokenDetails) {
            setTokenAddressError(`Token not found on ${connectedNetwork.title}`)
        } else if (!token_regex.test(tokenAddress)) {
            setTokenAddressError('Invalid token address')
        } else if (connectedNetwork && tokenAddress?.length === 0) {
            setTokenAddressError('Token address is required')
        } else {
            setTokenAddressError(false)
            setStepOne(false)
            setStepTwo(true)
            scrollToStepSecond()
            goToStep(2)
            setActiveStep(2)
        }
    }

    const completeSecondStep = () => {
        if (stepTwoValiation()) {
            setStepTwo(false)
            setStepThree(true)
            scrollToStepThird()
            goToStep(3)
            setActiveStep(3)
        }
    }

    const resetCreateFairSale = () => {
        setActiveStep(1)
        setStepOne(true)
        setStepTwo(false)
        setStepThree(false)
        setTokenAddress('')
        getTokenDetails(null)
        setTokenDetails(null)
        setTokenQuantity('')
        setSoftCap('')
        setStartTime(new Date())
        setEndTime(new Date())
        setLpTokensDurationInMinutes('')
        setLiquidity('')
        setSaleTitle('')
        setLogoUrl('')
        setWebsite('')
        setTwitter('')
        setTelegramLink('')
        setDiscord('')
        setDiscription('')
    }

    return (
        <>
            {isMianPageOpen ? (
                <Wrapper>
                    <CustomRow>
                        <Col>
                            <Flexed direction="row" justify="flex-end">
                                <a target="_blank" href={DocumentLinks('createFairSale')}>
                                    <InfoIconAlign isDarkTheme={isDarkTheme} />
                                </a>
                            </Flexed>
                        </Col>
                        <Container>
                            <StapperRow>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List onClick={() => (isBegin ? goToStep(1) : null)}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 0 || activeStep > 4}>
                                                    1
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 0 || activeStep > 4}>
                                                    Token & Network
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Enter the token address</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 2 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List
                                        onClick={() => {
                                            isBegin ? completeFirstStep() : null
                                        }}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4}>
                                                    2
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 1 || activeStep > 4}>
                                                    Presale Info
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Enter the fair presale information that you want to raise</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                                <CardCol isDarkTheme={isDarkTheme} active={activeStep > 3 || activeStep > 4} lg={3.5} md={4} sm={4} xs={4}>
                                    <List onClick={() => (isBegin ? completeSecondStep() : null)}>
                                        <Card>
                                            <Flex>
                                                <Step isDarkTheme={isDarkTheme} active={activeStep > 2 || activeStep > 4}>
                                                    3
                                                </Step>
                                                <StepperHeading isDarkTheme={isDarkTheme} active={activeStep > 2 || activeStep > 4}>
                                                    Social Info
                                                </StepperHeading>
                                            </Flex>
                                            <Discription>
                                                <Para isDarkTheme={isDarkTheme}>Let the crypto family know who you are</Para>
                                            </Discription>
                                        </Card>
                                    </List>
                                </CardCol>
                            </StapperRow>

                            <Row>
                                <Col>
                                    <Stepper>
                                        {stepOne && (
                                            <Item id="firstStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <Col>
                                                                <Flexed direction="row" align="center" justify="space-between">
                                                                    <Label isDarkTheme={isDarkTheme}>
                                                                        Token Address <span>*</span>
                                                                    </Label>
                                                                    <CreateTokenLabel isDarkTheme={isDarkTheme} onClick={() => (!connectedNetwork ? showConnectionError() : setOpenCreateTokenModal(true))}>
                                                                        Create token
                                                                    </CreateTokenLabel>
                                                                </Flexed>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={tokenAddress}
                                                                    onChange={(e) => {
                                                                        setTokenAddress(e.target.value.toLowerCase())
                                                                        getTokenDetails(e.target.value.toLowerCase())
                                                                        setTokenAddressError(false)
                                                                    }}
                                                                />
                                                                {tokenAddressError && <Alblur>{tokenAddressError}</Alblur>}
                                                                <Info isDarkTheme={isDarkTheme}>Fair Sale Creation Fee: 3.72 BNB</Info>
                                                            </Col>
                                                            <TokenDetails tokenDetails={tokenDetails} />
                                                            <NetworksDropdown />
                                                            <CreateFeeOptions feeOption={feeOption} setFeeOption={setFeeOption} />
                                                            <Col>
                                                                {(activeStep > 0 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button isDarkTheme={isDarkTheme} ifClicked={completeFirstStep} label={'Next'} />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}
                                        {stepTwo && (
                                            <Item id="secondStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <CustomCol lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Total Selling Tokens <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={tokenQuantity}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setTokenQuantity(e.target.value)
                                                                        settokenQuantityError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkTokenQuantity}
                                                                />
                                                                {tokenQuantityError && <Alblur>{tokenQuantityError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>

                                                            <CustomCol lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Softcap {formatCurrency(connectedNetwork)} <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={softCap}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setSoftCap(e.target.value)
                                                                        setSoftCapError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkSoftCap}
                                                                />
                                                                {softCapError && <Alblur>{softCapError}</Alblur>}
                                                                <Info isDarkTheme={isDarkTheme}>Can't be changed later</Info>
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Fair Sale Start Time <span>*</span>
                                                                </Label>
                                                                <DatetimePicker isDarkTheme={isDarkTheme} state={startTime} setState={setStartTime} setError={setStartTimeError} />
                                                                {startTimeError && <Alblur>{startTimeError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Fair Sale End Time <span>*</span>
                                                                </Label>
                                                                <DatetimePicker isDarkTheme={isDarkTheme} state={endTime} setState={setEndTime} setError={setEndTimeError} />
                                                                <br />
                                                                {endTimeError && <Alblur>{endTimeError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Liquidity Lockup Days <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    type="number"
                                                                    min={1}
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={lpTokensDurationInMinutes}
                                                                    onChange={(e) => {
                                                                        setLpTokensDurationInMinutes(e.target.value)
                                                                        setLpTokensDurationInMinutesError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkLpTokensDurationInDays}
                                                                />
                                                                {lpTokensDurationInMinutesError && <Alblur>{lpTokensDurationInMinutesError}</Alblur>}

                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <CustomCol lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Liquidity (%) <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={liquidity}
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    pattern="\d+"
                                                                    onChange={(e) => {
                                                                        setLiquidity(e.target.value)
                                                                        setLiquidityError(false)
                                                                    }}
                                                                    onKeyDown={preventNegativeValues}
                                                                    onBlur={checkLiquidity}
                                                                />
                                                                {liquidityError && <Alblur>{liquidityError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </CustomCol>
                                                            <Col>
                                                                {(activeStep > 1 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Back'}
                                                                            margin={'0rem 1rem 0rem 0rem'}
                                                                            ifClicked={() => {
                                                                                scrollToStepFirst()
                                                                                setStepOne(true)
                                                                                setStepTwo(false)
                                                                                setActiveStep(1)
                                                                            }}
                                                                        />
                                                                        <Button isDarkTheme={isDarkTheme} label={'Next'} ifClicked={completeSecondStep} />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}

                                        {stepThree && (
                                            <Item id="thirdStep">
                                                <StepperBody>
                                                    <Container>
                                                        <Form isDarkTheme={isDarkTheme}>
                                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>
                                                                    Fair Sale Title <span>*</span>
                                                                </Label>
                                                                <InputText
                                                                    value={saleTitle}
                                                                    onChange={(e) => {
                                                                        setSaleTitle(e.target.value)
                                                                        setSaleTitleError(false)
                                                                    }}
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {saleTitleError && <Alblur>{saleTitleError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Logo URL</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={logoUrl}
                                                                    onChange={(e) => {
                                                                        setLogoUrlError(false)
                                                                        setLogoUrl(e.target.value)
                                                                        validateImageURl(e.target.value)
                                                                    }}
                                                                    placeholder="Supported image extensions: png, jpg, jpeg or gif"
                                                                />
                                                                {!isValidationCompleted && logoUrl?.length > 0 && logoUrl?.endsWith('png') && <Alblur>Processing logo url</Alblur>}
                                                                {logoUrlError && <Alblur>{logoUrlError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Website</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={website}
                                                                    onChange={(e) => {
                                                                        setWebsite(e.target.value)
                                                                        setWebsiteError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {websiteError && <Alblur>{websiteError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Twitter</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={twitter}
                                                                    onChange={(e) => {
                                                                        setTwitter(e.target.value)
                                                                        setTwitterError(false)
                                                                    }}
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                {twitterError && <Alblur>{twitterError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Telegram</Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={telegramLink}
                                                                    onChange={(e) => {
                                                                        setTelegramLink(e.target.value)
                                                                        setTelegramLinkError(false)
                                                                    }}
                                                                    placeholder=""
                                                                />
                                                                {telegramLinkError && <Alblur>{telegramLinkError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Label isDarkTheme={isDarkTheme}>Discord </Label>
                                                                <InputText
                                                                    isDarkTheme={isDarkTheme}
                                                                    value={discord}
                                                                    onChange={(e) => {
                                                                        setDiscord(e.target.value)
                                                                    }}
                                                                    placeholder=""
                                                                />
                                                                {discordError && <Alblur>{discordError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col>
                                                                <Label isDarkTheme={isDarkTheme}>Description</Label>
                                                                <TextArea
                                                                    isDarkTheme={isDarkTheme}
                                                                    type="text"
                                                                    value={discription}
                                                                    onChange={(e) => {
                                                                        setDiscription(e.target.value)
                                                                        setDescriptionError(false)
                                                                    }}
                                                                    row="3"
                                                                    placeholder="Minimum of 128 characters"
                                                                />
                                                                {descriptionError && <Alblur>{descriptionError}</Alblur>}
                                                                <Spacer height={1} />
                                                            </Col>
                                                            <Col>
                                                                {(activeStep > 2 || onFinish) && (
                                                                    <StepperFooter>
                                                                        <Button
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Back'}
                                                                            margin={'0rem 1rem 0rem 0rem'}
                                                                            ifClicked={() => {
                                                                                scrollToStepSecond()
                                                                                setStepTwo(true)
                                                                                setStepThree(false)
                                                                                setActiveStep(2)
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            isDarkTheme={isDarkTheme}
                                                                            label={'Finish'}
                                                                            ifClicked={() => {
                                                                                if (socialValiation() && !logoUrlError) {
                                                                                    if (!connectedNetwork) {
                                                                                        showConnectionError()
                                                                                    } else createMyTokenPreSale()
                                                                                }
                                                                            }}
                                                                        />
                                                                    </StepperFooter>
                                                                )}
                                                            </Col>
                                                        </Form>
                                                    </Container>
                                                </StepperBody>
                                            </Item>
                                        )}
                                    </Stepper>
                                </Col>
                            </Row>
                        </Container>
                        <Spacer height={3} />
                    </CustomRow>
                </Wrapper>
            ) : (
                <FairSaleDetails _launchpadInfo={launchpadInfo} />
            )}
            {loading && (
                <LoadingPanelContent>
                    <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                </LoadingPanelContent>
            )}
            {openSuccessModel && (
                <SuccessModal
                    successFull={successFull}
                    openSuccessModel={openSuccessModel}
                    userLogo={logoUrl}
                    onClose={() => {
                        setOpenSuccessModel(false)
                        setSuccessFull(false)
                    }}
                />
            )}
            {openCreateTokenModal && (
                <CreateTokenModal
                    user={user}
                    open={openCreateTokenModal}
                    setTokenAddress={setTokenAddress}
                    setTokenDetails={setTokenDetails}
                    onCloseModal={() => {
                        setOpenCreateTokenModal(false)
                    }}
                />
            )}
        </>
    )
}

const Wrapper = styled(Container)`
    padding-top: 2rem;
    ${media.lg`
    width: 90%;
    `};
    ${media.xl`
    width: 90%;
    `};
    ${media.xs`
    padding-top: 0rem;
    `}
    ${media.sm`
    padding-top: 2rem;
    `}
`

const StapperRow = styled(Row)`
    ${media.xs`
    display:none;
    `}
`

const Form = styled(Row)`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`
const CustomRow = styled(Row)`
    justify-content: center;

    ${media.sm`
    width: 100%;
    `};
`

const CustomCol = styled(Col)`
    padding: 0rem 1rem;
`

const CardCol = styled(Col)`
    &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 20%;
        left: 11rem;
        right: 0rem;
        z-index: 1;
        border-top: 1px solid ${({active, isDarkTheme}) => (active ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_dark}` : `${palette.gray_light}`)};
        ${media.xs`
        left: 4rem;
        top: 34%;

      `}
        ${media.sm`
        left: 6rem;
        top: 26%;
      `}
      @media screen and (min-width: 0px) and (max-width: 669px) {
            display: none !important;
        }
        ${media.md`
        top: 20%;
        left: 11rem;
      `}
        ${media.lg`
        left: 11rem;
      `}
      ${media.xl`
        left: 11rem;
      `}
    }
`
const List = styled.div`
    display: flex;
    margin: 1rem 0rem;
    cursor: pointer;
`
const Card = styled.div`
    position: relative;
    border-radius: 0.2rem;
    border: none;
    font-size: 1rem;
    width: 12rem;
    height: 3rem;
    ${media.xs`
        width: 16rem;
        height: 4rem;
    `}
    ${media.sm`
        width: 15rem;
        height: 6rem;
    `}
    ${media.md`
        width: 14rem;
        height: 8rem;
    `}
    ${media.lg`
        width: 13rem;
        height: 8rem;
    `}
    ${media.xl`
        width: 14rem;
        height: 8rem;
    `}
`

const Stepper = styled.ul`
    padding: 0;
`

const Item = styled.li`
    list-style: none;
    margin-bottom: 2rem;
`

const StepperBody = styled.div``

const StepperFooter = styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`
const Discription = styled.div`
    display: flex;
    flex-direction: column;
`

const InfoIconAlign = styled(InfoIcon)`
    right: 0.938rem;
    ${media.xs`right:0rem;`}
    ${media.sm`right:0.938rem;`}
`

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateFairSale)
