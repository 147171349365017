import {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Col, media, Row} from 'styled-bootstrap-grid'
import {Flexed, ProfileImageDiv, Dot, Heading, TextPlaceHolder, Blocked, Label} from '../styles/shared'
import {CircularProgressbar, CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar'

import {palette} from '../assets/common'
import moment from 'moment'
import Button from './Button'
import {useCountdown} from '../hooks'
import {ethers} from 'ethers'
import {getJsonRpcProvider, getNetworkById} from '../utils'
import {PrivatePresaleContract} from '../contracts/PrivatePresaleContract'
import {FairPresaleContract} from '../contracts/FairPresaleContract'
import {PresaleContract} from '../contracts/PresaleContract'
import CountdownTimer from './CountdownTimer'
import {Copy} from './CopyTooltip'

const LeaderboardItem = (props) => {
    const [softCapInWei, setMinCap] = useState(null)
    const [hardCapInWei, setMaxCap] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [totalCollected, setTotalCollected] = useState(null)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const [value, setValue] = useState({
        chainId: props?.data?.chainId,
        launchpadAddress: props?.data?.launchpadAddress,
        saleTitle: props?.data?.saleTitle,
        isFairSale: props?.data?.isFairSale,
        startTime: props?.data?.startTime,
        endTime: props?.data?.endTime,
        softCapInWei: props?.data?.softCapInWei,
        hardCapInWei: props?.data?.hardCapInWei,
        isDataLoaded: props?.data?.isDataLoaded,
        isPrivateSale: props?.data?.isPrivateSale,
        totalCollected: props?.data?.totalCollected,
        isSuccessful: props?.data?.isSuccessful
    })

    const [isPreSaleStarted, setIsPreSaleStarted] = useState(value?.startTime < moment().unix())
    const [isPresaleClosed, setIsPresaleClosed] = useState(null)

    useEffect(async () => {
        if (value?.isDataLoaded) {
            setIsPresaleClosed(value?.isPresaleClosed)
            setIsSuccessful(value?.isSuccessful)
            setMinCap(value?.softCapInWei)
            setMaxCap(value?.hardCapInWei)
            setTotalCollected(value?.totalCollected)
            setStartTime(value?.startTime)
            setEndTime(value?.endTime)
        } else {
            await fetchPresalepadDetail(value)
        }
    }, [value])

    const fetchPresalepadDetail = async (item) => {
        const provider = await getJsonRpcProvider(item.chainId)
        try {
            item.preSaleNetwork = await getNetworkById(item.chainId)
            const investementPreSale = new ethers.Contract(item.launchpadAddress, item?.isPrivateSale ? PrivatePresaleContract.abi : item?.isFairSale ? FairPresaleContract.abi : PresaleContract.abi, provider)
            const _isPresaleClosed = await investementPreSale.checkStatus()
            item.isPresaleClosed = _isPresaleClosed
            setIsPresaleClosed(_isPresaleClosed)
            setMinCap(item.softCapInWei)
            if (!item.isFairSale) {
                setMaxCap(item.hardCapInWei)
            }
            let _totalCollectedWei = await investementPreSale.totalCollectedWei()
            _totalCollectedWei = ethers.utils.formatEther(_totalCollectedWei)
            item.totalCollected = _totalCollectedWei
            setTotalCollected(_totalCollectedWei)
            if (_totalCollectedWei >= item.softCapInWei) {
                item.isSuccessful = true
                setIsSuccessful(true)
            } else {
                item.isSuccessful = false
            }
            setStartTime(item.startTime)
            setEndTime(item.endTime)
            item.isDataLoaded = true
            setValue(item)
            props?.updateItem(item)
        } catch (error) {
            console.log(error)
        }
    }

    const updateSale = async () => {
        setIsPreSaleStarted(moment(parseInt(value?.startTime) * 1000).unix() < moment().unix())
        setIsPresaleClosed(moment(parseInt(value?.endTime) * 1000).unix() < moment().unix())
    }

    return (
        <Column isDarkTheme={props?.isDarkTheme}>
            <ProfileContent>
                <Flexed2 direction="row" align="center">
                    {endTime ? <Number isDarkTheme={props?.isDarkTheme}>{!props?.loading && props?.page * props?.limit + (parseInt(props?.id?.substring(0)) + 1)}</Number> : <Placeholder />}
                    <div>
                        <ProfileImageDiv isDarkTheme={props?.isDarkTheme} src={props?.data?.logoUrl} />
                    </div>
                    <FullFlex direction="row" align="center" justify="space-between">
                        <Blocked>
                            <Flexed direction="row" justify="start" align="center">
                                <TitleLabel isDarkTheme={props?.isDarkTheme}>{props?.data?.saleTitle?.toUpperCase()}</TitleLabel>
                            </Flexed>
                            <Flexed3 direction="row" justify="start" align="center">
                                {value?.preSaleNetwork ? (
                                    <>
                                        <Address isDarkTheme={props?.isDarkTheme} href={`${value?.preSaleNetwork?.networkUrl}/${value?.launchpadAddress}`} target="_blank">
                                            {value?.launchpadAddress}
                                        </Address>
                                        <Copy text={`${value?.preSaleNetwork?.networkUrl}/${value?.launchpadAddress}`} isDarkTheme={props?.isDarkTheme} />
                                    </>
                                ) : (
                                    <TextPlaceHolder />
                                )}
                            </Flexed3>
                            <SimpleLabel isDarkTheme={props?.isDarkTheme}>
                                {startTime ? (
                                    <>
                                        Start Time:
                                        {' ' + moment.unix(startTime).format('dddd, MMMM Do, YYYY h:mm a')?.toString()}
                                    </>
                                ) : (
                                    <TextPlaceHolder />
                                )}
                            </SimpleLabel>
                            <SimpleLabel isDarkTheme={props?.isDarkTheme}>
                                {endTime ? (
                                    <>
                                        End Time:
                                        {' ' + moment.unix(endTime).format('dddd, MMMM Do, YYYY h:mm a')?.toString()}
                                    </>
                                ) : (
                                    <TextPlaceHolder />
                                )}
                            </SimpleLabel>
                            {/* </Flexed3> */}
                        </Blocked>
                        <Flexed4 direction="row" align="center" justify="end">
                            <HiddenText>{endTime ? <>{isPreSaleStarted && !isPresaleClosed ? <CountdownTimer targetDate={endTime * 1000} updateList={updateSale} /> : isPresaleClosed ? null : <CountdownTimer targetDate={startTime * 1000} updateList={updateSale} />}</> : <TextPlaceHolder />}</HiddenText>
                            {endTime ? <Status status={isSuccessful ? 'successful' : isPreSaleStarted && !isPresaleClosed ? 'live' : isPresaleClosed ? 'closed' : 'upcoming'}>{isSuccessful ? 'Successful' : isPreSaleStarted && !isPresaleClosed ? 'Live' : isPresaleClosed ? 'Closed' : 'Upcoming'}</Status> : <Placeholder />}
                        </Flexed4>
                        <Flexed4 direction="row" align="center" justify="end">
                            {props?.data?.isFairSale == false && props?.data?.isPrivateSale == false && <StatusLabel status={'normal'}>Normal</StatusLabel>}
                            {props?.data?.isFairSale == true && <StatusLabel status={'fair'}>Fair</StatusLabel>}
                            {props?.data?.isPrivateSale == true && <StatusLabel status={'private'}>Private</StatusLabel>}
                            {props?.data?.isFairSale ? (
                                <>
                                    {softCapInWei ? (
                                        <Circle
                                            value={(parseFloat(props?.data?.totalCollected) / parseFloat(props?.data?.softCapInWei)) * 100}
                                            //  text={<tspan dy={0}>{(parseFloat(props?.data?.totalCollected) / parseFloat(props?.data?.minCap)) * 100}%</tspan>}
                                        />
                                    ) : (
                                        <Placeholder />
                                    )}
                                </>
                            ) : (
                                <>
                                    {softCapInWei && hardCapInWei ? (
                                        <>
                                            {/*
                                                        <CircleDual
                                                        value={50 + 80 / 2}
                                                        styles={buildStyles({
                                                            // pathColor: '#f00',
                                                            trailColor: '#eee'
                                                        })}>
                                                        */}
                                            <CircleDual
                                                // max cap - second half of circle
                                                value={50 + parseFloat(props?.data?.totalCollected) <= parseFloat(props?.data?.softCapInWei) ? 0 : ((parseFloat(props?.data?.totalCollected) / parseFloat(props?.data?.hardCapInWei)) * 100) / 2}
                                                styles={buildStyles({
                                                    pathColor: props?.isDarkTheme ? palette?.aqua_blue : palette?.blue,
                                                    trailColor: '#eee',
                                                    strokeLinecap: 'butt'
                                                })}>
                                                {/* <Circle
                                                        value={parseFloat(props?.data?.totalCollected) <= parseFloat(props?.data?.minCap) ? 0 : (parseFloat(props?.data?.totalCollected) / parseFloat(props?.data?.maxCap)) * 100}
                                                        styles={buildStyles({
                                                            trailColor: 'transparent',
                                                            strokeLinecap: 'butt'
                                                        })}
                                                    /> */}
                                            </CircleDual>
                                            <CircleInner
                                                // mincap - first half of circle
                                                value={((parseFloat(props?.data?.totalCollected) / parseFloat(props?.data?.softCapInWei)) * 100) / 2}
                                                styles={buildStyles({
                                                    trailColor: 'transparent',
                                                    // pathColor: '#f00',
                                                    strokeLinecap: 'butt'
                                                })}
                                            />
                                            <CircleInner
                                                // white space at the end of first half - left amount in min cap
                                                value={50 - ((parseFloat(props?.data?.totalCollected) / parseFloat(props?.data?.softCapInWei)) * 100) / 2}
                                                styles={buildStyles({
                                                    rotation: parseFloat(props?.data?.totalCollected) / parseFloat(props?.data?.softCapInWei), // min =0, max=0.5
                                                    trailColor: 'transparent',
                                                    // pathColor: '#fff',
                                                    strokeLinecap: 'butt'
                                                })}
                                            />
                                        </>
                                    ) : (
                                        <Placeholder />
                                    )}
                                </>
                            )}
                            <Button isDarkTheme={props?.isDarkTheme} ifClicked={() => props?.viewItem(props?.data)} label={'View Presale'} />
                        </Flexed4>
                    </FullFlex>
                </Flexed2>
            </ProfileContent>
        </Column>
    )
}

const ProfileContent = styled(Col)`
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    ${media.xs`
        display: block;
    `};
    ${media.sm`
        display: flex;
    `};
`

const Number = styled(Heading)`
    font-size: 1rem;
    margin-right: 0.1rem;
    width: 2rem;
    font-weight: 500;
    font-size: 0.876rem;
`

const Column = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.75rem;
    // padding-left:0;
    border-bottom: ${({isDarkTheme, hideBottomLine}) => (hideBottomLine ? `` : ` 0.063rem solid ${palette.off_dark}`)};
    gap: ${({gap}) => (gap ? `${gap}` : '')};
`

const Flexed2 = styled(Flexed)`
    width: 100%;
`

const FullFlex = styled(Flexed)`
    width: 100%;
    ${media.xs`
        display: block;
    `};
    ${media.sm`
        display: block;
    `};
    ${media.md`
        display: flex;
    `};
    ${media.lg`
        display: flex;
    `};
`

const TitleLabel = styled(Label)`
    font-size: 1rem;
    margin-right: 2rem;
    overflow-wrap: anywhere;
    white-space: initial;
`

const SimpleLabel = styled(Label)`
    font-size: 0.75rem;
`

const SimpleLabel2 = styled(Label)`
    font-size: 0.75rem;
    margin-left: 0.5rem;
`

const ColoredLabel = styled(Label)`
    color: ${palette.aqua_blue};
    // margin-right: 0.25rem;
    font-size: 0.75rem;
    cursor: pointer;
`

const ColoredSpan = styled.span`
    color: ${palette.aqua_blue} !important;
    font-size: 0.75rem !important;
    cursor: pointer;
`

const Address = styled.a`
    text-decoration: none;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
    // margin-bottom: 0.1rem;
    // line-height: 1.5;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    cursor: pointer;
    &:hover {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
        opacity: 0.8;
    }
`

const Circle = styled(CircularProgressbar)`
    margin-right: 1rem;
    width: 3rem;
    ${media.xs`
        display: none;
    `};
    ${media.sm`
        display: none;
    `};
    ${media.md`
        display: flex;
    `};
    ${media.lg`
        display: flex;
    `};
`

const CircleDual = styled(CircularProgressbarWithChildren)`
    margin-right: 1rem;
    width: 3rem;
    ${media.xs`
        display: none;
    `};
    ${media.sm`
        display: none;
    `};
    ${media.md`
        display: flex;
    `};
    ${media.lg`
        display: flex;
    `};
`

const CircleInner = styled(Circle)`
    position: absolute;
    // top: 1.88rem;
    right: 10.36rem;
`

const Flexed3 = styled(Flexed)`
    ${media.xs`
        display: block;
    `};
    ${media.sm`
        display: block;
    `};
    ${media.md`
        display: flex;
    `};
    ${media.lg`
        display: flex;
    `};
`

const Flexed4 = styled(Flexed)`
    ${media.xs`
        margin-top:0.5rem;
		justify-content:start;
    `};
    ${media.sm`
        margin-top:0.5rem;
		justify-content:start;
    `};
    ${media.md`
        margin-top:0;
    `};
    ${media.lg`
        margin-top:0;
    `};
`

const StatusTag = styled(Label)`
    display: flex;
    justify-content: center;
`

export const StatusLabel = styled.div`
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 0.8rem;
    line-height: 1.38rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    padding: 0.2rem 0.5rem;
    // width: fit-content;
    width: 4.8rem;
    border-radius: 0.4rem;
    text-transform: uppercase;
    color: ${palette.white};
    z-index: 2;
    background: ${({status}) => (status == 'live' ? `${palette.green_gradient}` : status == 'upcoming' ? `${palette.yellow_gradient}` : status == 'normal' ? `${palette.green_gradient}` : status == 'fair' ? `${palette.aqua_blue}` : status == 'private' ? `${palette.theme_Text}` : `${palette.red_gradient}`)};
    margin-right: 1rem;
`

const Placeholder = styled(TextPlaceHolder)`
    width: 3rem;
    margin-right: 1rem;
    margin-top: 0.4rem;
`

const Status = styled(StatusLabel)`
    background: transparent;
    color: ${({status, isDarkTheme}) => (status == 'live' ? `${palette.green}` : status == 'upcoming' ? (isDarkTheme ? `${palette.yellow}` : `${palette.binance}`) : status == 'successful' ? `${palette.aqua_blue}` : `${palette.red}`)};
`

const HiddenText = styled.div`
    display: none !important;
`
export default LeaderboardItem
