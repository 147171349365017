import {useState} from 'react'
import styled from 'styled-components'
import {Tooltip, OverlayTrigger} from 'react-bootstrap'
import {AiOutlineCopy} from 'react-icons/ai'
import {palette} from '../assets/common'

export const Copy = ({text, isDarkTheme, top}) => {
    const [show, setShow] = useState(false)

    return (
        <OverlayTrigger placement="top" overlay={renderTooltip(show ? 'Copied' : 'Copy')} onExit={() => setShow(false)}>
            {({placement, arrowProps, show: _show, popper, ...props}) => (
                <div
                    {...props}
                    style={{
                        ...props.style
                    }}>
                    <CopyIcon
                        isDarkTheme={isDarkTheme}
                        onClick={() => {
                            navigator.clipboard.writeText(text)
                            setShow(true)
                        }}
                        top={top}
                    />
                </div>
            )}
        </OverlayTrigger>
    )
}

const renderTooltip = (props) => <TooltipBox {...props}>{props}</TooltipBox>

const TooltipBox = styled(Tooltip)`
    border-radius: 0.5rem;
    padding: 0.1rem 0.25rem;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px;
`

const CopyIcon = styled(AiOutlineCopy)`
    color: ${({isDarkTheme}) => (isDarkTheme ? ` ${palette.aqua_blue}` : ` ${palette.blue}`)};
    margin: ${({top}) => (top ? `${top} 0 0 0.25rem` : '-0.1rem 0 0 0.25rem')};
    font-size: 0.9rem;
    cursor: pointer;
    z-index: 1;
    &:hover {
        opacity: 0.65;
    }
`
