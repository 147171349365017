import {NetworksTypes} from '../utils'

export const TokenLiquidityFactoryContract = {
    contractId: (chainId) => {
        if (chainId == NetworksTypes.EthereumMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.EthereumTestNet.chainId) {
            return '0x0CA49f32E2f5f793a1E88e53146f5bae3005355d'
        } else if (chainId == NetworksTypes.BinanceMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.BinanceTestNet.chainId) {
            return '0x71Fb09645cb9A9004B9835C5C17EC9b13F1F9Fda'
        } else if (chainId == NetworksTypes.PolygonMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.PolygonTestNet.chainId) {
            return '0xB0E1397f6C5290f21b9fd040AC536c033ccDf675'
        }
    },
    abi: [
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'string',
                            name: 'name',
                            type: 'string'
                        },
                        {
                            internalType: 'string',
                            name: 'symbol',
                            type: 'string'
                        },
                        {
                            internalType: 'uint256',
                            name: 'quantity',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'taxFee',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidityFee',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct CreateTokenFactory.info',
                    name: '_info',
                    type: 'tuple'
                }
            ],
            name: 'createToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: '_tokenCreator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: '_tokenAddress',
                    type: 'address'
                }
            ],
            name: 'tokenCreated',
            type: 'event'
        }
    ]
}
