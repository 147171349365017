import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { useDispatch, useSelector } from 'react-redux'
import { ethers } from 'ethers'
import { connect } from 'react-redux'
import { api, url } from '../config/index'
import { AirdropContract } from '../contracts/AirdropContract'
import { AirdropPrivateContract } from '../contracts/AirdropPrivateContract'
import moment from 'moment'
import CountdownTimer from './CountdownTimer'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import { Flexed, NetworkContent, StatusContent, ProfileImageDiv, AddressText, Heading, DetailLabel, Content, SocialIcons, InfoIcon, Alblur, TextPlaceHolder, Blocked, FlexedEnd, ShareIcon, ShareIconContainer, renderTooltip } from '../styles/shared'
import axios from 'axios'
import Chart from 'react-google-charts'
import { useCountdown } from '../hooks'
import { openMainPage } from '../actions/authActions'

import { updateAirdrop, DocumentLinks, showConnectionError, showSuccess, showError, getJsonRpcProvider, getNetworkById } from '../utils'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { img_url_regex, startsWithNumber, token_regex, url_regex } from '../utils/regex'
import { Disqussion } from './Disqussion'
import { OverlayTrigger } from 'react-bootstrap'
import { Copy } from './CopyTooltip'
import { ERC20 } from '../contracts/ERC20'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.post['Accept'] = 'application/json'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const AirdropDetails = ({ _airdropInfo }) => {
    let { chain, address } = Object.fromEntries(new URLSearchParams(location.search))
    address = address?.substring(0, 42)
    const [airdropInfo, setAirdropInfo] = useState(_airdropInfo == undefined ? { airdropAddress: address, chainId: chain } : _airdropInfo)

    const _dispatch = useDispatch()

    const signer = useSelector((state) => state.auth.signer)
    const user = useSelector((state) => state.auth.user)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    const [totalTokens, setTotalTokens] = useState(0)

    const [meParticipated, setMeParticipated] = useState(false)
    const [participantCount, setParticipantCount] = useState(null)
    const [airdropCreatorAddress, setAirdropCreatorAddress] = useState('')
    const [isDeveloper, setIsDeveloper] = useState(false)
    const [airdropContract, setAirdropContract] = useState(null)
    const [tokenAddress, setTokenAddress] = useState(null)
    const [closingTime, setClosingTime] = useState(null)
    const [closeDate, setCloseDate] = useState(null)

    const [whitelist, setWhitelist] = useState([])
    const [whitelistValues, setWhitelistValues] = useState([])
    const [newWhitelistInput, setNewWhitelistInput] = useState('')
    const [whitelistInputError, setWhitelistInputError] = useState(false)
    const [whitelistTab, setWhitelistTab] = useState(1)
    const [removedWhitelistAddresses, setRemovedWhitelistAddresses] = useState([])
    const [addedWhitelistAddresses, setAddedWhitelistAddresses] = useState([])
    const [whitelistSpinner, setWhitelistSpinner] = useState(false)

    const [isAirdropInfoFetched, setIsAirdropInfoFetched] = useState(false)
    const [airdropDbInfo, setAirdropDbInfo] = useState(null)
    const [updateSocials, setUpdateSocials] = useState(null)
    const [isAirdropReleased, setIsAirdropReleased] = useState(false)

    const [isUpdating, setIsUpdating] = useState(false)
    const [isParticipating, setIsParticipating] = useState(false)
    const [isReleasing, setIsReleasing] = useState(false)
    const [airdropNetwork, setAirdropNetwork] = useState('')
    let isAirdropClosed = useCountdown(closingTime, false)
    const [isValidationCompleted, setIsValidationCompleted] = useState(true)

    const [show, setShow] = useState(false)
    const target = useRef(null)

    const [pieData, setPieData] = useState([
        ['Task', 'Hours per Day'],
        ['Tokens', 0],
        ['Participants', 0]
    ])

    const pieOptions = {
        title: '',
        is3D: true,
        width: 350,
        height: 320,
        chartArea: { width: '100%', height: '80%' },
        legend: {
            position: 'bottom',
            textStyle: {
                fontSize: 9,
                color: isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`
            }
        },
        ...(isDarkTheme ? { backgroundColor: `${palette.pie_chart_bg}` } : { backgroundColor: `${palette.pure_white}` }),
        ...(isDarkTheme ? { legendTextStyle: { color: `${palette.off_white}` } } : { legendTextStyle: { color: `${palette.pure_black}` } })
    }

    useEffect(async () => {
        if (user == null) {
            const _airdropNetwork = await getNetworkById(airdropInfo?.chainId)
            setAirdropNetwork(_airdropNetwork)
            const provider = await getJsonRpcProvider(airdropInfo?.chainId)
            let _airdropContract = null
            if (airdropInfo.isPrivate) {
                _airdropContract = new ethers.Contract(airdropInfo.airdropAddress, AirdropPrivateContract.abi, provider)
            } else {
                _airdropContract = new ethers.Contract(airdropInfo.airdropAddress, AirdropContract.abi, provider)
            }
            setAirdropContract(_airdropContract)
        }
    }, [])

    useEffect(async () => {
        if (user != null) {
            if (airdropInfo?.chainId != connectedNetwork?.chainId) {
                _dispatch(openMainPage(true))
                return
            }
            setIsDeveloper(false)
            for (let i = 0; i < user.airdrops.length; i++) {
                if (airdropInfo.airdropAddress == user.airdrops[i].airdropAddress) {
                    setIsDeveloper(true)
                }
            }
            const _airdropNetwork = await getNetworkById(airdropInfo?.chainId)
            setAirdropNetwork(_airdropNetwork)

            let _airdropContract = null
            if (airdropInfo.isPrivate) {
                _airdropContract = new ethers.Contract(airdropInfo.airdropAddress, AirdropPrivateContract.abi, signer)
            } else {
                _airdropContract = new ethers.Contract(airdropInfo.airdropAddress, AirdropContract.abi, signer)
            }
            setAirdropContract(_airdropContract)
        } else {
            setIsDeveloper(false)
        }
    }, [user])

    useEffect(async () => {
        if (airdropContract) {
            await readAirdropInfo()
        }
    }, [airdropContract, connectedNetwork])

    const getAirdropDbInfo = async () => {
        axios
            .post(`${api}/air_drop/find`, {
                airdropAddress: airdropInfo.airdropAddress
            })
            .then((response) => {
                if (response.data.status) {
                    setAirdropDbInfo(response?.data?.data)
                    setUpdateSocials({ logoUrl: { val: response?.data?.data?.logoUrl, edit: false }, website: { val: response?.data?.data?.website, edit: false }, title: { val: response?.data?.data?.title, edit: false }, twitter: { val: response?.data?.data?.twitter, edit: false }, discord: { val: response?.data?.data?.discord, edit: false }, telegram: { val: response?.data?.data?.telegram, edit: false }, description: { val: response?.data?.data?.description, edit: false } })
                }
            })
            .catch(function (error) {
                showError(error.message)
            })
    }

    const readAirdropInfo = async () => {
        await getAirdropDbInfo()
        const _tokenAddress = await airdropContract.tokenAddress()
        setTokenAddress(_tokenAddress)

        const erc20 = new ethers.Contract(_tokenAddress, ERC20.abi, signer)
        const decimals = await erc20.decimals()

        let distributionTime = await airdropContract.distributionTime()
        const _isClosed = await airdropContract.checkStatus()
        isAirdropClosed = _isClosed

        const _isAirdropped = await airdropContract.airdropped()
        setIsAirdropReleased(_isAirdropped)

        let tokens = await airdropContract.tokens()
        tokens = ethers.utils.formatUnits(tokens, decimals)
        setTotalTokens(tokens)

        let _participantCount = 0

        if (airdropInfo.isPrivate) {
            await getBlockchainWhiteList()
            if (user?.address) {
                _participantCount = await airdropContract.userAndQuantity(user?.address) //todo
                _participantCount = ethers.utils.formatEther(_participantCount)
            }
        } else {
            if (user?.address) {
                const _setMeParticipated = await airdropContract.participated(user?.address)
                setMeParticipated(_setMeParticipated)
            }
            _participantCount = await airdropContract.totalParticipants()
        }
        setParticipantCount(_participantCount)



        const _airdropCreatorAddress = await airdropContract.airdropCreator()
        setAirdropCreatorAddress(_airdropCreatorAddress)

        setClosingTime(distributionTime * 1000)

        const _endDate = moment.unix(distributionTime).format('dddd, MMMM Do, YYYY h:mm a')
        setCloseDate(_endDate)

        const pieData = [
            ['Task', 'Hours per Day'],
            ['Tokens', parseFloat(tokens)],
            ['Participants', parseInt(_participantCount)]
        ]
        setPieData(pieData)
        setIsAirdropInfoFetched(true)
    }

    const getBlockchainWhiteList = async () => {
        const erc20 = new ethers.Contract(tokenAddress, ERC20.abi, signer)
        const decimals = await erc20.decimals()

        const _whiteListArrays = await airdropContract.showUsers()
        let _whitelist = []
        _whiteListArrays[1]?.map((val, index) => {
            if (parseInt(ethers.utils.formatUnits(val, decimals)) > 0) {
                _whitelist?.push({ address: _whiteListArrays[0][index], value: parseInt(ethers.utils.formatUnits(val, decimals)) })
            }
        })
        setWhitelist(_whitelist)
    }

    const participateInAirdrop = async () => {
        if (!connectedNetwork) {
            showConnectionError()
            return
        }
        setIsParticipating(true)
        try {
            let participateTx = await airdropContract.participate()
            participateTx = await participateTx.wait()
            if (participateTx) {
                showSuccess('Participated successfully')
                await readAirdropInfo()
                //todo..update requried info only
                setMeParticipated(true)
            }
            setIsParticipating(false)
        } catch (error) {
            setIsParticipating(false)
            console.log('ParticipateAirdrop Error: ', error)
            if (error?.reason) {
                showError(error?.reason)
            } else {
                showError(error?.data?.message)
            }
        }
    }

    const releaseAirdrop = async () => {
        if (!connectedNetwork) {
            showConnectionError()
            return
        }
        setIsReleasing(true)
        try {
            let participateTx = await airdropContract.airdropUsers()
            participateTx = await participateTx.wait()
            if (participateTx) {
                showSuccess('Tokens released successfully')
                await readAirdropInfo()
                //todo..update requried info only
            }
            setIsReleasing(false)
        } catch (error) {
            console.log('ReleaseAirdrop Error: ', error)
            setIsReleasing(false)
            if (error.reason) {
                showError(error.reason)
            } else {
                showError(error.data.message)
            }
        }
    }

    const doUpdateAirdrop = async () => {
        if (connectedNetwork) {
            let decimals = await airdropDbInfo.decimals()
            if (socialValiation() && !updateSocials?.logoUrlErr && isValidationCompleted) {
                let addresslist = []
                let quantityList = []
                addedWhitelistAddresses?.map((i) => addresslist.push(i?.address))
                addedWhitelistAddresses?.map((i) => quantityList.push(ethers.utils.formatUnits(i?.value.toString(), decimals)))

                setIsUpdating(true)
                const _updateData = {
                    airdropAddress: airdropDbInfo.airdropAddress,
                    title: updateSocials?.title?.val,
                    logoUrl: updateSocials?.logoUrl?.val,
                    website: updateSocials?.website?.val,
                    twitter: updateSocials?.twitter?.val,
                    telegram: updateSocials?.telegram?.val,
                    discord: updateSocials?.discord?.val,
                    description: updateSocials?.description?.val,
                    users: airdropInfo.isPrivate ? addresslist : [],
                    userQuantity: airdropInfo.isPrivate ? quantityList : []
                }
                const _dbResponse = await updateAirdrop(_updateData, user.token).then((res) => {
                    return res
                })
                if (_dbResponse?.data?.status) {
                    showSuccess('Airdrop social info updated successfully')
                } else {
                    showError('Error in airdrop updation. Please try again later.')
                }
                setIsUpdating(false)
            }
        } else {
            showConnectionError()
        }
    }

    function validateImageURl(url) {
        setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: false })
        setIsValidationCompleted(false)
        if (url) {
            if (!img_url_regex.test(url.trim())) {
                setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: 'Only supported image extensions are allowed' })
                setIsValidationCompleted(true)
            } else if (url?.length > 255) {
                setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: 'Maximum 255 characters allowed' })
                setIsValidationCompleted(true)
            } else {
                let img = new Image()
                img.src = url
                img.onload = function () {
                    setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: false })
                    setIsValidationCompleted(true)
                }
                img.onerror = function () {
                    setUpdateSocials({ ...updateSocials, logoUrl: { ...updateSocials?.logoUrl, val: url }, logoUrlErr: 'Only supported image extensions are allowed' })
                    setIsValidationCompleted(true)
                }
            }
        } else {
        }
    }

    const socialValiation = () => {
        let _isValid = true
        let titleErr = false
        let websiteErr = false
        let twitterErr = false
        let telegramErr = false
        let discordErr = false
        let descriptionErr = false

        if (updateSocials?.title?.val?.length === 0) {
            _isValid = false
            titleErr = 'Title is required'
        } else if (updateSocials?.title?.val?.length < 3 || startsWithNumber(updateSocials?.title?.val)) {
            _isValid = false
            titleErr = 'Invalid title. Must start with a letter, at least three characters long and no special characters'
        } else {
            titleErr = false
        }

        if (updateSocials?.website?.val?.length > 0) {
            if (!url_regex.test(updateSocials?.website?.val)) {
                _isValid = false
                websiteErr = 'Invalid website url'
            } else if (updateSocials?.website?.val?.length > 255) {
                _isValid = false
                websiteErr = 'Maximum 255 characters allowed'
            } else {
                websiteErr = false
            }
        } else {
            websiteErr = false
        }

        if (updateSocials?.twitter?.val?.length > 0) {
            if (!url_regex.test(updateSocials?.twitter?.val)) {
                _isValid = false
                twitterErr = 'Invalid twitter url'
            } else if (updateSocials?.twitter?.val?.length > 255) {
                _isValid = false
                twitterErr = 'Maximum 255 characters allowed'
            } else {
                twitterErr = false
            }
        }

        if (updateSocials?.telegram?.val?.length > 0) {
            if (!url_regex.test(updateSocials?.telegram?.val)) {
                _isValid = false
                telegramErr = 'Invalid telegram url'
            } else if (updateSocials?.telegram?.val?.length > 255) {
                _isValid = false
                telegramErr = 'Maximum 255 characters allowed'
            } else {
                telegramErr = false
            }
        } else {
            telegramErr = false
        }

        if (updateSocials?.discord?.val?.length > 0) {
            if (!url_regex.test(updateSocials?.discord?.val)) {
                _isValid = false
                discordErr = 'Invalid discord url'
            } else if (updateSocials?.discord?.val?.length > 255) {
                _isValid = false
                discordErr = 'Maximum 255 characters allowed'
            } else {
                discordErr = false
            }
        } else {
            discordErr = false
        }

        if (updateSocials?.description?.val?.length > 0 && updateSocials?.description?.val?.length < 128) {
            _isValid = false
            descriptionErr = 'Description must be at least 128 characters long'
        } else {
            descriptionErr = false
        }
        setUpdateSocials({ ...updateSocials, titleErr: titleErr, websiteErr: websiteErr, twitterErr: twitterErr, telegramErr: telegramErr, discordErr: discordErr, descriptionErr: descriptionErr })
        return _isValid
    }

    const addWhiteListAddress = (addVal, keyPressed) => {
        setNewWhitelistInput(addVal)
        let _whiteList = []
        if (addVal?.includes(',')) {
            _whiteList = addVal.split(',')
        } else if (keyPressed !== null && keyPressed === 'Enter') {
            _whiteList.push(addVal)
        }
        _whiteList = _whiteList.map((_item) => {
            if (_item?.charAt(42) === ':') {
                let _tokenAddress = _item.slice(0, 42)
                let _value = parseInt(_item?.slice(43, _item?.length))
                if (token_regex.test(_tokenAddress) === true) {
                    if (parseInt(_value) > 0) {
                        if (whitelist.find((x) => x?.address?.toLowerCase() == _tokenAddress?.toLowerCase())) {
                            setWhitelistInputError('Address was added previously')
                        } else if (addedWhitelistAddresses.find((x) => x.address?.toLowerCase() == _tokenAddress?.toLowerCase())) {
                            setWhitelistInputError('Address already added above')
                        } else {
                            setWhitelistInputError(false)
                            setNewWhitelistInput('')
                            return { address: _tokenAddress, isValid: true, value: _value }
                        }
                    } else {
                        setWhitelistInputError('Invalid quantity')
                        return { address: _tokenAddress, isValid: false, value: _value }
                    }
                } else {
                    setWhitelistInputError('Invalid address')
                    return { address: _tokenAddress, isValid: false, value: _value }
                }
            } else {
                setWhitelistInputError('Invalid address or value')
                return { address: _item?.substring(0, 42), isValid: false, value: parseInt(_item?.slice(43, _item?.length)) }
            }
        })
        addedWhitelistAddresses.map((_item) => _whiteList.push(_item))
        // whitelist.map((_item) => _whiteList.push(_item))
        _whiteList = _whiteList.filter((i) => i?.isValid === true)
        _whiteList = _whiteList?.filter((value, index, self) => index === self.findIndex((t) => t.address === value.address))
        // setWhitelist(_whiteList)
        setAddedWhitelistAddresses(_whiteList)
    }

    const removeWhiteListAddress = (removeVal) => {
        if (whitelistTab == 2) {
            let _addedlist = addedWhitelistAddresses.filter((_item) => {
                return _item !== removeVal // or _item?.address !== removeVal?.address
            })
            setAddedWhitelistAddresses(_addedlist)
        } else {
            let _whitelist = whitelist.filter((_item) => {
                return _item?.address !== removeVal
            })
            setWhitelist(_whitelist)
            removedWhitelistAddresses?.push(removeVal)
        }

        // if (whitelist?.length > 0) {
        //     let _whitelist = whitelist.filter((_item) => {
        //         return _item !== removeVal
        //     })
        //     removeWhiteListAddress?.push(addVal)
        //     // setWhitelist(_whitelist)
        // }
    }

    const handleBlockchainWhiteList = async () => {
        // console.log('whitelistAddresses', whitelist, 'removed', removedWhitelistAddresses, 'added', addedWhitelistAddresses)
        if (whitelistTab == 2 && addedWhitelistAddresses?.length == 0) {
            showError('Nothing to add')
            return
        }
        if (whitelistTab == 3 && removedWhitelistAddresses?.length == 0) {
            showError('Nothing to remove')
            return
        }
        try {
            let decimals = await airdropDbInfo.decimals()
            setWhitelistSpinner(true)
            let addresslist = []
            let quantityList = []
            addedWhitelistAddresses?.map((i) => addresslist.push(i?.address))
            addedWhitelistAddresses?.map((i) => quantityList.push(ethers.utils.parseUnits(i?.value.toString(), decimals)))
            let _whitelistSuccess = null
            if (whitelistTab == 2) {
                if (removedWhitelistAddresses?.length > 0) {
                    showError('Unsaved changes in removed list. Finish them first')
                    setWhitelistSpinner(false)
                    return
                }
                _whitelistSuccess = await airdropContract.addUsersAndQuantity(addresslist, quantityList)
            } else if (whitelistTab == 3) {
                if (addedWhitelistAddresses?.length > 0) {
                    showError('Unsaved changes in added list. Finish them first')
                    setWhitelistSpinner(false)
                    return
                }
                _whitelistSuccess = await airdropContract.updateUsers(removedWhitelistAddresses)
            }
            const response = await _whitelistSuccess.wait()
            if (response) {
                showSuccess(`Whitelist ${whitelistTab == 2 ? 'added' : 'removed'} successfully`)
                if (whitelistTab == 2) {
                    setAddedWhitelistAddresses([])
                } else if (whitelistTab == 3) {
                    setRemovedWhitelistAddresses([])
                }
            }
            await getBlockchainWhiteList()
            setWhitelistSpinner(false)
            setWhitelistTab(1)
        } catch (error) {
            console.log('BlockchainWhiteList Error: ', error)
            setWhitelistSpinner(false)
            if (error?.reason) {
                showError(error?.reason)
            } else {
                showError(error?.data?.message)
            }
        }
    }

    const shareUrl = `${url}/airdrop-details?chain=${airdropInfo?.chainId}&address=${airdropInfo?.airdropAddress}/`

    return (
        <>
            <Wrapper>
                {airdropContract && (
                    <>
                        <MainRow>
                            <Col lg={12} xl={7} xxl={8}>
                                <Form isDarkTheme={isDarkTheme}>
                                    <Row>
                                        <ShareIconContainer>
                                            <OverlayTrigger placement="top" overlay={renderTooltip(show ? 'Copied' : 'Copy')}>
                                                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                                                    <div
                                                        {...props}
                                                        style={{
                                                            ...props.style
                                                        }}>
                                                        <ShareIcon
                                                            isDarkTheme={isDarkTheme}
                                                            ref={target}
                                                            xsPosition="relative"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(shareUrl)
                                                                setShow(!show)
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </OverlayTrigger>
                                        </ShareIconContainer>
                                        <a target="_blank" href={DocumentLinks('airDropDetails')}>
                                            <InfoIcon isDarkTheme={isDarkTheme} right={'1rem'} top={'1rem'} xsPosition="absolute" />
                                        </a>
                                        <ProfileContent>
                                            <Flexed direction="row" align="center">
                                                <div>
                                                    <ProfileImageDiv isDarkTheme={isDarkTheme} src={updateSocials != null ? updateSocials?.logoUrl?.val : ''} />
                                                </div>
                                                <Heading isDarkTheme={isDarkTheme}>{updateSocials?.title?.val}</Heading>
                                            </Flexed>
                                        </ProfileContent>
                                        <Column isDarkTheme={isDarkTheme} hideBottomLine={true}>
                                            {updateSocials !== null ? (
                                                <DescriptionContent>
                                                    <Link href={updateSocials != null ? updateSocials?.website?.val : ''} target={updateSocials !== null && updateSocials?.website?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Web.png' : '/svg/WebW.svg'} alt="Web" />
                                                    </Link>
                                                    <Link href={updateSocials != null ? updateSocials?.discord?.val : ''} target={updateSocials !== null && updateSocials?.discord?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Discord.png' : '/svg/DiscordW.svg'} alt="Discord" />
                                                    </Link>
                                                    <Link href={updateSocials != null ? updateSocials?.telegram?.val : ''} target={updateSocials !== null && updateSocials?.telegram?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Telegram.png' : '/svg/TelegramW.svg'} alt="Telegram" />
                                                    </Link>
                                                    <Link href={updateSocials != null ? updateSocials?.twitter?.val : ''} target={updateSocials !== null && updateSocials?.twitter?.val ? '_blank' : '_self'} rel="noopener noreferrer">
                                                        <SocialIcons isDarkTheme={isDarkTheme} src={isDarkTheme ? '/images/Twitter.png' : '/svg/TwitterW.svg'} alt="Twitter" />
                                                    </Link>
                                                </DescriptionContent>
                                            ) : (
                                                <TextPlaceHolder />
                                            )}
                                        </Column>
                                        {updateSocials?.description?.val?.length > 0 && <Column isDarkTheme={isDarkTheme}>{updateSocials !== null ? <LeftContent isDarkTheme={isDarkTheme}>{updateSocials?.description?.val}</LeftContent> : <TextPlaceHolder />}</Column>}
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Airdrop Address</DetailLabel>
                                            <Content isDarkTheme={isDarkTheme}>
                                                {airdropNetwork ? (
                                                    <Flexed direction="row" align="center">
                                                        <AddressText isDarkTheme={isDarkTheme} href={`${airdropNetwork.networkUrl}/${airdropInfo.airdropAddress}`} target="_blank" rel="noopener noreferrer">
                                                            {airdropInfo.airdropAddress?.toString()}
                                                        </AddressText>
                                                        <Copy text={`${airdropNetwork.networkUrl}/${airdropInfo.airdropAddress?.toString()}`} isDarkTheme={isDarkTheme} />
                                                    </Flexed>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Content>
                                        </AddressColumn>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Token Address</DetailLabel>
                                            <Content isDarkTheme={isDarkTheme}>
                                                {tokenAddress ? (
                                                    <Flexed direction="row" align="center">
                                                        <AddressText isDarkTheme={isDarkTheme} href={`${airdropNetwork.tokenUrl}/${tokenAddress}`} target="_blank" rel="noopener noreferrer">
                                                            {tokenAddress?.toString()}
                                                        </AddressText>
                                                        <Copy text={`${airdropNetwork.tokenUrl}/${tokenAddress?.toString()}`} isDarkTheme={isDarkTheme} />
                                                    </Flexed>
                                                ) : (
                                                    <TextPlaceHolder />
                                                )}
                                            </Content>
                                        </AddressColumn>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Total Tokens</DetailLabel>
                                            {totalTokens && airdropDbInfo ? <Content isDarkTheme={isDarkTheme}>{totalTokens?.toString() + ' ' + airdropDbInfo?.tokenSymbol}</Content> : <TextPlaceHolder />}
                                        </Column>
                                        {airdropInfo.isPrivate ? (
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Total Participants</DetailLabel>
                                                {whitelist ? <Content isDarkTheme={isDarkTheme}>{whitelist?.length}</Content> : <TextPlaceHolder />}
                                            </Column>
                                        ) : (
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Total Participants</DetailLabel>
                                                {participantCount ? <Content isDarkTheme={isDarkTheme}>{participantCount?.toString()}</Content> : <TextPlaceHolder />}
                                            </Column>
                                        )}
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Airdrop End Time</DetailLabel>
                                            {closeDate ? <Content isDarkTheme={isDarkTheme}>{closeDate?.toString()}</Content> : <TextPlaceHolder />}
                                        </AddressColumn>
                                    </Row>
                                    {isAirdropInfoFetched && isDeveloper && isAirdropClosed && !isAirdropReleased && (
                                        <List isDarkTheme={isDarkTheme} justifyContent="center">
                                            <SecondButtonContainer sm={6} md={4} lg={4} xl={4} xxl={3}>
                                                <Button
                                                    isDarkTheme={isDarkTheme}
                                                    label={'Release Tokens'}
                                                    // width={'100%'}
                                                    disabled={isReleasing}
                                                    ifClicked={(e) => {
                                                        releaseAirdrop()
                                                    }}
                                                />
                                            </SecondButtonContainer>
                                        </List>
                                    )}
                                </Form>

                                {isDeveloper && !isAirdropClosed && updateSocials != null && (
                                    <Form isDarkTheme={isDarkTheme}>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel width={8} isDarkTheme={isDarkTheme}>
                                                Logo URL
                                            </DetailLabel>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    type="text"
                                                    value={updateSocials?.logoUrl?.val}
                                                    onChange={(e) => {
                                                        validateImageURl(e.target.value)
                                                    }}
                                                />
                                                {!isValidationCompleted && !updateSocials?.logoUrlErr && updateSocials?.logoUrl?.val?.length > 0 && <Alblur>Processing logo url. Please wait...</Alblur>}
                                                {updateSocials?.logoUrlErr && <Alblur isDarkTheme={isDarkTheme}>{updateSocials?.logoUrlErr}</Alblur>}
                                            </Blocked>
                                        </AddressColumn>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel width={8} isDarkTheme={isDarkTheme}>
                                                Airdrop Title
                                            </DetailLabel>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    type="text"
                                                    value={updateSocials?.title?.val}
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, title: { ...updateSocials?.title, val: e.target.value }, titleErr: false })
                                                    }}
                                                />
                                                {updateSocials?.titleErr && <Alblur isDarkTheme={isDarkTheme}>{updateSocials?.titleErr}</Alblur>}
                                            </Blocked>
                                        </AddressColumn>
                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Description</DetailLabel>
                                            <FlexedEnd>
                                                <Blocked>
                                                    <EditDescription
                                                        width={97}
                                                        rows={3}
                                                        placeholder="Minimum of 128 characters"
                                                        isDarkTheme={isDarkTheme}
                                                        value={updateSocials?.description?.val}
                                                        onChange={(e) => {
                                                            setUpdateSocials({ ...updateSocials, description: { ...updateSocials.description, val: e.target.value, descriptionErr: false } })
                                                        }}
                                                    />
                                                    {updateSocials?.descriptionErr && <Alblur>{updateSocials?.descriptionErr}</Alblur>}
                                                </Blocked>
                                            </FlexedEnd>
                                        </AddressColumn>
                                        <Column2 isDarkTheme={isDarkTheme} gap={'0.3rem'}>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    width="100"
                                                    type="text"
                                                    value={updateSocials.website.val}
                                                    placeholder="https://yoursite.com"
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, website: { ...updateSocials.website, val: e.target.value }, websiteErr: false })
                                                    }}
                                                />{' '}
                                                {updateSocials?.websiteErr && <Alblur>{updateSocials?.websiteErr}</Alblur>}
                                            </Blocked>

                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    width="100"
                                                    type="text"
                                                    value={updateSocials.discord.val}
                                                    placeholder="https://discord.com"
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, discord: { ...updateSocials.discord, val: e.target.value }, discordErr: false })
                                                    }}
                                                />{' '}
                                                {updateSocials?.discordErr && <Alblur>{updateSocials?.discordErr}</Alblur>}
                                            </Blocked>
                                        </Column2>
                                        <Column isDarkTheme={isDarkTheme} gap={'0.3rem'}>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    width="100"
                                                    type="text"
                                                    value={updateSocials.twitter.val}
                                                    placeholder="https://twitter.com"
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, twitter: { ...updateSocials.twitter, val: e.target.value }, twitterErr: false })
                                                    }}
                                                />
                                                {updateSocials?.twitterErr && <Alblur>{updateSocials?.twitterErr}</Alblur>}
                                            </Blocked>
                                            <Blocked>
                                                <InputText
                                                    isDarkTheme={isDarkTheme}
                                                    width="100"
                                                    type="text"
                                                    value={updateSocials.telegram.val}
                                                    placeholder="https://telegram.com"
                                                    onChange={(e) => {
                                                        setUpdateSocials({ ...updateSocials, telegram: { ...updateSocials.telegram, val: e.target.value }, telegramErr: false })
                                                    }}
                                                />
                                                {updateSocials?.telegramErr && <Alblur>{updateSocials?.telegramErr}</Alblur>}
                                            </Blocked>
                                        </Column>
                                        <List isDarkTheme={isDarkTheme} justifyContent="center">
                                            <SecondButtonContainer sm={6} md={4} lg={4} xl={4} xxl={3}>
                                                <Button
                                                    isDarkTheme={isDarkTheme}
                                                    label={'Update Airdrop'}
                                                    // width={'100%'}
                                                    disabled={isUpdating}
                                                    ifClicked={(e) => {
                                                        doUpdateAirdrop()
                                                    }}
                                                />
                                            </SecondButtonContainer>
                                        </List>
                                    </Form>
                                )}

                                {airdropInfo.isPrivate && (
                                    <Form isDarkTheme={isDarkTheme}>
                                        <Row>
                                            {isDeveloper && !isAirdropReleased ? (
                                                <AddressColumn isDarkTheme={isDarkTheme} start={true}>
                                                    <ListTab isDarkTheme={isDarkTheme} onClick={() => setWhitelistTab(1)} $active={whitelistTab === 1} disabled={whitelistSpinner}>
                                                        White List
                                                    </ListTab>
                                                    <ListTab isDarkTheme={isDarkTheme} onClick={() => setWhitelistTab(2)} $active={whitelistTab === 2} disabled={whitelistSpinner}>
                                                        Add White List
                                                    </ListTab>
                                                    <ListTab isDarkTheme={isDarkTheme} onClick={() => setWhitelistTab(3)} $active={whitelistTab === 3} disabled={whitelistSpinner}>
                                                        Remove White List
                                                    </ListTab>
                                                </AddressColumn>
                                            ) : (
                                                <AddressColumn isDarkTheme={isDarkTheme}>
                                                    <ListTab isDarkTheme={isDarkTheme} $active={true}>
                                                        White List
                                                    </ListTab>
                                                </AddressColumn>
                                            )}
                                            {whitelist?.length > 0
                                                ? whitelistTab !== 2 &&
                                                whitelist?.map((_item, i) => (
                                                    <AddressColumn isDarkTheme={isDarkTheme}>
                                                        <DetailLabel width={'100%'} isDarkTheme={isDarkTheme}>
                                                            {_item?.address + ':' + _item?.value}
                                                        </DetailLabel>
                                                        {isDeveloper && whitelistTab != 1 && <CloseIcon onClick={() => removeWhiteListAddress(_item?.address)} disabled={whitelistSpinner} />}
                                                    </AddressColumn>
                                                ))
                                                : !isDeveloper && (
                                                    <AddressColumn isDarkTheme={isDarkTheme}>
                                                        <DetailLabel width={'100%'} isDarkTheme={isDarkTheme}>
                                                            No white list addresses
                                                        </DetailLabel>
                                                    </AddressColumn>
                                                )}
                                            {isDeveloper && whitelistTab === 2 && (
                                                <>
                                                    {addedWhitelistAddresses?.map((_address) => (
                                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                                            <DetailLabel width={'100%'} isDarkTheme={isDarkTheme}>
                                                                {_address?.address + ':' + _address?.value}
                                                            </DetailLabel>
                                                            {isDeveloper && <CloseIcon onClick={() => removeWhiteListAddress(_address)} />}
                                                        </AddressColumn>
                                                    ))}
                                                    <AddressColumn isDarkTheme={isDarkTheme}>
                                                        <SmallInput
                                                            border={'none'}
                                                            isDarkTheme={isDarkTheme}
                                                            value={newWhitelistInput}
                                                            placeholder={(user != null ? user?.address : '0x0000000000000000000000000000000000000000') + ':100'}
                                                            autoFocus
                                                            onChange={(e) => {
                                                                addWhiteListAddress(e.target.value.toLowerCase(), null)
                                                                setWhitelistInputError(false)
                                                            }}
                                                            onKeyDown={(e) => addWhiteListAddress(newWhitelistInput, e?.key)}
                                                        />
                                                    </AddressColumn>
                                                    {whitelistInputError && (
                                                        <AddressColumn isDarkTheme={isDarkTheme}>
                                                            <Alblur>{whitelistInputError}</Alblur>
                                                        </AddressColumn>
                                                    )}
                                                </>
                                            )}
                                        </Row>
                                        {isDeveloper && whitelistTab !== 1 && (
                                            <Row justifyContent="center">
                                                <Button active isDarkTheme={isDarkTheme} margin={'1rem 0 0 0'} label={whitelistSpinner ? 'Please wait ...' : whitelistTab === 2 ? 'Add' : whitelistTab === 3 && 'Update'} disabled={whitelistSpinner} ifClicked={handleBlockchainWhiteList} />
                                            </Row>
                                        )}
                                    </Form>
                                )}
                                <Disqussion isDarkTheme={isDarkTheme} title={'Airdrop discussion'} shareUrl={shareUrl} />
                            </Col>
                            <Col lg={12} xl={5} xxl={4}>
                                {isAirdropInfoFetched && !isAirdropClosed && (
                                    <Form isDarkTheme={isDarkTheme}>
                                        <Row justifyContent="center">
                                            <ColAlign isDarkTheme={isDarkTheme}>Remaining time for airdrop release</ColAlign>
                                            <Col>
                                                <CountdownTimer targetDate={closingTime} />
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                                {/* {(!airdropInfo.isPrivate || whitelist?.some((e) => e?.address?.toLowerCase() == user?.address?.toLowerCase())) && isAirdropInfoFetched && !isDeveloper && !isAirdropClosed && !meParticipated && ( */}
                                {!airdropInfo.isPrivate && isAirdropInfoFetched && !isDeveloper && !isAirdropClosed && !meParticipated && (
                                    <Form isDarkTheme={isDarkTheme}>
                                        <Row justifyContent="center">
                                            <Col>
                                                <Button
                                                    active
                                                    isDarkTheme={isDarkTheme}
                                                    width={'100% !important'}
                                                    label={'Participate'}
                                                    disabled={isParticipating}
                                                    ifClicked={(e) => {
                                                        participateInAirdrop()
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                                <Form isDarkTheme={isDarkTheme}>
                                    <Row>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Type</DetailLabel>
                                            <Content color={airdropInfo.isPrivate ? 'theme_Text' : 'aqua_blue'}>{airdropInfo.isPrivate ? 'Private ' : 'Public'} Airdrop</Content>
                                        </Column>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Airdrop Status</DetailLabel>
                                            {isAirdropInfoFetched ? (
                                                <StatusContent isDarkTheme={isDarkTheme} status={isAirdropClosed ? 'failed' : ''}>
                                                    {isAirdropClosed ? 'Closed' : 'In Progress'}
                                                </StatusContent>
                                            ) : (
                                                <TextPlaceHolder />
                                            )}
                                        </Column>
                                        <Column isDarkTheme={isDarkTheme}>
                                            <DetailLabel isDarkTheme={isDarkTheme}>Network</DetailLabel>
                                            <NetworkContent isDarkTheme={isDarkTheme} chainId={airdropNetwork.chainId}>
                                                {airdropNetwork?.title}
                                            </NetworkContent>
                                        </Column>
                                        {airdropInfo.isPrivate ? (
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>Your Tokens</DetailLabel>
                                                <Content isDarkTheme={isDarkTheme}>
                                                    {participantCount} {airdropDbInfo?.tokenSymbol}
                                                </Content>
                                            </Column>
                                        ) : (
                                            <Column isDarkTheme={isDarkTheme}>
                                                <DetailLabel isDarkTheme={isDarkTheme}>You Participated</DetailLabel>
                                                {isAirdropInfoFetched ? <Content isDarkTheme={isDarkTheme}>{meParticipated ? 'Yes' : 'No'}</Content> : <TextPlaceHolder />}
                                            </Column>
                                        )}
                                    </Row>
                                </Form>
                                <Form isDarkTheme={isDarkTheme}>
                                    <Row justifyContent="center">{isAirdropInfoFetched ? <Chart width={'350px'} height={'320px'} chartType="PieChart" data={pieData} options={isDarkTheme ? pieOptions : pieOptions} rootProps={{ 'data-testid': '3' }} /> : <TextPlaceHolder />}</Row>
                                </Form>
                            </Col>
                        </MainRow>
                        <Spacer />
                    </>
                )}
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Container)`
    // padding: 0rem 3rem 0rem 3rem;
`

const MainRow = styled(Row)`
    ${media.lg`margin-left:-5px`};
    ${media.xl`margin-left:-5px`};
    ${media.xxl`margin-left:-15px`};
`

const Form = styled(Container)`
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 1rem;
    position: relative;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`

const ProfileContent = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    ${media.xs`
      display: block;
    `};
    ${media.sm`
      display: flex;
    `};
`

const Column = styled(Col)`
    justify-content: ${({ start }) => (start ? 'start' : 'space-between')};
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    // cursor: pointer;
    border-bottom: ${({ isDarkTheme, hideBottomLine }) => (hideBottomLine ? `` : ` 0.063rem solid ${palette.pure_black}`)};
    gap: ${({ gap }) => (gap ? `${gap}` : '')};
`

const AddressColumn = styled(Column)`
    ${media.xs`
      display: block;
    `};
    ${media.sm`
      display: flex;
    `};
`

const List = styled(Row)`
    border-bottom: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_dark}` : `${palette.gray}`)};
`

const SecondButtonContainer = styled(Col)`
    margin-top: 0.5rem;
    justify-content: center;
    margin-bottom: 0rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
`

const DescriptionContent = styled.div`
    display: flex;
    align-items: center;
    & a {
        margin-left: 0.5rem;
    }
`

const Flexeding = styled(Column)`
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-bottom: 0rem;
`

const Spacer = styled.div`
    height: 0.75rem;
`

const InputText = styled.input`
    outline: none;
    height: 2.3rem;
    width: ${({ width }) => (width ? `${width}%` : '100%')};
    font-size: 0.9rem;
    padding: 0.4rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    border-radius: 0.3rem;
    box-sizing: border-box;
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const ColAlign = styled(Col)`
    text-align: center;
    margin-bottom: 1rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
`

const Link = styled.a`
    cursor: pointer;
`

const EditDescription = styled.textarea`
    outline: none;
    width: ${({ width }) => (width ? `${width}%` : '100%')};
    font-size: 0.9rem;
    margin: 0 0 0 1rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    // background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    background: transparent;
    border: 0.09rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    padding: 0.2rem;
    border-radius: 0.3rem;
    box-sizing: border-box;
    & input {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)};
    }
    & > div > button > svg {
        stroke: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.pure_black}`)} !important;
    }
    & div {
        border: 0rem !important;
    }
    ::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        opacity: 1; /* Firefox */
        font-size: 0.9rem;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
        font-size: 0.9rem;
    }
`

const Column2 = styled(Column)`
    border-bottom: none;
    padding-bottom: 0;
`

const CloseIcon = styled(AiOutlineCloseCircle)`
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    cursor: pointer;
    margin: 0.2rem 1rem 0 0;
`

const SmallInput = styled(InputText)`
    border: none;
`

const LeftContent = styled(Content)`
    text-align: left;
`

const ListTab = styled.button`
    background: transparent !important;
    border: none !important;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 0.9rem;
    font-weight:bold;
    margin: 0 1.5rem 0 0;
    padding: 0.5rem 0;
    cursor: pointer !important;
    border-bottom: ${({ $active, isDarkTheme }) => ($active ? (isDarkTheme ? `0.2rem solid ${palette.aqua_blue} !important` : `0.2rem solid ${palette.blue}`) : ``)};
    color ${({ $active, isDarkTheme }) => (isDarkTheme ? ($active ? `${palette.aqua_blue}` : `${palette.off_white}`) : $active ? `${palette.blue}` : `${palette.pure_black}`)} !important;
`

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AirdropDetails)
