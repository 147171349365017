import React from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../assets/common'
import {Link, useNavigate} from 'react-router-dom'
import Button from '../components/Button'
import {useSelector} from 'react-redux'
import {Spacer, Text, Flexed} from '../styles/shared'
import {Heading} from '../styles/shared'
const Services = () => {
    let _navigate = useNavigate()
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    return (
        <Container>
            <Spacer height="2" />
            <CustomRow>
                <Col lg={11} xl={9} xxl={8}>
                    <Heading isCentered margin={'0rem 0rem 0.5rem 0rem'} isDarkTheme={isDarkTheme}>
                        What we provide
                    </Heading>
                    <Content isDarkTheme={isDarkTheme}>Decentralized system is build to help you create your own tokens and presales without any hassle & without any prior code knowledge.</Content>
                </Col>
            </CustomRow>
            <Spacer height="4" />
            <CustomRow>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card isDarkTheme={isDarkTheme}>
                        <CardHeading isDarkTheme={isDarkTheme}>Presales</CardHeading>
                        <Content xsmall isDarkTheme={isDarkTheme} isCentered>
                            Use the token you mint to create a Presale with just a few clicks
                        </Content>
                    </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card isDarkTheme={isDarkTheme}>
                        <CardHeading isDarkTheme={isDarkTheme}>3 Networks</CardHeading>
                        <Content xsmall isDarkTheme={isDarkTheme} isCentered>
                            Mint standard tokens on ETH, BSC, Matic
                        </Content>
                    </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card isDarkTheme={isDarkTheme}>
                        <CardHeading isDarkTheme={isDarkTheme}>Customization</CardHeading>
                        <Content xsmall isDarkTheme={isDarkTheme} isCentered>
                            Create a token sale for your own custom token easily
                        </Content>
                    </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card isDarkTheme={isDarkTheme}>
                        <CardHeading isDarkTheme={isDarkTheme}>Fixed Price</CardHeading>
                        <Content xsmall isDarkTheme={isDarkTheme} isCentered>
                            Create your presale with fixed price. No more % any more!
                        </Content>
                    </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card isDarkTheme={isDarkTheme}>
                        <CardHeading isDarkTheme={isDarkTheme}>Locking</CardHeading>
                        <Content small isDarkTheme={isDarkTheme} isCentered>
                            Lock your liquidity to PinkSwap, PancakeSwap, QuickSwap after presale
                        </Content>
                    </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card isDarkTheme={isDarkTheme}>
                        <CardHeading isDarkTheme={isDarkTheme}>Branding</CardHeading>
                        <Content small isDarkTheme={isDarkTheme} isCentered>
                            Adding logo, social links, description, and listing
                        </Content>
                    </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card isDarkTheme={isDarkTheme}>
                        <CardHeading isDarkTheme={isDarkTheme}>Management</CardHeading>
                        <Content small isDarkTheme={isDarkTheme} isCentered>
                            The portal to help you easily update content for your presales
                        </Content>
                    </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card isDarkTheme={isDarkTheme}>
                        <CardHeading isDarkTheme={isDarkTheme}>Community</CardHeading>
                        <Content small isDarkTheme={isDarkTheme} isCentered>
                            Fastly growing
                        </Content>
                    </Card>
                </Col>
            </CustomRow>
        </Container>
    )
}
const CustomRow = styled(Row)`
    justify-content: center;
`
const Flex = styled(Flexed)`
    flex-wrap: wrap;
`
const Card = styled.div`
    padding: 1rem;
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    border-radius: 0.5rem;
    // border: none;
    font-size: 1rem;
    width: 100%;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    min-height: 10rem;
    margin-bottom: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    &:hover {
        box-shadow: ${({isDarkTheme}) => (isDarkTheme ? `0 0 4px ${palette.dark}, 0 0 7px ${palette.dark_gray}` : `0 0 1px rgb(0 0 0 / 17%), 0 8px 8px rgb(0 0 0 / 8%), 0 10px 12px rgb(0 0 0 / 0%), 0 14px 16px rgb(0 0 0 / 2%)`)};
    }
`
const CardHeading = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
`
const Content = styled.p`
    font-size: 0.9rem;
    // line-height: 0.5;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.off_dark}`)};
    text-align: center;
`
export default Services
