export const PresaleContract = {
    id: '',
    abi: [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_FactoryAddress',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_DevAddress',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'string',
                    name: 'status',
                    type: 'string'
                }
            ],
            name: 'collectFunds',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'string',
                    name: 'investStatus',
                    type: 'string'
                }
            ],
            name: 'invested',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'string',
                    name: 'liquidityStatus',
                    type: 'string'
                }
            ],
            name: 'liquidityAdded',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'string',
                    name: 'refundStatus',
                    type: 'string'
                }
            ],
            name: 'refunded',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'string',
                    name: 'claimStatus',
                    type: 'string'
                }
            ],
            name: 'tokenClaim',
            type: 'event'
        },
        {
            inputs: [],
            name: 'DevAddress',
            outputs: [
                {
                    internalType: 'address payable',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'DevFeesExempted',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'Id',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'LiqLockAddress',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'addLiquidityAndLockLPTokens',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'checkStatus',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'claimTokens',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'claimed',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'closeTime',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'collectFundsRaised',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_openTime',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_closeTime',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_maxInvestInWei',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_minInvestInWei',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_uniLiquidityAddingTime',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_uniLPTokensLockDurationInDays',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_uniLiquidityPercentageAllocation',
                    type: 'uint256'
                }
            ],
            name: 'editInfoPresaleDev',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'fixedPresale',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getRefund',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'hardCapInWei',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'invest',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            name: 'investments',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'maxInvestInWei',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'minInvestInWei',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'openTime',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'presaleCancelled',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'presaleCreatorAddress',
            outputs: [
                {
                    internalType: 'address payable',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'presaleCreatorClaimTime',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'presaleCreatorClaimWei',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_presaleCreator',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_tokenAddress',
                    type: 'address'
                }
            ],
            name: 'setAddressInfo',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_totalTokens',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_decimals',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_totalTokensinPool',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_tokenPriceInWei',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_hardCapInWei',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_softCapInWei',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_maxInvestInWei',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_minInvestInWei',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_openTime',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_closeTime',
                    type: 'uint256'
                },
                {
                    internalType: 'bool',
                    name: '_fixedPresale',
                    type: 'bool'
                }
            ],
            name: 'setGeneralInfo',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_LiqLockAddress',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: '_Id',
                    type: 'uint256'
                }
            ],
            name: 'setInfo',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_uniListingPriceInWei',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_uniLiquidityAddingTime',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_uniLPTokensLockDurationInDays',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: '_uniLiquidityPercentageAllocation',
                    type: 'uint256'
                }
            ],
            name: 'setUniswapInfo',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'softCapInWei',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'token',
            outputs: [
                {
                    internalType: 'contract IERC20',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'tokenPriceInWei',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'tokensLeft',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalCollectedWei',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalInvestorsCount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalTokens',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'uniLPTokensLockDurationInDays',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'uniLiquidityAdded',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'uniLiquidityAddingTime',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'uniLiquidityPercentageAllocation',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'uniListingPriceInWei',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            stateMutability: 'payable',
            type: 'receive'
        }
    ]
}
