import React, {useState, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../assets/common'
import {Label, Info, Alblur} from '../../styles/shared'
import styled from 'styled-components'
import {Spacer, InputText, Flexed, Heading, LoadingPanelContent, LoadingPanel, InfoIcon} from '../../styles/shared'
import Button from '../Button'
import {useDispatch, useSelector} from 'react-redux'
import {openMainPage} from '../../actions/authActions'
import Select from '../Select'
import {saveUser} from '../../actions/authActions'
import axios from 'axios'
import {api} from '../../config/index'
import {connect} from 'react-redux'
import MyTokenDetails from '../MyTokenDetails'
import NetworksDropdown from '../NetworksDropdown'
import {DocumentLinks, showConnectionError, showError, showSuccess, preventNegativeValues} from '../../utils/index'
import {string_regex} from '../../utils/regex'
import Modal from 'react-responsive-modal'

const CreateTokenModal = ({user, open, onCloseModal, setTokenAddress, setTokenDetails}) => {
    const isMianPageOpen = useSelector((state) => state.auth.openMainParentPage)
    const tokenStandardFactoryContract = useSelector((state) => state.auth.tokenStandardFactoryContract)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const connectedNetwork = useSelector((state) => state.auth.connectedNetwork)

    const [loading, setLoading] = useState(false)
    const [tokenType, setTokenType] = useState('Standard')
    const [name, setName] = useState('')
    const [symbol, setSymbol] = useState('')
    const [decimals, setDecimals] = useState('')
    const [supply, setSupply] = useState('')

    const [yieldTransactionFee, setYieldTransactionFee] = useState('')
    const [liquidityTransactionFee, setLiquidityTransactionFee] = useState('')

    const [nameError, setNameError] = useState(false)
    const [symbolError, setSymbolError] = useState(false)
    const [decimalsError, setDecimalsError] = useState(false)
    const [supplyError, setSupplyError] = useState(false)

    const [yieldTransactionFeeError, setYieldTransactionFeeError] = useState(false)
    const [liquidityTransactionFeeError, setLiquidityTransactionFeeError] = useState(false)

    useEffect(() => {
        if (!isMianPageOpen) {
            setLoading(false)
            setTokenType('Standard')
            setName('')
            setSymbol('')
            setDecimals('')
            setSupply('')
        }
    }, [isMianPageOpen])

    const validation = async () => {
        let _isValid = true
        if (name.trim().length < 2) {
            _isValid = false
            setNameError('Token name must contains at least 2 characters')
        } else {
            setNameError(false)
        }

        if (symbol.trim().length < 3 || symbol.trim().length > 5 || !string_regex.test(symbol)) {
            _isValid = false
            setSymbolError('Token symbol must be between 3 and 5 characters')
        } else {
            setSymbolError(false)
        }

        if (supply.trim() == '') {
            _isValid = false
            setSupplyError('Total supply is required')
        } else if (tokenType != 'Liquidity' && supply < 1) {
            _isValid = false
            setSupplyError('Minimum supply is 1 token')
        } else if (tokenType === 'Liquidity' && supply < 1000000000) {
            _isValid = false
            setSupplyError('Minimum supply is 1000000000 tokens')
        } else {
            setSupplyError(false)
        }

        if (decimals.toString().includes('.')) {
            _isValid = false
            setDecimalsError('Token decimal can not be a fractional number')
        } else if (decimals.trim() > 64) {
            _isValid = false
            setDecimalsError('Token decimal must be between 2 and 64')
        } else if (decimals.trim() < 2) {
            _isValid = false
            setDecimalsError('Token decimal must be between 2 and 64')
        } else {
            setDecimalsError(false)
        }
        return _isValid
    }

    const createNewToken = async () => {
        if (!tokenStandardFactoryContract) {
            showConnectionError()
            return false
        }
        const isValidRequest = await validation()
        if (isValidRequest) {
            setLoading(true)
            let tokenTuple = {
                name: name,
                symbol: symbol,
                decimals: decimals,
                quantity: supply
            }
            try {
                const createTokenTx = await tokenStandardFactoryContract.createToken(tokenTuple)
                const response = await createTokenTx.wait()
                const tokenAddress = response.events[0].address
                tokenTuple.tokenAddress = tokenAddress
                tokenTuple.type = tokenType
                if (response) {
                    axios
                        .post(
                            `${api}/tokens/add`,
                            {
                                userAddress: user.address,
                                tokenAddress: tokenAddress,
                                type: tokenType,
                                title: name,
                                chainId: connectedNetwork.chainId
                            },
                            {
                                headers: {
                                    authorization: `bearer ${user.token}`
                                }
                            }
                        )
                        .then((response) => {
                            setLoading(false)
                            if (response.data.status) {
                                setTokenAddress(tokenAddress?.toString()?.toLowerCase())
                                // user.tokens.push(response.data.data)
                                showSuccess('Standard token created successfully')
                                // tokenTuple = {...tokenTuple, chainId: connectedNetwork?.chainId}
                                setTokenDetails(tokenTuple)
                                // _dispatch(openMainPage(false))
                                onCloseModal()
                            }
                        })
                        .catch(function (error) {
                            setLoading(false)
                            showError(error.message)
                        })
                } else {
                    showError('Token creation failed')
                }
            } catch (error) {
                console.log(error)
                setLoading(false)
                showError(error.reason)
            }
        } else {
            setLoading(false)
            return false
        }
    }

    return (
        <Modal
            open={open}
            center
            // closeIcon={closeIcon}
            classNames={{
                overlay: 'customOverlay',
                modal: isDarkTheme ? 'PopupModalDark' : 'PopupModalWhite'
            }}
            onClose={onCloseModal}
            // closeOnOverlayClick={false}
            showCloseIcon={false}>
            <Wrapper>
                <Row>
                    <Container>
                        <Row>
                            <Col lg={6} md={6} sm={6} xs={8}>
                                <Heading margin="0rem 0rem 1.25rem 0rem" isDarkTheme={isDarkTheme}>
                                    Create Token
                                </Heading>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={4}>
                                <Flexed direction="row" justify="flex-end">
                                    <a target="_blank" href={DocumentLinks('createToken')}>
                                        <InfoIconAlign isDarkTheme={isDarkTheme} />
                                    </a>
                                </Flexed>
                            </Col>
                        </Row>
                        <Col>
                            <Form isDarkTheme={isDarkTheme}>
                                <Col md={6} lg={6}>
                                    <Label isDarkTheme={isDarkTheme}>
                                        Name <span>*</span>
                                    </Label>
                                    <InputText
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                        isDarkTheme={isDarkTheme}
                                        type="text"
                                        placeholder=""
                                    />
                                    {nameError ? <Alblur>{nameError}</Alblur> : ''}
                                    <Spacer height={1} />
                                </Col>
                                <Col md={6} lg={6}>
                                    <Label isDarkTheme={isDarkTheme}>
                                        Symbol <span>*</span>
                                    </Label>
                                    <InputText
                                        value={symbol}
                                        onChange={(e) => {
                                            setSymbol(e.target.value)
                                        }}
                                        isDarkTheme={isDarkTheme}
                                        type="text"
                                        placeholder=""
                                    />
                                    {symbolError && <Alblur>{symbolError}</Alblur>}
                                    <Spacer height={1} />
                                </Col>
                                {tokenType === 'Standard' && (
                                    <Col md={6} lg={6}>
                                        <Label isDarkTheme={isDarkTheme}>
                                            Decimals <span>*</span>
                                        </Label>
                                        <InputText
                                            value={decimals}
                                            onChange={(e) => {
                                                if (!e.target.value.toString().includes('.')) {
                                                    setDecimals(e.target.value)
                                                    setDecimalsError(false)
                                                }
                                            }}
                                            onKeyDown={preventNegativeValues}
                                            isDarkTheme={isDarkTheme}
                                            type="number"
                                            min="0"
                                            placeholder="18"
                                        />
                                        {decimalsError ? <Alblur>{decimalsError}</Alblur> : ''}
                                        <Spacer height={1} />
                                    </Col>
                                )}
                                <Col md={6} lg={6}>
                                    <Label isDarkTheme={isDarkTheme}>
                                        Total Supply <span>*</span>
                                    </Label>
                                    <InputText
                                        value={supply}
                                        onChange={(e) => {
                                            setSupply(e.target.value)
                                        }}
                                        onKeyDown={preventNegativeValues}
                                        isDarkTheme={isDarkTheme}
                                        type="number"
                                        min="0"
                                        placeholder="0"
                                    />
                                    {supplyError ? <Alblur>{supplyError}</Alblur> : ''}
                                    <Spacer height={1} />
                                </Col>
                                {tokenType === 'Liquidity' && (
                                    <>
                                        <Col md={6} lg={6}>
                                            <Label isDarkTheme={isDarkTheme}>Transaction fee to generate yield (%)</Label>
                                            <InputText
                                                value={yieldTransactionFee}
                                                onChange={(e) => {
                                                    setYieldTransactionFee(e.target.value)
                                                }}
                                                onKeyDown={preventNegativeValues}
                                                isDarkTheme={isDarkTheme}
                                                type="number"
                                                min="0"
                                                placeholder="0"
                                            />
                                            {yieldTransactionFeeError && <Alblur>{yieldTransactionFeeError}</Alblur>}
                                            <Spacer height={1} />
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Label isDarkTheme={isDarkTheme}>Transaction fee to generate liquidity (%)</Label>
                                            <InputText
                                                value={liquidityTransactionFee}
                                                onChange={(e) => {
                                                    setLiquidityTransactionFee(e.target.value)
                                                }}
                                                onKeyDown={preventNegativeValues}
                                                isDarkTheme={isDarkTheme}
                                                type="number"
                                                min="0"
                                                placeholder="0"
                                            />
                                            {liquidityTransactionFeeError && <Alblur>{liquidityTransactionFeeError}</Alblur>}
                                            <Spacer height={1} />
                                        </Col>
                                    </>
                                )}
                                <NetworksDropdown />
                                <ActionContent>
                                    <Spacer height={1.5} />
                                    <FlexedButtons direction="row">
                                        <Button
                                            isDarkTheme={isDarkTheme}
                                            label={'Create Token'}
                                            ifClicked={() => {
                                                createNewToken()
                                            }}
                                        />
                                    </FlexedButtons>
                                    <FlexedButtons direction="row">
                                        <Button
                                            margin={'1rem 1rem 0 1rem'}
                                            isDarkTheme={isDarkTheme}
                                            label={'Close'}
                                            ifClicked={() => {
                                                onCloseModal()
                                            }}
                                        />
                                    </FlexedButtons>
                                </ActionContent>
                            </Form>
                        </Col>
                    </Container>
                </Row>
            </Wrapper>
            {loading && (
                <LoadingPanelContent>
                    <LoadingPanel isDarkTheme={isDarkTheme} src="/images/Preloader.gif" />
                </LoadingPanelContent>
            )}
        </Modal>
    )
}

const Wrapper = styled(Container)`
    padding-top: 2rem;
    ${media.lg`
width: 90%;
`};
    ${media.xl`
width: 90%;
`};
    ${media.xs`
padding-top: 0rem;
`}
    ${media.sm`
padding-top: 2rem;
`}
`

const Form = styled(Row)`
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    ${media.xs`
    padding:  1.5rem 1rem;
    `};
    ${media.sm`
    padding: 1.5rem 1rem;
    `};
    ${media.md`
    padding: 2rem;
    `};
`

const ActionContent = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    ${media.xs`
        display: block !important;
        flex-direction: row;
    `};
    ${media.sm`
        display: block !important;
        flex-direction: row;
    `};
    ${media.md`
        display: block !important;
        flex-direction: row;
    `};
`

const FlexedButtons = styled(Flexed)`
    justify-content: center;
`

const InfoIconAlign = styled(InfoIcon)`
    right: 0.938rem;
    ${media.xs`right:0rem;`}
    ${media.sm`right:0.938rem;`}
`

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTokenModal)
