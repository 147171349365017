import Disqus from 'disqus-react'
import {Heading, Form} from '../styles/shared'
import {useEffect} from 'react'
import {useState} from 'react'

const disqusShortname = 'site-v6mkrmblnu'

export const Disqussion = (props) => {
    let [config, setConfig] = useState(null)
    useEffect(() => {
        const disqusConfig = {
            url: props?.shareUrl,
            identifier: props?.shareUrl + '_0',
            title: props?.shareUrl + '_title',
            sso: {
                width: '400',
                height: '200'
            }
        }
        setConfig(disqusConfig)
    }, [props])

    return (
        <Form isDarkTheme={props?.isDarkTheme}>
            <div className="article-container">
                <Disqus.DiscussionEmbed shortname={disqusShortname} config={config !== null ? config : ''} />
            </div>
        </Form>
    )
}
