import {NetworksTypes} from '../utils'

export const AirdropPrivateFactoryContract = {
    contractId: (chainId) => {
        if (chainId == NetworksTypes.EthereumMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.EthereumTestNet.chainId) {
            return '0xEfF080BcAA086c188fa3f60c9569c727740235a2'
        } else if (chainId == NetworksTypes.BinanceMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.BinanceTestNet.chainId) {
            return '0x7c833C64c56a4c509bBcDA6747B8470d5A8cf27c'
        } else if (chainId == NetworksTypes.PolygonMainNet.chainId) {
            return ''
        } else if (chainId == NetworksTypes.PolygonTestNet.chainId) {
            return '0x243FCAC7284CeB21f85152a9EED24D1270E0976a'
        }
    },
    abi: [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnershipTransferred',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'airdropCreator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'airdropAddress',
                    type: 'address'
                }
            ],
            name: 'airdropCreated',
            type: 'event'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenAddress',
                            type: 'address'
                        },
                        {
                            internalType: 'address[]',
                            name: 'users',
                            type: 'address[]'
                        },
                        {
                            internalType: 'uint256[]',
                            name: 'userQuantity',
                            type: 'uint256[]'
                        },
                        {
                            internalType: 'uint256',
                            name: 'quantity',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'distributionTimeUnix',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct SSSairdropfactory.airdropinfo',
                    name: '_airdropinfoo',
                    type: 'tuple'
                }
            ],
            name: 'createAirdrop',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'Id',
                    type: 'uint256'
                }
            ],
            name: 'getairdropAddress',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getairdropCount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
}
