import React, {useEffect, useState} from 'react'
import {palette} from '../assets/common'
import styled from 'styled-components'
import {Col} from 'styled-bootstrap-grid'
import Button from './Button'
import CountdownTimer from './CountdownTimer'
import {useSelector, useDispatch} from 'react-redux'
import {media} from 'styled-bootstrap-grid'
import {openMainPage} from '../actions/authActions'
import {showError, getJsonRpcProvider, getNetworkById} from '../utils/index'
import {AirdropContract} from '../contracts/AirdropContract'
import {AirdropPrivateContract} from '../contracts/AirdropPrivateContract'
import {ethers} from 'ethers'
import moment from 'moment'
import {TextPlaceHolder, Flexed} from '../styles/shared'
import {useCountdown} from '../hooks'
import {Copy} from './CopyTooltip'

const AirdropList = ({id, _value, setAirdropInfo, updateAirdrop}) => {
    const _dispatch = useDispatch()
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const [distributionTime, setDistributionTime] = useState(null)
    const [isAirdropClosed, setAirdropClosed] = useState(null)

    const [value, setValue] = useState({
        chainId: _value.chainId,
        airdropAddress: _value.airdropAddress,
        userAddress: _value.userAddress,
        tokenAddress: _value.tokenAddress,
        title: _value.title,
        isAirdropClosed: _value?.isClosed,
        isPrivate: _value?.isPrivate,
        distributionTime: _value?.distributionTime,
        isDataLoaded: _value?.isDataLoaded,
        airdropNetwork: _value?.airdropNetwork,
        logoUrl: _value?.logoUrl
    })

    useEffect(async () => {
        if (!value?.isDataLoaded) {
            await fetchAirdropDetails(value)
        } else {
            setAirdropClosed(value?.isAirdropClosed)
            setDistributionTime(value?.distributionTime)
        }
    }, [value])

    const fetchAirdropDetails = async (airdropData) => {
        const provider = await getJsonRpcProvider(airdropData.chainId)
        try {
            airdropData.airdropNetwork = await getNetworkById(airdropData.chainId)
            let aidropContract = null
            if (airdropData.isPrivate) {
                aidropContract = new ethers.Contract(airdropData.airdropAddress, AirdropPrivateContract.abi, provider)
            } else {
                aidropContract = new ethers.Contract(airdropData.airdropAddress, AirdropContract.abi, provider)
            }

            const _isClosed = await aidropContract.checkStatus()
            airdropData.isClosed = _isClosed
            setAirdropClosed(_isClosed)

            // airdropData.distributionTime = await aidropContract.distributionTime()
            // airdropData.distributionTime = parseInt(airdropData.distributionTime + '')
            setDistributionTime(airdropData.distributionTime)

            airdropData.isDataLoaded = true
            setValue(airdropData)
            updateAirdrop(airdropData)
        } catch (error) {
            console.log(error)
        }
    }

    const updateSale = async () => {
        setAirdropClosed(value?.distributionTime < moment().unix())
    }

    return (
        <CustomCol id={id} key={id} xxl={4} xl={6} lg={6} md={6}>
            <Card isDarkTheme={isDarkTheme} disabled={distributionTime ? false : true} lg={12}>
                <Profile>
                    <ProfileBlur src={value?.logoUrl ? value?.logoUrl : `/images/economicsale.png`} />
                    <Flexed justify="center" align="center">
                        <Name isDarkTheme={isDarkTheme}>{value?.title}</Name>
                        <ProfileContent>
                            <ProfileImage isDarkTheme={isDarkTheme} src={value?.logoUrl} />
                            {value?.airdropNetwork?.icon ? <NetworkIcon src={value?.airdropNetwork?.icon} /> : ''}
                        </ProfileContent>
                    </Flexed>
                    <Content isDarkTheme={isDarkTheme} directionColumn>
                        <CoverLabel isDarkTheme={isDarkTheme}>Airdrop Address:</CoverLabel>
                        {value.airdropNetwork ? (
                            <Flexed direction="row" align="center">
                                <AddressText isDarkTheme={isDarkTheme} href={`${value.airdropNetwork?.networkUrl}/${value?.airdropAddress}`} target="_blank">
                                    {value.airdropAddress}
                                </AddressText>
                                <Copy text={`${value.airdropNetwork?.networkUrl}/${value?.airdropAddress}`} isDarkTheme={isDarkTheme} />
                            </Flexed>
                        ) : (
                            <TextPlaceHolder />
                        )}
                    </Content>
                </Profile>
                <CardBody>
                    <BlockItems>
                        {value?.isPrivate == true ? <Status status={'private'}>Private Airdrop</Status> : <Status status={'fair'}>Public Airdrop</Status>}
                        {distributionTime ? (
                            <Status shape={value?.isPrivate == true || value?.isPrivate == false} status={isAirdropClosed ? 'ended' : 'live'}>
                                Airdrop {isAirdropClosed ? 'Ended' : 'Live'}
                            </Status>
                        ) : (
                            <TextPlaceHolder height={'1.5rem'} width={'6rem'} />
                        )}
                    </BlockItems>
                    <Content isDarkTheme={isDarkTheme} directionColumn>
                        <Label isDarkTheme={isDarkTheme}>Token Address:</Label>
                        {value.airdropNetwork ? (
                            <Flexed direction="row" align="center">
                                <AddressText isDarkTheme={isDarkTheme} href={`${value.airdropNetwork?.networkUrl}/${value?.tokenAddress}`} target="_blank">
                                    {value.tokenAddress}
                                </AddressText>
                                <Copy text={`${value.airdropNetwork?.networkUrl}/${value?.tokenAddress}`} isDarkTheme={isDarkTheme} />
                            </Flexed>
                        ) : (
                            <TextPlaceHolder />
                        )}
                    </Content>
                    <Flex>
                        <PreSaleStatus>
                            {/* {distributionTime ? ( */}
                            <>
                                {isAirdropClosed == false && (
                                    <>
                                        <Text isCentered isDarkTheme={isDarkTheme}>
                                            Airdrop Ends In:
                                        </Text>
                                        <div id="minitimer">
                                            <CountdownTimer targetDate={value?.distributionTime * 1000} updateList={updateSale} />
                                        </div>
                                    </>
                                )}
                            </>
                            {/* ) : (
                                <TextPlaceHolder />
                            )} */}
                        </PreSaleStatus>
                    </Flex>
                    <Flex>
                        <PreSaleStatus margin="0.75rem 0rem 0rem 0rem">
                            {/* {distributionTime ? ( */}
                            <Button
                                isDarkTheme={isDarkTheme}
                                label={'View Airdrop'}
                                ifClicked={() => {
                                    let _airdropInfo = {}
                                    _airdropInfo.airdropAddress = value?.airdropAddress
                                    _airdropInfo.isPrivate = value.isPrivate
                                    _airdropInfo.chainId = value.chainId
                                    setAirdropInfo(_airdropInfo)
                                    _dispatch(openMainPage(false))
                                }}></Button>
                            {/* ) : (
                                <TextPlaceHolder />
                            )} */}
                        </PreSaleStatus>
                    </Flex>
                </CardBody>
            </Card>
        </CustomCol>
    )
}

const CustomCol = styled(Col)`
    margin-bottom: 1rem;
    cursor: pointer;
`

const Profile = styled.div`
    display: flex;
    // align-items: center;
    margin-bottom: 1rem;
    // justify-content: center;
    flex-direction: column;
    padding: 0.2rem 0.3rem 1.5rem 0.3rem;
    background: ${palette.dark};
    position: relative;
`

const ProfileBlur = styled.div`
    background: ${({src}) => (src ? `url(${src}) no-repeat` : `${palette.dark}`)};
    background-size: cover;
    background-position: center;
    filter: blur(1rem);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
`

const ProfileContent = styled.div`
    position: relative;
    margin: 0.2rem 0rem 0.5rem 0rem;
    z-index: 2;
`

const ProfileImage = styled.div`
    width: 6.3rem;
    height: 6.3rem;
    background: ${({src, isDarkTheme}) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.charcol_light}` : `${palette.blue_light}`)};
    background-size: cover;
    background-position: center;
    border: 0.1rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.blue}`)};
    border-radius: 3rem;
    z-index: 2;
`

const BlockItems = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: -1.9rem;
`

const Status = styled.div`
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 0.8rem;
    line-height: 1.38rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    padding: 0.2rem 0.5rem;
    width: fit-content;
    border-top-left-radius: ${({shape}) => (shape ? `0.4rem` : `0rem`)};
    border-bottom-left-radius: ${({shape}) => (shape ? `0.4rem` : `0rem`)};
    border-top-right-radius: ${({shape}) => (shape ? `0rem` : `0.4rem`)};
    border-bottom-right-radius: ${({shape}) => (shape ? `0rem` : `0.4rem`)};
    text-transform: uppercase;
    color: ${palette.white};
    z-index: 2;
    background: ${({status}) => (status == 'live' ? `${palette.green_gradient}` : status == 'upcoming' ? `${palette.yellow_gradient}` : status == 'fair' ? `${palette.blue_gradient_list}` : status == 'private' ? `${palette.theme_Text}` : `${palette.red_gradient}`)};
`

const Card = styled.div`
    overflow: hidden;
    position: relative;
    border-radius: 1rem;
    box-sizing: border-box;
    // background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
    border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.blue}`)};
    // width: 100%;
    box-shadow: 0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%);
    cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
    transition: all 0.4s ease-in-out;
    &:hover {
        box-shadow: ${({isDarkTheme}) => (isDarkTheme ? `0 0 4px ${palette.dark}, 0 0 7px ${palette.dark_gray}` : `0 0 1px rgb(0 0 0 / 17%), 0 8px 8px rgb(0 0 0 / 8%), 0 10px 12px rgb(0 0 0 / 0%), 0 14px 16px rgb(0 0 0 / 2%)`)};
    }
`

const Content = styled.span`
    display: flex;
    flex-direction: ${({directionColumn}) => (directionColumn ? 'column' : 'row')};
    font-size: 1rem;
    z-index: 2;
`

const CardBody = styled.div`
    position: relative;
    padding: 0.5rem 2rem 2rem 2rem;
    ${media.xs`
        padding: 0.5rem 1rem 1rem 1rem;
    `};
    ${media.sm`
        padding: 0.5rem 2rem 2rem 2rem;
    `};
`

const Label = styled.span`
    font-size: 0.93rem;
    font-weight: bold;
    display: block;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
`

const CoverLabel = styled(Label)`
    color: ${palette.off_white};
`

const Flex = styled.div`
    // border-top: 0.1rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: ${({margin}) => (margin ? `${margin}` : '0')};
`

const PreSaleStatus = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${({margin}) => (margin ? `${margin}` : '0')};
`

const Text = styled.div`
    font-size: 14px;
    line-height: 1.5;
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.pure_black}`)};
    display: inline-block;
    // width: calc(100% - 3rem);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: ${({isCentered}) => (isCentered ? `center` : `left`)};
`

const Name = styled(Text)`
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_white}`)};
    z-index: 2;
`

const AddressText = styled.a`
    font-size: 14px;
    line-height: 1.5;
    margin-left: ${({margin}) => (margin ? `${'0.5rem'}` : '0')};
    color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    z-index: 2;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    word-wrap: break-word;
    &:hover {
        color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
        opacity: 0.8;
    }
`

const NetworkIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    bottom: 0;
    right: 0.5rem;
    border-radius: 2rem;
    position: absolute;
`

export default AirdropList
