import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { palette } from '../assets/common'
import { media } from 'styled-bootstrap-grid'
import { btnReset, v } from '../styles/variables'
import { AiOutlineApartment, AiOutlineHome, AiOutlineLeft, AiOutlineSearch, AiOutlineSetting, AiOutlineRocket, AiFillUnlock, AiOutlineSafety, AiOutlineBarChart } from 'react-icons/ai'

import { MdLogout, MdKeyboardArrowUp, MdKeyboardArrowDown, MdOutlineAnalytics, MdCloudDone, MdOutlineLeaderboard } from 'react-icons/md'
import { BsPeople, BsSunFill, BsMoonFill, BsCoin, BsPersonCheck } from 'react-icons/bs'

import { ThemeContext } from '../App'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { switchTheme, setDisconnected, openSideMenu, openMainPage } from '../actions/authActions'
import useWindowSize from '../hooks/useWindowSize'
import styled from 'styled-components'
import { tradingChartUrl } from '../config'

const Sidebar = () => {
    const _dispatch = useDispatch()
    const { width, height } = useWindowSize()
    let user = useSelector((state) => state.auth.user)
    const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
    const isSideMenuOpen = useSelector((state) => state.auth.openSideMenu)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [dropOne, setDropOne] = useState(false)
    const [dropTwo, setDropTwo] = useState(false)
    const [dropThree, setDropThree] = useState(false)
    const [dropFour, setDropFour] = useState(false)
    const [dropFive, setDropFive] = useState(false)
    const [dropSix, setDropSix] = useState(false)
    const [dropKYC, setDropKYC] = useState(false)
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const { pathname } = useLocation()

    useEffect(() => {
        if (isSideMenuOpen) {
            setSidebarOpen(true)
        } else if (isSideMenuOpen == false) {
            setSidebarOpen(false)
        }
    }, [isSideMenuOpen])

    const goBackToMainPage = () => {
        _dispatch(openMainPage(true))
        if (width <= 992) {
            _dispatch(openSideMenu(false))
        }
    }

    return (
        <SideNavBar isOpen={sidebarOpen}>
            <SSidebar isOpen={sidebarOpen} isDarkTheme={isDarkTheme}>
                {width > 992 && (
                    <SSidebarButton
                        isDarkTheme={isDarkTheme}
                        isOpen={sidebarOpen}
                        onClick={() => {
                            setSidebarOpen((p) => !p)
                            isSideMenuOpen ? _dispatch(openSideMenu(false)) : _dispatch(openSideMenu(true))
                        }}>
                        <AiOutlineLeft />
                    </SSidebarButton>
                )}
                <ScrollingContent isDarkTheme={isDarkTheme}>
                    <SLinkContainer
                        isOpen={sidebarOpen}
                        isDarkTheme={isDarkTheme}
                        isActive={pathname === '/'}
                        onClick={() => {
                            if (sidebarOpen) {
                                setDropDownOpen(false)
                            } else {
                                setSidebarOpen(true)
                                _dispatch(openSideMenu(true))
                            }
                        }}>
                        <SLink to={'/'} style={!sidebarOpen ? { width: `fit-content` } : {}} onClick={goBackToMainPage}>
                            <SLinkIcon>
                                <AiOutlineHome />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Home</SLinkLabel>
                                </>
                            )}
                        </SLink>
                    </SLinkContainer>
                    <SLinkDropMenu isOpen={sidebarOpen}>
                        <SDrop
                            isDarkTheme={isDarkTheme}
                            style={!sidebarOpen ? { width: `fit-content` } : {}}
                            dropOpen={dropOne}
                            onClick={() => {
                                if (sidebarOpen) {
                                    setDropOne(!dropOne)
                                } else {
                                    setSidebarOpen(true)
                                    _dispatch(openSideMenu(true))
                                }
                            }}
                            isActive={pathname === '/create-presale' || pathname === '/view-presales'}>
                            <SLinkIcon>
                                <AiOutlineRocket />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Presale</SLinkLabel>
                                    <SLinkNotification> {dropOne ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</SLinkNotification>
                                </>
                            )}
                        </SDrop>
                        {sidebarOpen && (
                            <DropMenu isDarkTheme={isDarkTheme} sidebarOpen={sidebarOpen}>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/create-presale'} isActive={pathname === '/create-presale'} onClick={goBackToMainPage}>
                                    Create Presale
                                </DropMenuList>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/view-presales'} isActive={pathname === '/view-presales'} onClick={goBackToMainPage}>
                                    Presale List
                                </DropMenuList>
                            </DropMenu>
                        )}
                    </SLinkDropMenu>
                    <SLinkDropMenu isOpen={sidebarOpen}>
                        <SDrop
                            isDarkTheme={isDarkTheme}
                            style={!sidebarOpen ? { width: `fit-content` } : {}}
                            dropOpen={dropSix}
                            onClick={() => {
                                if (sidebarOpen) {
                                    setDropSix(!dropSix)
                                } else {
                                    setSidebarOpen(true)
                                    _dispatch(openSideMenu(true))
                                }
                            }}
                            isActive={pathname === '/create-fair-sale' || pathname === '/view-fair-sales'}>
                            <SLinkIcon>
                                <AiOutlineRocket />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Fair Presale</SLinkLabel>
                                    <SLinkNotification> {dropSix ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</SLinkNotification>
                                </>
                            )}
                        </SDrop>
                        {sidebarOpen && (
                            <DropMenu isDarkTheme={isDarkTheme} sidebarOpen={sidebarOpen}>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/create-fair-sale'} isActive={pathname === '/create-fair-sale'} onClick={goBackToMainPage}>
                                    Create Fair Presale
                                </DropMenuList>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/view-fair-sales'} isActive={pathname === '/view-fair-sales'} onClick={goBackToMainPage}>
                                    Fair Presale List
                                </DropMenuList>
                            </DropMenu>
                        )}
                    </SLinkDropMenu>
                    <SLinkDropMenu isOpen={sidebarOpen}>
                        <SDrop
                            isDarkTheme={isDarkTheme}
                            style={!sidebarOpen ? { width: `fit-content` } : {}}
                            dropOpen={dropThree}
                            onClick={() => {
                                if (sidebarOpen) {
                                    setDropThree(!dropThree)
                                } else {
                                    setSidebarOpen(true)
                                    _dispatch(openSideMenu(true))
                                }
                            }}
                            isActive={pathname === '/create-private-sale' || pathname === '/view-private-sales'}>
                            <SLinkIcon>
                                <AiOutlineSafety />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Private Presale</SLinkLabel>
                                    <SLinkNotification> {dropThree ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</SLinkNotification>
                                </>
                            )}
                        </SDrop>
                        {sidebarOpen && (
                            <DropMenu isDarkTheme={isDarkTheme} sidebarOpen={sidebarOpen}>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/create-private-sale'} isActive={pathname === '/create-private-sale'} onClick={goBackToMainPage}>
                                    Create Private Presale
                                </DropMenuList>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/view-private-sales'} isActive={pathname === '/view-private-sales'} onClick={goBackToMainPage}>
                                    Private Presale List
                                </DropMenuList>
                            </DropMenu>
                        )}
                    </SLinkDropMenu>
                    <SLinkContainer
                        isOpen={sidebarOpen}
                        isDarkTheme={isDarkTheme}
                        isActive={pathname === '/leaderboard'}
                        onClick={() => {
                            if (sidebarOpen) {
                                setDropDownOpen(false)
                            } else {
                                setSidebarOpen(true)
                                _dispatch(openSideMenu(true))
                                _dispatch(openMainPage(true))
                            }
                        }}>
                        <SLink to={'/leaderboard'} style={!sidebarOpen ? { width: `fit-content` } : {}} onClick={goBackToMainPage}>
                            <SLinkIcon>
                                <MdOutlineLeaderboard />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Leaderboard</SLinkLabel>
                                </>
                            )}
                        </SLink>
                    </SLinkContainer>
                    <SDivider isDarkTheme={isDarkTheme} />
                    <SLinkDropMenu isOpen={sidebarOpen}>
                        <SDrop
                            isDarkTheme={isDarkTheme}
                            style={!sidebarOpen ? { width: `fit-content` } : {}}
                            dropOpen={dropFour}
                            onClick={() => {
                                if (sidebarOpen) {
                                    setDropFour(!dropFour)
                                } else {
                                    setSidebarOpen(true)
                                    _dispatch(openSideMenu(true))
                                }
                            }}
                            isActive={pathname === '/create-lock' || pathname === '/view_all_locks' || pathname === '/liquidity'}>
                            <SLinkIcon>
                                <AiFillUnlock />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Lock Tokens</SLinkLabel>
                                    <SLinkNotification> {dropFour ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</SLinkNotification>
                                </>
                            )}
                        </SDrop>
                        {sidebarOpen && (
                            <DropMenu isDarkTheme={isDarkTheme} sidebarOpen={sidebarOpen}>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/create-lock'} isActive={pathname === '/create-lock'} onClick={goBackToMainPage}>
                                    Create Lock
                                </DropMenuList>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/view-all-locks'} isActive={pathname === '/view-all-locks'} onClick={goBackToMainPage}>
                                    View All Locks
                                </DropMenuList>
                            </DropMenu>
                        )}
                    </SLinkDropMenu>
                    <SLinkDropMenu isOpen={sidebarOpen}>
                        <SDrop
                            isDarkTheme={isDarkTheme}
                            style={!sidebarOpen ? { width: `fit-content` } : {}}
                            dropOpen={dropFive}
                            onClick={() => {
                                if (sidebarOpen) {
                                    setDropFive(!dropFive)
                                } else {
                                    setSidebarOpen(true)
                                    _dispatch(openSideMenu(true))
                                }
                            }}
                            isActive={pathname === '/create-airdrop' || pathname === '/airdrop-list'}>
                            <SLinkIcon>
                                <MdCloudDone />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Airdrop</SLinkLabel>
                                    <SLinkNotification> {dropFive ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</SLinkNotification>
                                </>
                            )}
                        </SDrop>
                        {sidebarOpen && (
                            <DropMenu isDarkTheme={isDarkTheme} sidebarOpen={sidebarOpen}>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/create-airdrop'} isActive={pathname === '/create-airdrop'} onClick={goBackToMainPage}>
                                    Create Airdrop
                                </DropMenuList>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/airdrop-list'} isActive={pathname === '/airdrop-list'} onClick={goBackToMainPage}>
                                    Airdrop List
                                </DropMenuList>
                            </DropMenu>
                        )}
                    </SLinkDropMenu>
                    <SDivider isDarkTheme={isDarkTheme} />
                    <SLinkDropMenu isOpen={sidebarOpen}>
                        <SDrop
                            isDarkTheme={isDarkTheme}
                            style={!sidebarOpen ? { width: `fit-content` } : {}}
                            dropOpen={dropTwo}
                            onClick={() => {
                                if (sidebarOpen) {
                                    setDropTwo(!dropTwo)
                                } else {
                                    setSidebarOpen(true)
                                    _dispatch(openSideMenu(true))
                                }
                            }}
                            isActive={pathname === '/create-token' || pathname === '/my-tokens'}>
                            <SLinkIcon>
                                <BsCoin />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Token</SLinkLabel>
                                    <SLinkNotification> {dropTwo ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</SLinkNotification>
                                </>
                            )}
                        </SDrop>
                        {sidebarOpen && (
                            <DropMenu isDarkTheme={isDarkTheme} sidebarOpen={sidebarOpen}>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/create-token'} isActive={pathname === '/create-token'} onClick={goBackToMainPage}>
                                    Create Token
                                </DropMenuList>
                                <DropMenuList isDarkTheme={isDarkTheme} to={'/my-tokens'} isActive={pathname === '/my-tokens'} onClick={goBackToMainPage}>
                                    My Tokens
                                </DropMenuList>
                            </DropMenu>
                        )}
                    </SLinkDropMenu>
                    <SDivider isDarkTheme={isDarkTheme} />
                </ScrollingContent>
                <STheme>
                    <SLinkContainer
                        isOpen={sidebarOpen}
                        isDarkTheme={isDarkTheme}
                        isActive={pathname === '/tradingchart'}
                        onClick={() => {
                            if (sidebarOpen) {
                                setDropDownOpen(false)
                            } else {
                                setSidebarOpen(true)
                                _dispatch(openSideMenu(true))
                            }
                        }}>
                        <Ref href={tradingChartUrl} target="_blank" style={!sidebarOpen ? { width: `fit-content` } : {}} onClick={goBackToMainPage}>
                            <SLinkIcon>
                                <AiOutlineBarChart />
                            </SLinkIcon>
                            {sidebarOpen && (
                                <>
                                    <SLinkLabel>Trading Chart</SLinkLabel>
                                </>
                            )}
                        </Ref>
                    </SLinkContainer>
                </STheme>
            </SSidebar>
        </SideNavBar>
    )
}

const SideNavBar = styled.div`
    width: ${({ isOpen }) => (!isOpen ? `4rem` : '15.625rem')};
    position: relative;
    @media screen and (min-width: 0px) and (max-width: 992px) {
        position: absolute;
        display: ${({ isOpen }) => (isOpen ? `block` : 'none')};
    }
`

const SSidebar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark_mode_shadow}` : `${palette.white_mode_shadow}`)};
    width: ${({ isOpen }) => (!isOpen ? `auto` : '13.75rem')};
    background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
    height: calc(100vh - 80px);
    bottom: 0rem;
    // padding: 0rem 0.75rem 0.75rem 0.75rem;
    position: fixed;
    z-index: 99;
    padding-top: 80px;

    overflow-x: hidden;
    overflow-y: auto;

    ${media.xs`
    padding-top: 6px;
    right:0;
    left:auto;
    `}

    ${media.sm`
    padding-top: 6px;
    right:0;
    left:auto;
    `}

    ${media.md`
    padding-top: 6px;
    right:0;
    left:auto;
    `}

    ${media.lg`
    height: 100%;
    padding-top: 80px;
    left:0;
    right:auto;
    `}

    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    }
`

const ScrollingContent = styled.div`
    padding: 0rem 0.75rem 0.75rem 0.75rem;
    height: 94%;
    overflow-y: auto;
    overflow-x: hidden;

    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue_bright}` : `${palette.blue}`)};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue_bright}` : `${palette.blue}`)};
    }
`

const SSidebarButton = styled.button`
    ${btnReset};
    position: absolute;
    top: 1.5rem;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0rem 0.75rem 0.75rem 0.75rem;
    background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black2}` : `${palette.blue_light}`)};
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.blue}`)};
    box-shadow: 0 0 4px ${({ theme }) => palette.aqua_blue}, 0 0 7px ${({ theme }) => palette.aqua_blue};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: ${({ isOpen }) => (!isOpen ? `rotate(180deg)` : `initial`)};
`

const Ref = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    padding: calc(${v.smSpacing} - 2px) 0;
`

const SLogo = styled.div`
    width: 52px;

    img {
        max-width: 100%;
        height: auto;
    }
    cursor: pointer;

    // margin-bottom: ${v.lgSpacing};
`

const SSearch = styled.div`
    background: ${({ theme }) => theme.bgAlpha};
    border: 1px solid ${({ theme }) => theme.bg3};
    border-radius: ${v.borderRadius};
    input {
        padding: 0 ${v.smSpacing};
        font-family: inherit;
        letter-spacing: inherit;
        font-size: 16px;
        width: 100%;
        outline: none;
        border: none;
        color: inherit;
        background: transparent;
    }
    display: flex;
`

const SSearchIcon = styled.button`
    ${btnReset};
    padding: calc(${v.mdSpacing} - 2px) ${v.mdSpacing};
    display: flex;
    cursor: pointer;

    svg {
        font-size: 20px;
    }
`

const SDivider = styled.div`
    height: 1px;
    width: 100%;
    // background: ${({ theme }) => theme.bg3};
    background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.pure_black}`)};
    margin: 12px 0;
`

const SLinkContainer = styled.div`
    position: relative;
    background: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.blue_gradient}` : `${palette.blue_light}`) : `transparent `)};
    color: ${({ isDarkTheme, isActive }) => (isDarkTheme ? `${palette.white}` : isActive ? `${palette.blue}` : `${palette.pure_black}`)};
    font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
    border-radius: ${v.borderRadius};
    margin: 8px 0;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'flex')};
    justify-content: ${({ isOpen }) => (isOpen ? 'start' : 'center')};
    & > a:hover {
        color: ${({ isDarkTheme, isActive }) => (isDarkTheme ? (isActive ? `${palette.white}` : `${palette.aqua_blue}`) : isActive ? `${palette.blue}` : `${palette.blue}`)} !important;
    }
`

const SLinkDropMenu = styled.div`
    position: relative;
    border-radius: ${v.borderRadius};
    margin: 8px 0;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'flex')};
    justify-content: ${({ isOpen }) => (isOpen ? 'start' : 'center')};
`

const DropMenu = styled.div`
    display: none;
    position: relative;
    background: white;
    border-radius: ${v.borderRadius};
    background: ${({ isDarkTheme, isActive }) => (isActive ? `${palette.blue_light}` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};
    // position: ${({ sidebarOpen }) => (!sidebarOpen ? `absolute` : 'relative')};
    // background: ${({ sidebarOpen }) => (!sidebarOpen ? `white` : '')};
    // border-radius: ${v.borderRadius};
    // top: ${({ sidebarOpen }) => (!sidebarOpen ? `2rem` : '')};
    // width: ${({ sidebarOpen }) => (!sidebarOpen ? `15rem` : 'auto')};
    // right: ${({ sidebarOpen }) => (!sidebarOpen ? `-17rem` : '')};
`

const DropMenuList = styled(Link)`
    color: ${({ isDarkTheme, isActive }) => (isDarkTheme ? `${palette.white}` : isActive ? `${palette.blue}` : `${palette.pure_black}`)};
    font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
    background: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.blue_gradient}` : `${palette.blue_light}`) : `transparent `)};
    border-radius: ${v.borderRadius};
    display: flex;
    justify-content: start;
    text-decoration: none;
    font-size: 0.9rem;
    padding: 0.8rem 1rem 0.8rem 2rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    :hover {
        color: ${({ isDarkTheme, isActive }) => (isDarkTheme ? (isActive ? `${palette.white}` : `${palette.aqua_blue}`) : `${palette.blue}`)};
    }
`

const SLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    padding: calc(${v.smSpacing} - 2px) 0;
`

const SDrop = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    padding: calc(${v.smSpacing} - 2px) 0;
    cursor: pointer;
    color: ${({ isDarkTheme, isActive }) => (isDarkTheme ? (isActive ? `${palette.aqua_blue}` : `${palette.white}`) : isActive ? `${palette.blue}` : `${palette.pure_black}`)};
    font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};

    & + div {
        display: ${({ dropOpen }) => (dropOpen ? 'block' : 'none')};
    }
    :hover {
        color: ${({ isDarkTheme, isActive }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
    }
`

const SLinkIcon = styled.div`
    padding: 0.5rem;
    display: flex;
    svg {
        font-size: 1.2rem;
    }
`

const SLinkLabel = styled.span`
    display: block;
    flex: 1;
    font-size: 0.9rem;
    margin-left: ${v.smSpacing};
`

const SLinkNotification = styled.span`
    font-size: 14px;
    padding: calc(${v.smSpacing} / 2) ${v.smSpacing};
    // color: ${palette.blue};
    // margin-right: ${v.mdSpacing};
`

const STheme = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    padding: 0rem 0.75rem 0.75rem 0.75rem;
`

export default Sidebar
